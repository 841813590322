import * as React from 'react';
import { Textbox } from 'src/react/common/components/Textbox';
import './TextSearch.scss';

type Props = React.InputHTMLAttributes<HTMLInputElement>;

export function TextSearch(props: Props): JSX.Element {
    return (
        <div className="text-search">
            <Textbox {...props} />
            <i className="form-control-feedback si si-Search"/>
        </div>
    );
}
