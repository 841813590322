import { partialReducers } from 'src/utils/partialReducers';
import { ItemFormState } from 'src/react/itemForm/state/ItemFormState';
import { itemFormLoadReducer } from 'src/react/itemForm/reducers/itemFormLoadReducer';
import { itemFormSaveReducer } from 'src/react/itemForm/reducers/itemFormSaveReducer';
import { itemFormRestoreReducer } from 'src/react/itemForm/reducers/itemFormRestoreReducer';
import { itemFormSaveRestoredReducer } from 'src/react/itemForm/reducers/itemFormSaveRestoredReducer';
import { itemFormLoadItemReducer } from 'src/react/itemForm/reducers/itemFormLoadItemReducer';

export const itemFormReducer = partialReducers<ItemFormState>({
    load: itemFormLoadReducer,
    save: itemFormSaveReducer,
    restore: itemFormRestoreReducer,
    saveRestored: itemFormSaveRestoredReducer,
    loadItem: itemFormLoadItemReducer,
});
