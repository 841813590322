import './ItemFormTagsInput.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { bind } from 'lodash-decorators/bind';
import { memoize } from 'lodash-decorators/memoize';
import { noop } from 'src/utils/noop';
import { classList } from 'src/utils/classList';
import { Textbox } from 'src/react/common/components/Textbox';
import { HtmlButton } from 'src/react/common/components/Button';
import { InputGroup, InputGroupItem } from 'src/react/common/components/InputGroup';

type Props = {
    readonly name?: string;
    readonly className?: string;
    readonly disabled?: boolean;
    readonly readOnly?: boolean;
    readonly value: ReadonlyArray<string>;
    readonly onBlur?: () => void;
    readonly onChange?: (value: ReadonlyArray<string>) => void;
    readonly onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
};
type State = {
    readonly input: string;
};

export class ItemFormTagsInput extends React.Component<Props, State> {
    public state: State = {
        input: '',
    };

    public render(): JSX.Element {
        const { input } = this.state;
        const { name, className, disabled, readOnly, value, onFocus, onBlur } = this.props;

        return (
            <div className={classList('item-form-tags-input', className)} data-field={name}>
                <InputGroup className="item-form-tags-input__input">
                    <InputGroupItem className="item-form-tags-input__col item-form-tags-input__col--input">
                        <Textbox className="item-form-tags-input__input-text"
                                 disabled={disabled}
                                 readOnly={readOnly}
                                 value={input}
                                 onChange={this.handleInput}
                                 onKeyDown={this.handleEnter}
                                 onFocus={onFocus}
                                 onBlur={onBlur}/>
                    </InputGroupItem>
                    <InputGroupItem className="item-form-tags-input__col item-form-tags-input__col--button">
                        <HtmlButton block={true}
                                    intent="none"
                                    disabled={this.isAddDisabled(input)}
                                    onClick={this.handleAddClick}
                                    className="item-form-tags-input__input-button">
                            <FormattedMessage id="itemForm_addTag"/>
                        </HtmlButton>
                    </InputGroupItem>
                </InputGroup>
                <div className="item-form-tags-input__value">
                    {value.map((item) => (
                        <span key={item} className="item-form-tags-input__value-item">
                            <a className="item-form-tags-input__value-remove"
                               onClick={this.getRemoveClickHandler(item)}><i className="fa fa-times" /></a>
                            <span className="item-form-tags-input__value-text">{item}</span>
                        </span>
                    ))}
                </div>
            </div>
        );
    }

    @bind
    private handleInput(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({ input: event.target.value });
    }

    @bind
    private handleEnter(event: React.KeyboardEvent<HTMLInputElement>): void {
        if (event.keyCode !== 13) {
            return;
        }

        event.preventDefault();

        const { input } = this.state;
        this.addTag(input);
    }

    @bind
    private handleAddClick(): void {
        const { input } = this.state;
        this.addTag(input);
    }

    @memoize
    private getRemoveClickHandler(tag: string): () => void {
        return () => {
            this.removeTag(tag);
        };
    }

    private isAddDisabled(tag: string): boolean {
        const { disabled, readOnly } = this.props;
        if (disabled || readOnly) {
            return true;
        }

        if (tag.length < 1) {
            return true;
        }

        const { value } = this.props;
        return value.includes(tag);
    }

    private addTag(tag: string): void {
        if (this.isAddDisabled(tag)) {
            return;
        }

        const { value, onChange = noop, onBlur = noop } = this.props;

        this.setState({ input: '' });

        onChange([...value, tag]);
        onBlur();
    }

    private removeTag(tag: string): void {
        const { value, onChange = noop, onBlur = noop } = this.props;

        this.setState({ input: '' });

        onChange(value.filter((other) => other !== tag));
        onBlur();
    }
}
