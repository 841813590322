import './Button.scss';
import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { classList } from 'src/utils/classList';

type CustomProps = {
    readonly intent?: 'none' | 'secondary' | 'info' | 'success' | 'danger' | 'warning' | 'link';
    readonly shape?: 'square' | 'circle';
    readonly block?: boolean;
    readonly active?: boolean;
};

type HtmlButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & CustomProps;
export function HtmlButton(props: HtmlButtonProps): JSX.Element {
    const {
        intent = 'none',
        shape = 'square',
        block = false,
        active = false,
        type = 'button',
        className,
        children,
        // tslint:disable-next-line:trailing-comma
        ...buttonProps
    } = props;
    const buttonClass = classList(
        className,
        'xss-button',
        'xss-button--btn',
        `xss-button--${shape}`,
        `xss-button--${intent}`,
        {
            'xss-button--block': block,
            'xss-button--active': active,
        },
    );

    return <button {...buttonProps} type={type} className={buttonClass}>{children}</button>;
}

type LinkButtonProps = LinkProps & CustomProps;
export function LinkButton(props: LinkButtonProps): JSX.Element {
    const {
        intent = 'none',
        shape = 'square',
        block = false,
        active = false,
        className,
        children,
        // tslint:disable-next-line:trailing-comma
        ...linkProps
    } = props;
    const linkClass = classList(
        className,
        'xss-button',
        'xss-button--lnk',
        `xss-button--${shape}`,
        `xss-button--${intent}`,
        {
            'xss-button--block': block,
            'xss-button--active': active,
        },
    );

    return <Link {...linkProps} className={linkClass}>{children}</Link>;
}
