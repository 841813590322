import { IHttpResponse, IPromise } from 'angular';
import { Uuid } from 'src/types/values/Uuid';
import { Widget } from 'src/types/entities/Widget';
import { WidgetForm } from 'src/types/dto/WidgetForm';
import { WidgetFilter } from 'src/types/dto/WidgetFilter';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { Config } from 'src/types/constants/Config';
import { IApiService } from 'src/modules/api/ApiService';

export interface IWidgetService {
    get(
        id: Uuid,
    ): IPromise<Widget>;

    list(
        filter: WidgetFilter,
    ): IPromise<ListWithTotal<Widget>>;

    create(
        form: Readonly<WidgetForm>,
    ): IPromise<Widget>;

    update(
        widget: Readonly<Widget>,
        form: Readonly<WidgetForm>,
    ): IPromise<Widget>;

    delete(
        widget: Readonly<Widget>,
    ): IPromise<void>;
}

export class WidgetService implements IWidgetService {
    public static $inject = [
        'config',
        'apiService',
    ];
    public constructor(
        private config: Config,
        private apiService: IApiService,
    ) {
    }

    public get(
        id: Uuid,
    ): IPromise<Widget> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(id),
        ].join('/');

        return this.apiService
            .makeGETRequest<Widget>(requestUrl)
            .then(extractResponseData);
    }

    public list(
        filter: WidgetFilter,
    ): IPromise<ListWithTotal<Widget>> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
        ].join('/');

        return this.apiService
            .makeGETRequest<ListWithTotal<Widget>>(requestUrl, { params: filter })
            .then(extractResponseData);
    }

    public create(
        form: Readonly<WidgetForm>,
    ): IPromise<Widget> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
        ].join('/');

        return this.apiService
            .makePOSTRequest<WidgetForm, Widget>(requestUrl, form)
            .then(extractResponseData);
    }

    public delete(
        widget: Readonly<Widget>,
    ): IPromise<void> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
        ].join('/');

        return this.apiService
            .makeDELETERequest<void>(requestUrl)
            .then(extractResponseData);
    }

    public update(
        widget: Readonly<Widget>,
        form: Readonly<WidgetForm>,
    ): IPromise<Widget> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
        ].join('/');

        return this.apiService
            .makePUTRequest<WidgetForm, Widget>(requestUrl, form)
            .then(extractResponseData);
    }
}

function extractResponseData<T>(response: IHttpResponse<T>): T {
    return response.data;
}
