import './DemoPageItemCustomFields.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Item } from 'src/types/entities/Item';
import { CustomField } from 'src/types/entities/CustomField';
import { CustomFieldValue } from 'src/types/values/CustomFieldValue';

type Props = {
    readonly item: Item;
    readonly customFields: ReadonlyArray<CustomField>;
};

function booleanToString(value: boolean | null): JSX.Element {
    return value
        ? <FormattedMessage id="searchDemo_booleanCustomFieldTrue" />
        : <FormattedMessage id="searchDemo_booleanCustomFieldFalse" />;
}

function formatCustomField(
    widgetCustomField: CustomField,
    customFieldValue: CustomFieldValue | null | undefined,
): React.ReactNode {
    if (
        customFieldValue === null ||
        customFieldValue === undefined ||
        customFieldValue === ''
    ) {
        return '-';
    }

    switch (widgetCustomField.type) {
        case 'keyword':
        case 'text':
            return typeof customFieldValue === 'string'
                ? customFieldValue
                : '-';

        case 'boolean':
            return typeof customFieldValue === 'boolean'
                ? booleanToString(customFieldValue)
                : '-';

        case 'tags':
            return Array.isArray(customFieldValue) && customFieldValue.length > 0
                ? customFieldValue.join(', ')
                : '-';

        default:
            return '-';
    }
}

export function DemoPageItemCustomFields({ item, customFields }: Props): JSX.Element {
    const formatted =
        customFields.map((customField) => ({
            id: `${item.id}-${customField.id}`,
            name: customField.name,
            value: formatCustomField(customField, item.customFields[customField.id]),
        }));

    return (
        <div className="demo-page-item-custom-fields">
            {formatted.map((customField) => (
                <span className="demo-page-item-custom-fields__field" key={customField.id}>
                    <span className="demo-page-item-custom-fields__field-name">{customField.name}:</span>
                    <span className="demo-page-item-custom-fields__field-value">{customField.value}</span>
                </span>
            ))}
        </div>
    );
}
