import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { NativeError } from 'src/errors/NativeError';
import { Item } from 'src/types/entities/Item';
import { ITEM_FORM_LOAD_ACTIONS } from 'src/react/itemForm/actions/ItemFormLoadActions';
import { itemFormMapToApp } from 'src/react/itemForm/services/itemFormMapToApp';
import { ItemFormValue } from 'src/react/itemForm/types/ItemFormValue';

export function* itemFormLoadSaga(
    { data: item }: ActionRequest<Item | null>,
): SagaIterator {
    try {
        yield put(ITEM_FORM_LOAD_ACTIONS.pending());

        const mappedForm: ItemFormValue = yield call(itemFormMapToApp, item);

        yield put(ITEM_FORM_LOAD_ACTIONS.success(mappedForm));
    } catch (error) {
        yield put(ITEM_FORM_LOAD_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
