import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { Widget } from 'src/types/entities/Widget';
import { WidgetForm } from 'src/types/dto/WidgetForm';
import { CustomField } from 'src/types/entities/CustomField';
import { SearchMatchMode } from 'src/types/values/SearchMatchMode';
import { IWidgetEndpoint } from 'src/react/api/endpoints/WidgetEndpoint';
import { getWidgetEndpoint } from 'src/react/api/selectors/getEndpoint';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { SettingsFormData } from 'src/react/settings/types/SettingsFormData';
import { WidgetCustomFieldBoost } from 'src/types/values/WidgetCustomFieldBoost';

export function* settingsFormSaveWidget(
    formData: SettingsFormData,
    customFields: ReadonlyArray<CustomField>,
): SagaIterator {
    const widget: Widget = yield select(getSelectedWidget);
    const widgetEndpoint: IWidgetEndpoint = yield select(getWidgetEndpoint);

    const widgetForm: WidgetForm = {
        organizationId: widget.organizationId,

        name: formData.widget.name,
        website: formData.widget.website,

        apiKey: widget.apiKey,
        isActive: widget.isActive,
        indexLanguage: widget.indexLanguage,

        defaultImage: formData.crawler.defaultImage,

        urlBoost: formData.urlBoosts.map((urlBoost) => ({
            boost: urlBoost.urlBoost,
            pattern: urlBoost.urlPattern,
        })),
        fieldBoost: {
            title: formData.fieldBoost.titleBoost,
            content: formData.fieldBoost.contentBoost,
            description: formData.fieldBoost.descriptionBoost,
        },
        customBoosts: formData.customBoosts.reduce<WidgetCustomFieldBoost[]>((result, fieldBoost) => {
            const customField = customFields.find((it) => it.name === fieldBoost.fieldName);
            if (customField) {
                result.push({
                    customFieldId: customField.id,
                    customFieldBoost: fieldBoost.fieldBoost,
                });
            }

            return result;
        }, []),
        includeToFacets: [...formData.includeToFacets],
        includeToRecommendations: [...formData.includeToRecommendations],
        autocomplete: {
            keyPhrase: formData.autocomplete.useKeyPhrase,
            itemTitle: formData.autocomplete.useItemTitle,
        },
        searchParams: {
            enableFuzziness: formData.searchParams.enableFuzziness,
            searchMatchMode: formData.searchParams.enablePrefixMatch
                ? SearchMatchMode.Prefix
                : SearchMatchMode.FullText,
        },
        htmlTemplates: {
            ...formData.integration.htmlTemplates,
        },
        feedbackSettings: {
            general: { ...formData.integration.feedbackSettings.general },
            noResults: { ...formData.integration.feedbackSettings.noResults },
        },

        crawlerLimit: formData.crawler.limit || 0,
        crawlerDepth: formData.crawler.depth || 0,
        crawlerEnabled: formData.crawler.cronjobEnabled,
        crawlerExclude: formData.crawler.exclude,
        crawlerWhiteList: formData.crawler.whiteList,
        crawlerDomains: formData.crawler.domains,
        crawlerPages: formData.crawler.pages,
        parserConfig: formData.crawler.parserConfig || null,
        ignoreRobotsTxt: formData.crawler.ignoreRobotsTxt,
    };

    yield call({
        fn: widgetEndpoint.update,
        context: widgetEndpoint,
    }, widget.id, widgetForm, {});
}
