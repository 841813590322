import { AnyAction } from 'redux';
import { OrganizationListState } from 'src/react/organization/state/OrganizationListState';
import {
    ORGANIZATION_LIST_TEAM_CLOSE_ACTIONS,
    ORGANIZATION_LIST_TEAM_CREATE_ACTIONS,
    ORGANIZATION_LIST_TEAM_DELETE_ACTIONS,
    ORGANIZATION_LIST_TEAM_OPEN_ACTIONS,
} from 'src/react/organization/actions/OrganizationListTeamActions';
import { assertNotNull } from 'src/utils/assertion';

export function organizationListTeamReducer(
    state: OrganizationListState,
    action: AnyAction,
): OrganizationListState {
    if (ORGANIZATION_LIST_TEAM_OPEN_ACTIONS.isSuccess(action)) {
        return {
            ...state,
            modalState: {
                type: 'team',
                organization: action.data,
            },
        };
    }

    if (ORGANIZATION_LIST_TEAM_CLOSE_ACTIONS.isSuccess(action)) {
        return {
            ...state,
            modalState: {
                type: 'none',
            },
        };
    }

    if (ORGANIZATION_LIST_TEAM_CREATE_ACTIONS.isSuccess(action)) {
        return {
            ...state,
            modalState: {
                type: 'team',
                organization: action.data,
            },
            organizations: assertNotNull(
                state.organizations,
                'Organization list is not initialized yet',
                { state, action },
            ).map((other) => (
                other.organization.id === action.data.organization.id
                    ? action.data
                    : other
            )),
        };
    }

    if (ORGANIZATION_LIST_TEAM_DELETE_ACTIONS.isSuccess(action)) {
        return {
            ...state,
            modalState: {
                type: 'team',
                organization: action.data,
            },
            organizations: assertNotNull(
                state.organizations,
                'Organization list is not initialized yet',
                { state, action },
            ).map((other) => (
                other.organization.id === action.data.organization.id
                    ? action.data
                    : other
            )),
        };
    }

    return state;
}
