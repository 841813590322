import { bind } from 'lodash-decorators/bind';
import { StateService } from '@uirouter/core';
import { IComponentController, IPromise, IQService } from 'angular';
import { MapOf } from 'src/utils/MapOf';
import { isUrlQuery } from 'src/utils/isUrlQuery';
import { BaseError } from 'src/errors/BaseError';
import { Widget } from 'src/types/entities/Widget';
import { CustomField } from 'src/types/entities/CustomField';
import { Item } from 'src/types/entities/Item';
import { ItemType } from 'src/types/values/ItemType';
import { ItemState } from 'src/types/values/ItemState';
import { ItemFilter } from 'src/types/dto/ItemFilter';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { ISafePromiseWrapper } from 'src/modules/core/utils/SafePromiseWrapper';
import { IItemService } from 'src/modules/api/ItemService';
import { ItemPageParams } from 'src/modules/item/ItemPageParams';
import { IItemDialogService } from 'src/modules/item/ItemDialogService';
import noImagePng from 'src/images/no-image.png';
import { transformImageUrl } from 'src/utils/transformImageUrl';
import { Config } from 'src/types/constants/Config';

type ApiData = {
    total: UnsignedInteger;
    data: Item[];
    activeCount: UnsignedInteger;
    inactiveCount: UnsignedInteger;
};
type MultiAction =
    | 'activate'
    | 'deactivate'
    | 'delete';

export class ItemPageController implements IComponentController {
    public readonly pageSize = 10;

    public viewerMode!: Readonly<boolean>;
    public widget!: Readonly<Widget>;
    public params!: Readonly<ItemPageParams>;
    public customFields!: ReadonlyArray<CustomField>;

    public loading = false;
    public error: BaseError | undefined;

    public data: ReadonlyArray<Item> = [];
    public total = 0;

    public activeCount: UnsignedInteger | undefined;
    public inactiveCount: UnsignedInteger | undefined;

    public selectedItems: MapOf<boolean> = {};
    public isSelectedAll: boolean = false;
    public hasSelected: boolean = false;
    public multiAction: MultiAction | null = null;
    public multiActionState: Record<MultiAction, boolean> = {
        activate: false,
        deactivate: false,
        delete: false,
    };
    public itemTypes = [
        ItemType.Crawled,
        ItemType.Manual,
        ItemType.Edited,
    ];

    private safePerformRequest = this.safePromiseWrapper
        .wrapPromiseFunction(this.performSearchRequest);

    public static $inject = [
        '$q',
        '$state',
        'safePromiseWrapper',
        'itemService',
        'itemDialogService',
        'config',
    ];
    public constructor(
        private $q: IQService,
        private $stateService: StateService,
        private safePromiseWrapper: ISafePromiseWrapper,
        private itemService: IItemService,
        private itemDialogService: IItemDialogService,
        private config: Config,
    ) {
    }

    public $onInit(): void {
        this.updateSearchResult();
    }

    public applyFilter(filter: Partial<ItemPageParams>): void {
        this.params = { ...this.params, ...filter };
        this.$stateService.go('items', this.params);
        this.updateSearchResult();
    }
    public toggleSort(field: string): void {
        const { sort } = this.params;
        this.applyFilter({
            page: 1,
            sort: sort === field
                ? `-${field}`
                : sort === `-${field}`
                    ? ''
                    : field,
        });
    }

    public getItemImage(item: Readonly<Item>): string {
        const image =  item.image || this.widget.defaultImage || noImagePng;
        return transformImageUrl(this.config.services.imagesDomain, image, this.widget.id, '50');
    }

    public createItem(): void {
        this.itemDialogService.createItem(
            this.widget,
            this.customFields,
        ).then(() => this.updateSearchResult(), () => {});
    }
    public editItem(item: Readonly<Item>): void {
        this.itemDialogService.updateItem(
            this.widget,
            this.customFields,
            item.id,
        ).then(() => this.updateSearchResult(), () => {});
    }

    public isSortActive(field: string): boolean {
        return (
            this.params.sort === field ||
            this.params.sort === `-${field}`
        );
    }

    public isItemActive(item: Readonly<Item>): boolean {
        return item.state === ItemState.Active;
    }
    public isItemInactive(item: Readonly<Item>): boolean {
        return item.state === ItemState.Inactive;
    }

    public isItemCrawled(item: Readonly<Item>): boolean {
        return item.type === ItemType.Crawled;
    }
    public isItemManual(item: Readonly<Item>): boolean {
        return item.type === ItemType.Manual;
    }
    public isItemEdited(item: Readonly<Item>): boolean {
        return item.type === ItemType.Edited;
    }

    public toggleAll(): void {
        if (!this.isSelectedAll) {
            this.selectedItems = {};
        } else {
            this.selectedItems = this.data.reduce((result, item) => ({
                ...result,
                [item.id]: true,
            }), {});
        }

        this.checkSelectAll();
    }
    public checkSelectAll(): void {
        if (!this.data.length) {
            this.isSelectedAll = false;
            this.hasSelected = false;
            return;
        }

        this.isSelectedAll = this.data.every((item) => this.selectedItems[item.id]);
        this.hasSelected = this.data.some((item) => this.selectedItems[item.id]);

        this.multiActionState = {
            activate: this.canApplyMultiAction('activate'),
            deactivate: this.canApplyMultiAction('deactivate'),
            delete: this.canApplyMultiAction('delete'),
        };
    }

    public activate(item: Readonly<Item>): void {
        this.updateItemState(item, ItemState.Active)
            .then(() => this.updateSearchResult())
            .then(() => this.checkSelectAll());
    }
    public deactivate(item: Readonly<Item>): void {
        this.updateItemState(item, ItemState.Inactive)
            .then(() => this.updateSearchResult())
            .then(() => this.checkSelectAll());
    }
    public delete(item: Readonly<Item>): void {
        this.deleteItem(item)
            .then(() => this.updateSearchResult())
            .then(() => this.checkSelectAll());
    }

    public isSelectedItems(): boolean {
        const selected = this.getSelectedItems();
        return selected.length > 0;
    }

    public applyMultiAction(): void {
        if (!this.canApplyMultiAction(this.multiAction)) {
            return;
        }

        let applyAction: (item: Item) => IPromise<void>;
        switch (this.multiAction) {
            case 'activate':
                applyAction = (item) => this.updateItemState(item, ItemState.Active);
                break;

            case 'deactivate':
                applyAction = (item) => this.updateItemState(item, ItemState.Inactive);
                break;

            case 'delete':
                applyAction = (item) => this.deleteItem(item);
                break;

            default:
                return;
        }

        this.$q.all(
            this.getSelectedItems().map(applyAction)
        ).then(() => {
            return this.updateSearchResult();
        }).then(() => {
            this.multiAction = null;
            this.selectedItems = {};
            this.checkSelectAll();
        });
    }

    private canApplyMultiAction(multiAction: MultiAction | null): boolean {
        switch (multiAction) {
            case 'activate':
                return (
                    this.hasSelected &&
                    this.getSelectedItems().every((item) => item.state === ItemState.Inactive)
                );

            case 'deactivate':
                return (
                    this.hasSelected &&
                    this.getSelectedItems().every((item) => item.state === ItemState.Active)
                );

            case 'delete':
                return (
                    this.hasSelected &&
                    this.getSelectedItems().every((item) => item.type === ItemType.Manual)
                );

            default:
                return false;
        }
    }

    private getSelectedItems(): Item[] {
        return this.data.filter((item) => this.selectedItems[item.id]);
    }

    private updateSearchResult(): void {
        this.loading = true;

        this.safePerformRequest()
            .then(({ data, total, activeCount, inactiveCount }) => {
                this.data = data;
                this.total = total;
                this.activeCount = activeCount;
                this.inactiveCount = inactiveCount;

                this.selectedItems = {};
                this.checkSelectAll();
            })
            .then(() => {
                this.loading = false;
                this.error = undefined;
            }, (error) => {
                this.loading = false;
                this.error = error;
            });
    }

    private updateItemState(item: Readonly<Item>, state: ItemState.Active | ItemState.Inactive): IPromise<void> {
        this.loading = true;
        return this.itemService.update(this.widget, item, {
            state,
            title: item.title,
            content: item.content,
            description: item.description,
            url: item.url,
            image: item.image,
            linkText: item.linkText,
            customFields: item.customFields,
        }).then(() => {});
    }

    private deleteItem(item: Readonly<Item>): IPromise<void> {
        this.loading = true;
        return this.itemService.delete(this.widget, item);
    }

    @bind
    private performSearchRequest(): IPromise<ApiData> {
        const filter: ItemFilter = isUrlQuery(this.params.query) ? {
            query: '',
            url: this.params.query,
            exact: true,
            type: [],
            state: [this.params.state],
            id: [],
            sort: [],
            page: 0,
            pageSize: 1,
        } : {
            query: this.params.query,
            url: '',
            exact: false,
            type: this.params.type ? [this.params.type] : [],
            state: [this.params.state],
            id: [],
            sort: this.params.sort ? [this.params.sort] : [],
            page: Math.max(0, this.params.page - 1),
            pageSize: this.pageSize,
        };

        return this.$q.all([
            this.itemService.list(this.widget, {
                ...filter,
            }),
            this.itemService.list(this.widget, {
                ...filter,
                state: [ItemState.Active],
                sort: [],
                page: 0,
                pageSize: 0,
            }).then(({ total }) => total),
            this.itemService.list(this.widget, {
                ...filter,
                state: [ItemState.Inactive],
                sort: [],
                page: 0,
                pageSize: 0,
            }).then(({ total }) => total),
        ]).then(([{ data, total }, activeCount, inactiveCount]) => ({
            data,
            total,
            activeCount,
            inactiveCount,
        }));
    }
}
