import './ManualCrawler.scss';
import * as React from 'react';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { connectDecorator } from 'src/decorators/connectDecorator';
import { RootState } from 'src/react/root/state/RootState';
import { CrawlerTask } from 'src/types/entities/CrawlerTask';
import { CrawlerStatus } from 'src/types/values/CrawlerStatus';
import { HtmlButton } from 'src/react/common/components/Button';
import { ManualCrawlerState } from 'src/react/manualCrawler/state/ManualCrawlerState';
import { getManualCrawler } from 'src/react/manualCrawler/selectors/getManualCrawler';
import { MANUAL_CRAWLER_START_ACTIONS } from 'src/react/manualCrawler/actions/ManualCrawlerStartActions';
import { MANUAL_CRAWLER_ABORT_ACTIONS } from 'src/react/manualCrawler/actions/ManualCrawlerAbortActions';
import { MANUAL_CRAWLER_WATCH_ACTIONS } from 'src/react/manualCrawler/actions/ManualCrawlerWatchActions';
import { ManualCrawlerLog } from 'src/react/manualCrawler/components/ManualCrawlerLog';
import { ManualCrawlerProgress } from 'src/react/manualCrawler/components/ManualCrawlerProgress';
import { CrawlerProgress } from 'src/types/dto/CrawlerProgress';

type OwnProps = {
    readonly crawlerTask: CrawlerTask | null;
} & InjectedIntlProps;
type StateProps = {
    readonly manualCrawler: ManualCrawlerState;
};
type DispatchProps = {
    readonly startCrawler: () => void;
    readonly abortCrawler: () => void;
    readonly startWatching: (crawlerTask: CrawlerTask) => void;
    readonly abortWatching: () => void;
};
type AllProps =
    & OwnProps
    & StateProps
    & DispatchProps;

class Connected extends React.Component<AllProps> {
    public componentDidMount(): void {
        const { crawlerTask } = this.props;
        if (!crawlerTask) {
            return;
        }

        const { startWatching } = this.props;
        startWatching(crawlerTask);
    }

    public componentWillUnmount(): void {
        const { abortWatching } = this.props;
        abortWatching();
    }

    public render(): JSX.Element {
        return (
            <div className="manual-crawler">
                <div className="manual-crawler__head">
                    <h3 className="manual-crawler__title">
                        <FormattedMessage id="manualCrawler_header"/>
                    </h3>
                    <div className="manual-crawler__action">
                        {this.isCrawlerRunning()
                            ? this.renderAbortButton()
                            : this.renderStartButton()}
                    </div>
                </div>
                <div className="manual-crawler__body">
                    {this.renderCrawlerProgress()}
                    {this.renderCrawlerLogs()}
                </div>
            </div>
        );
    }

    private renderStartButton(): JSX.Element {
        const { startCrawler, manualCrawler } = this.props;

        return (
            <HtmlButton type="button"
                        intent="info"
                        disabled={manualCrawler.loading}
                        onClick={startCrawler}>
                <FormattedMessage id="manualCrawler_startCrawler"/>
            </HtmlButton>
        );
    }

    private renderAbortButton(): JSX.Element {
        const { abortCrawler, manualCrawler } = this.props;

        return (
            <HtmlButton type="button"
                        intent="secondary"
                        disabled={manualCrawler.loading}
                        onClick={abortCrawler}>
                <FormattedMessage id="manualCrawler_abortCrawler"/>
            </HtmlButton>
        );
    }

    private renderCrawlerProgress(): JSX.Element | null {
        const { progress } = this.props.manualCrawler;
        if (
            progress === null ||
            progress.status === CrawlerStatus.Aborted
        ) {
            return null;
        }

        return (
            <div className="manual-crawler__progress">
                <ManualCrawlerProgress progress={progress} info={this.renderProgressInfo(progress)} />
            </div>
        );
    }

    private renderProgressInfo({
                                   total,
                                   processed,
                                   downloaded,
                                   queued,
                                   redirected,
                                   notfound,
                                   noindex,
                                   failed,
                               }: CrawlerProgress): string {
        const { intl } = this.props;
        return intl.formatMessage({ id: 'crawlerlog_status_info' }, {
            total,
            processed,
            downloaded,
            queued,
            redirected,
            notfound,
            noindex,
            failed,
        });
    }

    private renderCrawlerLogs(): JSX.Element | null {
        const { progress } = this.props.manualCrawler;
        if (
            progress === null ||
            progress.status === CrawlerStatus.Pending ||
            progress.status === CrawlerStatus.Aborted
        ) {
            return null;
        }

        const { fetchedUrls } = this.props.manualCrawler;
        return (
            <div className="manual-crawler__logs">
                <ManualCrawlerLog fetchedUrls={fetchedUrls}/>
            </div>
        );
    }

    private isCrawlerRunning(): boolean {
        const { progress } = this.props.manualCrawler;
        if (!progress) {
            return false;
        }

        return (
            progress.status === CrawlerStatus.Pending ||
            progress.status === CrawlerStatus.Running
        );
    }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
    manualCrawler: getManualCrawler,
});
const mapDispatchToProps: DispatchProps = {
    startCrawler: () => MANUAL_CRAWLER_START_ACTIONS.request(null),
    abortCrawler: () => MANUAL_CRAWLER_ABORT_ACTIONS.request(null),
    startWatching: (crawlerTask) => MANUAL_CRAWLER_WATCH_ACTIONS.request(crawlerTask),
    abortWatching: () => MANUAL_CRAWLER_WATCH_ACTIONS.request(null),
};

@connectDecorator<OwnProps, StateProps, DispatchProps>(Connected, mapStateToProps, mapDispatchToProps)
export class ManualCrawler extends React.Component<OwnProps> {}
