import { IPromise } from 'angular';
import { Uuid } from 'src/types/values/Uuid';
import { Organization } from 'src/types/entities/Organization';
import { OrganizationForm } from 'src/types/dto/OrganizationForm';
import { OrganizationFilter } from 'src/types/dto/OrganizationFilter';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { Config } from 'src/types/constants/Config';
import { IApiService } from 'src/modules/api/ApiService';

export interface IOrganizationService {
    get(id: Uuid): IPromise<Organization>;
    list(filter: OrganizationFilter): IPromise<ListWithTotal<Organization>>;
    create(form: Readonly<OrganizationForm>): IPromise<Organization>;
    update(widget: Readonly<Organization>, form: Readonly<OrganizationForm>): IPromise<Organization>;
    delete(widget: Readonly<Organization>): IPromise<void>;
}

export class OrganizationService implements IOrganizationService {
    public static $inject = [
        'config',
        'apiService',
    ];
    public constructor(
        private config: Config,
        private apiService: IApiService,
    ) {
    }

    public get(id: Uuid): IPromise<Organization> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'organizations',
            encodeURIComponent(id),
        ].join('/');

        return this.apiService
            .makeGETRequest<Organization>(requestUrl)
            .then(({ data }) => data);
    }

    public list(filter: OrganizationFilter): IPromise<ListWithTotal<Organization>> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'organizations',
        ].join('/');

        return this.apiService
            .makeGETRequest<ListWithTotal<Organization>>(requestUrl, { params: filter })
            .then(({ data }) => data);
    }

    public create(formData: Readonly<OrganizationForm>): IPromise<Organization> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'organizations',
        ].join('/');

        return this.apiService
            .makePOSTRequest<OrganizationForm, Organization>(requestUrl, formData)
            .then(({ data }) => data);
    }

    public delete(widget: Readonly<Organization>): IPromise<void> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'organizations',
            encodeURIComponent(widget.id),
        ].join('/');

        return this.apiService
            .makeDELETERequest<void>(requestUrl)
            .then(({ data }) => data);
    }

    public update(widget: Readonly<Organization>, formData: Readonly<OrganizationForm>): IPromise<Organization> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'organizations',
            encodeURIComponent(widget.id),
        ].join('/');

        return this.apiService
            .makePUTRequest<OrganizationForm, Organization>(requestUrl, formData)
            .then(({ data }) => data);
    }
}
