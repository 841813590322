import { FormErrors, startSubmit, stopSubmit } from 'redux-form';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { NativeError } from 'src/errors/NativeError';
import { ActionRequest } from 'src/utils/createActions';
import { FieldErrors } from 'src/types/values/FieldError';
import { OrganizationListItem } from 'src/react/organization/state/OrganizationListItem';
import {
    ORGANIZATION_LIST_LEAVE_CANCEL_ACTIONS,
    ORGANIZATION_LIST_LEAVE_OPEN_ACTIONS,
    ORGANIZATION_LIST_LEAVE_SUBMIT_ACTIONS,
} from 'src/react/organization/actions/OrganizationListLeaveActions';
import { getSelectedOrganization } from 'src/react/organization/selectors/getSelectedOrganization';
import { permissionDelete } from 'src/react/organization/services/permissionDelete';
import { handleFormError } from 'src/react/organization/services/handleFormError';

export function* organizationListLeaveOpenSaga(
    { data: organization }: ActionRequest<OrganizationListItem>,
): SagaIterator {
    yield put(ORGANIZATION_LIST_LEAVE_OPEN_ACTIONS.success(organization));
}

export function* organizationListLeaveCancelSaga(): SagaIterator {
    yield put(ORGANIZATION_LIST_LEAVE_CANCEL_ACTIONS.success());
}

export function* organizationListLeaveSubmitSaga(): SagaIterator {
    try {
        yield put(ORGANIZATION_LIST_LEAVE_SUBMIT_ACTIONS.pending());
        yield put(startSubmit('organization-leave'));

        const item: OrganizationListItem = yield select(getSelectedOrganization);
        yield call(permissionDelete, item.organization, item.organization.permission);

        yield put(ORGANIZATION_LIST_LEAVE_SUBMIT_ACTIONS.success(item));
        yield put(stopSubmit('organization-leave'));
    } catch (error) {
        const handledError = NativeError.wrapError(error);
        const formErrors: FormErrors<{}, FieldErrors> = yield call(handleFormError, handledError);

        yield put(ORGANIZATION_LIST_LEAVE_SUBMIT_ACTIONS.failure(handledError));
        yield put(stopSubmit('organization-leave', formErrors));
    }
}
