import { StatisticsPageState } from 'src/react/statistics/state/StatisticsPageState';
import {
    STATISTICS_SUCCESSFUL_UPDATE_ACTIONS,
    StatisticsSuccessfulUpdateActions
} from 'src/react/statistics/actions/StatisticsSuccessfulUpdateActions';

export function statisticsSuccessfulUpdateReducer(
    state: StatisticsPageState,
    action: StatisticsSuccessfulUpdateActions,
): StatisticsPageState {
    switch (action.type) {
        case STATISTICS_SUCCESSFUL_UPDATE_ACTIONS.PENDING:
            return {
                ...state,
                successfulSearches: {
                    loading: true,
                    error: null,
                    data: state.successfulSearches.data
                }
            };

        case STATISTICS_SUCCESSFUL_UPDATE_ACTIONS.SUCCESS:
            return {
                ...state,
                successfulSearches: {
                    loading: false,
                    error: null,
                    data: action.data
                }
            };
        case STATISTICS_SUCCESSFUL_UPDATE_ACTIONS.FAILURE:
            return {
                ...state,
                successfulSearches: {
                    loading: false,
                    data: null,
                    error: action.data,
                }
            };

        default:
            return state;
    }
}
