import { buffers, Channel, END, eventChannel } from 'redux-saga';
import { CrawlerTaskState } from 'src/types/websocket/CrawlerTaskState';

export function createWebsocketChannel(socket: WebSocket): Channel<CrawlerTaskState> {
    return eventChannel((emit) => {
        socket.onclose = () => {
            emit(END);
        };
        socket.onmessage = (event) => {
            const status = parseMessage(event);
            if (typeof status === 'object' && status !== null) {
                emit(status);
            }
        };

        return () => {
            socket.close();
        };
    }, buffers.sliding(1));
}

function parseMessage(event: MessageEvent): CrawlerTaskState | null {
    try {
        // TODO: validate event data
        return JSON.parse(event.data);
    } catch {
        return null;
    }
}
