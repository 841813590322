import { Ng1StateDeclaration } from '@uirouter/angularjs';
import { organizationListResolver } from 'src/modules/routes/resolvers/organizationListResolver';

export const ROUTES_STATES_ORGANIZATIONS: ReadonlyArray<Ng1StateDeclaration> = [
    {
        name: 'organizations',
        url: '/organizations',
        parent: 'authorized:index',
        component: 'organizationList',
        reloadOnSearch: false,
        resolve: {
            $title: () => {
                return 'User Management';
            },
            organizations: organizationListResolver,
        },
    },
];
