import { SagaIterator } from 'redux-saga';
import { call, Pattern, race, take } from 'redux-saga/effects';

type Saga<TArgs extends any[]> = (...args: TArgs) => SagaIterator;

export function cancelSagaOn<TArgs extends any[]>(
    saga: Saga<TArgs>,
    cancelOn: Pattern,
): Saga<TArgs> {
    return function* (...args: TArgs): SagaIterator {
        const { result } = yield race({
            // @ts-ignore
            result: call(saga, ...args),
            cancel: take(cancelOn),
        });

        if (result) {
            return result;
        }
    };
}
