import { FormErrors, getFormValues, startSubmit, stopSubmit } from 'redux-form';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { NativeError } from 'src/errors/NativeError';
import { ActionRequest } from 'src/utils/createActions';
import { FieldErrors } from 'src/types/values/FieldError';
import { Organization } from 'src/types/entities/Organization';
import { OrganizationForm } from 'src/types/dto/OrganizationForm';
import { OrganizationListItem } from 'src/react/organization/state/OrganizationListItem';
import {
    ORGANIZATION_LIST_UPDATE_CANCEL_ACTIONS,
    ORGANIZATION_LIST_UPDATE_OPEN_ACTIONS,
    ORGANIZATION_LIST_UPDATE_SUBMIT_ACTIONS,
} from 'src/react/organization/actions/OrganizationListUpdateActions';
import { getSelectedOrganization } from 'src/react/organization/selectors/getSelectedOrganization';
import { organizationUpdate } from 'src/react/organization/services/organizationUpdate';
import { handleFormError } from 'src/react/organization/services/handleFormError';

export function* organizationListUpdateOpenSaga(
    { data: organization }: ActionRequest<OrganizationListItem>,
): SagaIterator {
    yield put(ORGANIZATION_LIST_UPDATE_OPEN_ACTIONS.success(organization));
}

export function* organizationListUpdateCancelSaga(): SagaIterator {
    yield put(ORGANIZATION_LIST_UPDATE_CANCEL_ACTIONS.success());
}

export function* organizationListUpdateSubmitSaga(): SagaIterator {
    try {
        yield put(ORGANIZATION_LIST_UPDATE_SUBMIT_ACTIONS.pending());
        yield put(startSubmit('organization-form'));

        const selector = yield call(getFormValues, 'organization-form');
        const formData: OrganizationForm = yield select(selector);
        const item: OrganizationListItem = yield select(getSelectedOrganization);
        const organization: Organization = yield call(organizationUpdate, item.organization, formData);

        const updated: OrganizationListItem = {
            ...item,
            organization,
        };

        yield put(ORGANIZATION_LIST_UPDATE_SUBMIT_ACTIONS.success(updated));
        yield put(stopSubmit('organization-form'));
    } catch (error) {
        const handledError = NativeError.wrapError(error);
        const formErrors: FormErrors<{}, FieldErrors> = yield call(handleFormError, handledError);

        yield put(ORGANIZATION_LIST_UPDATE_SUBMIT_ACTIONS.failure(handledError));
        yield put(stopSubmit('organization-form', formErrors));
    }
}
