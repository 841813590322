import { Item } from 'src/types/entities/Item';
import { assertNotNull } from 'src/utils/assertion';
import { SearchItem } from 'src/types/dto/SearchItem';
import { DemoPageData, DemoPageState } from 'src/react/demoPage/state/DemoPageState';
import { DEMO_UPDATE_ITEM_ACTIONS, DemoUpdateItemActions } from 'src/react/demoPage/actions/DemoUpdateItemActions';

export function demoUpdateItemReducer(
    state: DemoPageState,
    action: DemoUpdateItemActions,
): DemoPageState {
    switch (action.type) {
        case DEMO_UPDATE_ITEM_ACTIONS.SUCCESS:
            return {
                ...state,
                data: replaceData(
                    assertNotNull(state.data, 'Demo page data has to be fetched first', { state, action }),
                    action.data,
                ),
            };

        default:
            return state;
    }
}

function replaceData(data: DemoPageData, item: Item): DemoPageData {
    return {
        ...data,
        pinnedItems: replaceItem(data.pinnedItems, item),
        foundItems: {
            ...data.foundItems,
            data: replaceSearchItem(data.foundItems.data, item),
        },
    };
}

function replaceItem(items: ReadonlyArray<Item>, item: Item): Item[] {
    const index = items.findIndex((other) => other.id === item.id);
    if (index < 0) {
        return [...items];
    }

    return [
        ...items.slice(0, index),
        item,
        ...items.slice(index + 1),
    ];
}

function replaceSearchItem(items: ReadonlyArray<SearchItem>, item: Item): SearchItem[] {
    const index = items.findIndex((other) => other.item.id === item.id);
    if (index < 0) {
        return [...items];
    }

    return [
        ...items.slice(0, index),
        { ...items[index], item },
        ...items.slice(index + 1),
    ];
}
