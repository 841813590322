import { Ng1StateDeclaration } from '@uirouter/angularjs';
import { ItemState } from 'src/types/values/ItemState';
import { ItemPageParams } from 'src/modules/item/ItemPageParams';

export const ROUTES_STATES_ITEMS: ReadonlyArray<Ng1StateDeclaration> = [
    {
        name: 'items',
        parent: 'authorized:widget',
        url: '/items?query&type&state&page&sort',
        component: 'itemPage',
        reloadOnSearch: false,
        params: {
            query: {
                type: 'string',
                value: '',
                squash: true,
                dynamic: true,
            },
            state: {
                type: 'int',
                value: ItemState.Active,
                squash: true,
                dynamic: true,
            },
            sort: {
                type: 'string',
                value: '',
                squash: true,
                dynamic: true,
            },
            page: {
                type: 'int',
                value: 1,
                squash: true,
                dynamic: true,
            },
        },
        resolve: {
            $title: () => {
                return 'Items';
            },
            params: [
                '$stateParams',
                ({ query, state, type, sort, page }: any): ItemPageParams => ({
                    query,
                    state,
                    type,
                    sort,
                    page,
                }),
            ],
        },
    },
];
