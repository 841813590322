import * as React from 'react';
import { PropsType } from 'react-utils';
import { BaseFieldProps, Field, WrappedFieldProps } from 'redux-form';
import { Omit } from 'mapped-types-utils';
import { classList } from 'src/utils/classList';
import { fieldMetaClass } from 'src/utils/fieldMetaClass';
import { Textarea } from 'src/react/common/components/Textarea';

type TextareaProps = PropsType<typeof Textarea>;
function renderTextarea(props: WrappedFieldProps & TextareaProps): JSX.Element {
    const { input, meta, disabled = false, readOnly = false, ...inputProps } = props;
    const className = classList(props.className, fieldMetaClass(meta, { disabled, readOnly }));

    return (
        <Textarea {...inputProps}
                  className={className}
                  disabled={disabled}
                  readOnly={readOnly}
                  name={input.name}
                  value={input.value}
                  onBlur={input.onBlur}
                  onFocus={input.onFocus}
                  onChange={input.onChange}/>
    );
}

type TextareaFieldProps = Omit<BaseFieldProps<TextareaProps> & TextareaProps, 'component'>;
export function TextareaField(props: TextareaFieldProps): JSX.Element {
    return <Field component={renderTextarea} {...props}/>;
}
