import { SagaIterator } from 'redux-saga';
import { select } from 'redux-saga/effects';
import { CrawlerStatus } from 'src/types/values/CrawlerStatus';
import { getManualCrawler } from 'src/react/manualCrawler/selectors/getManualCrawler';
import { ManualCrawlerState } from 'src/react/manualCrawler/state/ManualCrawlerState';

export function* isManualCrawlerRunning(): SagaIterator {
    const { progress }: ManualCrawlerState = yield select(getManualCrawler);
    if (!progress) {
        return false;
    }

    return(
        progress.status === CrawlerStatus.Pending ||
        progress.status === CrawlerStatus.Running
    );
}
