import * as React from 'react';
import { PropsType } from 'react-utils';
import { BaseFieldProps, Field, WrappedFieldProps } from 'redux-form';
import { Omit } from 'mapped-types-utils';
import { classList } from 'src/utils/classList';
import { fieldMetaClass } from 'src/utils/fieldMetaClass';
import { ItemImageUpload } from 'src/react/itemImageUpload/components/ItemImageUpload';

type ComponentProps = Omit<PropsType<ItemImageUpload>, 'value'>;

function KeyPhrasePinInputComponent(props: WrappedFieldProps & ComponentProps): JSX.Element {
    const { input, meta, disabled = false, ...inputProps } = props;
    const className = classList(props.className, fieldMetaClass(meta, { disabled, readOnly: false }));

    return (
        <ItemImageUpload {...inputProps}
                         className={className}
                         disabled={disabled}
                         name={input.name}
                         value={input.value}
                         onChange={input.onChange}/>
    );
}

type FieldProps = Omit<BaseFieldProps<ComponentProps> & ComponentProps, 'component'>;
export function ItemImageUploadField(props: FieldProps): JSX.Element {
    return <Field component={KeyPhrasePinInputComponent} {...props}/>;
}
