import { KeyPhraseGroupIsExistState } from 'src/react/keyPhraseGroup/state/KeyPhraseGroupIsExistState';
import {
    KEY_PHRASE_GROUP_IS_EXIST_ACTIONS,
    KeyPhraseGroupIsExistActions
} from 'src/react/keyPhraseGroup/actions/keyPhraseGroupIsExistActions';

export function keyPhraseGroupIsExistReducer(
    state: KeyPhraseGroupIsExistState,
    action: KeyPhraseGroupIsExistActions,
): KeyPhraseGroupIsExistState {
    switch (action.type) {
        case KEY_PHRASE_GROUP_IS_EXIST_ACTIONS.PENDING:
            return { ...state, loading: true, error: null };

        case KEY_PHRASE_GROUP_IS_EXIST_ACTIONS.SUCCESS:
            return { ...state, loading: false, data: action.data, error: null };

        case KEY_PHRASE_GROUP_IS_EXIST_ACTIONS.FAILURE:
            return { ...state, loading: false, data: null, error: action.data };

        default:
            return state;
    }
}
