import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { NativeError } from 'src/errors/NativeError';
import { Widget } from 'src/types/entities/Widget';
import { CrawlerTask } from 'src/types/entities/CrawlerTask';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { getCrawlerTaskEndpoint } from 'src/react/api/selectors/getEndpoint';
import { ICrawlerTaskEndpoint } from 'src/react/api/endpoints/CrawlerTaskEndpoint';
import { isManualCrawlerRunning } from 'src/react/manualCrawler/services/isManualCrawlerRunning';
import { MANUAL_CRAWLER_ABORT_ACTIONS } from 'src/react/manualCrawler/actions/ManualCrawlerAbortActions';
import { getManualCrawlerTask } from 'src/react/manualCrawler/selectors/getManualCrawlerTask';

export function* manualCrawlerAbortSaga(): SagaIterator {
    const isRunning: boolean = yield call(isManualCrawlerRunning);
    if (!isRunning) {
        return;
    }

    try {
        yield put(MANUAL_CRAWLER_ABORT_ACTIONS.pending());

        const widget: Widget = yield select(getSelectedWidget);
        const crawlerTaskEndpoint: ICrawlerTaskEndpoint = yield select(getCrawlerTaskEndpoint);

        const crawlerTaskOld: CrawlerTask = yield select(getManualCrawlerTask);
        yield call({
            fn: crawlerTaskEndpoint.delete,
            context: crawlerTaskEndpoint,
        }, crawlerTaskOld.id, { widgetId: widget.id });

        const crawlerTaskNew: CrawlerTask = yield call({
            fn: crawlerTaskEndpoint.get,
            context: crawlerTaskEndpoint,
        }, crawlerTaskOld.id, { widgetId: widget.id });

        yield put(MANUAL_CRAWLER_ABORT_ACTIONS.success(crawlerTaskNew));
    } catch (error) {
        yield put(MANUAL_CRAWLER_ABORT_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
