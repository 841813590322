import axios from 'axios';
import { createSelector } from 'reselect';
import { getConfig } from 'src/react/root/selectors/getConfig';

export const getApiClient = createSelector([
    getConfig,
], (config) => axios.create({
    baseURL: config.services.adminApiUrl,
    withCredentials: true,
}));
