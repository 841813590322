import { KeyPhrasePinInputState } from 'src/react/keyPhrasePinInput/state/KeyPhrasePinInputState';
import {
    KEY_PHRASE_PIN_INPUT_SEARCH_ACTIONS,
    KeyPhrasePinInputSearchActions,
} from 'src/react/keyPhrasePinInput/actions/KeyPhrasePinInputSearchActions';

export function keyPhrasePinInputSearchReducer(
    state: KeyPhrasePinInputState,
    action: KeyPhrasePinInputSearchActions,
): KeyPhrasePinInputState {
    switch (action.type) {
        case KEY_PHRASE_PIN_INPUT_SEARCH_ACTIONS.PENDING:
            return { ...state, loading: true, error: null };

        case KEY_PHRASE_PIN_INPUT_SEARCH_ACTIONS.SUCCESS:
            return { ...state, loading: false, data: action.data, error: null };

        case KEY_PHRASE_PIN_INPUT_SEARCH_ACTIONS.FAILURE:
            return { ...state, loading: false, data: null, error: action.data };

        default:
            return state;
    }
}
