import { Ng1StateDeclaration } from '@uirouter/angularjs';
import { KeyPhraseState } from 'src/types/values/KeyPhraseState';
import { KeyPhrasePageParams } from 'src/modules/keyPhrase/KeyPhrasePageParams';
import { IKeyPhraseGroupService } from 'src/modules/api/KeyPhraseGroupService';
import { Widget } from 'src/types/entities/Widget';

export const ROUTES_STATES_KEY_PHRASES: ReadonlyArray<Ng1StateDeclaration> = [
    {
        name: 'keyPhrases',
        parent: 'authorized:widget',
        url: '/keyPhrases?query&state&group&page&sort',
        component: 'keyPhrasePage',
        reloadOnSearch: false,
        params: {
            query: {
                type: 'string',
                value: '',
                squash: true,
                dynamic: true,
            },
            state: {
                type: 'int',
                value: KeyPhraseState.Active,
                squash: true,
                dynamic: true,
            },
            group: {
                type: 'string',
                value: '',
                squash: true,
                dynamic: true,
            },
            sort: {
                type: 'string',
                value: '',
                squash: true,
                dynamic: true,
            },
            page: {
                type: 'int',
                value: 1,
                squash: true,
                dynamic: true,
            },
        },
        resolve: {
            $title: () => {
                return 'Keywords';
            },
            groups:['widget', 'keyPhraseGroupService', (widget: Widget, service: IKeyPhraseGroupService) => {
                  return service.list(widget, { pageSize: 1000, page: 0, query: '', sort: [], exact: false, id: [] })
                      .then((result) => {
                        return result.data;
                      });
            }],
            params: [
                '$stateParams',
                ({ query, state, group, sort, page }: any): KeyPhrasePageParams => ({
                    query,
                    state,
                    group,
                    sort,
                    page,
                }),
            ],
        },
    },
];
