import { IComponentOptions, IScope } from 'angular';
import { WidgetLayoutController } from 'src/modules/layout/WidgetLayoutController';
import * as WidgetLayoutComponent from './WidgetLayoutComponent.html';

export const WIDGET_LAYOUT_COMPONENT: IComponentOptions = {
    template: WidgetLayoutComponent,
    controller: WidgetLayoutController,
    controllerAs: 'widgetLayoutCtrl',
    bindings: {
        widget: '<',
        organization: '<',
        language: '<',
    },
};

export interface WidgetLayoutScope extends IScope {
    readonly widgetLayoutCtrl: WidgetLayoutController;
}
