import * as React from 'react';
import { CrawlerLogPage } from 'src/react/crawlerLog/components/CrawlerLogPage';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { CrawlerLog } from 'src/types/entities/CrawlerLog';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { CrawlerTask } from 'src/types/entities/CrawlerTask';
import { injectIntl, InjectedIntlProps } from 'react-intl';

export type CrawlerLogPageBridge = {
    readonly crawlerLog: ListWithTotal<CrawlerLog>  | null;
    readonly crawlerTask: CrawlerTask | null;
    readonly page: number;
    readonly query: string;
    readonly onPageChange: (page: UnsignedInteger) => void;
    readonly onSearchChange: (event: string) => void;
} & InjectedIntlProps;

function CrawlerLogPageBridge(props: CrawlerLogPageBridge): JSX.Element {
    return (
        <CrawlerLogPage {...props} />
    );
}

export default injectIntl(CrawlerLogPageBridge);
