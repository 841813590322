import { IHttpResponse } from 'angular';
import { MapOf } from 'src/utils/MapOf';
import { BaseError } from 'src/errors/BaseError';
import { ErrorResponse } from 'src/react/api/types/Response';

type RequestData = {
    readonly method: string;
    readonly url: string;
    readonly data: any;
    readonly params: MapOf<string>;
    readonly headers: MapOf<string>;
};
type ResponseData = {
    readonly status: number;
    readonly statusText: string;
    readonly data: any;
    readonly headers: MapOf<string>;
};

export class HttpError extends BaseError {
    private readonly requestData: RequestData;
    private readonly responseData: ResponseData;

    public constructor(
        response: IHttpResponse<any> | ErrorResponse,
        previous?: BaseError,
    ) {
        super(previous);

        if (isNgResponse(response)) {
            this.requestData = {
                method: response.config.method,
                url: response.config.url,
                data: response.config.data,
                params: response.config.params,
                headers: { ...response.config.headers },
            };
            this.responseData = {
                status: response.status,
                statusText: response.statusText,
                data: response.data,
                headers: response.headers(),
            };
        } else {
            this.requestData = {
                method: response.config.method!,
                url: response.config.url!,
                data: response.config.data,
                params: response.config.params,
                headers: { ...response.config.headers },
            };
            this.responseData = {
                status: response.response ? response.response.status : -1,
                statusText: response.response ? response.response.statusText : 'Unknown Error',
                data: response.response ? response.response.data : 'Unknown Error',
                headers: response.response ? response.response.headers : {},
            };
        }
    }

    public get status(): number {
        return this.responseData.status;
    }

    public get request(): RequestData {
        return this.requestData;
    }

    public get response(): ResponseData {
        return this.responseData;
    }

    public get name(): string {
        return 'HttpError';
    }

    public get message(): string {
        const { status, statusText } = this.responseData;
        return `HTTP error ${status} ('${statusText}')`;
    }

    public toJSON(): object {
        return {
            ...super.toJSON(),
            request: this.requestData,
            response: this.responseData,
        };
    }
}

function isNgResponse(response: any): response is IHttpResponse<any> {
    return (
        typeof response.status === 'number' &&
        typeof response.headers === 'function'
    );
}
