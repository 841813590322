import './DashboardTopSearchTable.scss';
import * as React from 'react';
import { LocationDescriptor } from 'history';
import { Link } from 'react-router-dom';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import { Widget } from 'src/types/entities/Widget';
import { StatisticsTopSearch } from 'src/types/entities/StatisticsTopSearch';
import { encodeQueryForStatisticsLink } from 'src/utils/encodeQueryForStatisticsLink';

type Props = {
    readonly widget: Widget;
    readonly topSearches: ReadonlyArray<StatisticsTopSearch>;
};

export class DashboardTopSearchTable extends React.Component<Props> {
    public render(): JSX.Element {
        const { topSearches } = this.props;

        return (
            <div className="dashboard-top-search-table">
                {topSearches.length > 0
                    ? this.renderTable()
                    : this.renderEmpty()}
            </div>
        );
    }

    private renderEmpty(): JSX.Element {
        return (
            <div className="dashboard-top-search-table__empty">
                <FormattedMessage id="dashboard_topSearch_noData"/>
            </div>
        );
    }

    private renderTable(): JSX.Element {
        const { topSearches } = this.props;

        return (
            <table className="dashboard-top-search-table__table">
                <thead>
                <tr>
                    <th className="dashboard-top-search-table__th">
                        <FormattedMessage id="dashboard_topSearch_searchTerm"/>
                    </th>
                    <th className="dashboard-top-search-table__th">
                        <FormattedMessage id="dashboard_topSearch_searchCount"/>
                    </th>
                </tr>
                </thead>

                <tbody>
                {topSearches.map((topSearch) => (
                    <tr key={topSearch.query} className="dashboard-top-search-table__tr">
                        <td className="dashboard-top-search-table__td dashboard-top-search-table__td--search-term">
                            <Link to={this.getTopSearchLocation(topSearch)}>{topSearch.query}</Link>
                        </td>
                        <td className="dashboard-top-search-table__td dashboard-top-search-table__td--search-count">
                            <FormattedNumber value={topSearch.searchCount}/>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        );
    }

    private getTopSearchLocation(topSearch: StatisticsTopSearch): LocationDescriptor {
        const { widget } = this.props;
        return (
            `/${encodeURIComponent(widget.id)}` +
            '/demo' +
            `?query=${encodeQueryForStatisticsLink(topSearch.query)}`
        );
    }
}
