import { delay, SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { NativeError } from 'src/errors/NativeError';
import {
    ITEM_FORM_VALIDATE_ACTIONS,
    ItemFormValidateRequestData,
} from 'src/react/itemForm/actions/ItemFormValidateActions';
import { itemFormValidate } from 'src/react/itemForm/services/itemFormValidate';

export function* itemFormValidateSaga(
    { data: { item, formData, immediate } }: ActionRequest<ItemFormValidateRequestData>,
): SagaIterator {
    try {
        yield put(ITEM_FORM_VALIDATE_ACTIONS.pending());

        // input debounce
        if (!immediate) {
            yield call(delay, 500);
        }

        yield call(itemFormValidate, item, formData);

        yield put(ITEM_FORM_VALIDATE_ACTIONS.success(null));
    } catch (error) {
        yield put(ITEM_FORM_VALIDATE_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
