import { Schema, ValidationError } from 'yup';
import { FieldErrors } from 'src/types/values/FieldError';

export type SchemaValidator<T> = (value: T, context?: object) => FieldErrors;
export function createSchemaValidator<T>(schema: Schema<T>): SchemaValidator<T> {
    return (values, context) => {
        try {
            schema.validateSync(values, {
                context,
                strict: false,
                stripUnknown: false,
                recursive: true,
                abortEarly: false,
            });
            return [];
        } catch (errors) {
            return formatError(errors, context).slice(1);
        }
    };
}

function formatError(error: ValidationError, context?: object): FieldErrors {
    return error.inner.reduce<FieldErrors>((errors, inner) => [
        ...errors,
        ...formatError(inner, context),
    ], [{
        code: error.message,
        path: error.path,
        value: error.value,
        params: error.params,
        context,
    }]);
}
