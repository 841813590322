import { Ng1StateDeclaration } from '@uirouter/angularjs';
import { Widget } from 'src/types/entities/Widget';
import { IStatisticsFilterService } from 'src/modules/api/StatisticsFilterService';
import { StatisticsFilter } from 'src/types/entities/StatisticsFilter';
import { IStatisticsCombinedSearchService } from 'src/modules/api/StatisticsCombinedSearchService';

export const ROUTES_STATES_STATISTICS: ReadonlyArray<Ng1StateDeclaration> = [
    {
        name: 'statistics',
        parent: 'authorized:widget',
        url: '/statistics?query&page&sort&tab&dateFrom&dateTo&hasResults',
        component: 'statisticsPage',
        reloadOnSearch: false,
        params: {
            query: {
                type: 'string',
                value: '',
                squash: true,
            },
            sort: {
                type: 'string',
                value: '-searchCount',
                squash: true,
            },
            page: {
                type: 'int',
                value: 1,
                squash: true,
            },
            tab: {
                type: 'string',
                value: 'combined',
                squash: true,
            },
            dateFrom: {
                type: 'string',
                value: '',
                squash: true,
            },
            dateTo: {
                type: 'string',
                value: '',
                squash: true,
            },
            hasResults: {
                type: 'string',
                value: '',
                squash: true,
            }
        },
        resolve: {
            $title: () => {
                return 'Statistics';
            },
            statisticsFilter: [
                'widget',
                'statisticsFilterService',
                (
                    widget: Widget,
                    statisticsFilterService: IStatisticsFilterService,
                ) => statisticsFilterService.list(widget).then(({ data: [filter] }) => filter || null),
            ],
            hasStatisticsData: [
                'widget',
                'statisticsFilter',
                'statisticsCombinedSearchService',
                (
                    widget: Widget,
                    statisticsFilter: StatisticsFilter | null,
                    statisticsCombinedSearchService: IStatisticsCombinedSearchService,
                ) => statisticsCombinedSearchService.list(widget, {
                    query: '',
                    sort: ['-searchCount'],
                    page: 0,
                    pageSize: 0,
                    filterId: statisticsFilter ? statisticsFilter.id : null,
                    dateFrom: null,
                    dateTo: null,
                    hasResults: null,
                }).then(({ total }) => total > 0),
            ],
        },
    },
];
