import * as React from 'react';
import { FormattedDate } from 'react-intl';
import { DateTime } from 'src/types/values/DateTime';

interface Props {
    readonly date: Date | DateTime;
}

export function DateFormat({ date }: Props): JSX.Element {
    return (
        <FormattedDate value={date}
                       year="numeric"
                       month="short"
                       day="2-digit"
                       hour12={false}
                       hour="2-digit"
                       minute="2-digit"/>

    );
}
