import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { NativeError } from 'src/errors/NativeError';
import {
    KEY_PHRASE_GROUP_IS_EXIST_ACTIONS,
    KeyPhraseGroupIsExistRequestData
} from 'src/react/keyPhraseGroup/actions/keyPhraseGroupIsExistActions';
import { keyPhraseGroupList } from 'src/react/keyPhraseGroup/services/keyPhraseGroupList';
import { KeyPhraseGroup } from 'src/types/entities/KeyPhraseGroup';

export function* keyPhraseGroupIsExistSaga(
    { data }: ActionRequest<KeyPhraseGroupIsExistRequestData>,
): SagaIterator {
    try {
        const { groupName , groupId } = data;
        yield put(KEY_PHRASE_GROUP_IS_EXIST_ACTIONS.pending());

        const keyPhraseGroups: KeyPhraseGroup[] = yield call(keyPhraseGroupList, groupName, groupId);
        const result = keyPhraseGroups.length > 0 ? keyPhraseGroups[0] : null;
        yield put(KEY_PHRASE_GROUP_IS_EXIST_ACTIONS.success(result));
    } catch (error) {
        yield put(KEY_PHRASE_GROUP_IS_EXIST_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
