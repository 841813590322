import { ActionRequest } from 'src/utils/createActions';
import { SiteLanguages } from 'src/types/values/siteLanguage';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { SITE_LANGUAGES_CHANGE_LANGUAGE_ACTIONS }
    from 'src/react/siteLanguage/actions/SiteLanguagesChangeLanguageActions';
import { updateRoute } from 'src/react/siteLanguage/services/updateRoute';

export function* siteLanguageChangeLanguageDataSaga(
    { data: language }: ActionRequest<SiteLanguages>,
): SagaIterator {
    if (language !== localStorage.getItem('locale')) {
        yield call({
            fn: localStorage.setItem,
            context: localStorage,
        }, 'locale', language);
        yield put(SITE_LANGUAGES_CHANGE_LANGUAGE_ACTIONS.success(language));
        yield call(updateRoute);
    }
}
