import './DemoPageSearchItemTableView.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Item } from 'src/types/entities/Item';
import { Widget } from 'src/types/entities/Widget';
import { Highlight } from 'src/types/dto/Highlight';
import { DemoPageItemDescription } from 'src/react/demoPage/components/DemoPageItemDescription';
import { DemoPageItemImage } from 'src/react/demoPage/components/DemoPageItemImage';
import { DemoPageViewMode } from 'src/react/demoPage/state/DemoPageViewMode';
import { DemoPageHighlight } from 'src/react/demoPage/components/DemoPageHighlight';

type Props = {
    readonly item: Item;
    readonly widget: Widget;
    readonly viewerMode: boolean;
    readonly highlight: Highlight;
    readonly isPinned: boolean;
    readonly onPinItem: () => void;
    readonly onUnpinItem: () => void;
    readonly onEditItem: () => void;
};

export function DemoPageSearchItemTableView({
                                               item,
                                               widget,
                                               viewerMode,
                                               highlight,
                                               isPinned,
                                               onPinItem,
                                               onUnpinItem,
                                               onEditItem,
                                           }: Props): JSX.Element {
    return (
        <div className="demo-page-search-item-table-view">
            {!viewerMode &&
            <div className="demo-page-search-item-table-view__col demo-page-search-item-table-view__col--pin">
                {isPinned && (
                    <FormattedMessage id="searchDemo_unpinItem">
                        {(message) => (
                            <a className="demo-page-search-item-table-view__unpin"
                               title={message.toString()}
                               href="javascript:void(0)"
                               onClick={onUnpinItem}>
                                <i className="fa fa-thumb-tack"/>
                            </a>
                        )}
                    </FormattedMessage>
                )}
                {!isPinned && (
                    <FormattedMessage id="searchDemo_pinItem">
                        {(message) => (
                            <a className="demo-page-search-item-table-view__pin"
                               title={message.toString()}
                               href="javascript:void(0)"
                               onClick={onPinItem}>
                                <i className="fa fa-thumb-tack"/>
                            </a>
                        )}
                    </FormattedMessage>
                )}
            </div>}
            <div className="demo-page-search-item-table-view__col demo-page-search-item-table-view__col--image">
                <DemoPageItemImage item={item} widget={widget} viewMode={DemoPageViewMode.table}/>
            </div>

            <div className="demo-page-search-item-table-view__col demo-page-search-item-table-view__col--title">
                <a className="demo-page-search-item-table-view__title"
                   href={item.url}
                   target="_blank"
                   rel="noreferrer">
                    <DemoPageHighlight text={item.title}
                                       html={highlight.title}/>
                </a>
            </div>
            <div className="demo-page-search-item-table-view__col demo-page-search-item-table-view__col--content">
                <DemoPageItemDescription item={item}
                                         highlight={highlight}
                                         viewMode={DemoPageViewMode.table}/>
            </div>
            {!viewerMode &&
            <div className="demo-page-search-item-table-view__col demo-page-search-item-table-view__col--edit">
                <FormattedMessage id="searchDemo_editItem">
                    {(message) => (
                        <a className="demo-page-search-item-table-view__edit"
                           title={message.toString()}
                           onClick={onEditItem}>
                            <i className="fa fa-cog"/>
                        </a>
                    )}
                </FormattedMessage>
            </div>}
        </div>
    );
}
