import * as React from 'react';
import { PropsType } from 'react-utils';
import { BaseFieldProps, Field, WrappedFieldProps } from 'redux-form';
import { Omit } from 'mapped-types-utils';
import { classList } from 'src/utils/classList';
import { fieldMetaClass } from 'src/utils/fieldMetaClass';
import { KeyPhrasePinInput } from 'src/react/keyPhrasePinInput/components/KeyPhrasePinInput';

type ComponentProps = Omit<PropsType<KeyPhrasePinInput>, 'value'>;

function KeyPhrasePinInputComponent(props: WrappedFieldProps & ComponentProps): JSX.Element {
    const { input, meta, disabled = false, readOnly = false, ...inputProps } = props;
    const className = classList(props.className, fieldMetaClass(meta, { disabled, readOnly }));

    return (
        <KeyPhrasePinInput {...inputProps}
                           className={className}
                           disabled={disabled}
                           readOnly={readOnly}
                           name={input.name}
                           value={input.value}
                           onBlur={input.onBlur.bind(input, undefined)}
                           onFocus={input.onFocus}
                           onChange={input.onChange}/>
    );
}

type FieldProps = Omit<BaseFieldProps<ComponentProps> & ComponentProps, 'component'>;
export function KeyPhrasePinInputField(props: FieldProps): JSX.Element {
    return <Field component={KeyPhrasePinInputComponent} {...props}/>;
}
