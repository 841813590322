import './ItemFormForm.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { bind } from 'lodash-decorators/bind';
import { InnerFormProps, OuterFormProps, withFormDecorator } from 'src/decorators/withFormDecorator';
import { assertNever } from 'src/utils/assertion';
import { Item } from 'src/types/entities/Item';
import { ItemType } from 'src/types/values/ItemType';
import { CustomField } from 'src/types/entities/CustomField';
import { Collapse } from 'src/react/common/components/Collapse';
import { HtmlButton } from 'src/react/common/components/Button';
import { ModalBody, ModalClose, ModalFooter, ModalHeader } from 'src/react/common/components/Modal';
import { LoadingWrapper } from 'src/react/common/components/LoadingWrapper';
import { TextboxField } from 'src/react/forms/components/TextboxField';
import { TextareaField } from 'src/react/forms/components/TextareaField';
import { UrlInputField } from 'src/react/forms/components/UrlInputField';
import { CheckboxField } from 'src/react/forms/components/CheckboxField';
import { FormFieldError } from 'src/react/forms/components/FormFieldError';
import { KeyPhrasePinInputField } from 'src/react/keyPhrasePinInput/components/KeyPhrasePinInputField';
import { ItemImageUploadField } from 'src/react/itemImageUpload/components/ItemImageUploadField';
import { ItemFormValue } from 'src/react/itemForm/types/ItemFormValue';
import { ItemFormTagsInputField } from 'src/react/itemForm/components/ItemFormTagsInputField';

type Props = {
    readonly isNewItem: boolean;
    readonly itemType: ItemType | null;
    readonly customFields: ReadonlyArray<CustomField>;
    readonly onSubmit: (formData: ItemFormValue) => Promise<Item>;
    readonly onCancel: () => void;
    readonly restore: () => void;
    readonly saveRestored: () => void;
    readonly isRestoring: boolean;
};
type State = {
    readonly isMoreOpen: boolean;
};

class Connected extends React.Component<InnerFormProps<ItemFormValue, Props>, State> {
    public state: State = {
        isMoreOpen: false,
    };

    public render(): JSX.Element {
        const { isMoreOpen } = this.state;
        const { isNewItem, itemType, onSubmit, onCancel, restore, isRestoring, saveRestored } = this.props;
        const { handleSubmit, pristine, invalid, submitting, asyncValidating } = this.props;
        const isEdited = itemType === ItemType.Edited;
        const isCrawled = itemType === ItemType.Crawled;

        const infoRestoreWithLink = (
                <a onClick={restore}>
                    <FormattedMessage id="itemForm_infoText_restore"/>
                </a>
        );
        const infoRestore = <FormattedMessage id="itemForm_infoText_restore"/>;

        return (
            <form className="item-form-form" onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader className="item-form-form__header">
                    <ModalClose className="item-form-form__close" onClick={onCancel}/>
                    <div className="item-form-form__title">
                        {isNewItem
                            ? <FormattedMessage id="itemForm_createItemTitle"/>
                            : <FormattedMessage id="itemForm_updateItemTitle"/>}
                    </div>
                </ModalHeader>
                <ModalBody className="item-form-form__content">
                    <LoadingWrapper loading={submitting || asyncValidating === true}>
                        <div className="item-form-form">
                            {isEdited || isCrawled && !pristine
                                ? <div className="item-form-form__row">
                                    <div className="item-form-form--info-message">
                                        <i className="si si-info item-form-form--info"/>
                                        <div className="item-form-form--info-text">
                                            {isEdited
                                                ? <FormattedMessage id="itemForm_infoText"
                                                              values={{ button: infoRestoreWithLink }}/>
                                                : null}
                                            {isCrawled && !pristine
                                                ? <FormattedMessage id="itemForm_infoText"
                                                                    values={{ button: infoRestore }}/>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                : null }
                            <div className="item-form-form__row">
                                <div className="item-form-form__label">
                                    <FormattedMessage id="itemForm_title"/>
                                </div>
                                <div className="item-form-form__value">
                                    <TextboxField type="text"
                                                  name="title"
                                                  autoComplete="off"/>
                                </div>
                                <FormFieldError className="item-form-form__error"
                                                field="title"/>
                            </div>
                            <div className="item-form-form__row">
                                <div className="item-form-form__label">
                                    <FormattedMessage id="itemForm_description"/>
                                </div>
                                <div className="item-form-form__value">
                                    <TextareaField name="description"/>
                                </div>
                                <FormFieldError className="item-form-form__error"
                                                field="description"/>
                            </div>
                            <div className="item-form-form__row">
                                <div className="item-form-form__label">
                                    <FormattedMessage id="itemForm_url"/>
                                </div>
                                <div className="item-form-form__value">
                                    <UrlInputField type="text"
                                                   name="url"
                                                   autoComplete="off"/>
                                </div>
                                <FormFieldError className="item-form-form__error"
                                                field="url"/>
                            </div>
                            <div className="item-form-form__row">
                                <div className="item-form-form__label">
                                    <FormattedMessage id="itemForm_keyPhrases"/>
                                </div>
                                <div className="item-form-form__value">
                                    <KeyPhrasePinInputField name="keyPhrases"/>
                                </div>
                                <FormFieldError className="item-form-form__error"
                                                field="keyPhrases"/>
                            </div>
                            <div className="item-form-form__row">
                                <ItemImageUploadField name="image"/>
                                <FormFieldError className="item-form-form__error"
                                                field="image"/>
                            </div>
                            <div className="item-form-form__more">
                                <a className={(isMoreOpen ?
                                    'item-form-form__more-toggle item-form-form__more-toggle--open' :
                                    'item-form-form__more-toggle')}
                                   onClick={this.handleToggleMore}>
                                    <FormattedMessage id="itemForm_moreInfo"/> <i className="fa" />
                                </a>
                                <Collapse expanded={isMoreOpen}>
                                    <div className="item-form-form__more-content">
                                        {this.renderCustomFields()}
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </LoadingWrapper>
                </ModalBody>
                <ModalFooter className="item-form-form__footer">
                    <div className="item-form-form__footer-left">
                        <HtmlButton block={false}
                                    type="button"
                                    intent="none"
                                    onClick={onCancel}>
                            <FormattedMessage id="itemForm_cancelButton"/>
                        </HtmlButton>
                    </div>
                    <div className="item-form-form__footer-right">
                        {!isRestoring || (isRestoring && !pristine)
                            ? <HtmlButton block={false}
                                          type="submit"
                                          intent="secondary"
                                          disabled={submitting || pristine
                                          || invalid || !!asyncValidating}>
                                {isNewItem
                                    ? <FormattedMessage id="itemForm_createItemButton"/>
                                    : <FormattedMessage id="itemForm_updateItemButton"/>}
                            </HtmlButton>
                            : <HtmlButton block={false}
                                          type="button"
                                          intent="success"
                                          onClick={saveRestored}
                                          disabled={invalid || !!asyncValidating}>
                                <FormattedMessage id="itemForm_updateItemButton"/>
                            </HtmlButton>
                             }
                    </div>
                </ModalFooter>
            </form>
        );
    }

    private renderCustomFields(): JSX.Element {
        const { customFields } = this.props;
        if (!customFields.length) {
            return (
                <div className="item-form-form__no-custom-fields">
                    <FormattedMessage id="itemForm_noCustomFields"/>
                </div>
            );
        }

        return (
            <div className="item-form-form__custom-fields">
                {customFields.map((customField) => this.renderCustomField(customField))}
            </div>
        );
    }

    private renderCustomField(customField: CustomField): JSX.Element {
        if (customField.type === 'boolean') {
            return (
                <div className="item-form-form__row" key={customField.id}>
                    <label className="item-form-form__checkbox">
                        <span className="item-form-form__checkbox-input">
                            <CheckboxField name={`customFields.${customField.id}`}/>
                        </span>
                        <span className="item-form-form__checkbox-label">
                            {customField.name}
                        </span>
                    </label>
                </div>
            );
        }

        if (customField.type === 'keyword') {
            return (
                <div className="item-form-form__row" key={customField.id}>
                    <div className="item-form-form__label">
                        {customField.name}
                    </div>
                    <div className="item-form-form__value">
                        <TextboxField name={`customFields.${customField.id}`}/>
                        <FormFieldError className="item-form-form__error"
                                        field={`customFields.${customField.id}`}/>
                    </div>
                </div>
            );
        }

        if (customField.type === 'price' || customField.type === 'numeric') {
            return (
                <div className="item-form-form__row" key={customField.id}>
                    <div className="item-form-form__label">
                        {customField.name}
                    </div>
                    <div className="item-form-form__value">
                        <TextboxField type={'number'} name={`customFields.${customField.id}`}/>
                        <FormFieldError className="item-form-form__error"
                                        field={`customFields.${customField.id}`}/>
                    </div>
                </div>
            );
        }

        if (customField.type === 'text') {
            return (
                <div className="item-form-form__row" key={customField.id}>
                    <div className="item-form-form__label">
                        {customField.name}
                    </div>
                    <div className="item-form-form__value">
                        <TextareaField name={`customFields.${customField.id}`}/>
                        <FormFieldError className="item-form-form__error"
                                        field={`customFields.${customField.id}`}/>
                    </div>
                </div>
            );
        }

        if (customField.type === 'tags') {
            return (
                <div className="item-form-form__row" key={customField.id}>
                    <div className="item-form-form__label">
                        {customField.name}
                    </div>
                    <div className="item-form-form__value">
                        <ItemFormTagsInputField name={`customFields.${customField.id}`}/>
                        <FormFieldError className="item-form-form__error"
                                        field={`customFields.${customField.id}`}/>
                    </div>
                </div>
            );
        }

        return assertNever('Invalid custom field type', { customField });
    }

    @bind
    private handleToggleMore(): void {
        this.setState(({ isMoreOpen }) => ({ isMoreOpen: !isMoreOpen }));
    }
}

@withFormDecorator<ItemFormValue, Props>(Connected, {})
export class ItemFormForm extends React.Component<OuterFormProps<ItemFormValue, Props>> {}
