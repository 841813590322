import { module } from 'angular';
import { CRAWLER_STATUS_COMPONENT } from 'src/modules/crawlerStatus/CrawlerStatusComponent';

export const CRAWLER_STATUS_MODULE = module('crawlerStatus', [])
    /**
     * @ngdoc directive
     * @name crawlerStatus
     * @restrict E
     *
     * @param {expression} widget
     * @param {expression} crawlerTask
     */
    .component(
        'crawlerStatus',
        CRAWLER_STATUS_COMPONENT,
    );
