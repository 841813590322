import * as React from 'react';
import { PropsType } from 'react-utils';
import { BaseFieldProps, Field, WrappedFieldInputProps, WrappedFieldMetaProps, WrappedFieldProps } from 'redux-form';
import { Omit } from 'mapped-types-utils';
import { classList } from 'src/utils/classList';
import { fieldMetaClass } from 'src/utils/fieldMetaClass';
import { Textbox } from 'src/react/common/components/Textbox';
import { InputStatus } from 'src/react/common/components/InputStatus';
import { ValidationIcon } from 'src/react/common/components/ValidationIcon';

type TextboxProps = PropsType<typeof Textbox>;
function UrlInputComponent(props: WrappedFieldProps & TextboxProps): JSX.Element {
    const { input, meta, disabled = false, readOnly = false, ...inputProps } = props;
    const className = classList(props.className, fieldMetaClass(meta, { disabled, readOnly }));

    return (
        <InputStatus prefix={<i className="fa fa-search"/>}
                     suffix={renderUrlValidationStatus(input, meta)}>
            <Textbox {...inputProps}
                     className={className}
                     disabled={disabled}
                     readOnly={readOnly}
                     name={input.name}
                     value={input.value}
                     onBlur={input.onBlur}
                     onFocus={input.onFocus}
                     onChange={input.onChange}/>
        </InputStatus>
    );
}

function renderUrlValidationStatus(
    input: WrappedFieldInputProps,
    meta: WrappedFieldMetaProps,
): JSX.Element | null {
    if (meta.asyncValidating) {
        return <ValidationIcon state="pending"/>;
    }
    if (meta.valid) {
        return <ValidationIcon state="valid"/>;
    }
    if (meta.invalid) {
        return <ValidationIcon state="invalid"/>;
    }

    return null;
}

type TextboxFieldProps = Omit<BaseFieldProps<TextboxProps> & TextboxProps, 'component'>;
export function UrlInputField(props: TextboxFieldProps): JSX.Element {
    return <Field component={UrlInputComponent} {...props}/>;
}
