import './DemoPageFilterItem.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { classList } from 'src/utils/classList';
import { Item } from 'src/types/entities/Item';
import { Widget } from 'src/types/entities/Widget';
import { DemoPageItemImage } from 'src/react/demoPage/components/DemoPageItemImage';
import { DemoPageItemDescription } from 'src/react/demoPage/components/DemoPageItemDescription';
import { DemoPageViewMode } from 'src/react/demoPage/state/DemoPageViewMode';
import { DEMO_PAGE_EMPTY_HIGHLIGHT } from 'src/react/demoPage/constants/DemoPageEmptyHighlight';

type Props = {
    readonly item: Item;
    readonly widget: Widget;
    readonly isPinned: boolean;
    readonly onPinItem: () => void;
    readonly onUnpinItem: () => void;
    readonly onEditItem: () => void;
};

export function DemoPageFilterItem({
                                       item,
                                       widget,
                                       isPinned,
                                       onPinItem,
                                       onUnpinItem,
                                       onEditItem,
}: Props): JSX.Element {
    return (
        <div className={classList('demo-page-filter-item', { 'demo-page-filter-item--pinned': isPinned })}>
            <div className="demo-page-filter-item__col demo-page-filter-item__col--pin">
                {isPinned && (
                    <FormattedMessage id="searchDemo_unpinItem">
                        {(message) => (
                            <a className="demo-page-filter-item__unpin"
                               title={message.toString()}
                               href="javascript:void(0)"
                               onClick={onUnpinItem}>
                                <i className="fa fa-thumb-tack"/>
                            </a>
                        )}
                    </FormattedMessage>
                )}
                {!isPinned && (
                    <FormattedMessage id="searchDemo_pinItem">
                        {(message) => (
                            <a className="demo-page-filter-item__pin"
                               title={message.toString()}
                               href="javascript:void(0)"
                               onClick={onPinItem}>
                                <i className="fa fa-thumb-tack"/>
                            </a>
                        )}
                    </FormattedMessage>
                )}
            </div>
            <div className="demo-page-filter-item__col demo-page-filter-item__col--image">
                <DemoPageItemImage item={item} widget={widget} viewMode={DemoPageViewMode.table}/>
            </div>
            <div className="demo-page-filter-item__col demo-page-filter-item__col--title">
                <a className="demo-page-filter-item__title"
                   href={item.url}
                   target="_blank"
                   rel="noreferrer">{item.title}</a>
            </div>
            <div className="demo-page-filter-item__col demo-page-filter-item__col--content">
                <DemoPageItemDescription item={item}
                                         highlight={DEMO_PAGE_EMPTY_HIGHLIGHT}
                                         viewMode={DemoPageViewMode.table}/>
            </div>
            <div className="demo-page-filter-item__col demo-page-filter-item__col--edit">
                <FormattedMessage id="searchDemo_editItem">
                    {(message) => (
                        <a className="demo-page-filter-item__edit"
                           title={message.toString()}
                           onClick={onEditItem}>
                            <i className="fa fa-cog"/>
                        </a>
                    )}
                </FormattedMessage>
            </div>
        </div>
    );
}
