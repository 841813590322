import { createSelector } from 'reselect';
import { isNotNull } from 'src/utils/isNotNull';
import { SearchMatchMode } from 'src/types/values/SearchMatchMode';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { getCustomFields } from 'src/react/selectedWidget/selectors/getCustomFields';
import { getStatisticsFilter } from 'src/react/selectedWidget/selectors/getStatisticsFilter';
import { SettingsFormData } from 'src/react/settings/types/SettingsFormData';

export const getSettingsFormInitialValues = createSelector([
    getSelectedWidget,
    getCustomFields,
    getStatisticsFilter,
], (widget, customFields, statisticsFilter): SettingsFormData => ({
    widget: {
        name: widget.name,
        website: widget.website,
    },

    urlBoosts: widget.urlBoost.map((urlBoost) => ({
        urlBoost: urlBoost.boost,
        urlPattern: urlBoost.pattern,
    })),
    fieldBoost: {
        titleBoost: widget.fieldBoost.title,
        contentBoost: widget.fieldBoost.content,
        descriptionBoost: widget.fieldBoost.description,
    },
    customBoosts: widget.customBoosts.map((fieldBoost) => {
        const customField = customFields.find((it) => it.id === fieldBoost.customFieldId);
        return customField ? { fieldName: customField.name, fieldBoost: fieldBoost.customFieldBoost } : null;
    }).filter(isNotNull),
    customFields: customFields.map((customField) => ({
        fieldType: customField.type,
        fieldName: customField.name,
    })),
    includeToFacets: [...widget.includeToFacets],
    includeToRecommendations: [...widget.includeToRecommendations],
    autocomplete: {
        useItemTitle: widget.autocomplete.itemTitle,
        useKeyPhrase: widget.autocomplete.keyPhrase,
    },
    searchParams: {
        enableFuzziness: widget.searchParams.enableFuzziness,
        enablePrefixMatch: widget.searchParams.searchMatchMode === SearchMatchMode.Prefix,
    },
    crawler: {
        limit: widget.crawlerLimit || null,
        depth: widget.crawlerDepth || null,
        defaultImage: widget.defaultImage,
        ignoreRobotsTxt: widget.ignoreRobotsTxt,
        cronjobEnabled: widget.crawlerEnabled,
        exclude: widget.crawlerExclude,
        whiteList: widget.crawlerWhiteList,
        pages: widget.crawlerPages,
        domains: widget.crawlerDomains,
        parserConfig: widget.parserConfig || '',
    },
    integration: {
        htmlTemplates: widget.htmlTemplates,
        feedbackSettings: widget.feedbackSettings,
    },
    statistics: {
        excludedSearchTerms: statisticsFilter
            ? statisticsFilter.excludedTerms.concat('').join('\n')
            : '',
    },
}));
