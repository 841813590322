import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { Widget } from 'src/types/entities/Widget';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { KeyPhraseFilter } from 'src/types/dto/KeyPhraseFilter';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { IKeyPhraseEndpoint } from 'src/react/api/endpoints/KeyPhraseEndpoint';
import { getKeyPhraseEndpoint } from 'src/react/api/selectors/getEndpoint';

export function* keyPhraseSynonymsCheckQuery(query: string): SagaIterator {
    const widget: Widget = yield select(getSelectedWidget);
    const keyPhraseEndpoint: IKeyPhraseEndpoint = yield select(getKeyPhraseEndpoint);
    const keyPhraseFilter: KeyPhraseFilter = {
        query,
        exact: true,
        state: [],
        itemId: [],
        sort: [],
        page: 0,
        pageSize: 100,
        groups: [],
    };

    const { data }: ListWithTotal<KeyPhrase> = yield call({
        fn: keyPhraseEndpoint.list,
        context: keyPhraseEndpoint,
    }, keyPhraseFilter, { widgetId: widget.id });
    return data;
}
