import './LogoutPage.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export function LogoutSuccessPage(): JSX.Element {
    return (
        <div className="logout-page">
            <div className="logout-page__icon">
                <i className="si si-Success"/>
            </div>
            <h1 className="logout-page__header">
                <FormattedMessage id="logoutPage_header"/>
            </h1>
            <div className="logout-page__text">
                <FormattedMessage id="logoutPage_text"/>
            </div>
            <div className="logout-page__text">
                <a href="/"><FormattedMessage id="logoutPage_link"/></a>
            </div>
        </div>
    );
}
