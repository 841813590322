import { IHttpResponse, IPromise } from 'angular';
import { Config } from 'src/types/constants/Config';
import { Widget } from 'src/types/entities/Widget';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { IApiService } from 'src/modules/api/ApiService';
import { CrawlerTask } from 'src/types/entities/CrawlerTask';
import { CrawlerTaskFilter } from 'src/types/dto/CrawlerTaskFilter';

export interface ICrawlerTaskService {
    list(
        widget: Readonly<Widget>,
        filter: Readonly<CrawlerTaskFilter>,
    ): IPromise<ListWithTotal<CrawlerTask>>;

    get(
        widget: Readonly<Widget>,
        id: string,
    ): IPromise<CrawlerTask>;
}

export class CrawlerTaskService implements ICrawlerTaskService {
    public static $inject = [
        'config',
        'apiService',
    ];
    public constructor(
        private config: Config,
        private apiService: IApiService,
    ) {
    }

    public list(
        widget: Readonly<Widget>,
        filter: CrawlerTaskFilter,
    ): IPromise<ListWithTotal<CrawlerTask>> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
            'crawler-tasks',
        ].join('/');

        return this.apiService
            .makeGETRequest<ListWithTotal<CrawlerTask>>(requestUrl, { params: filter })
            .then(extractResponseData);
    }

    public get(widget: Readonly<Widget>, id: string): IPromise<CrawlerTask> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
            'crawler-tasks',
            encodeURIComponent(id),
        ].join('/');

        return this.apiService
            .makeGETRequest<CrawlerTask>(requestUrl)
            .then(extractResponseData);
    }
}

function extractResponseData<T>(response: IHttpResponse<T>): T {
    return response.data;
}
