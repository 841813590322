import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { Organization } from 'src/types/entities/Organization';
import { getOrganizationEndpoint } from 'src/react/api/selectors/getEndpoint';
import { IOrganizationEndpoint } from 'src/react/api/endpoints/OrganizationEndpoint';

export function* organizationDelete(
    organization: Organization,
): SagaIterator {
    const organizationEndpoint: IOrganizationEndpoint = yield select(getOrganizationEndpoint);

    return yield call({
        fn: organizationEndpoint.delete,
        context: organizationEndpoint,
    }, organization.id, {});
}
