import { DemoPageState } from 'src/react/demoPage/state/DemoPageState';
import { DEMO_SET_VIEW_MODE_ACTIONS, DemoSetViewModeActions } from 'src/react/demoPage/actions/DemoSetViewModeActions';

export function demoSetViewModeReducer(
    state: DemoPageState,
    action: DemoSetViewModeActions,
): DemoPageState {
    switch (action.type) {
        case DEMO_SET_VIEW_MODE_ACTIONS.SUCCESS:
            return { ...state, viewMode: action.data };

        default:
            return state;
    }
}
