import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';
import { NativeError } from 'src/errors/NativeError';
import { STATISTICS_RESET_ACTIONS } from 'src/react/statistics/actions/StatisticsResetActions';

export function* statisticsResetSaga(): SagaIterator {
    try {
        yield put(STATISTICS_RESET_ACTIONS.success(null));
    } catch (error) {
        yield put(STATISTICS_RESET_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
