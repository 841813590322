import { IPromise } from 'angular';
import { User } from 'src/types/entities/User';
import { Config } from 'src/types/constants/Config';
import { IApiService } from 'src/modules/api/ApiService';

export interface IUserService {
    whoAmI(): IPromise<User>;
}

export class UserService implements IUserService {
    public static $inject = [
        'config',
        'apiService',
    ];
    public constructor(
        private config: Config,
        private apiService: IApiService,
    ) {
    }

    public whoAmI(): IPromise<User> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'whoami',
        ].join('/');

        return this.apiService
            .makeGETRequest<User>(requestUrl)
            .then(({ data }) => data);
    }
}
