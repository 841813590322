import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { NativeError } from 'src/errors/NativeError';
import { ITEM_IMAGE_UPLOAD_RESET_ACTIONS } from 'src/react/itemImageUpload/actions/ItemImageUploadResetActions';

export function* itemImageUploadResetSaga(
    action: ActionRequest<null>,
): SagaIterator {
    try {
        yield put(ITEM_IMAGE_UPLOAD_RESET_ACTIONS.pending());
        yield put(ITEM_IMAGE_UPLOAD_RESET_ACTIONS.success(null));
    } catch (error) {
        yield put(ITEM_IMAGE_UPLOAD_RESET_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
