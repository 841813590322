import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { NativeError } from 'src/errors/NativeError';
import { ITEM_FORM_SAVE_RESTORED_ACTIONS } from 'src/react/itemForm/actions/ItemFormSaveRestoredActions';
import { itemSaveRestored } from 'src/react/itemForm/services/itemSaveRestored';
import { Uuid } from 'src/types/values/Uuid';
import { Item } from 'src/types/entities/Item';

export function* itemFormSaveRestoredSaga(
    { data: itemId }: ActionRequest<Uuid>,
): SagaIterator {
    try {
        yield put(ITEM_FORM_SAVE_RESTORED_ACTIONS.pending());

        const restored: Item = yield call(itemSaveRestored, itemId);

        yield put(ITEM_FORM_SAVE_RESTORED_ACTIONS.success(restored));
    } catch (error) {
        yield put(ITEM_FORM_SAVE_RESTORED_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
