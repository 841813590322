import { SagaIterator } from 'redux-saga';
import { all, takeLatest } from 'redux-saga/effects';
import { ITEM_FORM_LOAD_ACTIONS } from 'src/react/itemForm/actions/ItemFormLoadActions';
import { ITEM_FORM_SAVE_ACTIONS } from 'src/react/itemForm/actions/ItemFormSaveActions';
import { ITEM_FORM_VALIDATE_ACTIONS } from 'src/react/itemForm/actions/ItemFormValidateActions';
import { ITEM_FORM_RESTORE_ACTIONS } from 'src/react/itemForm/actions/ItemFormRestoreActions';
import { ITEM_FORM_LOAD_ITEM_ACTIONS } from 'src/react/itemForm/actions/ItemFormLoadItemActions';
import { ITEM_FORM_SAVE_RESTORED_ACTIONS } from 'src/react/itemForm/actions/ItemFormSaveRestoredActions';
import { itemFormLoadSaga } from 'src/react/itemForm/sagas/itemFormLoadSaga';
import { itemFormSaveSaga } from 'src/react/itemForm/sagas/itemFormSaveSaga';
import { itemFormValidateSaga } from 'src/react/itemForm/sagas/itemFormValidateSaga';
import { itemFormRestoreSaga } from 'src/react/itemForm/sagas/itemFormRestoreSaga';
import { itemFormSaveRestoredSaga } from 'src/react/itemForm/sagas/itemFormSaveRestoredSaga';
import { itemFormLoadItemSaga } from 'src/react/itemForm/sagas/itemFormLoadItemSaga';

export function* itemFormSaga(): SagaIterator {
    yield all([
        takeLatest(ITEM_FORM_LOAD_ACTIONS.REQUEST, itemFormLoadSaga),
        takeLatest(ITEM_FORM_SAVE_ACTIONS.REQUEST, itemFormSaveSaga),
        takeLatest(ITEM_FORM_VALIDATE_ACTIONS.REQUEST, itemFormValidateSaga),
        takeLatest(ITEM_FORM_RESTORE_ACTIONS.REQUEST, itemFormRestoreSaga),
        takeLatest(ITEM_FORM_SAVE_RESTORED_ACTIONS.REQUEST, itemFormSaveRestoredSaga),
        takeLatest(ITEM_FORM_LOAD_ITEM_ACTIONS.REQUEST, itemFormLoadItemSaga),
    ]);
}
