import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { NativeError } from 'src/errors/NativeError';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import {
    KEY_PHRASE_PIN_INPUT_SEARCH_ACTIONS,
    KeyPhrasePinInputSearchRequestData,
} from 'src/react/keyPhrasePinInput/actions/KeyPhrasePinInputSearchActions';
import { keyPhraseList } from 'src/react/keyPhrasePinInput/services/keyPhraseList';

export function* keyPhrasePinInputSearchSaga(
    { data: { query, exclude } }: ActionRequest<KeyPhrasePinInputSearchRequestData>,
): SagaIterator {
    try {
        yield put(KEY_PHRASE_PIN_INPUT_SEARCH_ACTIONS.pending());

        const keyPhrases: KeyPhrase[] = yield call(keyPhraseList, query, exclude);

        yield put(KEY_PHRASE_PIN_INPUT_SEARCH_ACTIONS.success(keyPhrases));
    } catch (error) {
        yield put(KEY_PHRASE_PIN_INPUT_SEARCH_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
