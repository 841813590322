import './Collapse.scss';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import { MAX_ANIMATION_TIMEOUT } from 'src/constants/animation';
import { transitionEndListener } from 'src/utils/transitionEndListener';

interface Props {
    readonly expanded: boolean;
    readonly children: React.ReactNode;
}

export function Collapse({ expanded, children }: Props): JSX.Element {
    return (
        <CSSTransition in={expanded}
                       classNames="xss-collapse"
                       appear={false}
                       timeout={MAX_ANIMATION_TIMEOUT}
                       addEndListener={transitionEndListener}
                       mountOnEnter={false}
                       unmountOnExit={true}

                       onExit={setContentHeight}
                       onExiting={setZeroHeight}
                       onExited={cleanupElement}

                       onEnter={setZeroHeight}
                       onEntering={setContentHeight}
                       onEntered={cleanupElement}>
            <div className="xss-collapse">{children}</div>
        </CSSTransition>
    );
}

function setContentHeight(el: HTMLElement): void {
    const bodyHeight = el.querySelector('div')!.clientHeight;
    el.style.height = `${bodyHeight}px`;
}
function setZeroHeight(el: HTMLElement): void {
    el.style.height = '0';
}
function cleanupElement(el: HTMLElement): void {
    el.style.height = '';
}
