// tslint:disable-next-line:import-name
import emptyImage from 'src/images/empty-state-icon__organization.svg';

import './OrganizationList.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { TransitionGroup } from 'react-transition-group';
import { createStructuredSelector } from 'reselect';
import { memoize } from 'lodash-decorators/memoize';
import { connectDecorator } from 'src/decorators/connectDecorator';
import { RootState } from 'src/react/root/state/RootState';
import { Modal } from 'src/react/common/components/Modal';
import { HtmlButton } from 'src/react/common/components/Button';
import { Permission } from 'src/types/entities/Permission';
import { OrganizationListItem } from 'src/react/organization/state/OrganizationListItem';
import { OrganizationItem } from 'src/react/organization/components/OrganizationItem';
import { getOrganizationList } from 'src/react/organization/selectors/getOrganizationList';
import { OrganizationModal } from 'src/react/organization/components/OrganizationModal';
import { OrganizationTeamViewModal } from 'src/react/organization/components/OrganizationTeamViewModal';
import { OrganizationTeamEditModal } from 'src/react/organization/components/OrganizationTeamEditModal';
import { OrganizationLeaveModal } from 'src/react/organization/components/OrganizationLeaveModal';
import { OrganizationDeleteModal } from 'src/react/organization/components/OrganizationDeleteModal';
import {
    ORGANIZATION_LIST_LEAVE_CANCEL_ACTIONS,
    ORGANIZATION_LIST_LEAVE_OPEN_ACTIONS,
    ORGANIZATION_LIST_LEAVE_SUBMIT_ACTIONS,
} from 'src/react/organization/actions/OrganizationListLeaveActions';
import {
    ORGANIZATION_LIST_DELETE_CANCEL_ACTIONS,
    ORGANIZATION_LIST_DELETE_OPEN_ACTIONS,
    ORGANIZATION_LIST_DELETE_SUBMIT_ACTIONS,
} from 'src/react/organization/actions/OrganizationListDeleteActions';
import {
    ORGANIZATION_LIST_CREATE_CANCEL_ACTIONS,
    ORGANIZATION_LIST_CREATE_OPEN_ACTIONS,
    ORGANIZATION_LIST_CREATE_SUBMIT_ACTIONS,
} from 'src/react/organization/actions/OrganizationListCreateActions';
import {
    ORGANIZATION_LIST_UPDATE_CANCEL_ACTIONS,
    ORGANIZATION_LIST_UPDATE_OPEN_ACTIONS,
    ORGANIZATION_LIST_UPDATE_SUBMIT_ACTIONS,
} from 'src/react/organization/actions/OrganizationListUpdateActions';
import {
    ORGANIZATION_LIST_TEAM_CLOSE_ACTIONS,
    ORGANIZATION_LIST_TEAM_CREATE_ACTIONS,
    ORGANIZATION_LIST_TEAM_DELETE_ACTIONS,
    ORGANIZATION_LIST_TEAM_OPEN_ACTIONS,
} from 'src/react/organization/actions/OrganizationListTeamActions';
import { OrganizationModalState } from 'src/react/organization/state/OrganizationModalState';
import { getModalState } from 'src/react/organization/selectors/getModalState';
import { UserRole } from 'src/types/values/UserRole';

export type OwnProps = {};
export type StateProps = {
    readonly organizations: ReadonlyArray<OrganizationListItem>;
    readonly modalState: OrganizationModalState
};
type DispatchProps = {
    readonly leaveModalOpen: (item: OrganizationListItem) => void;
    readonly leaveModalSubmit: () => void;
    readonly leaveModalCancel: () => void;

    readonly deleteModalOpen: (item: OrganizationListItem) => void;
    readonly deleteModalSubmit: () => void;
    readonly deleteModalCancel: () => void;

    readonly createModalOpen: () => void;
    readonly createModalSubmit: () => void;
    readonly createModalCancel: () => void;

    readonly updateModalOpen: (item: OrganizationListItem) => void;
    readonly updateModalSubmit: () => void;
    readonly updateModalCancel: () => void;

    readonly teamModalOpen: (item: OrganizationListItem) => void;
    readonly teamModalClose: () => void;
    readonly teamModalCreate: () => void;
    readonly teamModalDelete: (permission: Permission) => void;
};
type AllProps =
    & OwnProps
    & StateProps
    & DispatchProps;

class Connected extends React.Component<AllProps> {
    public render(): JSX.Element {
        const { createModalOpen } = this.props;

        return (
            <div className="organization-list">
                <div className="top-block">
                    <div className="top-block__left">
                        <h1><FormattedMessage id="organizationList_pageTitle"/></h1>
                    </div>
                    <div className="top-block__right">
                        <HtmlButton intent="secondary" onClick={createModalOpen}>
                            <i className="si si-Add"/>{' '}
                            <FormattedMessage id="organizationList_createOrganization"/>
                        </HtmlButton>
                    </div>
                </div>

                <div className="organization-list__page">
                    {this.renderContent()}
                    {this.renderEmpty()}
                    {this.renderTeamModal()}
                    {this.renderLeaveModal()}
                    {this.renderDeleteModal()}
                    {this.renderCreateModal()}
                    {this.renderUpdateModal()}
                </div>
            </div>
        );
    }

    private renderContent(): JSX.Element | null {
        const { organizations } = this.props;
        if (organizations.length === 0) {
            return null;
        }

        return (
            <div className="organization-list__content">
                {organizations.map((item) => this.renderItem(item))}
            </div>
        );
    }

    private renderEmpty(): JSX.Element | null {
        const { organizations } = this.props;
        if (organizations.length > 0) {
            return null;
        }

        return (
            <div className="organization-list__empty">
                <div className="organization-list__empty-image"
                     style={{ backgroundImage: `url(${emptyImage})` }}/>
                <div className="organization-list__empty-text">
                    <FormattedMessage id="organizationList_emptyText"/>
                </div>
            </div>
        );
    }

    private renderItem(item: OrganizationListItem): JSX.Element {
        return (
            <div className="organization-list__item" key={item.organization.id}>
                <OrganizationItem item={item}
                                  onTeam={this.getTeamModalHandler(item)}
                                  onLeave={this.getLeaveModalHandler(item)}
                                  onDelete={this.getDeleteModalHandler(item)}
                                  onUpdate={this.getUpdateModalHandler(item)}/>
            </div>
        );
    }

    private renderTeamModal(): JSX.Element | null {
        const { modalState, teamModalClose, teamModalCreate, teamModalDelete } = this.props;

        return (
            <Modal isOpen={modalState.type === 'team'}>
                <TransitionGroup>
                    {modalState.type !== 'team' ? null : (
                        modalState.organization.organization.permission.userRole === UserRole.Admin
                            ? (
                                <OrganizationTeamEditModal organization={modalState.organization}
                                                           onClose={teamModalClose}
                                                           onCreate={teamModalCreate}
                                                           onDelete={teamModalDelete}/>
                            )
                            : (
                                <OrganizationTeamViewModal organization={modalState.organization}
                                                           onClose={teamModalClose}/>
                            )
                    )}
                </TransitionGroup>
            </Modal>
        );
    }

    private renderLeaveModal(): JSX.Element | null {
        const { modalState, leaveModalCancel, leaveModalSubmit } = this.props;

        return (
            <Modal isOpen={modalState.type === 'leave'}>
                <TransitionGroup>
                    {modalState.type !== 'leave' ? null : (
                        <OrganizationLeaveModal organization={modalState.organization}
                                                onCancel={leaveModalCancel}
                                                onSubmit={leaveModalSubmit}/>
                    )}
                </TransitionGroup>
            </Modal>
        );
    }

    private renderDeleteModal(): JSX.Element | null {
        const { modalState, deleteModalCancel, deleteModalSubmit } = this.props;

        return (
            <Modal isOpen={modalState.type === 'delete'}>
                <TransitionGroup>
                    {modalState.type !== 'delete' ? null : (
                        <OrganizationDeleteModal organization={modalState.organization}
                                                 onCancel={deleteModalCancel}
                                                 onSubmit={deleteModalSubmit}/>
                    )}
                </TransitionGroup>
            </Modal>
        );
    }

    private renderCreateModal(): JSX.Element | null {
        const { modalState, createModalCancel, createModalSubmit } = this.props;

        return (
            <Modal isOpen={modalState.type === 'create'}>
                <OrganizationModal organization={null}
                                   onCancel={createModalCancel}
                                   onSubmit={createModalSubmit}/>
            </Modal>
        );
    }

    private renderUpdateModal(): JSX.Element | null {
        const { modalState, updateModalCancel, updateModalSubmit } = this.props;

        return (
            <Modal isOpen={modalState.type === 'update'}>
                <TransitionGroup>
                    {modalState.type !== 'update' ? null : (
                        <OrganizationModal organization={modalState.organization}
                                           onCancel={updateModalCancel}
                                           onSubmit={updateModalSubmit}/>
                    )}
                </TransitionGroup>
            </Modal>
        );
    }

    @memoize()
    private getTeamModalHandler(item: OrganizationListItem): () => void {
        return () => {
            const { teamModalOpen } = this.props;
            teamModalOpen(item);
        };
    }

    @memoize()
    private getLeaveModalHandler(item: OrganizationListItem): () => void {
        return () => {
            const { leaveModalOpen } = this.props;
            leaveModalOpen(item);
        };
    }

    @memoize()
    private getDeleteModalHandler(item: OrganizationListItem): () => void {
        return () => {
            const { deleteModalOpen } = this.props;
            deleteModalOpen(item);
        };
    }

    @memoize()
    private getUpdateModalHandler(item: OrganizationListItem): () => void {
        return () => {
            const { updateModalOpen } = this.props;
            updateModalOpen(item);
        };
    }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
    organizations: getOrganizationList,
    modalState: getModalState,
});
const mapDispatchToProps: DispatchProps = {
    leaveModalOpen: (item) => ORGANIZATION_LIST_LEAVE_OPEN_ACTIONS.request(item),
    leaveModalCancel: () => ORGANIZATION_LIST_LEAVE_CANCEL_ACTIONS.request(),
    leaveModalSubmit: () => ORGANIZATION_LIST_LEAVE_SUBMIT_ACTIONS.request(),

    deleteModalOpen: (item) => ORGANIZATION_LIST_DELETE_OPEN_ACTIONS.request(item),
    deleteModalCancel: () => ORGANIZATION_LIST_DELETE_CANCEL_ACTIONS.request(),
    deleteModalSubmit: () => ORGANIZATION_LIST_DELETE_SUBMIT_ACTIONS.request(),

    createModalOpen: () => ORGANIZATION_LIST_CREATE_OPEN_ACTIONS.request(),
    createModalCancel: () => ORGANIZATION_LIST_CREATE_CANCEL_ACTIONS.request(),
    createModalSubmit: () => ORGANIZATION_LIST_CREATE_SUBMIT_ACTIONS.request(),

    updateModalOpen: (item) => ORGANIZATION_LIST_UPDATE_OPEN_ACTIONS.request(item),
    updateModalCancel: () => ORGANIZATION_LIST_UPDATE_CANCEL_ACTIONS.request(),
    updateModalSubmit: () => ORGANIZATION_LIST_UPDATE_SUBMIT_ACTIONS.request(),

    teamModalOpen: (item) => ORGANIZATION_LIST_TEAM_OPEN_ACTIONS.request(item),
    teamModalClose: () => ORGANIZATION_LIST_TEAM_CLOSE_ACTIONS.request(),
    teamModalCreate: () => ORGANIZATION_LIST_TEAM_CREATE_ACTIONS.request(),
    teamModalDelete: (permission) => ORGANIZATION_LIST_TEAM_DELETE_ACTIONS.request(permission),
};

@connectDecorator<OwnProps, StateProps, DispatchProps>(Connected, mapStateToProps, mapDispatchToProps)
export class OrganizationList extends React.Component<OwnProps> {}
