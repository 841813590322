import { SagaIterator } from 'redux-saga';
import { all, takeLatest } from 'redux-saga/effects';
import { cancelSagaOn } from 'src/utils/cancelSagaOn';
import { ITEM_IMAGE_UPLOAD_UPLOAD_ACTIONS } from 'src/react/itemImageUpload/actions/ItemImageUploadUploadActions';
import { ITEM_IMAGE_UPLOAD_RESET_ACTIONS } from 'src/react/itemImageUpload/actions/ItemImageUploadResetActions';
import { itemImageUploadUploadSaga } from 'src/react/itemImageUpload/sagas/itemImageUploadUploadSaga';
import { itemImageUploadResetSaga } from 'src/react/itemImageUpload/sagas/itemImageUploadResetSaga';

export function* itemImageUploadSaga(): SagaIterator {
    yield all([
        takeLatest(ITEM_IMAGE_UPLOAD_UPLOAD_ACTIONS.REQUEST, cancelSagaOn(itemImageUploadUploadSaga, [
            ITEM_IMAGE_UPLOAD_RESET_ACTIONS.REQUEST,
            ITEM_IMAGE_UPLOAD_UPLOAD_ACTIONS.REQUEST,
        ])),
        takeLatest(ITEM_IMAGE_UPLOAD_RESET_ACTIONS.REQUEST, cancelSagaOn(itemImageUploadResetSaga, [
            ITEM_IMAGE_UPLOAD_RESET_ACTIONS.REQUEST,
            ITEM_IMAGE_UPLOAD_UPLOAD_ACTIONS.REQUEST,
        ])),
    ]);
}
