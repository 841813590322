import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { NativeError } from 'src/errors/NativeError';
import { KEY_PHRASE_PIN_INPUT_RESET_ACTIONS } from 'src/react/keyPhrasePinInput/actions/KeyPhrasePinInputResetActions';

export function* keyPhrasePinInputResetSaga(
    action: ActionRequest<null>,
): SagaIterator {
    try {
        yield put(KEY_PHRASE_PIN_INPUT_RESET_ACTIONS.pending());
        yield put(KEY_PHRASE_PIN_INPUT_RESET_ACTIONS.success(null));
    } catch (error) {
        yield put(KEY_PHRASE_PIN_INPUT_RESET_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
