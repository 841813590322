import { UiState } from 'src/react/common/state/UiState';
import { UI_MODAL_CLOSE, UI_MODAL_OPEN, UiModalAction } from 'src/react/common/actions/UiModalActions';

export function uiModalReducer(
    state: UiState,
    action: UiModalAction,
): UiState {
    switch (action.type) {
        case UI_MODAL_OPEN:
            return state.openModals.includes(action.modalId)
                ? state
                : { ...state, openModals: [...state.openModals, action.modalId] };

        case UI_MODAL_CLOSE:
            return state.openModals.includes(action.modalId)
                ? { ...state, openModals: state.openModals.filter((id) => id !== action.modalId) }
                : state;

        default:
            return state;
    }
}
