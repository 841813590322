import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { StateService } from '@uirouter/core';
import { ngSelect } from 'src/utils/ngSelect';

export function* updateRoute(): SagaIterator {
    const $state: StateService = yield select(ngSelect, '$state');
    if ($state.current.parent && $state.current.parent === 'unauthorized') {
        yield call($state.reload, 'unauthorized');
    } else {
        yield call($state.reload, 'authorized');
    }
}
