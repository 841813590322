import './SettingsFormBoostField.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { parseNumberValue } from 'src/utils/parseNumberValue';
import { RangeField } from 'src/react/forms/components/RangeField';

type Props = {
    readonly name: string;
};

export function SettingsFormBoostField({ name }: Props): JSX.Element {
    return (
        <div className="settings-form-boost-field">
            <div className="settings-form-boost-field__impact">
                <FormattedMessage id="settings_customBoost_impact"/>
            </div>
            <div className="settings-form-boost-field__input">
                <RangeField name={name}
                            normalize={normalizeBoostValue}
                            min={0}
                            max={10}
                            step={0.5}/>
            </div>
        </div>
    );
}

function normalizeBoostValue(value: unknown): number {
    const n = parseNumberValue(value);
    return n === null ? 0 : Math.max(Math.min(n, 10), 0);
}
