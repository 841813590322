import './OrganizationModalForm.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { InnerFormProps, OuterFormProps, withFormDecorator } from 'src/decorators/withFormDecorator';
import { OrganizationForm } from 'src/types/dto/OrganizationForm';
import { HtmlButton } from 'src/react/common/components/Button';
import { LoadingWrapper } from 'src/react/common/components/LoadingWrapper';
import { ModalBody, ModalClose, ModalFooter, ModalHeader } from 'src/react/common/components/Modal';
import { TextboxField } from 'src/react/forms/components/TextboxField';
import { FormFieldError } from 'src/react/forms/components/FormFieldError';

type Props = {
    readonly mode: 'create' | 'update';
    readonly onCancel: () => void;
    readonly onSubmit: (formData: OrganizationForm) => void;
};

class Connected extends React.Component<InnerFormProps<OrganizationForm, Props>> {
    public render(): JSX.Element {
        const { mode, onSubmit, onCancel } = this.props;
        const {
            handleSubmit,
            submitting,
            submitSucceeded,
            submitFailed,
            asyncValidating,
            invalid,
        } = this.props;

        const isPending = submitting || submitSucceeded || asyncValidating === true;
        const isDisabled = submitFailed ? invalid : isPending;

        return (
            <form className="organization-modal-form" onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader className="organization-modal-form__header">
                    <ModalClose className="organization-modal-form__close"
                                onClick={onCancel}/>
                    <div className="organization-modal-form__title">
                        {mode === 'create'
                            ? <FormattedMessage id="organizationForm_createTitle"/>
                            : <FormattedMessage id="organizationForm_updateTitle"/>}
                    </div>
                </ModalHeader>
                <ModalBody className="organization-modal-form__content">
                    <LoadingWrapper loading={isPending}>
                        <div className="organization-modal-form">
                            <div className="organization-modal-form__row">
                                <div className="organization-modal-form__label">
                                    <FormattedMessage id="organizationForm_name"/>
                                </div>
                                <div className="organization-modal-form__value">
                                    <TextboxField type="text"
                                                  name="name"
                                                  autoComplete="off"/>
                                </div>
                                <FormFieldError className="organization-modal-form__error"
                                                field="name"/>
                            </div>
                        </div>
                    </LoadingWrapper>
                </ModalBody>
                <ModalFooter className="organization-modal-form__footer">
                    <div className="organization-modal-form__action">
                        <HtmlButton block={false}
                                    type="button"
                                    intent="none"
                                    onClick={onCancel}>
                            <FormattedMessage id="organizationForm_cancelButton"/>
                        </HtmlButton>
                    </div>
                    <div className="organization-modal-form__action">
                        <HtmlButton block={false}
                                    type="submit"
                                    intent="secondary"
                                    disabled={isDisabled}>
                            {mode === 'create'
                                ? <FormattedMessage id="organizationForm_createButton"/>
                                : <FormattedMessage id="organizationForm_updateButton"/>}
                        </HtmlButton>
                    </div>
                </ModalFooter>
            </form>
        );
    }
}

@withFormDecorator<OrganizationForm, Props>(Connected, {})
export class OrganizationModalForm extends React.Component<OuterFormProps<OrganizationForm, Props>> {}
