export const UI_POPOVER_OPEN = 'UI_POPOVER_OPEN';
export const UI_POPOVER_CLOSE = 'UI_POPOVER_CLOSE';

export type UiPopoverAction =
    | UiPopoverOpen
    | UiPopoverClose;

export interface UiPopoverOpen {
    readonly type: typeof UI_POPOVER_OPEN;
    readonly popoverId: string;
}
export interface UiPopoverClose {
    readonly type: typeof UI_POPOVER_CLOSE;
    readonly popoverId: string;
}

export function uiPopoverOpen(popoverId: string): UiPopoverOpen {
    return {
        type: UI_POPOVER_OPEN,
        popoverId,
    };
}
export function uiPopoverClose(popoverId: string): UiPopoverClose {
    return {
        type: UI_POPOVER_CLOSE,
        popoverId,
    };
}
