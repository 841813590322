import './Range.scss';
import * as React from 'react';
import { Omit } from 'mapped-types-utils';
import { classList } from 'src/utils/classList';

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'>;

export function Range({ name, className, ...inputProps }: Props): JSX.Element {
    return (
        <input {...inputProps}
               className={classList(className, 'xss-range')}
               type="range"
               name={name}
               data-field={name}/>
    );
}
