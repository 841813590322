import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';
import { NativeError } from 'src/errors/NativeError';
import { DEMO_RESET_ACTIONS } from 'src/react/demoPage/actions/DemoResetActions';

export function* demoResetSaga(): SagaIterator {
    try {
        yield put(DEMO_RESET_ACTIONS.success(null));
    } catch (error) {
        yield put(DEMO_RESET_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
