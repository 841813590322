import { IComponentController } from 'angular';
import { Widget } from 'src/types/entities/Widget';
import { CrawlerTask } from 'src/types/entities/CrawlerTask';
import { CrawlerStatus } from 'src/types/values/CrawlerStatus';

export class CrawlerStatusController implements IComponentController {
    public widget!: Widget;
    public crawlerTask!: CrawlerTask;

    public getStatusIcon(): string {
        switch (this.crawlerTask.status) {
            case CrawlerStatus.Pending:
            case CrawlerStatus.Running:
                return 'in';

            case CrawlerStatus.Success:
            case CrawlerStatus.Aborted:
                return 'ok';

            case CrawlerStatus.Failure:
                return 'fail';

            default:
                return 'none';
        }
    }
}
