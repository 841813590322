import { IndexLanguage } from 'src/types/values/IndexLanguage';

interface Language {
    code: IndexLanguage;
    name: string;
}

export const WIDGET_LANGUAGES: Language[] = [
    { code: 'en', name: 'English' },
    { code: 'de', name: 'German' },
    { code: 'fr', name: 'French' },
    { code: 'it', name: 'Italian' },
    { code: 'ru', name: 'Russian' },
    { code: 'br', name: 'Brazilian' },
    { code: 'ar', name: 'Arabic' },
    { code: 'zh', name: 'Chinese' },
];

export const MIN_KEY_PHRASE_LENGTH = 2;
