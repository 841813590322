import { InitializedReducer } from 'redux-utils';

type PartialReducerMap<TState> =  {
    [TKey in keyof TState]?: InitializedReducer<TState[TKey], any>;
};

export function partialReducers<TState extends {} | null>(
    reducers: PartialReducerMap<TState>,
): InitializedReducer<TState> {
    return (state, action) => {
        if (!state) {
            return state;
        }

        return Object.keys(reducers).reduce((result, key) => {
            const reducer = reducers[key as keyof TState]!;

            const prevVal = result![key as keyof TState];
            const nextVal = reducer(prevVal, action);

            return prevVal === nextVal
                ? result
                : Object.assign({}, result, { [key]: nextVal });
        }, state);
    };
}
