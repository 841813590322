import IntlMessageFormat from 'intl-messageformat';
import { MessageValue } from 'react-intl';
import { I18N_MESSAGES } from 'src/react/root/constants/i18nMessages';
import { ILanguageService } from 'src/services/languageService';
import { SiteLanguages } from 'src/types/values/siteLanguage';

const intlCache: Record<string, IntlMessageFormat> = {};

i18nFilter.$inject = [
    'languageService',
];
export function i18nFilter(
    languageService: ILanguageService,
): (messageId: string, params?: Readonly<Record<string, MessageValue>>)  => string {
    return (messageId, params) => {
        const locale: SiteLanguages = languageService.get();
        const cacheKey = `${locale}-${messageId}`;
        if (!intlCache[cacheKey]) {
            const messageText = I18N_MESSAGES[locale][messageId] || messageId;
            intlCache[cacheKey] = new IntlMessageFormat(messageText, locale);
        }

        return intlCache[cacheKey].format(params);
    };
}
