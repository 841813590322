import { FormErrors, getFormValues, reset, startSubmit, stopSubmit } from 'redux-form';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { NativeError } from 'src/errors/NativeError';
import { ActionRequest } from 'src/utils/createActions';
import { FieldErrors } from 'src/types/values/FieldError';
import { Permission } from 'src/types/entities/Permission';
import { PermissionForm } from 'src/types/dto/PermissionForm';
import { OrganizationListItem } from 'src/react/organization/state/OrganizationListItem';
import {
    ORGANIZATION_LIST_TEAM_CLOSE_ACTIONS,
    ORGANIZATION_LIST_TEAM_CREATE_ACTIONS,
    ORGANIZATION_LIST_TEAM_DELETE_ACTIONS,
    ORGANIZATION_LIST_TEAM_OPEN_ACTIONS,
} from 'src/react/organization/actions/OrganizationListTeamActions';
import { getSelectedOrganization } from 'src/react/organization/selectors/getSelectedOrganization';
import { permissionCreate } from 'src/react/organization/services/permissionCreate';
import { permissionDelete } from 'src/react/organization/services/permissionDelete';
import { handleFormError } from 'src/react/organization/services/handleFormError';

export function* organizationListTeamOpenSaga(
    action: ActionRequest<OrganizationListItem>,
): SagaIterator {
    yield put(ORGANIZATION_LIST_TEAM_OPEN_ACTIONS.success(action.data));
}

export function* organizationListTeamCloseSaga(): SagaIterator {
    yield put(ORGANIZATION_LIST_TEAM_CLOSE_ACTIONS.success());
}

export function* organizationListTeamCreateSaga(): SagaIterator {
    try {
        yield put(ORGANIZATION_LIST_TEAM_CREATE_ACTIONS.pending());
        yield put(startSubmit('permission-create'));

        const selector = yield call(getFormValues, 'permission-create');
        const formData: PermissionForm = yield select(selector);
        const item: OrganizationListItem = yield select(getSelectedOrganization);
        const permission: Permission = yield call(permissionCreate, item.organization, formData);

        const updated: OrganizationListItem = {
            ...item,
            permissions: [...item.permissions, permission],
        };

        yield put(ORGANIZATION_LIST_TEAM_CREATE_ACTIONS.success(updated));
        yield put(reset('permission-create'));
    } catch (error) {
        const handledError = NativeError.wrapError(error);
        const formErrors: FormErrors<{}, FieldErrors> = yield call(handleFormError, handledError);

        yield put(ORGANIZATION_LIST_TEAM_CREATE_ACTIONS.failure(handledError));
        yield put(stopSubmit('permission-create', formErrors));
    }
}

export function* organizationListTeamDeleteSaga(
    { data: permission }: ActionRequest<Permission>,
): SagaIterator {
    try {
        yield put(ORGANIZATION_LIST_TEAM_DELETE_ACTIONS.pending());
        yield put(startSubmit(`permission-delete--${permission.id}`));

        const item: OrganizationListItem = yield select(getSelectedOrganization);
        yield call(permissionDelete, item.organization, permission);

        const updated: OrganizationListItem = {
            ...item,
            permissions: item.permissions.filter((other) => other.id !== permission.id),
        };

        yield put(ORGANIZATION_LIST_TEAM_DELETE_ACTIONS.success(updated));
        yield put(stopSubmit(`permission-delete--${permission.id}`));
    } catch (error) {
        const handledError = NativeError.wrapError(error);
        const formErrors: FormErrors<{}, FieldErrors> = yield call(handleFormError, handledError);

        yield put(ORGANIZATION_LIST_TEAM_DELETE_ACTIONS.failure(handledError));
        yield put(stopSubmit(`permission-delete--${permission.id}`, formErrors));
    }
}
