import angular from 'angular';

angular.module('backend.widgets.imageUploadService', []).service('imageUploadService', [
    'apiService',
    'config',
    function (apiService, config) {
        function sendFile(widgetId, data, cancelDefer) {
            const options = {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined,
                },
            };
            if (cancelDefer && cancelDefer.promise) {
                options.timeout = cancelDefer.promise;
            }
            return apiService.makePOSTRequest(
                `${config.services.adminApiUrl}/widgets/${encodeURIComponent(widgetId)}/images`,
                data,
                options,
            );
        }

        return {
            sendFile,
        };
    },
]);
