import { createSelector } from 'reselect';
import { getConfig } from 'src/react/root/selectors/getConfig';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';

export const getSnippetCode = createSelector([
    getConfig,
    getSelectedWidget,
], (config, widget) => {
    const scriptSrc = `${config.services.publicApiUrl}/${encodeURIComponent(widget.apiKey)}/widget.js`;

    return `
<div id="widget-${widget.apiKey}"></div>
<script src="${scriptSrc}" type="text/javascript" async="async" defer="defer"></script>
        `.trim();
});
