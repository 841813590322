import './ValidationIcon.scss';
import * as React from 'react';

type Props = {
    readonly state: 'valid' | 'invalid' | 'pending';
};
export function ValidationIcon({ state }: Props): JSX.Element {
    if (state === 'valid') {
        return (
            <span className="xss-validation-icon xss-validation-icon--valid">
                <i className="fa fa-check"/>
            </span>
        );
    }

    if (state === 'invalid') {
        return (
            <span className="xss-validation-icon xss-validation-icon--invalid">
                <i className="fa fa-times"/>
            </span>
        );
    }

    return (
        <span className="xss-validation-icon xss-validation-icon--pending">
            <i className="fa fa-spinner fa-spin"/>
        </span>
    );
}
