import { ManualCrawlerState } from 'src/react/manualCrawler/state/ManualCrawlerState';
import {
    MANUAL_CRAWLER_START_ACTIONS,
    ManualCrawlerStartActions,
} from 'src/react/manualCrawler/actions/ManualCrawlerStartActions';

export function manualCrawlerStartReducer(
    state: ManualCrawlerState,
    action: ManualCrawlerStartActions,
): ManualCrawlerState {
    switch (action.type) {
        case MANUAL_CRAWLER_START_ACTIONS.PENDING:
            return { ...state, loading: true };

        case MANUAL_CRAWLER_START_ACTIONS.FAILURE:
            return { ...state, loading: false, error: action.data };

        case MANUAL_CRAWLER_START_ACTIONS.SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                crawlerTask: action.data,
                fetchedUrls: [],
                progress: {
                    total: action.data.total,
                    notfound: action.data.notfound,
                    redirected: action.data.redirected,
                    failed: action.data.failed,
                    downloaded: action.data.downloaded,
                    processed: action.data.processed,
                    queued: action.data.queued,
                    noindex: action.data.noindex,
                    status: action.data.status,
                },
            };

        default:
            return state;
    }
}
