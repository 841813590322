import { module } from 'angular';
import { INDEX_LAYOUT_COMPONENT } from 'src/modules/layout/IndexLayoutComponent';
import { WIDGET_LAYOUT_COMPONENT } from 'src/modules/layout/WidgetLayoutComponent';

export const LAYOUT_MODULE = module('layout', [])
    /**
     * @ngdoc directive
     * @name indexLayout
     * @restrict E
     *
     * @param {expression} version
     * @param {expression} language
     */
    .component(
        'indexLayout',
        INDEX_LAYOUT_COMPONENT,
    )
    /**
     * @ngdoc directive
     * @name widgetLayout
     * @restrict E
     *
     * @param {expression} widget
     * @param {expression} organization
     */
    .component(
        'widgetLayout',
        WIDGET_LAYOUT_COMPONENT,
    );
