import { createActions } from 'src/utils/createActions';
import { SettingsFormData } from 'src/react/settings/types/SettingsFormData';

export type SettingsFormValidateRequestData = {
    readonly formData: SettingsFormData;
    readonly immediate: boolean;
};

export const SETTINGS_FORM_VALIDATE_ACTIONS =
    createActions<SettingsFormValidateRequestData, void>('SETTINGS_FORM_VALIDATE');
