import { module } from 'angular';
import { KEYPHRASE_POPUP_COMPONENT } from 'src/modules/keyPhrasePopup/KeyPhrasePopupComponent';

export const KEY_PHRASE_POPUP_MODULE = module('keyPhrasePopup', [])
    /**
     * @ngdoc directive
     * @name keyPhrasePopup
     * @restrict E
     *
     * @param {expression} resolve
     * @param {expression} modalInstance
     */
    .component(
        'keyPhrasePopup',
        KEYPHRASE_POPUP_COMPONENT,
    );
