import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { Organization } from 'src/types/entities/Organization';
import { PermissionForm } from 'src/types/dto/PermissionForm';
import { getPermissionEndpoint } from 'src/react/api/selectors/getEndpoint';
import { IPermissionEndpoint } from 'src/react/api/endpoints/PermissionEndpoint';

export function* permissionCreate(
    organization: Organization,
    formData: PermissionForm,
): SagaIterator {
    const permissionEndpoint: IPermissionEndpoint = yield select(getPermissionEndpoint);

    return yield call({
        fn: permissionEndpoint.create,
        context: permissionEndpoint,
    }, formData, { organizationId: organization.id });
}
