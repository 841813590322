import { Ng1StateDeclaration } from '@uirouter/angularjs';

export const ROUTES_STATES_DEMO: ReadonlyArray<Ng1StateDeclaration> = [
    {
        name: 'demo',
        parent: 'authorized:widget',
        url: '/demo?query&page&mode&fuzzy&pinMode',
        component: 'demoPage',
        reloadOnSearch: false,
        resolve: {
            $title: () => {
                return 'Search Demo';
            },
        },
        params: {
            query: {
                type: 'string',
                value: '',
                squash: false,
            },
            page: {
                type: 'int',
                value: 1,
                squash: true,
            },
            mode: {
                type: 'string',
                value: '',
                squash: true,
            },
            fuzzy: {
                type: 'string',
                value: '',
                squash: true,
            },
            pinMode: {
                type: 'bool',
                value: false,
                squash: true,
            },
        },
    },
];
