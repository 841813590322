import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { CustomField } from 'src/types/entities/CustomField';
import { SettingsFormData } from 'src/react/settings/types/SettingsFormData';
import { settingsFormSaveCustomFields } from 'src/react/settings/services/settingsFormSaveCustomFields';
import { settingsFormSaveWidget } from 'src/react/settings/services/settingsFormSaveWidget';
import { settingsFormSaveStatisticsFilter } from 'src/react/settings/services/settingsFormSaveStatisticsFilter';

export function* settingsFormSave(
    formData: SettingsFormData,
): SagaIterator {
    const customFields: ReadonlyArray<CustomField>
        = yield call(settingsFormSaveCustomFields, formData);

    yield call(settingsFormSaveWidget, formData, customFields);
    yield call(settingsFormSaveStatisticsFilter, formData);
}
