import { module } from 'angular';
import { TransitionService } from '@uirouter/angularjs';

export const TITLE_MODULE = module('levo.router.title', ['ui.router'])
    .run([
        '$transitions',
        ($transitions: TransitionService) => {
        $transitions.onSuccess({}, (trans) => {
            document.title = `LEVO - ${trans.injector().get('$title')}`;
        });
    }]);
