import { assertNotNull } from 'src/utils/assertion';
import { DemoPageState } from 'src/react/demoPage/state/DemoPageState';
import {
    DEMO_UPDATE_SYNONYMS_ACTIONS,
    DemoUpdateSynonymsActions,
} from 'src/react/demoPage/actions/DemoUpdateSynonymsActions';

export function demoUpdateSynonymsReducer(
    state: DemoPageState,
    action: DemoUpdateSynonymsActions,
): DemoPageState {
    switch (action.type) {
        case DEMO_UPDATE_SYNONYMS_ACTIONS.SUCCESS:
            return {
                ...state,
                pinMode: true,
                data: {
                    ...assertNotNull(state.data, 'Demo page data has to be fetched first', { state, action }),
                    synonyms: action.data,
                },
            };

        default:
            return state;
    }
}
