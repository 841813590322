import { ItemFormSaveState } from 'src/react/itemForm/state/ItemFormSaveState';
import { ITEM_FORM_SAVE_ACTIONS, ItemFormSaveActions } from 'src/react/itemForm/actions/ItemFormSaveActions';

export function itemFormSaveReducer(
    state: ItemFormSaveState,
    action: ItemFormSaveActions,
): ItemFormSaveState {
    switch (action.type) {
        case ITEM_FORM_SAVE_ACTIONS.PENDING:
            return { ...state, loading: true, error: null };

        case ITEM_FORM_SAVE_ACTIONS.SUCCESS:
            return { ...state, loading: false, data: action.data, error: null };

        case ITEM_FORM_SAVE_ACTIONS.FAILURE:
            return { ...state, loading: false, data: null, error: action.data };

        default:
            return state;
    }
}
