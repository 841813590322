import { SagaIterator } from 'redux-saga';
import { select } from 'redux-saga/effects';
import { ItemForm } from 'src/types/dto/ItemForm';
import { CustomField } from 'src/types/entities/CustomField';
import { getCustomFields } from 'src/react/selectedWidget/selectors/getCustomFields';
import { ItemFormValue } from 'src/react/itemForm/types/ItemFormValue';

export function* itemFormMapToApi(formData: ItemFormValue): SagaIterator {
    const customFields: ReadonlyArray<CustomField> = yield select(getCustomFields);

    const mappedForm: ItemForm = {
        state: formData.state,
        title: formData.title,
        content: formData.content,
        description: formData.description,
        url: formData.url,
        image: formData.image === null ? '' : formData.image,
        linkText: formData.linkText,
        customFields: customFields.reduce<ItemFormValue['customFields']>((result, field) => (
            formData.customFields[field.id] !== undefined
                ? { ...result, [field.id]: formData.customFields[field.id] }
                : result
        ), {}),
    };

    return mappedForm;
}
