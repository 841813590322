import { IPromise } from 'angular';
import { IModalService } from 'angular-ui-bootstrap';
import { Widget } from 'src/types/entities/Widget';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { Organization } from 'src/types/entities/Organization';
import { KeyPhraseGroup } from 'src/types/entities/KeyPhraseGroup';
import { KeyPhraseGroupFormForModal } from 'src/types/dto/KeyPhraseGroupFormForModal';
import { IKeyPhraseMappingService } from 'src/modules/keyPhrase/KeyPhraseMappingService';
import { Uuid } from 'src/types/values/Uuid';
import { StateService } from '@uirouter/core';

export interface IKeyPhraseDialogService {
    createPhrase(widget: Readonly<Widget>): IPromise<Widget>;
    manageSynonyms(
        widget: Readonly<Widget>,
        organization: Readonly<Organization>,
        keyPhrase: Readonly<KeyPhrase>,
    ): IPromise<string[]>;
    deleteKeyPhrase(
        widget: Readonly<Widget>,
        organization: Readonly<Organization>,
        keyPhrase: Readonly<KeyPhrase>,
    ): IPromise<KeyPhrase>;
    manageGroup(
        widget: Readonly<Widget>,
        keyPhraseGroup: Readonly<KeyPhraseGroup>| null,
    ): IPromise<KeyPhraseGroupFormForModal>;
}

export class KeyPhraseDialogService implements IKeyPhraseDialogService {
    public static $inject = [
        '$uibModal',
        'KeyPhraseMappingService',
    ];
    public constructor(
        private $uibModal: IModalService,
        private keyPhraseMappingService: IKeyPhraseMappingService,
    ) {
    }

    public createPhrase(widget: Readonly<Widget>):  IPromise<Widget> {
         return this.$uibModal.open({
            animation: true,
            backdrop: 'static',
            component: 'keyPhrasePopup',
            resolve: {
                widget: () => widget,
            },
        }).result;
    }

    public manageSynonyms(
        widget: Readonly<Widget>,
        organization: Readonly<Organization>,
        keyPhrase: Readonly<KeyPhrase>,
    ): IPromise<string[]> {
        return this.$uibModal.open({
            animation: true,
            backdrop: 'static',
            component: 'keyPhraseSynonyms',
            resolve: {
                widget: () => widget,
                organization: () => organization,
                keyPhrase: () => keyPhrase,
            },
        }).result;
    }

    public deleteKeyPhrase(
        widget: Readonly<Widget>,
        organization: Readonly<Organization>,
        keyPhrase: Readonly<KeyPhrase>,
    ): IPromise<KeyPhrase> {
        return this.$uibModal.open({
            animation: true,
            backdrop: 'static',
            component: 'keyPhraseDeletePopup',
            resolve: {
                widget: () => widget,
                organization: () => organization,
                keyPhrase: () => keyPhrase,
            },
        }).result;
    }

    public manageGroup(
        widget: Readonly<Widget>,
        keyPhraseGroup: Readonly<KeyPhraseGroup>| null,
    ): IPromise<KeyPhraseGroupFormForModal> {
        return this.$uibModal.open({
            animation: true,
            backdrop: 'static',
            component: 'keyPhraseGroup',
            resolve: {
                widget: () => widget,
                keyPhraseGroup: () => keyPhraseGroup,
                keyphrases: () => this.keyPhraseMappingService.getNames(widget, keyPhraseGroup),
                goToGroup: [
                    '$state',
                    ($state: StateService) =>
                        (groupId: Uuid): void => {
                            $state.go(
                                'keyPhrases',
                                { group: groupId, page: 1 },
                                { reload: 'keyPhrases' }
                                );
                        }
                ],
            },
        }).result;
    }
}
