import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { NativeError } from 'src/errors/NativeError';
import { ActionRequest } from 'src/utils/createActions';
import { Item } from 'src/types/entities/Item';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { KeyPhraseState } from 'src/types/values/KeyPhraseState';
import { SearchItem } from 'src/types/dto/SearchItem';
import { KeyPhraseForm } from 'src/types/dto/KeyPhraseForm';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { DEMO_PIN_MODE_LEAVE_ACTIONS } from 'src/react/demoPage/actions/DemoPinModeLeaveActions';
import { DemoPageData } from 'src/react/demoPage/state/DemoPageState';
import { DemoPageParams } from 'src/react/demoPage/state/DemoPageParams';
import { getDemoPageParams } from 'src/react/demoPage/selectors/getDemoPageParams';
import { getDemoPageResult } from 'src/react/demoPage/selectors/getDemoPageResult';
import { demoSearchQuery } from 'src/react/demoPage/services/demoSearchQuery';
import { demoUpdateQueryString } from 'src/react/demoPage/services/demoUpdateQueryString';
import { keyPhraseUpdate } from 'src/react/demoPage/services/keyPhraseUpdate';
import { keyPhraseCreate } from 'src/react/demoPage/services/keyPhraseCreate';
import { keyPhraseDelete } from 'src/react/demoPage/services/keyPhraseDelete';
import { keyPhraseItems } from 'src/react/demoPage/services/keyPhraseItems';

export function* demoPinModeLeaveSaga(
    { data: action }: ActionRequest<'save' | 'cancel'>,
): SagaIterator {
    try {
        yield put(DEMO_PIN_MODE_LEAVE_ACTIONS.pending());

        if (action === 'save') {
            const params: DemoPageParams = yield select(getDemoPageParams);
            const { keyPhrase: originalKeyPhrase, synonyms, pinnedItems }: DemoPageData
                = yield select(getDemoPageResult);

            if (pinnedItems.length) {
                const keyPhraseForm: KeyPhraseForm = {
                    text: params.query,
                    state: KeyPhraseState.Active,
                    itemIds: pinnedItems.map((it) => it.id),
                    synonyms: [...synonyms],
                };

                const keyPhrase: KeyPhrase = originalKeyPhrase
                    ? yield call(keyPhraseUpdate, originalKeyPhrase, keyPhraseForm)
                    : yield call(keyPhraseCreate, keyPhraseForm);
                const foundItems: ListWithTotal<SearchItem> = yield call(demoSearchQuery, { ...params, page: 1 });

                yield put(DEMO_PIN_MODE_LEAVE_ACTIONS.success({
                    synonyms,
                    foundItems,
                    pinnedItems,
                    keyPhrase,
                    groups: [],
                }));
                yield call(demoUpdateQueryString, { ...params, page: 1, pinMode: false  });
            } else if (originalKeyPhrase) {
                yield call(keyPhraseDelete, originalKeyPhrase);

                const keyPhrase = null;
                const foundItems: ListWithTotal<SearchItem> = yield call(demoSearchQuery, { ...params, page: 1 });

                yield put(DEMO_PIN_MODE_LEAVE_ACTIONS.success({
                    foundItems,
                    pinnedItems,
                    keyPhrase,
                    synonyms: [],
                    groups: [],
                }));
                yield call(demoUpdateQueryString, { ...params, page: 1, pinMode: false });
            }
        } else {
            const params: DemoPageParams = yield select(getDemoPageParams);
            const foundItems: ListWithTotal<SearchItem> = yield call(demoSearchQuery, { ...params, page: 1 });

            const { keyPhrase }: DemoPageData = yield select(getDemoPageResult);
            const synonyms = keyPhrase
                ? keyPhrase.synonyms
                : [];
            const pinnedItems: Item[] = keyPhrase
                ? yield call(keyPhraseItems, keyPhrase)
                : [];

            yield put(DEMO_PIN_MODE_LEAVE_ACTIONS.success({
                foundItems,
                pinnedItems,
                keyPhrase,
                synonyms,
                groups: [],
            }));
            yield call(demoUpdateQueryString, { ...params, page: 1, pinMode: false });
        }
    } catch (error) {
        yield put(DEMO_PIN_MODE_LEAVE_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
