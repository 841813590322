import * as React from 'react';
import { connectDecorator } from 'src/decorators/connectDecorator';
import { RootState } from 'src/react/root/state/RootState';
import { OrganizationListData } from 'src/react/organization/state/OrganizationListData';
import { ORGANIZATION_LIST_SEED_ACTIONS } from 'src/react/organization/actions/OrganizationListSeedActions';

type OwnProps = {
    readonly initialData: OrganizationListData;
};
type StateProps = {
    readonly currentData: OrganizationListData | null;
};
type DispatchProps = {
    readonly seedData: (data: OrganizationListData | null) => void;
};
type AllProps =
    & OwnProps
    & StateProps
    & DispatchProps;

class Connected extends React.Component<AllProps> {
    public componentDidMount(): void {
        const { seedData, initialData } = this.props;
        seedData(initialData);
    }

    public componentWillUnmount(): void {
        const { seedData } = this.props;
        seedData(null);
    }

    public render(): React.ReactNode {
        const { currentData } = this.props;
        return currentData ? this.props.children : null;
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    currentData: state.organizationList.organizations,
});
const mapDispatchToProps: DispatchProps = {
    seedData: (widget) => ORGANIZATION_LIST_SEED_ACTIONS.request(widget),
};

@connectDecorator<OwnProps, StateProps, DispatchProps>(Connected, mapStateToProps, mapDispatchToProps)
export class OrganizationListSeed extends React.Component<OwnProps> {}
