import './StatisticsTabs.scss';
import * as React from 'react';
import { classList } from 'src/utils/classList';
import { memoize } from 'lodash-decorators';

type TabData<TabKey> = {
    readonly key: TabKey;
    readonly title: React.ReactNode;
    readonly content: React.ReactNode;
};

type Props<TabKey> = {
    readonly tabs: ReadonlyArray<TabData<TabKey>>;
    readonly active: TabKey;
    readonly onChange: (tab: TabKey) => void;
};

export class StatisticsTabs<TabKey> extends React.Component<Props<TabKey>> {
    public render(): JSX.Element {
        const { tabs, active } = this.props;

        return (
            <div className="statistics-tabs">
                <div className="statistics-tabs__header">
                    {tabs.map((tab) => this.renderTabHeader(tab))}
                </div>
                <div className="statistics-tabs__content">
                    {tabs.filter((t) => t.key === active).map((tab) => this.renderTabContent(tab))}
                </div>
            </div>
        );
    }

    private renderTabHeader(tab: TabData<TabKey>): JSX.Element {
        const { active } = this.props;
        const className = classList('statistics-tabs__tab-header', {
            'statistics-tabs__tab-header--active': tab.key === active,
        });

        return (
            <div key={tab.key.toString()}
                 className={className}
                 onClick={this.getSelectTabHandler(tab.key)}>
                {tab.title}
            </div>
        );
    }

    private renderTabContent(tab: TabData<TabKey>): JSX.Element {
        const { active } = this.props;
        const className = classList('statistics-tabs__tab-content', {
            'statistics-tabs__tab-content--active': tab.key === active,
        });

        return (
            <div key={tab.key.toString()} className={className}>
                {tab.content}
            </div>
        );
    }

    @memoize
    private getSelectTabHandler(tabKey: TabKey): () => void {
        const { onChange } = this.props;
        return () => onChange(tabKey);
    }
}
