import { chainReducers } from 'src/utils/chainReducers';
import { DemoPageState } from 'src/react/demoPage/state/DemoPageState';
import { demoResetReducer } from 'src/react/demoPage/reducers/demoResetReducer';
import { demoFilterReducer } from 'src/react/demoPage/reducers/demoFilterReducer';
import { demoSearchReducer } from 'src/react/demoPage/reducers/demoSearchReducer';
import { demoUpdateItemReducer } from 'src/react/demoPage/reducers/demoUpdateItemReducer';
import { demoUpdatePinnedReducer } from 'src/react/demoPage/reducers/demoUpdatePinnedReducer';
import { demoUpdateSynonymsReducer } from 'src/react/demoPage/reducers/demoUpdateSynonymsReducer';
import { demoPinModeEnterReducer } from 'src/react/demoPage/reducers/demoPinModeEnterReducer';
import { demoPinModeLeaveReducer } from 'src/react/demoPage/reducers/demoPinModeLeaveReducer';
import { demoSetViewModeReducer } from 'src/react/demoPage/reducers/demoSetViewModeReducer';
import { demoSetHighlightReducer } from 'src/react/demoPage/reducers/demoSetHighlightReducer';

export const demoPageReducer = chainReducers<DemoPageState>(
    demoResetReducer,
    demoUpdateItemReducer,
    demoSearchReducer,
    demoFilterReducer,
    demoUpdatePinnedReducer,
    demoUpdateSynonymsReducer,
    demoPinModeEnterReducer,
    demoPinModeLeaveReducer,
    demoSetViewModeReducer,
    demoSetHighlightReducer,
);
