import { HISTORY } from 'src/react/root/constants/history';
import { RootState } from 'src/react/root/state/RootState';
import { UI_DEFAULT_STATE } from 'src/react/common/constants/uiDefaultState';
import { DEMO_PAGE_DEFAULT_STATE } from 'src/react/demoPage/constants/DemoPageDefaultState';
import { SELECTED_WIDGET_DEFAULT_STATE } from 'src/react/selectedWidget/constants/SelectedWidgetDefaultState';
import {
    KEY_PHRASE_PIN_INPUT_DEFAULT_STATE,
} from 'src/react/keyPhrasePinInput/constants/KeyPhrasePinInputDefaultState';
import { ITEM_IMAGE_UPLOAD_DEFAULT_STATE } from 'src/react/itemImageUpload/constants/ItemImageUploadDefaultState';
import { ITEM_FORM_DEFAULT_STATE } from 'src/react/itemForm/constants/ItemFormDefaultState';
import { MANUAL_CRAWLER_DEFAULT_STATE } from 'src/react/manualCrawler/constants/ManualCrawlerDefaultState';
import { STATISTICS_PAGE_DEFAULT_STATE } from 'src/react/statistics/constants/StatisticsPageDefaultState';
import { KEY_PHRASE_SYNONYMS_DEFAULT_STATE } from 'src/react/keyPhraseSynonyms/constants/KeyPhraseSynonymsDefaultState';
import { ORGANIZATION_LIST_DEFAULT_STATE } from 'src/react/organization/constants/OrganizationListDefaultState';
import {
    KEY_PHRASE_GROUP_IS_EXIST_DEFAULT_STATE,
} from 'src/react/keyPhraseGroup/constants/keyPhraseGroupIsExistDefaultState';
import { SITE_LANGUAGES_DEFAULT_STATE } from 'src/react/siteLanguage/constants/SiteLanguageDefaultState';

export const DEFAULT_STATE: RootState = {
    form: {},
    routing: { locationBeforeTransitions: HISTORY.location },
    locale: SITE_LANGUAGES_DEFAULT_STATE,
    ui: UI_DEFAULT_STATE,
    demoPage: DEMO_PAGE_DEFAULT_STATE,
    statisticsPage: STATISTICS_PAGE_DEFAULT_STATE,
    selectedWidget: SELECTED_WIDGET_DEFAULT_STATE,
    keyPhrasePinInput: KEY_PHRASE_PIN_INPUT_DEFAULT_STATE,
    itemItemUpload: ITEM_IMAGE_UPLOAD_DEFAULT_STATE,
    itemForm: ITEM_FORM_DEFAULT_STATE,
    manualCrawler: MANUAL_CRAWLER_DEFAULT_STATE,
    keyPhraseSynonyms: KEY_PHRASE_SYNONYMS_DEFAULT_STATE,
    organizationList: ORGANIZATION_LIST_DEFAULT_STATE,
    keyPhraseGroupIsExist: KEY_PHRASE_GROUP_IS_EXIST_DEFAULT_STATE,
};
