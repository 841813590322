import { isOneOf } from 'src/utils/isOneOf';
import { DemoPageState } from 'src/react/demoPage/state/DemoPageState';
import { DemoPageViewMode } from 'src/react/demoPage/state/DemoPageViewMode';

const viewMode = localStorage.getItem('demoPageViewMode');
const showHighlight = localStorage.getItem('demoPageShowHighlight');

export const DEMO_PAGE_DEFAULT_STATE: DemoPageState = {
    loading: false,
    saving: false,
    pinMode: false,
    viewMode: isOneOf(viewMode, [DemoPageViewMode.grid, DemoPageViewMode.table]) ? viewMode : DemoPageViewMode.table,
    showHighlight: showHighlight === 'true',
    data: null,
    error: null,
};
