import './KeyPhraseSynonymsInput.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { bind } from 'lodash-decorators/bind';
import { memoize } from 'lodash-decorators/memoize';
import { Textbox } from 'src/react/common/components/Textbox';
import { HtmlButton } from 'src/react/common/components/Button';
import { InputGroup, InputGroupItem } from 'src/react/common/components/InputGroup';

type Props = {
    readonly value: ReadonlyArray<string>;
    readonly onChange: (value: ReadonlyArray<string>) => void;
};
type State = {
    readonly input: string;
};

export class KeyPhraseSynonymsInput extends React.Component<Props, State> {
    public state: State = {
        input: '',
    };

    public render(): JSX.Element {
        const { input } = this.state;
        const { value } = this.props;

        return (
            <div className="keyphrase-synonyms-input">
                <InputGroup className="keyphrase-synonyms-input__input">
                    <InputGroupItem className="keyphrase-synonyms-input__col keyphrase-synonyms-input__col--input">
                        <Textbox className="keyphrase-synonyms-input__input-text"
                                 value={input}
                                 onChange={this.handleInput}
                                 onKeyDown={this.handleEnter}/>
                    </InputGroupItem>
                    <InputGroupItem className="keyphrase-synonyms-input__col keyphrase-synonyms-input__col--button">
                        <HtmlButton block={true}
                                    intent="none"
                                    disabled={this.isAddDisabled(input)}
                                    onClick={this.handleAddClick}
                                    className="keyphrase-synonyms-input__input-button">
                            <FormattedMessage id="keyPhraseSynonyms_addSynonym"/>
                        </HtmlButton>
                    </InputGroupItem>
                </InputGroup>
                <div className="keyphrase-synonyms-input__value">
                    {value.map((item) => (
                        <span key={item} className="keyphrase-synonyms-input__value-item">
                            <a className="keyphrase-synonyms-input__value-remove"
                               onClick={this.getRemoveClickHandler(item)}><i className="fa fa-times" /></a>
                            <span className="keyphrase-synonyms-input__value-text">{item}</span>
                        </span>
                    ))}
                </div>
            </div>
        );
    }

    @bind
    private handleInput(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({ input: event.target.value });
    }

    @bind
    private handleEnter(event: React.KeyboardEvent<HTMLInputElement>): void {
        if (event.keyCode !== 13) {
            return;
        }

        event.preventDefault();

        const { input } = this.state;
        this.addSynonym(input);
    }

    @bind
    private handleAddClick(): void {
        const { input } = this.state;
        this.addSynonym(input);
    }

    @memoize
    private getRemoveClickHandler(synonym: string): () => void {
        return () => {
            this.removeSynonym(synonym);
        };
    }

    private isAddDisabled(synonym: string): boolean {
        const normalized = normalizeSynonym(synonym);
        if (normalized.length < 2) {
            return true;
        }

        const { value } = this.props;
        return value.includes(normalized);
    }

    private addSynonym(synonym: string): void {
        if (this.isAddDisabled(synonym)) {
            return;
        }

        const { value, onChange } = this.props;
        onChange([...value, normalizeSynonym(synonym)]);

        this.setState({ input: '' });
    }

    private removeSynonym(synonym: string): void {
        const { value, onChange } = this.props;
        onChange(value.filter((other) => other !== synonym));

        this.setState({ input: '' });
    }
}

function normalizeSynonym(synonym: string): string {
    return synonym
        .replace(/\s+/g, ' ')
        .toLowerCase()
        .trim();
}
