import { module } from 'angular';
import { SNIPPET_PAGE_COMPONENT } from 'src/modules/snippetPage/SnippetPageComponent';

export const SNIPPET_PAGE_MODULE = module('snippetPage', [])
    /**
     * @ngdoc directive
     * @name snippetPage
     * @restrict E
     *
     * @param {expression} widget
     */
    .component(
        'snippetPage',
        SNIPPET_PAGE_COMPONENT,
    );
