import { delay, SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { NativeError } from 'src/errors/NativeError';
import {
    SETTINGS_FORM_VALIDATE_ACTIONS,
    SettingsFormValidateRequestData,
} from 'src/react/settings/actions/SettingsFormValidateActions';
import { settingsFormValidate } from 'src/react/settings/services/settingsFormValidate';

export function* settingsFormValidateSaga(
    { data: { formData, immediate } }: ActionRequest<SettingsFormValidateRequestData>,
): SagaIterator {
    try {
        yield put(SETTINGS_FORM_VALIDATE_ACTIONS.pending());

        if (!immediate) {
            // input debounce
            yield call(delay, 500);
        }

        yield call(settingsFormValidate, formData);

        yield put(SETTINGS_FORM_VALIDATE_ACTIONS.success());
    } catch (error) {
        yield put(SETTINGS_FORM_VALIDATE_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
