import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { Uuid } from 'src/types/values/Uuid';
import { Widget } from 'src/types/entities/Widget';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { getItemEndpoint } from 'src/react/api/selectors/getEndpoint';
import { IItemEndpoint } from 'src/react/api/endpoints/ItemEndpoint';

export function* itemLoad(
    itemId: Uuid,
): SagaIterator {
    const widget: Widget = yield select(getSelectedWidget);
    const itemEndpoint: IItemEndpoint = yield select(getItemEndpoint);

    return yield call({
        fn: itemEndpoint.get,
        context: itemEndpoint,
    }, itemId, { widgetId: widget.id });

}
