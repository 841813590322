import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { NativeError } from 'src/errors/NativeError';
import { Item } from 'src/types/entities/Item';
import { DEMO_UPDATE_ITEM_ACTIONS } from 'src/react/demoPage/actions/DemoUpdateItemActions';

export function* demoUpdateItemSaga(
    { data: item }: ActionRequest<Item>,
): SagaIterator {
    try {
        yield put(DEMO_UPDATE_ITEM_ACTIONS.success(item));
    } catch (error) {
        yield put(DEMO_UPDATE_ITEM_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
