import { ItemFormLoadState } from 'src/react/itemForm/state/ItemFormLoadState';
import { ITEM_FORM_LOAD_ACTIONS, ItemFormLoadActions } from 'src/react/itemForm/actions/ItemFormLoadActions';

export function itemFormLoadReducer(
    state: ItemFormLoadState,
    action: ItemFormLoadActions,
): ItemFormLoadState {
    switch (action.type) {
        case ITEM_FORM_LOAD_ACTIONS.PENDING:
            return { ...state, loading: true, error: null };

        case ITEM_FORM_LOAD_ACTIONS.SUCCESS:
            return { ...state, loading: false, data: action.data, error: null };

        case ITEM_FORM_LOAD_ACTIONS.FAILURE:
            return { ...state, loading: false, data: null, error: action.data };

        default:
            return state;
    }
}
