import { applyMiddleware, createStore, DeepPartial, StoreEnhancer } from 'redux';
import { default as sagaMiddlewareFactory } from 'redux-saga';
import { routerMiddleware, syncHistoryWithStore } from 'react-router-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { identity } from 'src/utils/identity';
import { RootState } from 'src/react/root/state/RootState';
import { HISTORY } from 'src/react/root/constants/history';
import { DEFAULT_STATE } from 'src/react/root/constants/defaultState';
import { PROMISE_LISTENER } from 'src/react/root/constants/promiseListener';
import { rootReducer } from 'src/react/root/reducers/rootReducer';
import { rootSaga } from 'src/react/root/sagas/rootSaga';

const sagaMiddleware = sagaMiddlewareFactory();
const routeMiddleware = routerMiddleware(HISTORY);

type Identity<T> = (value: T) => T;
type Enhancer = StoreEnhancer<{}, RootState>;

const composeEnhancers: Identity<Enhancer> = process.env.NODE_ENV === 'development'
    ? composeWithDevTools
    : identity;
const applicationEnhancer: StoreEnhancer<{}, RootState> = composeEnhancers(applyMiddleware(
    sagaMiddleware,
    routeMiddleware,
    PROMISE_LISTENER.middleware,
));

export const STORE = createStore(
    rootReducer,
    DEFAULT_STATE as DeepPartial<RootState>,
    applicationEnhancer,
);
syncHistoryWithStore(HISTORY, STORE);
sagaMiddleware.run(rootSaga);
