import './PermissionCreateForm.scss';
import * as React from 'react';
import { InnerFormProps, OuterFormProps, withFormDecorator } from 'src/decorators/withFormDecorator';
import { PermissionFormData } from 'src/react/organization/types/PermissionFormData';

export type PermissionCreateProps = {
    readonly isPending: boolean;
    readonly isDisabled: boolean;
};
type Props = {
    readonly children: (props: PermissionCreateProps) => React.ReactNode;
    readonly onSubmit: () => void;
};

class Connected extends React.Component<InnerFormProps<PermissionFormData, Props>> {
    public render(): JSX.Element {
        const { children, onSubmit } = this.props;
        const {
            handleSubmit,
            submitting,
            submitSucceeded,
            submitFailed,
            asyncValidating,
            invalid,
        } = this.props;

        const isPending = submitting || submitSucceeded || asyncValidating === true;
        const isDisabled = submitFailed ? invalid : isPending;

        return (
            <form className="permission-create-form" onSubmit={handleSubmit(onSubmit)}>
                {children({ isPending, isDisabled })}
            </form>
        );
    }
}

@withFormDecorator<PermissionFormData, Props>(Connected, {})
export class PermissionCreateForm extends React.Component<OuterFormProps<PermissionFormData, Props>> {}
