import './Switcher.scss';
import * as React from 'react';
import { Omit } from 'mapped-types-utils';
import { classList } from 'src/utils/classList';

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'value' | 'checked'> & {
    readonly checked: boolean;
};

export function Switcher({ name, className, id, ...inputProps }: Props): JSX.Element {
    return (
        <div className={classList('xss-switcher', 'form-switcher', className)} data-field={name}>
            <input type="checkbox" id={id} name={name} {...inputProps}/>
            <label className="switcher" htmlFor={id}/>
        </div>
    );
}
