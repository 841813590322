import { ActionAny, createActions } from 'src/utils/createActions';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';

export type KeyPhrasePinInputSearchRequestData = {
    readonly query: string;
    readonly exclude: ReadonlyArray<string>;
};
export type KeyPhrasePinInputSearchActions =
    ActionAny<KeyPhrasePinInputSearchRequestData, ReadonlyArray<KeyPhrase> | null>;
export const KEY_PHRASE_PIN_INPUT_SEARCH_ACTIONS =
    createActions<KeyPhrasePinInputSearchRequestData, ReadonlyArray<KeyPhrase> | null>('KEY_PHRASE_PIN_INPUT_SEARCH');
