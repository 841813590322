import './DemoPage.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { bind } from 'lodash-decorators/bind';
import { memoize } from 'lodash-decorators/memoize';
import { Widget } from 'src/types/entities/Widget';
import { Item } from 'src/types/entities/Item';
import { CustomField } from 'src/types/entities/CustomField';
import { RootState } from 'src/react/root/state/RootState';
import { SearchMatchMode } from 'src/types/values/SearchMatchMode';
import { SearchFuzziness } from 'src/types/values/SearchFuzziness';
import { DemoPageViewMode } from 'src/react/demoPage/state/DemoPageViewMode';
import { connectDecorator } from 'src/decorators/connectDecorator';
import { Modal } from 'src/react/common/components/Modal';
import { HtmlButton } from 'src/react/common/components/Button';
import { ItemFormModal } from 'src/react/itemForm/components/ItemFormModal';
import { KeyPhraseSynonyms } from 'src/react/keyPhraseSynonyms/components/KeyPhraseSynonyms';
import { DemoFilterParams } from 'src/react/demoPage/state/DemoFilterParams';
import { DemoPageParams } from 'src/react/demoPage/state/DemoPageParams';
import { DemoPageState } from 'src/react/demoPage/state/DemoPageState';
import { DemoPageSearchMode } from 'src/react/demoPage/components/DemoPageSearchMode';
import { DemoPagePinMode } from 'src/react/demoPage/components/DemoPagePinMode';
import { DemoPageSettings } from 'src/react/demoPage/components/DemoPageSettings';
import { getDemoPageParams } from 'src/react/demoPage/selectors/getDemoPageParams';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { getCustomFields } from 'src/react/selectedWidget/selectors/getCustomFields';
import { DEMO_SEARCH_ACTIONS } from 'src/react/demoPage/actions/DemoSearchActions';
import { DEMO_FILTER_ACTIONS } from 'src/react/demoPage/actions/DemoFilterActions';
import { DEMO_RESET_ACTIONS } from 'src/react/demoPage/actions/DemoResetActions';
import { DEMO_UPDATE_ITEM_ACTIONS } from 'src/react/demoPage/actions/DemoUpdateItemActions';
import { DEMO_UPDATE_PINNED_ACTIONS } from 'src/react/demoPage/actions/DemoUpdatePinnedActions';
import { DEMO_UPDATE_SYNONYMS_ACTIONS } from 'src/react/demoPage/actions/DemoUpdateSynonymsActions';
import { DEMO_PIN_MODE_ENTER_ACTIONS } from 'src/react/demoPage/actions/DemoPinModeEnterActions';
import { DEMO_PIN_MODE_LEAVE_ACTIONS } from 'src/react/demoPage/actions/DemoPinModeLeaveActions';
import { DEMO_SET_VIEW_MODE_ACTIONS } from 'src/react/demoPage/actions/DemoSetViewModeActions';
import { DEMO_SET_HIGHLIGHT_ACTIONS } from 'src/react/demoPage/actions/DemoSetHighlightActions';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { KeyPhraseDeletePopup } from 'src/react/demoPage/components/KeyPhraseDeletePopup';
import { noop } from 'src/utils/noop';
import { getViewerMode } from 'src/react/selectedWidget/selectors/getViewerMode';

type OwnProps = {};
type StateProps = {
    readonly widget: Widget;
    readonly viewerMode: boolean;
    readonly customFields: ReadonlyArray<CustomField>;
    readonly params: DemoPageParams;
    readonly state: DemoPageState;
};
type DispatchProps = {
    readonly resetData: () => void;
    readonly enterPinMode: () => void;
    readonly leavePinMode: (action: 'save' | 'cancel') => void;
    readonly setViewMode: (viewMode: DemoPageViewMode) => void;
    readonly setHighlight: (highlight: boolean) => void;
    readonly updateSynonyms: (synonyms: ReadonlyArray<string>) => void;
    readonly updatePinnedItems: (items: ReadonlyArray<Item>) => void;
    readonly updateItemContent: (item: Item) => void;
    readonly updateSearchData: (params: DemoPageParams) => void;
    readonly updateFilterData: (params: DemoFilterParams) => void;
};
type AllProps =
    & OwnProps
    & StateProps
    & DispatchProps;

type State = {
    readonly editingItem: Item | null;
    readonly showAdvanced: boolean;
    readonly showSynonyms: boolean;
    readonly deleteKeyphrase: boolean;
    readonly addNewItem: boolean;
};

class Connected extends React.Component<AllProps, State> {
    public state: State = {
        editingItem: null,
        showAdvanced: false,
        showSynonyms: false,
        deleteKeyphrase: false,
        addNewItem: false,
    };

    public componentDidMount(): void {
        const { params, updateSearchData } = this.props;
        updateSearchData(params);
    }

    public componentWillUnmount(): void {
        const { resetData } = this.props;
        resetData();
    }

    public render(): React.ReactNode {
        const { params: { pinMode }  }  = this.props;

        return (
            <div className="demo-page">
                <div className="top-block">
                    <div className="top-block__left">
                        <h1><FormattedMessage id="searchDemo_title"/></h1>
                        <span className="top-block__subtitle">
                            <FormattedMessage id="searchDemo_subtitle"/>
                        </span>
                    </div>
                    <div className="top-block__right">
                        <div className="demo-page__toggle-advanced">
                            <HtmlButton intent="none"
                                        disabled={pinMode}
                                        onClick={this.toggleAdvancedSettings}>
                                <span className="demo-page__toggle-advanced-icon">
                                    <i className="si si-Rules"/>
                                </span>
                                <FormattedMessage id="searchDemo_toggleAdvanced"/>
                                <span className="demo-page__toggle-advanced-arrow">
                                    <i className="si si-ArrowDown"/>
                                </span>
                            </HtmlButton>
                        </div>
                    </div>
                    <div className="top-block__right">
                        <HtmlButton onClick={this.handleOpenAddNewItemDialog}
                                    intent="info"
                                    block={true}>
                            <i className="si si-Add"/>{' '}
                            <FormattedMessage id="searchDemo_createNew"/>
                        </HtmlButton>
                    </div>
                </div>

                <div className="demo-page__content">
                    {this.renderSearchMode()}
                    {this.renderPinMode()}
                    {this.renderAddNewItemModal()}
                    {this.renderEditModal()}
                    {this.renderSynonymsModal()}
                    {this.renderDeleteKeyPhraseModal()}
                </div>
            </div>
        );
    }

    @memoize
    @bind
    private handleChangeViewMode(viewMode: DemoPageViewMode): () => void {
        return () => {
            const { setViewMode } = this.props;
            setViewMode(viewMode);
        };
    }

    @bind
    private handlePinItem(item: Item): void {
        const { state } = this.props;
        if (!state.data) {
            return;
        }

        const { updatePinnedItems } = this.props;
        updatePinnedItems([...state.data.pinnedItems, item]);
    }

    @bind
    private handleUnpinItem(item: Item): void {
        const { state } = this.props;
        if (!state.data) {
            return;
        }

        const { updatePinnedItems } = this.props;
        updatePinnedItems(state.data.pinnedItems.filter((other) => other.id !== item.id));
    }

    @bind
    private handleChangeSort(pinnedItems: ReadonlyArray<Item>): void {
        const { updatePinnedItems } = this.props;
        updatePinnedItems(pinnedItems);
    }

    @bind
    private handleEditItem(item: Item): void {
        this.setState({ editingItem: item });
    }

    @bind
    private handleEditCancel(): void {
        this.setState({ editingItem: null });
    }

    @bind
    private handleItemSaved(item: Item): void {
        const { updateItemContent } = this.props;
        updateItemContent(item);

        this.setState({ editingItem: null });
    }

    @bind
    private handleItemRestored(item: Item): void {
        const { updateItemContent } = this.props;
        updateItemContent(item);
    }

    @bind
    private handleSynonymsEdit(): void {
        this.setState({ showSynonyms: true });
    }

    @bind
    private handleSynonymsSubmit(synonyms: ReadonlyArray<string>): void {
        const { updateSynonyms } = this.props;
        updateSynonyms(synonyms);

        this.setState({ showSynonyms: false });
    }

    @bind
    private handleSynonymsCancel(): void {
        this.setState({ showSynonyms: false });
    }

    @bind
    private handleDeleteKeyPhraseModalOpen(): void {
        this.setState({ deleteKeyphrase: true });
    }

    @bind
    private handleDeleteKeyPhraseSubmit(): void {
        const { leavePinMode } = this.props;
        leavePinMode('save');

        this.setState({ deleteKeyphrase: false });
    }

    @bind
    private handleDeleteKeyPhraseCancel(): void {
        this.setState({ deleteKeyphrase: false });
    }

    private renderEditModal(): JSX.Element | null {
        const { editingItem } = this.state;

        return (
            <Modal isOpen={editingItem !== null}>
                {editingItem !== null
                    ? <ItemFormModal id={editingItem.id}
                                     onCancel={this.handleEditCancel}
                                     onSaved={this.handleItemSaved}
                                     onRestored={this.handleItemRestored}/>
                    : null}

            </Modal>
        );
    }

    private renderSynonymsModal(): JSX.Element | null {
        const { state } = this.props;
        if (!state.data) {
            return null;
        }

        const { params: { query } } = this.props;
        const { showSynonyms } = this.state;

        return (
            <Modal isOpen={showSynonyms}>
                <KeyPhraseSynonyms title={state.data.keyPhrase ? state.data.keyPhrase.text : query}
                                   synonyms={state.data.synonyms}
                                   keyPhrase={state.data.keyPhrase}
                                   onSubmit={this.handleSynonymsSubmit}
                                   onCancel={this.handleSynonymsCancel}/>
            </Modal>
        );
    }

    private renderDeleteKeyPhraseModal(): JSX.Element | null {
        const { state } = this.props;
        if (!state.data) {
            return null;
        }

        const { deleteKeyphrase } = this.state;
        const keyphrase: KeyPhrase | null = state.data.keyPhrase;
        if (!keyphrase) {
            return null;
        }

        return (
            <Modal isOpen={deleteKeyphrase}>
                <KeyPhraseDeletePopup keyPhrase={keyphrase}
                                      onCancel={this.handleDeleteKeyPhraseCancel}
                                      onSubmit={this.handleDeleteKeyPhraseSubmit}
                                      fromDemoPinMode={true}/>
            </Modal>
        );
    }

    private renderAddNewItemModal(): JSX.Element | null {
        const { addNewItem } = this.state;

        return (
            <Modal isOpen={addNewItem}>
                <ItemFormModal
                     id={null}
                     onCancel={this.handleCloseAddNewItemDialog}
                     onSaved={this.handleCloseAddNewItemDialog}
                     onRestored={noop}
                />
            </Modal>
        );
    }

    private renderSearchMode(): JSX.Element | null {
        const { params: { pinMode }, customFields } = this.props;
        if (pinMode) {
            return null;
        }

        const {
            params,
            state,
            widget,
            updateSearchData,
            viewerMode,
            params: { query },
        } = this.props;

        const synonyms = this.getSynonymsForQuery(
            query,
            state.data ? state.data.synonyms : [],
            state.data && state.data.keyPhrase ? state.data.keyPhrase.text : ''
        );

        return (
            <div className="demo-page__content-search">
                {this.renderAdvancedSettings()}

                <DemoPageSearchMode widget={widget}
                                    viewerMode={viewerMode}
                                    params={params}
                                    customFields={customFields}
                                    searchResult={state}
                                    updateResult={updateSearchData}
                                    onPinItem={this.handlePinItem}
                                    onUnpinItem={this.handleUnpinItem}
                                    onEditItem={this.handleEditItem}
                                    onEnterPinMode={this.handleEnterPinMode}
                                    viewMode={state.viewMode}
                                    synonyms={synonyms}
                                    handleChangeViewMode={this.handleChangeViewMode}/>
            </div>
        );
    }

    private renderPinMode(): JSX.Element | null {
        const { state: { saving }, params: { pinMode } } = this.props;
        if (!pinMode) {
            return null;
        }

        const {
            state,
            widget,
            leavePinMode,
            updateFilterData,
            params: { query, page },
        } = this.props;

        const synonyms = this.getSynonymsForQuery(
            query,
            state.data ? state.data.synonyms : [],
            state.data && state.data.keyPhrase ? state.data.keyPhrase.text : ''
        );
        return (
            <div className="demo-page__content-pinmode">
                <DemoPagePinMode widget={widget}
                                 activePage={page}
                                 activeQuery={query}
                                 isSaving={saving}
                                 searchResult={state}
                                 updateResult={updateFilterData}
                                 onPinItem={this.handlePinItem}
                                 onUnpinItem={this.handleUnpinItem}
                                 onEditItem={this.handleEditItem}
                                 onEditSynonyms={this.handleSynonymsEdit}
                                 onDeleteKeyPhrase={this.handleDeleteKeyPhraseModalOpen}
                                 onChangeSort={this.handleChangeSort}
                                 synonyms={synonyms}
                                 onLeavePinMode={leavePinMode}/>
            </div>
        );
    }

    private renderAdvancedSettings(): JSX.Element | null {
        const { showAdvanced } = this.state;
        if (!showAdvanced) {
            return null;
        }

        const { state, params } = this.props;

        return (
            <div className="demo-page__advanced-settings">
                <DemoPageSettings matchMode={params.mode}
                                  fuzziness={params.fuzzy}
                                  highlight={state.showHighlight}
                                  setMatchMode={this.setMatchMode}
                                  setFuzziness={this.setFuzziness}
                                  setHighlight={this.setHighlight}/>
            </div>
        );
    }

    @bind
    private setMatchMode(matchMode: SearchMatchMode): void {
        const { params, updateSearchData } = this.props;
        updateSearchData({ ...params, mode: matchMode });
    }

    @bind
    private setFuzziness(fuzziness: SearchFuzziness): void {
        const { params, updateSearchData } = this.props;
        updateSearchData({ ...params, fuzzy: fuzziness });
    }

    @bind
    private setHighlight(highlight: boolean): void {
        const { setHighlight } = this.props;
        setHighlight(highlight);
    }

    @bind
    private toggleAdvancedSettings(): void {
        this.setState(({ showAdvanced }) => ({ showAdvanced: !showAdvanced }));
    }

    @bind
    private handleEnterPinMode(): void {
        this.props.enterPinMode();
        this.setState({ showAdvanced: false });
    }
    @bind
    private handleOpenAddNewItemDialog(): void {
        this.setState({ addNewItem: true });
    }
    @bind
    private handleCloseAddNewItemDialog(item?: Item): void {
        const { params, updateSearchData } = this.props;

        this.setState({ addNewItem: false });
        if (item) {
            updateSearchData(params);
        }
    }

    private getSynonymsForQuery(query: string, synonyms: ReadonlyArray<string>, keyPhrase: string): JSX.Element | null {
        if (!synonyms.length) {
            return null;
        }
        const isActiveQueryOneOfSynonyms = synonyms.includes(query);
        if (isActiveQueryOneOfSynonyms) {
            return (
                <FormattedMessage
                    id="searchDemo_synonymOf"
                    values={{ word: keyPhrase }}
                />
            );
        }
        return (
            <FormattedMessage id="searchDemo_synonymsList"
                              values={{ synonyms: synonyms.join(', ') }}/>
        );
    }

}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
    params: getDemoPageParams,
    widget: getSelectedWidget,
    viewerMode: getViewerMode,
    customFields: getCustomFields,
    state: (state) => state.demoPage,
});
const mapDispatchToProps: DispatchProps = {
    resetData: () => DEMO_RESET_ACTIONS.request(null),
    updateSynonyms: (items) => DEMO_UPDATE_SYNONYMS_ACTIONS.request(items),
    updatePinnedItems: (items) => DEMO_UPDATE_PINNED_ACTIONS.request(items),
    updateItemContent: (item) => DEMO_UPDATE_ITEM_ACTIONS.request(item),
    updateSearchData: (params) => DEMO_SEARCH_ACTIONS.request(params),
    updateFilterData: (params) => DEMO_FILTER_ACTIONS.request(params),
    enterPinMode: () => DEMO_PIN_MODE_ENTER_ACTIONS.request(null),
    leavePinMode: (action) => DEMO_PIN_MODE_LEAVE_ACTIONS.request(action),
    setViewMode: (viewMode) => DEMO_SET_VIEW_MODE_ACTIONS.request(viewMode),
    setHighlight: (highlight) => DEMO_SET_HIGHLIGHT_ACTIONS.request(highlight),
};

@connectDecorator<OwnProps, StateProps, DispatchProps>(Connected, mapStateToProps, mapDispatchToProps)
export class DemoPage extends React.Component<OwnProps> {}
