import { IHttpResponse, IPromise } from 'angular';
import { Uuid } from 'src/types/values/Uuid';
import { Item } from 'src/types/entities/Item';
import { Widget } from 'src/types/entities/Widget';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { ItemFilter } from 'src/types/dto/ItemFilter';
import { ItemForm } from 'src/types/dto/ItemForm';
import { Config } from 'src/types/constants/Config';
import { IApiService } from 'src/modules/api/ApiService';

export interface IItemService {
    get(
        widget: Readonly<Widget>,
        id: Uuid,
    ): IPromise<Item>;

    list(
        widget: Readonly<Widget>,
        filter: Readonly<ItemFilter>,
    ): IPromise<ListWithTotal<Item>>;

    create(
        widget: Readonly<Widget>,
        form: Readonly<ItemForm>,
    ): IPromise<Item>;

    update(
        widget: Readonly<Widget>,
        item: Readonly<Item>,
        form: Readonly<ItemForm>,
    ): IPromise<Item>;

    delete(
        widget: Readonly<Widget>,
        item: Readonly<Item>,
    ): IPromise<void>;
}

export class ItemService implements IItemService {
    public static $inject = [
        'config',
        'apiService',
    ];
    public constructor(
        private config: Config,
        private apiService: IApiService,
    ) {
    }

    public get(
        widget: Readonly<Widget>,
        id: Uuid,
    ): IPromise<Item> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
            'items',
            encodeURIComponent(id),
        ].join('/');

        return this.apiService
            .makeGETRequest<Item>(requestUrl)
            .then(extractResponseData);
    }

    public list(
        widget: Readonly<Widget>,
        filter: Readonly<ItemFilter>,
    ): IPromise<ListWithTotal<Item>> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
            'items',
        ].join('/');

        return this.apiService.makeGETRequest<ListWithTotal<Item>>(
            requestUrl,
            { params: filter },
        ).then(extractResponseData);
    }

    public create(
        widget: Readonly<Widget>,
        formData: Readonly<ItemForm>,
    ): IPromise<Item> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
            'items',
        ].join('/');

        return this.apiService
            .makePOSTRequest<ItemForm, Item>(requestUrl, formData)
            .then(extractResponseData);
    }

    public update(
        widget: Readonly<Widget>,
        item: Readonly<Item>,
        form: Readonly<ItemForm>,
    ): IPromise<Item> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
            'items',
            encodeURIComponent(item.id),
        ].join('/');

        return this.apiService.makePUTRequest<ItemForm, Item>(
            requestUrl,
            form,
        ).then(extractResponseData);
    }

    public delete(
        widget: Readonly<Widget>,
        item: Readonly<Item>,
    ): IPromise<void> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
            'items',
            encodeURIComponent(item.id),
        ].join('/');

        return this.apiService.makeDELETERequest<void>(
            requestUrl,
        ).then(extractResponseData);
    }
}

function extractResponseData<T>(response: IHttpResponse<T>): T {
    return response.data;
}
