import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { ngSelect } from 'src/utils/ngSelect';
import { Widget } from 'src/types/entities/Widget';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';

export function* imageUpload(image: File): SagaIterator {
    const widget: Widget = yield select(getSelectedWidget);

    const data = new FormData();
    data.append('file', image);

    const imageUploadService = yield select(ngSelect, 'imageUploadService');
    const response = yield call({
        fn: imageUploadService.sendFile,
        context: imageUploadService,
    }, widget.id, data);
    return response.data.file;
}
