import { Ng1StateDeclaration } from '@uirouter/angularjs';
import { Config } from 'src/types/constants/Config';
import { Widget } from 'src/types/entities/Widget';
import { IUserService } from 'src/modules/api/UserService';
import { IWidgetService } from 'src/modules/api/WidgetService';
import { ICustomFieldService } from 'src/modules/api/CustomFieldService';
import { IOrganizationService } from 'src/modules/api/OrganizationService';
import { Organization } from 'src/types/entities/Organization';
import { UserRole } from 'src/types/values/UserRole';
import { ILanguageService } from 'src/services/languageService';

export const ROUTES_STATES_LAYOUTS: ReadonlyArray<Ng1StateDeclaration> = [
    {
        name: 'index',
        abstract: true,
        resolve: {
            version: [
                'config',
                (config: Config) => config.version,
            ],
            logoutUrl: [
                'config',
                (config: Config) => `${config.services.adminApiUrl}/logout`,
            ]
        },
    },
    {
        name: 'unauthorized',
        parent: 'index',
        abstract: true,
        component: 'indexLayout',
        resolve: {
            language: [
                'languageService',
                (
                    languageService: ILanguageService
                ) => languageService.get()
            ],
        }
    },
    {
        name: 'unauthorized:logout',
        url: '/logout/success',
        parent: 'unauthorized',
        component: 'logoutSuccessPage',
        resolve: {
            $title: () => 'Logout Success',
        },
    },
    {
        name: 'authorized',
        parent: 'index',
        abstract: true,
        resolve: {
            currentUser: [
                'userService',
                (userService: IUserService) => userService.whoAmI(),
            ],
            language: [
                'languageService',
                (
                    languageService: ILanguageService
                ) => languageService.get()
            ],
        },
    },
    {
        name: 'authorized:index',
        parent: 'authorized',
        abstract: true,
        component: 'indexLayout',
    },
    {
        name: 'authorized:widget',
        url: '/{widgetId:[^/]+}',
        parent: 'authorized',
        abstract: true,
        component: 'widgetLayout',
        onEnter: [
            'i18nFilter',
            'uibPaginationConfig',
            (i18n: (key: string) => string,
             paginationConfig: { firstText: string, previousText: string, nextText: string, lastText: string }) => {
                paginationConfig.firstText = i18n('pager_first');
                paginationConfig.lastText = i18n('pager_last');
                paginationConfig.nextText = i18n('pager_next');
                paginationConfig.previousText = i18n('pager_prev');
            },
        ],
        resolve: {
            widget: [
                '$stateParams',
                'widgetService',
                (
                    $stateParams: any,
                    widgetService: IWidgetService,
                ) => widgetService.get($stateParams.widgetId),
            ],
            organization: [
                'widget',
                'organizationService',
                (
                    widget: Widget,
                    organizationService: IOrganizationService,
                ) => organizationService.get(widget.organizationId),
            ],
            viewerMode: [
                'organization',
                (organization: Organization) => organization.permission.userRole === UserRole.Viewer
            ],
            customFields: [
                'widget',
                'customFieldService',
                (
                    widget: Readonly<Widget>,
                    customFieldService: ICustomFieldService,
                ) => customFieldService.list(widget, {
                    sort: ['createdAt'],
                    page: 0,
                    pageSize: 100,
                }).then(({ data }) => data),
            ],
        },
    },
];
