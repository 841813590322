import './Modal.scss';
import * as React from 'react';
import { Modal as BaseModal } from 'react-overlays/lib';
import { classList } from 'src/utils/classList';
import { ModalBodyFade } from 'src/react/common/components/ModalBodyFade';
import { ModalBackdropFade } from 'src/react/common/components/ModalBackdropFade';

type ModalProps = {
    readonly style?: React.CSSProperties;
    readonly className?: string;
    readonly children: React.ReactNode;
    readonly isOpen: boolean;
};
export function Modal({ style, className, isOpen, children }: ModalProps): JSX.Element {
    return (
        <BaseModal show={isOpen}
                   className="xss-modal"
                   backdropClassName="xss-modal__backdrop"
                   transition={ModalBodyFade}
                   backdropTransition={ModalBackdropFade}>
            <div className="xss-modal__wrapper">
                <div className={classList('xss-modal__content', className)} style={style}>
                    <div className="xss-modal__body">{children}</div>
                </div>
            </div>
        </BaseModal>
    );
}

type ModalHeaderProps = React.HTMLAttributes<HTMLDivElement>;
export function ModalHeader({ children, className, ...rest }: ModalHeaderProps): JSX.Element {
    return <div className={classList(className, 'xss-modal-header')} {...rest}>{children}</div>;
}

type ModalFooterProps = React.HTMLAttributes<HTMLDivElement>;
export function ModalFooter({ children, className, ...rest }: ModalFooterProps): JSX.Element {
    return <div className={classList(className, 'xss-modal-footer')} {...rest}>{children}</div>;
}

type ModalBodyProps = React.HTMLAttributes<HTMLDivElement>;
export function ModalBody({ children, className, ...rest }: ModalBodyProps): JSX.Element {
    return <div className={classList(className, 'xss-modal-body')} {...rest}>{children}</div>;
}

type ModalCloseProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;
export function ModalClose({ children, className, ...rest }: ModalCloseProps): JSX.Element {
    return <a className={classList(className, 'xss-modal-close')} {...rest}>&times;</a>;
}
