import { AnyAction } from 'redux';
import { OrganizationListState } from 'src/react/organization/state/OrganizationListState';
import {
    ORGANIZATION_LIST_CREATE_CANCEL_ACTIONS,
    ORGANIZATION_LIST_CREATE_OPEN_ACTIONS,
    ORGANIZATION_LIST_CREATE_SUBMIT_ACTIONS,
} from 'src/react/organization/actions/OrganizationListCreateActions';
import { assertNotNull } from 'src/utils/assertion';

export function organizationListCreateReducer(
    state: OrganizationListState,
    action: AnyAction,
): OrganizationListState {
    if (ORGANIZATION_LIST_CREATE_OPEN_ACTIONS.isSuccess(action)) {
        return {
            ...state,
            modalState: {
                type: 'create',
            },
        };
    }

    if (ORGANIZATION_LIST_CREATE_CANCEL_ACTIONS.isSuccess(action)) {
        return {
            ...state,
            modalState: {
                type: 'none',
            },
        };
    }

    if (ORGANIZATION_LIST_CREATE_SUBMIT_ACTIONS.isSuccess(action)) {
        return {
            ...state,
            modalState: {
                type: 'none',
            },
            organizations: [
                ...assertNotNull(
                    state.organizations,
                    'Organization list is not initialized yet',
                    { state, action },
                ),
                action.data,
            ],
        };
    }

    return state;
}
