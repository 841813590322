import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { DEMO_SET_HIGHLIGHT_ACTIONS } from 'src/react/demoPage/actions/DemoSetHighlightActions';

export function* demoSetHighlightSaga(
    { data: showHighlight }: ActionRequest<boolean>,
): SagaIterator {
    yield call({
        fn: localStorage.setItem,
        context: localStorage,
    }, 'demoPageShowHighlight', showHighlight.toString());

    yield put(DEMO_SET_HIGHLIGHT_ACTIONS.success(showHighlight));
}
