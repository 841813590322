import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { Item } from 'src/types/entities/Item';
import { ItemFormValue } from 'src/react/itemForm/types/ItemFormValue';
import { Widget } from 'src/types/entities/Widget';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { IItemEndpoint } from 'src/react/api/endpoints/ItemEndpoint';
import { getItemEndpoint } from 'src/react/api/selectors/getEndpoint';
import { ValidationError } from 'src/errors/ValidationError';

export function* itemFormValidate(item: Item | null, formData: ItemFormValue): SagaIterator {
    const widget: Widget = yield select(getSelectedWidget);
    const itemEndpoint: IItemEndpoint = yield select(getItemEndpoint);

    const { data: foundItems }: ListWithTotal<Item> = yield call({
        fn: itemEndpoint.list,
        context: itemEndpoint,
    }, {
        query: '',
        url: formData.url,
        exact: true,
        type: [],
        state: [],
        id: [],
        sort: [],
        page: 0,
        pageSize: 2,
    }, { widgetId: widget.id });
    const otherItems = item
        ? foundItems.filter((it) => it.id !== item.id)
        : foundItems;

    const duplicate = otherItems.find((it) => it.url === formData.url);
    if (duplicate) {
        throw new ValidationError([{
            code: 'formError_duplicateItem',
            path: 'url',
            value: formData.url,
            params: undefined,
            context: undefined,
        }]);
    }
}
