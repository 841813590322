import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { BaseError } from 'src/errors/BaseError';
import { HttpError } from 'src/errors/HttpError';
import { ValidationError } from 'src/errors/ValidationError';
import { instantiate } from 'src/utils/instantiate';
import { getValidationError } from 'src/utils/getFormErrors';

export function* handleFormError(error: BaseError): SagaIterator {
    // TODO: check HTTP error body
    const handledError = error instanceof HttpError && error.status === 400
        ? yield call(instantiate, ValidationError, error.response.data.errors)
        : error;

    return yield call(getValidationError, handledError);
}
