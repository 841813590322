import { ActionAny, createActions } from 'src/utils/createActions';
import { Item } from 'src/types/entities/Item';
import { ItemFormValue } from 'src/react/itemForm/types/ItemFormValue';

export type ItemFormSaveRequestData = {
    readonly item: Item | null;
    readonly formData: ItemFormValue;
};
export type ItemFormSaveActions =
    ActionAny<ItemFormSaveRequestData, Item>;
export const ITEM_FORM_SAVE_ACTIONS =
    createActions<ItemFormSaveRequestData, Item>('ITEM_FORM_SAVE');
