import './ActivityPage.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { bind } from 'lodash-decorators';
import { Widget } from 'src/types/entities/Widget';
import { ActivityLogCategory, ActivityLogItem } from 'src/types/entities/ActivityLogItem';
import { DateFormat } from 'src/react/common/components/DateFormat';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { Selectbox } from 'src/react/common/components/Selectbox';
import { DateRangePicker } from 'src/react/common/components/DateRangePicker';
import { Pager } from 'src/react/common/components/Pager';
import { ACTIVITY_PAGE_SIZE } from 'src/react/activity/constants/ActivityPageSize';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { DateRange } from 'src/types/values/DateRange';
import { ActivityLogRecordText } from 'src/react/activity/components/ActivityLogRecordText';
import emptyStateIcon from 'src/images/empty-state-icon__keyphrase.svg';
import { TextSearch } from 'src/react/common/components/TextSearch';

type Props = {
    readonly widget: Widget;
    readonly activityLog: ListWithTotal<ActivityLogItem>;
    readonly query: string;
    readonly category: ActivityLogCategory;
    readonly page: UnsignedInteger;
    readonly dateRange: DateRange;
    readonly onCategoryChange: (category: string | null) => void;
    readonly onSearchChange: (query: string) => void;
    readonly onDatesChange: (dateRange: DateRange) => void;
    readonly onPageChange: (page: UnsignedInteger) => void;
};

type State = {
    readonly category: ActivityLogCategory | null;
    readonly dateRange: DateRange;
    readonly query: string;
};

const options = [
    ActivityLogCategory.Item,
    ActivityLogCategory.Crawler,
    ActivityLogCategory.Keyword,
    ActivityLogCategory.KeywordGroup,
];

export class ActivityPage extends React.Component<Props, State> {
    public state: State = {
        category: this.props.category,
        dateRange: this.props.dateRange,
        query: this.props.query,
    };

    public render(): JSX.Element {
        const { activityLog: { data }, activityLog, page } = this.props;
        const { category, dateRange, query } = this.state;
        return (
            <div className="activityLog-page">
                <div className="top-block">
                    <div className="top-block__left">
                        <h1><FormattedMessage id="activity_pageTitle"/></h1>
                        <span className="top-block__subtitle">
                            <FormattedMessage id={'activity_subtitle'} />
                        </span>
                    </div>
                    <div className="top-block__right">
                        <div className="activityLog__dateFilter">
                            <DateRangePicker id="activity-date-range"
                                             clearable={true}
                                             value={dateRange}
                                             onChange={this.onDatesChange} />
                        </div>
                        <div className="activityLog__search">
                            <FormattedMessage id="activity_searchPlaceholder">
                                {(message) => (
                                    <TextSearch
                                        name="search"
                                        value={query}
                                        autoFocus={true}
                                        onChange={this.onSearchChange}
                                        placeholder={message.toString()}
                                    />
                                )}
                            </FormattedMessage>
                        </div>
                    </div>
                </div>
                <div className="activityLog__filter-container">
                    <div className="activityLog__category">
                        <Selectbox
                            name="category"
                            className="xss-field"
                            clearable={true}
                            placeholder={<FormattedMessage id="activity_categoryPlaceholder" />}
                            options={options}
                            value={category}
                            onChange={this.onCategoryChange}
                            renderOption={this.renderCategoryOption} />
                    </div>
                </div>
                {data.length ?
                    <>
                        <div className="activity-log-data">
                            {this.renderDataTable(data)}
                        </div>
                        {this.renderPager(activityLog, page)}
                    </> :
                    this.renderNoDataMessage() }
            </div>
        );
    }

    @bind
    private renderDataTable(activityLog: ActivityLogItem[]): JSX.Element {
        const { widget } = this.props;

        return (
            <table className="activity-log-data__table">
                <thead>
                    <tr>
                        <th className="activity-log-data__th"><FormattedMessage id="activity_titleActivity" /></th>
                        <th className="activity-log-data__th"><FormattedMessage id="activity_titleType" /></th>
                        <th className="activity-log-data__th"><FormattedMessage id="activity_titleTime" /></th>
                    </tr>
                </thead>
                <tbody>
                    {activityLog.map((item) => {
                        return (
                            <tr key={item.id}>
                                <td className="activity-log-data__td">
                                    <ActivityLogRecordText widget={widget}
                                                           record={item}/>
                                </td>
                                <td className="activity-log-data__td">
                                    <FormattedMessage id="activity_category" values={{
                                        category: item.category,
                                    }} />
                                </td>
                                <td className="activity-log-data__td">
                                    <DateFormat date={item.createdAt}/>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    private renderPager(activityLogData: ListWithTotal<ActivityLogItem>, page: number): JSX.Element | null {
        const { onPageChange } = this.props;
        const { total } = activityLogData;
        if (!total) {
            return null;
        }

        return (
            <div className="activityLog__pager">
                <Pager currentPage={page}
                       pageCount={Math.ceil(total / ACTIVITY_PAGE_SIZE)}
                       maxSize={ACTIVITY_PAGE_SIZE}
                       onChangePage={onPageChange} />
            </div>
        );
    }

    @bind
    private onCategoryChange(value: ActivityLogCategory | null): void {
        this.setState({ category: value });

        const { onCategoryChange } = this.props;
        onCategoryChange(value);
    }

    @bind
    private onSearchChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({ query: event.target.value });

        const { onSearchChange } = this.props;
        onSearchChange(event.target.value);
    }

    @bind
    private onDatesChange(dateRange: DateRange): void {
        this.setState({ dateRange });

        const { onDatesChange } = this.props;
        onDatesChange(dateRange);
    }

    private renderCategoryOption(category: ActivityLogCategory): JSX.Element {
        return <FormattedMessage id="activity_category" values={{ category }} />;
    }

    private renderNoDataMessage(): JSX.Element {
        return (
            <div className="table-empty-state">
                <div className="activityLog__empty-state-image"
                     style={{ backgroundImage: `url(${emptyStateIcon})` }} />
                <p>
                    <FormattedMessage id="activityLog_no_data" />
                </p>
            </div>
        );
    }
}
