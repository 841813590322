import './LoadingSpinner.scss';
import * as React from 'react';

export function LoadingSpinner(): JSX.Element {
    return (
        <div className="xss-loading-spinner">
            <i className="fa fa-spinner fa-spin"/>
        </div>
    );
}
