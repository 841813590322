import * as React from 'react';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { Widget } from 'src/types/entities/Widget';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { SelectedWidget } from 'src/react/selectedWidget/components/SelectedWidget';
import { KeyPhraseDeletePopup } from 'src/react/demoPage/components/KeyPhraseDeletePopup';
import { Organization } from 'src/types/entities/Organization';

export type KeyPhraseDeletePopupBridgeProps = {
    readonly modalInstance: IModalInstanceService;
    readonly resolve: {
        readonly widget: Widget;
        readonly organization: Organization;
        readonly keyPhrase: KeyPhrase;
    };
};

export default function ({
                             resolve: { widget, organization, keyPhrase },
                             modalInstance,
                         }: KeyPhraseDeletePopupBridgeProps): JSX.Element {
    return (
        <SelectedWidget widget={widget}
                        organization={organization}
                        customFields={[]}
                        statisticsFilter={null}>
            <KeyPhraseDeletePopup keyPhrase={keyPhrase}
                               onSubmit={modalInstance.close}
                               onCancel={modalInstance.dismiss}/>
        </SelectedWidget>
    );
}
