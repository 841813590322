import { Config } from 'src/types/constants/Config';

declare var angular: any;
let config: Config | undefined;

export function getConfig(): Config {
    if (!config) {
        config = angular.element(document).injector().get('config');
    }
    return config as Config;
}
