import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { NativeError } from 'src/errors/NativeError';
import { ActionRequest } from 'src/utils/createActions';
import { DEMO_PIN_MODE_ENTER_ACTIONS } from 'src/react/demoPage/actions/DemoPinModeEnterActions';
import { DemoPageParams } from 'src/react/demoPage/state/DemoPageParams';
import { getDemoPageParams } from 'src/react/demoPage/selectors/getDemoPageParams';
import { demoUpdateQueryString } from 'src/react/demoPage/services/demoUpdateQueryString';

export function* demoPinModeEnterSaga(
    action: ActionRequest<null>,
): SagaIterator {
    try {
        const pageParams: DemoPageParams = yield select(getDemoPageParams);
        yield call(demoUpdateQueryString, { ...pageParams, pinMode: true });
        yield put(DEMO_PIN_MODE_ENTER_ACTIONS.success(null));
    } catch (error) {
        yield put(DEMO_PIN_MODE_ENTER_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
