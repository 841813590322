import { ActionAny, createActions } from 'src/utils/createActions';
import { Item } from 'src/types/entities/Item';
import { ItemFormValue } from 'src/react/itemForm/types/ItemFormValue';

export type ItemFormValidateRequestData = {
    readonly item: Item | null;
    readonly formData: ItemFormValue;
    readonly immediate: boolean;
};
export type ItemFormValidateActions =
    ActionAny<ItemFormValidateRequestData, null>;
export const ITEM_FORM_VALIDATE_ACTIONS =
    createActions<ItemFormValidateRequestData, null>('ITEM_FORM_VALIDATE');
