import { IComponentOptions, IScope } from 'angular';
import { IndexLayoutController } from 'src/modules/layout/IndexLayoutController';
import * as IndexLayoutComponent from './IndexLayoutComponent.html';

export const INDEX_LAYOUT_COMPONENT: IComponentOptions = {
    template: IndexLayoutComponent,
    controller: IndexLayoutController,
    controllerAs: 'indexLayoutCtrl',
    bindings: { version: '<', language: '<', logoutUrl: '<' },
};

export interface IndexLayoutScope extends IScope {
    readonly indexLayoutCtrl: IndexLayoutController;
}
