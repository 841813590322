import { ItemImageUploadState } from 'src/react/itemImageUpload/state/ItemImageUploadState';
import {
    ITEM_IMAGE_UPLOAD_RESET_ACTIONS,
    ItemImageUploadResetActions,
} from 'src/react/itemImageUpload/actions/ItemImageUploadResetActions';

export function itemImageUploadResetReducer(
    state: ItemImageUploadState,
    action: ItemImageUploadResetActions,
): ItemImageUploadState {
    switch (action.type) {
        case ITEM_IMAGE_UPLOAD_RESET_ACTIONS.SUCCESS:
            return { ...state, loading: false, image: null, error: null };

        default:
            return state;
    }
}
