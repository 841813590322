import './ActivityPage.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Widget } from 'src/types/entities/Widget';
import { ActivityLogCategory, ActivityLogCrawlerRecord, ActivityLogItem } from 'src/types/entities/ActivityLogItem';

type Props = {
    readonly widget: Widget;
    readonly record: ActivityLogItem;
};
export function ActivityLogRecordText({ widget, record }: Props): JSX.Element {
    switch (record.category) {
        case ActivityLogCategory.Crawler:
            return (
                <FormattedMessage id="activity_crawlerAction" values={{
                    event: record.event,
                    reportUrl: (
                        <CrawlerTaskReportUrl widget={widget}
                                              record={record}/>
                    ),
                }}/>
            );

        case ActivityLogCategory.Keyword:
            return (
                <FormattedMessage id="activity_keywordAction" values={{
                    event: record.event,
                    keyword: record.meta.keyphraseName,
                    user: record.user ? record.user.email : '',
                }}/>
            );

        case ActivityLogCategory.KeywordGroup:
            return (
                <FormattedMessage id="activity_keywordGroupAction" values={{
                    event: record.event,
                    name: record.meta.keyphraseGroupName,
                    user: record.user ? record.user.email : '',
                }}/>
            );

        case ActivityLogCategory.Item:
            return (
                <FormattedMessage id="activity_itemAction" values={{
                    event: record.event,
                    item: record.meta.itemName,
                    user: record.user ? record.user.email : '',
                }}/>
            );
    }
}

type CrawlerTaskReportUrlProps = {
    readonly widget: Widget;
    readonly record: ActivityLogCrawlerRecord;
};
function CrawlerTaskReportUrl({ widget, record }: CrawlerTaskReportUrlProps): JSX.Element {
    const url = [
        `/${encodeURIComponent(widget.id)}`,
        '/crawlerLog',
        `?taskId=${encodeURIComponent(record.meta.taskId)}`,
    ].join('');

    return (
        <Link to={url}>
            <FormattedMessage id="activity_viewCrawlerReport"/>
        </Link>
    );
}
