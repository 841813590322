import './OrganizationDeleteForm.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { InnerFormProps, OuterFormProps, withFormDecorator } from 'src/decorators/withFormDecorator';
import { HtmlButton } from 'src/react/common/components/Button';
import { LoadingWrapper } from 'src/react/common/components/LoadingWrapper';
import { ModalBody, ModalClose, ModalFooter, ModalHeader } from 'src/react/common/components/Modal';
import { OrganizationListItem } from 'src/react/organization/state/OrganizationListItem';

type Props = {
    readonly organization: OrganizationListItem;
    readonly onCancel: () => void;
    readonly onSubmit: (formData: {}) => void;
};

class Connected extends React.Component<InnerFormProps<{}, Props>> {
    public render(): JSX.Element {
        const { organization, onSubmit, onCancel } = this.props;
        const { handleSubmit, submitting, submitSucceeded, asyncValidating } = this.props;

        const isPending = submitting || submitSucceeded || asyncValidating === true;

        return (
            <form className="organization-delete-form" onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader className="organization-delete-form__header">
                    <ModalClose className="organization-delete-form__close"
                                onClick={onCancel}/>
                    <div className="organization-delete-form__title">
                        <FormattedMessage id="organizationDelete_title"/>
                    </div>
                </ModalHeader>
                <ModalBody className="organization-delete-form__content">
                    <LoadingWrapper loading={isPending}>
                        <FormattedMessage id="organizationDelete_text"
                                          values={{ organization: organization.organization.name }}/>
                    </LoadingWrapper>
                </ModalBody>
                <ModalFooter className="organization-delete-form__footer">
                    <div className="organization-delete-form__action">
                        <HtmlButton block={false}
                                    type="button"
                                    intent="none"
                                    onClick={onCancel}>
                            <FormattedMessage id="organizationDelete_cancelButton"/>
                        </HtmlButton>
                    </div>
                    <div className="organization-delete-form__action">
                        <HtmlButton block={false}
                                    type="submit"
                                    intent="secondary"
                                    disabled={isPending}>
                            <FormattedMessage id="organizationDelete_confirmButton"/>
                        </HtmlButton>
                    </div>
                </ModalFooter>
            </form>
        );
    }
}

@withFormDecorator<{}, Props>(Connected, {})
export class OrganizationDeleteForm extends React.Component<OuterFormProps<{}, Props>> {}
