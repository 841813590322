import './StatisticsPage.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { LocationDescriptor } from 'history';
import { bind } from 'lodash-decorators/bind';
import { debounce } from 'lodash-decorators/debounce';
import { Widget } from 'src/types/entities/Widget';
import { StatisticsFilter } from 'src/types/entities/StatisticsFilter';
import { LinkButton } from 'src/react/common/components/Button';
import { InputStatus } from 'src/react/common/components/InputStatus';
import { Textbox } from 'src/react/common/components/Textbox';
import { Pager } from 'src/react/common/components/Pager';
import { StatisticsTabs } from 'src/react/common/components/StatisticsTabs';
import { StatisticsSearchTable } from 'src/react/statistics/components/StatisticsSearchTable';
import { StatisticsLastSearchTable } from 'src/react/statistics/components/StatisticsLastSearchTable';
import { StatisticsPageParams } from 'src/react/statistics/state/StatisticsPageParams';
import { getStatisticsPageParams } from 'src/react/statistics/selectors/getStatisticsPageParams';
import { RootState } from 'src/react/root/state/RootState';
import { createStructuredSelector } from 'reselect';
import { connectDecorator } from 'src/decorators/connectDecorator';
import { StatisticsPageState } from 'src/react/statistics/state/StatisticsPageState';

import {
    STATISTICS_COMBINED_UPDATE_ACTIONS
} from 'src/react/statistics/actions/StatisticsCombinedUpdateActions';
import {
    STATISTICS_SUCCESSFUL_UPDATE_ACTIONS
} from 'src/react/statistics/actions/StatisticsSuccessfulUpdateActions';
import {
    STATISTICS_UNSUCCESSFUL_UPDATE_ACTIONS
} from 'src/react/statistics/actions/StatisticsUnsuccessfulUpdateActions';
import {
    STATISTICS_LAST_UPDATE_ACTIONS
} from 'src/react/statistics/actions/StatisticsLastUpdateActions';

import { STATISTICS_SEARCH_PAGE_SIZE } from 'src/react/statistics/constants/StatisticsPageSize';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { LoadingWrapper } from 'src/react/common/components/LoadingWrapper';
import {
    STATISTICS_RESET_ACTIONS
} from 'src/react/statistics/actions/StatisticsResetActions';
import emptyStateIcon__statisticSvg from 'src/images/empty-state-icon__statistic.svg';
import { StatisticsTab } from 'src/react/statistics/state/StatisticsTab';
import { DateRangePicker } from 'src/react/common/components/DateRangePicker';
import { DateRange } from 'src/types/values/DateRange';
import { getCanChangeSettings } from 'src/react/statistics/selectors/getCanChangeSettings';

type OwnProps = {
    readonly widget: Widget;
    readonly filter: StatisticsFilter | null;
    readonly hasSearchesData: boolean;
};
type StateProps = {
    readonly params: StatisticsPageParams;
    readonly state: StatisticsPageState;
    readonly canChangeSettings: boolean;
};
type DispatchProps = {
    readonly resetData: () => void;
    readonly updateData: (
        filter: StatisticsFilter | null,
        params: StatisticsPageParams
    ) => void;
};

type AllProps =
    & OwnProps
    & StateProps
    & DispatchProps;

type State = {
    readonly query: string;
    readonly dateRange: DateRange;
    readonly tab: StatisticsTab;
    readonly page: UnsignedInteger;
    readonly sort: string;
    readonly hasResults: string | null;
};

class Connected extends React.Component<AllProps, State> {
    public state: State = this.props.params;

    public componentWillMount(): void {
        const {
            hasSearchesData,
            updateData,
            filter,
            params,
        } = this.props;
        if (hasSearchesData) {
            updateData(filter, params);
        }
    }

    public componentWillUnmount(): void {
        const { resetData } = this.props;
        resetData();
    }

    public render(): React.ReactNode {
        const { hasSearchesData, filter, canChangeSettings } = this.props;
        const { query, dateRange, hasResults, tab } = this.state;

        return (
            <div className="statistics-page">
                <div className="top-block">
                    <div className="top-block__left">
                        <h1><FormattedMessage id="statistics_pageTitle"/></h1>
                        <span className="top-block__subtitle">
                            <FormattedMessage id="statistics_subtitle"/>
                        </span>
                    </div>
                    {hasSearchesData && (
                        <div className="top-block__right">
                            <div className="statistics-page__input">
                                <FormattedMessage id="statistics_searchinputPlaceholder">
                                    {(message) => (
                                        <InputStatus
                                            suffix={<i className="statistics-page__input-suffix fa fa-search"/>}>
                                            <Textbox type="text"
                                                     autoFocus={false}
                                                     placeholder={message.toString()}
                                                     value={query}
                                                     onChange={this.handleQuery}/>
                                        </InputStatus>
                                    )}
                                </FormattedMessage>
                            </div>
                            <div className="statistics-page__input">
                                <DateRangePicker id="statistics-date-range"
                                                 clearable={true}
                                                 value={dateRange}
                                                 onChange={this.handleDatesChange}/>
                            </div>

                            {canChangeSettings &&
                            <LinkButton intent="none" to={this.getStatisticsFilterLocation()}>
                                <i className="si si-Statistics"/>{' '}
                                <FormattedMessage id="statistics_excludedSearchTerms"
                                                  values={{ excludedCount: filter ? filter.excludedTerms.length : 0 }}/>
                            </LinkButton>}
                        </div>
                    )}

                </div>
                {hasSearchesData && tab === StatisticsTab.Unsuccessful && (
                    <div className="dropdown-statistics-search">
                        <div style={{ position: 'relative' }}>
                            <i className="form-control-feedback si si-Rules" style={{ left: '10px' }}/>
                            <select
                                className="form-control custom-select"
                                value={hasResults === null ? '' : hasResults}
                                onChange={this.handleHasResultsChange}>
                                <FormattedMessage id="statistics_filter_all_results">
                                    {(txt) => <option value="">{txt}</option>}
                                </FormattedMessage>
                                <FormattedMessage id="statistics_filter_with_results">
                                    {(txt) => <option value="true">{txt}</option>}
                                </FormattedMessage>
                                <FormattedMessage id="statistics_filter_without_results">
                                    {(txt) => <option value="false">{txt}</option>}
                                </FormattedMessage>
                            </select>
                        </div>
                    </div>
                )}

                <div className="statistics-page__content">
                    {this.renderPageData()}
                    {this.renderEmpty()}
                </div>
                {this.renderPager()}

            </div>
        );
    }

    private renderPageData(): JSX.Element | null {
        const {
            hasSearchesData,
        } = this.props;

        if (!hasSearchesData) {
            return null;
        }

        return this.renderDataResults();
    }

    private renderDataResults(): JSX.Element | null {
        const {
            state: {
                combinedSearches,
                successfulSearches,
                unsuccessfulSearches,
                lastSearches,
            },
            params,
            widget,
            filter,
        } = this.props;
        const pageParams = { filter, params };
        const { params: { tab } } = this.props;

        return (
            <div className="statistics-page__statistics">
                <div className="statistics-page__searches">
                    <StatisticsTabs active={tab} onChange={this.handleTabChange} tabs={[
                        {
                            key: StatisticsTab.Combined,
                            title: (
                                <div className="statistics-page__tab-title">
                                    <FormattedMessage id="statistics_combinedSearches"/>
                                </div>
                            ),
                            content: (
                              <LoadingWrapper loading={combinedSearches.loading}>
                                  {combinedSearches.data !== null ?
                                      <StatisticsSearchTable
                                          params={pageParams}
                                          widget={widget}
                                          searches={combinedSearches.data}
                                          changeSort={this.handleSortChange}
                                          showCTR={true}/> : null
                                  }
                              </LoadingWrapper>
                            ),
                        },
                        {
                            key: StatisticsTab.Successfull,
                            title: (
                                <div className="statistics-page__tab-title">
                                    <FormattedMessage id="statistics_successfulSearches"/>
                                </div>
                            ),
                            content: (
                              <LoadingWrapper loading={successfulSearches.loading}>
                                  {successfulSearches.data ?
                                      <StatisticsSearchTable
                                          params={pageParams}
                                          widget={widget}
                                          searches={successfulSearches.data}
                                          changeSort={this.handleSortChange}
                                          showCTR={false}/> : null
                                  }
                              </LoadingWrapper>
                            ),
                        },
                        {
                            key: StatisticsTab.Unsuccessful,
                            title: (
                                <div className="statistics-page__tab-title">
                                    <FormattedMessage id="statistics_unsuccessfulSearches"/>
                                </div>
                            ),
                            content: (
                              <LoadingWrapper loading={unsuccessfulSearches.loading}>
                                  {unsuccessfulSearches.data ?
                                      <StatisticsSearchTable
                                          params={pageParams}
                                          widget={widget}
                                          searches={unsuccessfulSearches.data}
                                          changeSort={this.handleSortChange}
                                          showCTR={false}/> : null
                                  }
                              </LoadingWrapper>
                            ),
                        },
                        {
                            key: StatisticsTab.Last,
                            title: (
                                <div className="statistics-page__tab-title">
                                    <FormattedMessage id="statistics_lastSearches"/>
                                </div>
                            ),
                            content: (
                              <LoadingWrapper loading={lastSearches.loading}>
                                  {lastSearches.data ?
                                      <StatisticsLastSearchTable
                                          params={pageParams}
                                          widget={widget}
                                          searches={lastSearches.data}
                                          changeSort={this.handleSortChange}/> : null
                                  }
                              </LoadingWrapper>
                            ),
                        },
                    ]}/>
                </div>
            </div>
        );
    }

    private renderEmpty(): JSX.Element | null {
        const { hasSearchesData } = this.props;
        if (hasSearchesData) {
            return null;
        }

        return (
            <div className="statistics-page__empty-state">
                <div className="statistics-page__empty-state--image"
                     style={{ backgroundImage: `url(${emptyStateIcon__statisticSvg})` }}/>
                <div className="statistics-page__empty-state--text">
                    <FormattedMessage id="statistics_page_empty_stateText"/>
                </div>
            </div>
        );
    }

    private renderPager(): JSX.Element | null {
        const {
            state, hasSearchesData,
            params: { page, tab }
        } = this.props;
        if (!hasSearchesData) {
            return null;
        }

        const total = this.getTotalItemsCount(state, tab);
        if (total <= STATISTICS_SEARCH_PAGE_SIZE) {
            return null;
        }

        return (
            <div className="statistics-page__pager">
                <Pager currentPage={page}
                       pageCount={Math.ceil(total / STATISTICS_SEARCH_PAGE_SIZE)}
                       maxSize={STATISTICS_SEARCH_PAGE_SIZE}
                       onChangePage={this.handlePageChange}/>
            </div>
        );
    }

    private getStatisticsFilterLocation(): LocationDescriptor {
        const { widget } = this.props;
        return (
            `/${encodeURIComponent(widget.id)}` +
            '/settings' +
            '?tab=statistics'
        );
    }

    @bind
    private handlePageChange(page: UnsignedInteger): void {
        this.setState({ page }, this.updateResultFromState);
    }

    @bind
    private updateResultFromState(): void {
        const { query, page, tab, sort, dateRange, hasResults } = this.state;
        const { params, filter, updateData } = this.props;

        updateData(filter, { ...params, query, page, sort, tab, dateRange, hasResults });
    }

    @bind
    private handleTabChange(tab: StatisticsTab): void {
        this.setState({
            tab,
            page: 1,
            sort: tab === StatisticsTab.Last ? '-date' : '-searchCount'
        }, this.updateResultFromState);
    }

    @bind
    private handleQuery(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({ query: event.target.value },
            this.handleSearchDebounce);
    }

    @bind
    private handleDatesChange(value: DateRange): void {
        this.setState({ dateRange: value }, this.updateResultFromState);
    }

    @bind
    private handleHasResultsChange(event: React.ChangeEvent<HTMLSelectElement>): void {
        this.setState({ hasResults: event.target.value  }, this.updateResultFromState);
    }

    @debounce(500)
    private handleSearchDebounce(): void {
        this.setState({ tab: StatisticsTab.Combined, page: 1, sort: '-searchCount' }, this.updateResultFromState);
    }

    @bind
    private handleSortChange(sortField: string): void {
        const { sort } = this.state;
        this.setState(
            { sort: sort === sortField
                    ? `-${sortField}`
                    : sortField },
            this.updateResultFromState);
    }

    private getTotalItemsCount(state: StatisticsPageState, tab: string): number {
        const { combinedSearches, successfulSearches, unsuccessfulSearches, lastSearches } = state;
        switch (tab) {
            case StatisticsTab.Combined:
                return combinedSearches.data === null ? 0 : combinedSearches.data.total;
            case StatisticsTab.Successfull:
                return successfulSearches.data  === null ? 0 : successfulSearches.data.total;
            case StatisticsTab.Unsuccessful:
                return unsuccessfulSearches.data === null ? 0 : unsuccessfulSearches.data.total;
            case StatisticsTab.Last:
                return lastSearches.data === null ? 0 : lastSearches.data.total;
        }
        return 0;
    }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
    params: getStatisticsPageParams,
    canChangeSettings: getCanChangeSettings,
    state: (state) => state.statisticsPage,
});
const mapDispatchToProps: DispatchProps = {
    resetData: () => STATISTICS_RESET_ACTIONS.request(null),
    updateData: (filter, params) => {
        switch (params.tab) {
            case StatisticsTab.Combined:
                return STATISTICS_COMBINED_UPDATE_ACTIONS.request({ filter, params });
            case StatisticsTab.Successfull:
                return STATISTICS_SUCCESSFUL_UPDATE_ACTIONS.request({ filter, params });
            case StatisticsTab.Unsuccessful:
                return STATISTICS_UNSUCCESSFUL_UPDATE_ACTIONS.request({ filter, params });
            case StatisticsTab.Last:
                return STATISTICS_LAST_UPDATE_ACTIONS.request({ filter, params });
        }
    },
};

@connectDecorator<OwnProps, StateProps, DispatchProps>(Connected, mapStateToProps, mapDispatchToProps)
export class StatisticsPage extends React.Component<OwnProps> {}
