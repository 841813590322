import './DemoPageItemImage.scss';
import noImagePng from 'src/images/no-image.png';
import * as React from 'react';
import { Item } from 'src/types/entities/Item';
import { Widget } from 'src/types/entities/Widget';
import { DemoPageViewMode } from 'src/react/demoPage/state/DemoPageViewMode';
import { classList } from 'src/utils/classList';
import { transformImageUrl } from 'src/utils/transformImageUrl';
import { Config } from 'src/types/constants/Config';
import { createStructuredSelector } from 'reselect';
import { RootState } from 'src/react/root/state/RootState';
import { getConfig } from 'src/react/root/selectors/getConfig';
import { connectDecorator } from 'src/decorators/connectDecorator';

type OwnProps = {
    readonly item: Item;
    readonly widget: Widget;
    readonly viewMode: DemoPageViewMode;
};

type StateProps = {
    readonly config: Config;
};

type AllProps = OwnProps & StateProps;

function Connected({ item, widget, viewMode, config }: AllProps): JSX.Element {
    const isGrid = viewMode === DemoPageViewMode.grid;
    const className = classList('demo-page-item-image', {
        'demo-page-item-image--tableView': viewMode === DemoPageViewMode.table,
        'demo-page-item-image--gridView': isGrid,
    });
    const imageUrl = transformImageUrl(
        config.services.imagesDomain,
        item.image || widget.defaultImage || noImagePng,
        widget.id,
        isGrid ? '150' : '50',
        isGrid ? '150' : '-',
        isGrid ? 'crop' : 'preview',
    );
    return (
        <div className={className}
            style={{ backgroundImage: `url(${imageUrl})` }}/>
    );
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
    config: getConfig,
});

@connectDecorator<OwnProps, StateProps, {}>(Connected, mapStateToProps, {})
export class DemoPageItemImage extends React.Component<OwnProps> {}
