import * as React from 'react';
import { createStructuredSelector } from 'reselect';
import { RootState } from 'src/react/root/state/RootState';
import { connectDecorator } from 'src/decorators/connectDecorator';
import { getIsLocationChanged } from 'src/react/statistics/selectors/getIsLocationChanged';

type OwnProps = {
    readonly children: JSX.Element | null;
};
type StateProps = {
    readonly isLocationChanged: boolean;
};
type DispatchProps = {};
type AllProps =
    & OwnProps
    & StateProps
    & DispatchProps;

function Connected({ isLocationChanged, children }: AllProps): JSX.Element | null {
    // TODO: temporary workaround for UI-Router <-> Hash-History variance
    return isLocationChanged ? children : null;
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
    isLocationChanged: getIsLocationChanged,
});

@connectDecorator<OwnProps, StateProps, DispatchProps>(Connected, mapStateToProps, {})
export class StatisticsPageWrapper extends React.Component<OwnProps> {}
