import './OrganizationTeamViewModal.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { bind } from 'lodash-decorators/bind';
import { Permission } from 'src/types/entities/Permission';
import { ModalBody, ModalClose, ModalFooter, ModalHeader } from 'src/react/common/components/Modal';
import { HtmlButton } from 'src/react/common/components/Button';
import { OrganizationListItem } from 'src/react/organization/state/OrganizationListItem';

type Props = {
    readonly organization: OrganizationListItem;
    readonly onClose: () => void;
};

export class OrganizationTeamViewModal extends React.Component<Props> {
    public render(): JSX.Element {
        const { organization, onClose } = this.props;

        return (
            <div className="organization-team-view-modal">
                <ModalHeader className="organization-team-view-modal__header">
                    <ModalClose className="organization-team-view-modal__close"
                                onClick={onClose}/>
                    <div className="organization-team-view-modal__title">
                        <FormattedMessage id="organizationTeam_title"/>
                    </div>
                </ModalHeader>
                <ModalBody className="organization-team-view-modal__content">
                    <div className="organization-team-view-modal__warning">
                        <FormattedMessage id="organizationTeam_notAllowedWarning"/>
                    </div>

                    {organization.permissions.map((permission) => (
                        <div key={permission.id} className="organization-team-view-modal__item">
                            {this.renderPermission(permission)}
                        </div>
                    ))}
                </ModalBody>
                <ModalFooter className="organization-team-view-modal__footer">
                    <div className="organization-team-view-modal__action">
                        <HtmlButton block={false}
                                    type="button"
                                    intent="none"
                                    onClick={onClose}>
                            <FormattedMessage id="organizationTeam_closeButton"/>
                        </HtmlButton>
                    </div>
                </ModalFooter>
            </div>
        );
    }

    @bind()
    private renderPermission(permission: Permission): JSX.Element {
        return (
            <div className="row">
                <div className="col-md-4">
                    <FormattedMessage id="organizationList_userRole"
                                      values={{ userRole: permission.userRole }}/>
                </div>
                <div className="col-md-8">
                    <div className="organization-team-view-modal__email">
                        <a href={`mailto:${permission.userEmail}`}>{permission.userEmail}</a>
                    </div>
                    <div className="organization-team-view-modal__name">
                        {permission.userFullName}
                    </div>
                </div>
            </div>
        );
    }
}
