import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './DateRangePicker.scss';
import * as React from 'react';
import { DateRangePicker as BaseDateRangePicker, FocusedInputShape } from 'react-dates';
import { Moment } from 'moment';
import { bind } from 'lodash-decorators/bind';
import { noop } from 'src/utils/noop';
import { classList } from 'src/utils/classList';
import { falseStub } from 'src/utils/falseStub';
import { WithIntl } from 'src/react/common/components/WithIntl';
import { DateRange } from 'src/types/values/DateRange';
import { HtmlButton } from 'src/react/common/components/Button';
import { DATE_RANGES } from 'src/react/common/constants/dateRanges';

type State = {
    readonly focusedInput: FocusedInputShape | null;
    readonly previousValue: DateRange;
    readonly currentValue: DateRange;
};
type Props = {
    readonly id: string;
    readonly name?: string;
    readonly className?: string;
    readonly disabled?: boolean;
    readonly clearable?: boolean;
    readonly value: DateRange;
    readonly onChange?: (range: DateRange) => void;
};
type OnDatesChangeArgs = {
    readonly startDate: Moment | null,
    readonly endDate: Moment | null,
};

export class DateRangePicker extends React.Component<Props, State> {
    public readonly state: State = {
        focusedInput: null,
        currentValue: this.props.value,
        previousValue: this.props.value,
    };

    public static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> | null {
        if (isEqual(nextProps.value, prevState.previousValue)) {
            return null;
        }

        return {
            currentValue: nextProps.value,
            previousValue: nextProps.value,
        };
    }

    @bind
    private onFocusChange(focusedInput: FocusedInputShape | null): void {
        this.setState({ focusedInput });
    }

    @bind
    private onDatesChange({ startDate, endDate }: OnDatesChangeArgs): void {
        if (!startDate && !endDate) {
            const dateRange = { from: startDate, to: endDate };
            this.setState({ currentValue: dateRange, focusedInput: null });

            const { onChange = noop } = this.props;
            onChange(dateRange);
        } else if (startDate && endDate) {
            const dateRange = { from: startDate.startOf('day'), to: endDate.startOf('day') };
            this.setState({ currentValue: dateRange, focusedInput: null });

            const { onChange = noop } = this.props;
            onChange(dateRange);
        } else {
            const dateRange = {
                from: startDate ? startDate.startOf('day') : null,
                to: endDate ? endDate.startOf('day') : null,
            };
            this.setState({ currentValue: dateRange });
        }
    }

    @bind
    private renderDatePresets(): JSX.Element | string {
        return (
            <>
                {DATE_RANGES.map(({ label, dateRange }, index) => {
                    const handleChange = () => {
                        const { onChange = noop } = this.props;
                        onChange(dateRange);

                        this.setState({ currentValue: dateRange, focusedInput: null });
                    };

                    return (
                        <HtmlButton
                            key={index}
                            onClick={handleChange}>
                            {label}
                        </HtmlButton>
                    );
                })}
            </>
        );
    }

    public render(): JSX.Element {
        const { id, name, className, disabled = false, clearable = true } = this.props;
        const { focusedInput, currentValue } = this.state;

        return (
            <div className={classList(className, 'xss-daterange-picker')} data-field={name}>
                <WithIntl>{(intl) => (
                    <BaseDateRangePicker
                        disabled={disabled}
                        startDateId={`${id}--start`}
                        endDateId={`${id}--end`}
                        startDate={currentValue.from}
                        endDate={currentValue.to}
                        onFocusChange={this.onFocusChange}
                        onDatesChange={this.onDatesChange}
                        focusedInput={focusedInput}
                        isOutsideRange={falseStub}
                        calendarInfoPosition="after"
                        renderCalendarInfo={this.renderDatePresets}
                        showClearDates={clearable}
                        keepOpenOnDateSelect={false}
                        reopenPickerOnClearDates={false}
                        hideKeyboardShortcutsPanel={true}
                        startDatePlaceholderText={intl.formatMessage({ id: 'datePicker_startDate' })}
                        endDatePlaceholderText={intl.formatMessage({ id: 'datePicker_endDate' })}
                    />
                )}</WithIntl>
            </div>
        );
    }
}

function isEqual(a: DateRange, b: DateRange): boolean {
    return (
        (a === b) ||
        (a.to === b.to && a.from === b.from)
    );
}
