import { ActionAny, createActions } from 'src/utils/createActions';
import { StatisticsPageFilter } from 'src/react/statistics/state/StatisticsPageFilter';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { StatisticsLastSearch } from 'src/types/entities/StatisticsLastSearch';

export type StatisticsLastUpdateActions =
    ActionAny<
        StatisticsPageFilter,
        ListWithTotal<StatisticsLastSearch>>;
export const STATISTICS_LAST_UPDATE_ACTIONS =
    createActions<
        StatisticsPageFilter,
        ListWithTotal<StatisticsLastSearch>>('STATISTICS_LAST_UPDATE');
