import { module } from 'angular';
import { ITEM_PAGE_COMPONENT } from 'src/modules/item/ItemPageComponent';
import { ItemDialogService } from 'src/modules/item/ItemDialogService';
import { itemTypeNameFilter } from 'src/modules/item/ItemTypeNameFilter';

export const ITEM_MODULE = module('item', [])
    .filter('itemTypeName', itemTypeNameFilter)
    /**
     * @ngdoc directive
     * @name itemPage
     * @restrict E
     *
     * @param {expression} widget
     * @param {expression} params
     * @param {expression} customFields
     */
    .component(
        'itemPage',
        ITEM_PAGE_COMPONENT,
    )
    .service(
        'itemDialogService',
        ItemDialogService,
    );
