import { IKeyPhraseService } from 'src/modules/api/KeyPhraseService';
import { Widget } from 'src/types/entities/Widget';
import { Uuid } from 'src/types/values/Uuid';
import { IPromise, IQService } from 'angular';
import { KeyPhraseGroup } from 'src/types/entities/KeyPhraseGroup';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';

export interface IKeyPhraseMappingService {
    getIds(widget: Widget, names: ReadonlyArray<string>): IPromise<ReadonlyArray<Uuid>>;
    getNames(widget: Widget, group: KeyPhraseGroup | null): IPromise<ReadonlyArray<string>>;
}

export class KeyPhraseMappingService implements IKeyPhraseMappingService {
    public static $inject = [
        'keyPhraseService',
        '$q',
    ];
    public constructor(
        private keyPhraseService: IKeyPhraseService,
        private $q: IQService,
    ) {
    }

    public getIds(widget: Widget, names: ReadonlyArray<string>): IPromise<ReadonlyArray<Uuid>> {
        if (names.length === 0) {
            return this.$q.resolve([]);
        }
        const requests = names.map((query) => this.keyPhraseService.list(widget, {
                query,
                groups: [],
                state: [],
                itemId: [],
                exact: true,
                pageSize: 1,
                page: 0,
                sort: []
            }).then(({ data }) => {
                const value = data[0];
                return value.id;
            })
        );
        return this.$q.all(requests);
    }

    public getNames(widget: Widget, group: KeyPhraseGroup | null): IPromise<ReadonlyArray<string>> {
        if (group === null) {
            return this.$q.resolve([]);
        }
        if (group && group.keyPhrasesIds.length === 0) {
            return this.$q.resolve([]);
        }
        return this.keyPhraseService.list(widget, {
            groups: [group.id],
            sort: [],
            query: '',
            page: 0,
            pageSize: 100,
            exact: false,
            itemId: [],
            state: [],
        }).then(({ data }) => mapToText(data));
    }
}

function mapToText(data: KeyPhrase[]): string[] {
    return data.map((item) => item.text);
}
