import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { DemoPageParams } from 'src/react/demoPage/state/DemoPageParams';
import { updateQueryString } from 'src/react/common/services/updateQueryString';

export function* demoUpdateQueryString(params: DemoPageParams): SagaIterator {
    const queryString = new URLSearchParams({
        query: params.query,
        page: params.page.toString(),
        mode: params.mode.toString(),
        fuzzy: params.fuzzy.toString(),
        pinMode: params.pinMode ? '1' : '0',
    });
    yield call(updateQueryString, queryString);
}
