import './InputStatus.scss';
import * as React from 'react';
import { Omit } from 'mapped-types-utils';
import { classList } from 'src/utils/classList';

type Props = Omit<React.HTMLAttributes<HTMLDivElement>, 'prefix'> & {
    readonly prefix?: React.ReactNode;
    readonly suffix?: React.ReactNode;
};
export function InputStatus({ className, children, prefix, suffix, ...rest }: Props): JSX.Element {
    const classes = classList('xss-input-status', className, {
        'xss-input-status--with-prefix': !!prefix,
        'xss-input-status--with-suffix': !!suffix,
    });

    return (
        <div className={classes} {...rest}>
            {prefix ? <div className="xss-input-status__prefix">{prefix}</div> : null}
            <div className="xss-input-status__content">{children}</div>
            {suffix ? <div className="xss-input-status__suffix">{suffix}</div> : null}
        </div>
    );
}
