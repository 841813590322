import './DemoPageItemDescription.scss';
import * as React from 'react';
import { Item } from 'src/types/entities/Item';
import { Highlight } from 'src/types/dto/Highlight';
import { DemoPageViewMode } from 'src/react/demoPage/state/DemoPageViewMode';
import { classList } from 'src/utils/classList';
import { DemoPageHighlight } from 'src/react/demoPage/components/DemoPageHighlight';

type Props = {
    readonly item: Item;
    readonly highlight: Highlight;
    readonly viewMode: DemoPageViewMode;
};

export function DemoPageItemDescription({ item, highlight, viewMode }: Props): JSX.Element {
    const className = classList('demo-page-item-description', {
        'demo-page-item-description--tableView': viewMode === DemoPageViewMode.table,
        'demo-page-item-description--gridView': viewMode === DemoPageViewMode.grid,
    });

    return (
        <span className={className} >
            <DemoPageHighlight text={item.description}
                               html={highlight.description}/>
        </span>
    );
}
