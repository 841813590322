import * as React from 'react';
import { OrganizationList } from 'src/react/organization/components/OrganizationList';
import { OrganizationListWrapper } from 'src/react/organization/components/OrganizationListWrapper';
import { OrganizationListSeed } from 'src/react/organization/components/OrganizationListSeed';
import { OrganizationListItem } from 'src/react/organization/state/OrganizationListItem';

export type OrganizationListBridgeProps = {
    readonly organizations: ReadonlyArray<OrganizationListItem>;
};
export default function ({ organizations }: OrganizationListBridgeProps): JSX.Element {
    return (
        <OrganizationListSeed initialData={organizations}>
            <OrganizationListWrapper>
                <OrganizationList/>
            </OrganizationListWrapper>
        </OrganizationListSeed>
    );
}
