import { SagaIterator } from 'redux-saga';
import { all, takeLatest } from 'redux-saga/effects';
import { cancelSagaOn } from 'src/utils/cancelSagaOn';
import { DEMO_RESET_ACTIONS } from 'src/react/demoPage/actions/DemoResetActions';
import { demoResetSaga } from 'src/react/demoPage/sagas/demoResetSaga';
import { DEMO_SET_VIEW_MODE_ACTIONS } from 'src/react/demoPage/actions/DemoSetViewModeActions';
import { demoSetViewModeSaga } from 'src/react/demoPage/sagas/demoSetViewModeSaga';
import { DEMO_SEARCH_ACTIONS } from 'src/react/demoPage/actions/DemoSearchActions';
import { demoSearchSaga } from 'src/react/demoPage/sagas/demoSearchSaga';
import { DEMO_FILTER_ACTIONS } from 'src/react/demoPage/actions/DemoFilterActions';
import { demoFilterSaga } from 'src/react/demoPage/sagas/demoFilterSaga';
import { DEMO_UPDATE_ITEM_ACTIONS } from 'src/react/demoPage/actions/DemoUpdateItemActions';
import { demoUpdateItemSaga } from 'src/react/demoPage/sagas/demoUpdateItemSaga';
import { DEMO_UPDATE_PINNED_ACTIONS } from 'src/react/demoPage/actions/DemoUpdatePinnedActions';
import { demoUpdatePinnedSaga } from 'src/react/demoPage/sagas/demoUpdatePinnedSaga';
import { DEMO_UPDATE_SYNONYMS_ACTIONS } from 'src/react/demoPage/actions/DemoUpdateSynonymsActions';
import { demoUpdateSynonymsSaga } from 'src/react/demoPage/sagas/demoUpdateSynonymsSaga';
import { DEMO_PIN_MODE_ENTER_ACTIONS } from 'src/react/demoPage/actions/DemoPinModeEnterActions';
import { demoPinModeEnterSaga } from 'src/react/demoPage/sagas/demoPinModeEnterSaga';
import { DEMO_PIN_MODE_LEAVE_ACTIONS } from 'src/react/demoPage/actions/DemoPinModeLeaveActions';
import { demoPinModeLeaveSaga } from 'src/react/demoPage/sagas/demoPinModeLeaveSaga';
import { DEMO_SET_HIGHLIGHT_ACTIONS } from 'src/react/demoPage/actions/DemoSetHighlightActions';
import { demoSetHighlightSaga } from 'src/react/demoPage/sagas/demoSetHighlightSaga';

export function* demoPageSaga(): SagaIterator {
    yield all([
        takeLatest(DEMO_RESET_ACTIONS.REQUEST, demoResetSaga),
        takeLatest(DEMO_SET_VIEW_MODE_ACTIONS.REQUEST, demoSetViewModeSaga),
        takeLatest(DEMO_SET_HIGHLIGHT_ACTIONS.REQUEST, demoSetHighlightSaga),

        takeLatest(DEMO_PIN_MODE_ENTER_ACTIONS.REQUEST, cancelSagaOn(demoPinModeEnterSaga, [
            DEMO_RESET_ACTIONS.REQUEST,
        ])),
        takeLatest(DEMO_PIN_MODE_LEAVE_ACTIONS.REQUEST, cancelSagaOn(demoPinModeLeaveSaga, [
            DEMO_RESET_ACTIONS.REQUEST,
        ])),

        takeLatest(DEMO_UPDATE_ITEM_ACTIONS.REQUEST, cancelSagaOn(demoUpdateItemSaga, [
            DEMO_RESET_ACTIONS.REQUEST,
        ])),
        takeLatest(DEMO_UPDATE_PINNED_ACTIONS.REQUEST, cancelSagaOn(demoUpdatePinnedSaga, [
            DEMO_RESET_ACTIONS.REQUEST,
        ])),
        takeLatest(DEMO_UPDATE_SYNONYMS_ACTIONS.REQUEST, cancelSagaOn(demoUpdateSynonymsSaga, [
            DEMO_RESET_ACTIONS.REQUEST,
        ])),

        takeLatest(DEMO_SEARCH_ACTIONS.REQUEST, cancelSagaOn(demoSearchSaga, [
            DEMO_RESET_ACTIONS.REQUEST,
            DEMO_FILTER_ACTIONS.REQUEST,
        ])),
        takeLatest(DEMO_FILTER_ACTIONS.REQUEST, cancelSagaOn(demoFilterSaga, [
            DEMO_RESET_ACTIONS.REQUEST,
            DEMO_SEARCH_ACTIONS.REQUEST,
        ])),
    ]);
}
