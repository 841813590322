import './InputGroup.scss';
import * as React from 'react';
import { classList } from 'src/utils/classList';

type InputGroupProps = React.HTMLProps<HTMLDivElement>;
export function InputGroup({ className, children, ...rest }: InputGroupProps): JSX.Element {
    return (
        <div className={classList('xss-input-group', className)}>
            {children}
        </div>
    );
}

type InputGroupItemProps = React.HTMLProps<HTMLDivElement>;
export function InputGroupItem({ className, children, ...rest }: InputGroupItemProps): JSX.Element {
    return (
        <div className={classList('xss-input-group-item', className)}>
            {children}
        </div>
    );
}
