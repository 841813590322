import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { NativeError } from 'src/errors/NativeError';
import { ITEM_IMAGE_UPLOAD_UPLOAD_ACTIONS } from 'src/react/itemImageUpload/actions/ItemImageUploadUploadActions';
import { imageUpload } from 'src/react/itemImageUpload/services/imageUpload';

export function* itemImageUploadUploadSaga(
    { data: file }: ActionRequest<File>,
): SagaIterator {
    try {
        yield put(ITEM_IMAGE_UPLOAD_UPLOAD_ACTIONS.pending());

        const imageUrl: string = yield call(imageUpload, file);

        yield put(ITEM_IMAGE_UPLOAD_UPLOAD_ACTIONS.success(imageUrl));
    } catch (error) {
        yield put(ITEM_IMAGE_UPLOAD_UPLOAD_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
