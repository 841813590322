import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { DEMO_UPDATE_SYNONYMS_ACTIONS } from 'src/react/demoPage/actions/DemoUpdateSynonymsActions';

export function* demoUpdateSynonymsSaga(
    { data: synonyms }: ActionRequest<ReadonlyArray<string>>,
): SagaIterator {
    yield put(DEMO_UPDATE_SYNONYMS_ACTIONS.success(synonyms));
}
