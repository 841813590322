import * as React from 'react';
import { Widget } from 'src/types/entities/Widget';
import { CustomField } from 'src/types/entities/CustomField';
import { Organization } from 'src/types/entities/Organization';
import { SelectedWidget } from 'src/react/selectedWidget/components/SelectedWidget';
import { CrawlerTask } from 'src/types/entities/CrawlerTask';
import { StatisticsFilter } from 'src/types/entities/StatisticsFilter';
import { SettingsPage } from 'src/react/settings/components/SettingsPage';
import { SettingsPageWrapper } from 'src/react/settings/components/SettingsPageWrapper';
import { injectIntl, InjectedIntlProps } from 'react-intl';

export type SettingsPageBridgeProps = {
    readonly widget: Widget;
    readonly organization: Organization;
    readonly customFields: ReadonlyArray<CustomField>;
    readonly crawlerTask: CrawlerTask | null;
    readonly statisticsFilter: StatisticsFilter | null;
} & InjectedIntlProps;

function SettingsPageBridge({
                             widget,
                             organization,
                             customFields,
                             crawlerTask,
                             statisticsFilter,
                             intl,
}: SettingsPageBridgeProps): JSX.Element {
    return (
        <SelectedWidget widget={widget}
                        organization={organization}
                        customFields={customFields}
                        statisticsFilter={statisticsFilter}>
            <SettingsPageWrapper>
                <SettingsPage widget={widget}
                              intl={intl}
                              customFields={customFields}
                              crawlerTask={crawlerTask}/>
            </SettingsPageWrapper>
        </SelectedWidget>
    );
}

export default injectIntl(SettingsPageBridge);
