import './SettingsCollapsible.scss';
import * as React from 'react';
import { bind } from 'lodash-decorators/bind';
import { classList } from 'src/utils/classList';
import { Collapse } from 'src/react/common/components/Collapse';

type Props = {
    readonly title: React.ReactNode;
    readonly children: React.ReactNode;
    readonly defaultExpanded: boolean;
};
type State = {
    readonly expanded: boolean;
};

export class SettingsCollapsible extends React.Component<Props, State> {
    public readonly state: State = {
        expanded: this.props.defaultExpanded,
    };

    public render(): JSX.Element {
        const { expanded } = this.state;
        const { title, children } = this.props;

        const className = classList('settings-collapsible', {
            'settings-collapsible--expanded': expanded,
            'settings-collapsible--collapsed': !expanded,
        });

        return (
            <div className={className}>
                <a className="settings-collapsible__header" onClick={this.handleToggle}>
                    <div className="settings-collapsible__header-title">
                        {title}
                    </div>
                    <div className="settings-collapsible__header-arrow">
                        <div className="settings-collapsible__header-icon">
                            <i className="si si-ArrowDown"/>
                        </div>
                    </div>
                </a>
                <Collapse expanded={expanded}>
                    <div className="settings-collapsible__body">
                        {children}
                    </div>
                </Collapse>
            </div>
        );
    }

    @bind()
    private handleToggle(): void {
        this.setState(({ expanded }) => ({ expanded: !expanded }));
    }
}
