import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { cancelSagaOn } from 'src/utils/cancelSagaOn';
import { organizationListSeedSaga } from 'src/react/organization/sagas/OrganizationListSeedSaga';
import { ORGANIZATION_LIST_SEED_ACTIONS } from 'src/react/organization/actions/OrganizationListSeedActions';
import {
    organizationListLeaveCancelSaga,
    organizationListLeaveOpenSaga,
    organizationListLeaveSubmitSaga
} from 'src/react/organization/sagas/OrganizationListLeaveSaga';
import {
    organizationListDeleteCancelSaga,
    organizationListDeleteOpenSaga,
    organizationListDeleteSubmitSaga,
} from 'src/react/organization/sagas/OrganizationListDeleteSaga';
import {
    organizationListCreateCancelSaga,
    organizationListCreateOpenSaga,
    organizationListCreateSubmitSaga,
} from 'src/react/organization/sagas/OrganizationListCreateSaga';
import {
    organizationListUpdateCancelSaga,
    organizationListUpdateOpenSaga,
    organizationListUpdateSubmitSaga,
} from 'src/react/organization/sagas/OrganizationListUpdateSaga';
import {
    organizationListTeamCloseSaga,
    organizationListTeamCreateSaga,
    organizationListTeamDeleteSaga,
    organizationListTeamOpenSaga,
} from 'src/react/organization/sagas/OrganizationListTeamSaga';
import {
    ORGANIZATION_LIST_LEAVE_CANCEL_ACTIONS,
    ORGANIZATION_LIST_LEAVE_OPEN_ACTIONS,
    ORGANIZATION_LIST_LEAVE_SUBMIT_ACTIONS,
} from 'src/react/organization/actions/OrganizationListLeaveActions';
import {
    ORGANIZATION_LIST_DELETE_CANCEL_ACTIONS,
    ORGANIZATION_LIST_DELETE_OPEN_ACTIONS,
    ORGANIZATION_LIST_DELETE_SUBMIT_ACTIONS
} from 'src/react/organization/actions/OrganizationListDeleteActions';
import {
    ORGANIZATION_LIST_CREATE_CANCEL_ACTIONS,
    ORGANIZATION_LIST_CREATE_OPEN_ACTIONS,
    ORGANIZATION_LIST_CREATE_SUBMIT_ACTIONS,
} from 'src/react/organization/actions/OrganizationListCreateActions';
import {
    ORGANIZATION_LIST_UPDATE_CANCEL_ACTIONS,
    ORGANIZATION_LIST_UPDATE_OPEN_ACTIONS,
    ORGANIZATION_LIST_UPDATE_SUBMIT_ACTIONS,
} from 'src/react/organization/actions/OrganizationListUpdateActions';
import {
    ORGANIZATION_LIST_TEAM_CLOSE_ACTIONS,
    ORGANIZATION_LIST_TEAM_CREATE_ACTIONS,
    ORGANIZATION_LIST_TEAM_DELETE_ACTIONS,
    ORGANIZATION_LIST_TEAM_OPEN_ACTIONS,
} from 'src/react/organization/actions/OrganizationListTeamActions';

export function* organizationListSaga(): SagaIterator {
    yield takeLatest(ORGANIZATION_LIST_SEED_ACTIONS.REQUEST, organizationListSeedSaga);

    yield takeLatest(ORGANIZATION_LIST_LEAVE_OPEN_ACTIONS.REQUEST, cancelSagaOn(organizationListLeaveOpenSaga, [
        ORGANIZATION_LIST_SEED_ACTIONS.REQUEST,
    ]));
    yield takeLatest(ORGANIZATION_LIST_LEAVE_CANCEL_ACTIONS.REQUEST, cancelSagaOn(organizationListLeaveCancelSaga, [
        ORGANIZATION_LIST_SEED_ACTIONS.REQUEST,
    ]));
    yield takeLatest(ORGANIZATION_LIST_LEAVE_SUBMIT_ACTIONS.REQUEST, cancelSagaOn(organizationListLeaveSubmitSaga, [
        ORGANIZATION_LIST_SEED_ACTIONS.REQUEST,
        ORGANIZATION_LIST_LEAVE_CANCEL_ACTIONS.REQUEST,
    ]));

    yield takeLatest(ORGANIZATION_LIST_DELETE_OPEN_ACTIONS.REQUEST, cancelSagaOn(organizationListDeleteOpenSaga, [
        ORGANIZATION_LIST_SEED_ACTIONS.REQUEST,
    ]));
    yield takeLatest(ORGANIZATION_LIST_DELETE_CANCEL_ACTIONS.REQUEST, cancelSagaOn(organizationListDeleteCancelSaga, [
        ORGANIZATION_LIST_SEED_ACTIONS.REQUEST,
    ]));
    yield takeLatest(ORGANIZATION_LIST_DELETE_SUBMIT_ACTIONS.REQUEST, cancelSagaOn(organizationListDeleteSubmitSaga, [
        ORGANIZATION_LIST_SEED_ACTIONS.REQUEST,
        ORGANIZATION_LIST_DELETE_CANCEL_ACTIONS.REQUEST,
    ]));

    yield takeLatest(ORGANIZATION_LIST_CREATE_OPEN_ACTIONS.REQUEST, cancelSagaOn(organizationListCreateOpenSaga, [
        ORGANIZATION_LIST_SEED_ACTIONS.REQUEST,
    ]));
    yield takeLatest(ORGANIZATION_LIST_CREATE_CANCEL_ACTIONS.REQUEST, cancelSagaOn(organizationListCreateCancelSaga, [
        ORGANIZATION_LIST_SEED_ACTIONS.REQUEST,
    ]));
    yield takeLatest(ORGANIZATION_LIST_CREATE_SUBMIT_ACTIONS.REQUEST, cancelSagaOn(organizationListCreateSubmitSaga, [
        ORGANIZATION_LIST_SEED_ACTIONS.REQUEST,
        ORGANIZATION_LIST_CREATE_CANCEL_ACTIONS.REQUEST,
    ]));

    yield takeLatest(ORGANIZATION_LIST_UPDATE_OPEN_ACTIONS.REQUEST, cancelSagaOn(organizationListUpdateOpenSaga, [
        ORGANIZATION_LIST_SEED_ACTIONS.REQUEST,
    ]));
    yield takeLatest(ORGANIZATION_LIST_UPDATE_CANCEL_ACTIONS.REQUEST, cancelSagaOn(organizationListUpdateCancelSaga, [
        ORGANIZATION_LIST_SEED_ACTIONS.REQUEST,
    ]));
    yield takeLatest(ORGANIZATION_LIST_UPDATE_SUBMIT_ACTIONS.REQUEST, cancelSagaOn(organizationListUpdateSubmitSaga, [
        ORGANIZATION_LIST_SEED_ACTIONS.REQUEST,
        ORGANIZATION_LIST_UPDATE_CANCEL_ACTIONS.REQUEST,
    ]));

    yield takeLatest(ORGANIZATION_LIST_TEAM_OPEN_ACTIONS.REQUEST, cancelSagaOn(organizationListTeamOpenSaga, [
        ORGANIZATION_LIST_SEED_ACTIONS.REQUEST,
    ]));
    yield takeLatest(ORGANIZATION_LIST_TEAM_CLOSE_ACTIONS.REQUEST, cancelSagaOn(organizationListTeamCloseSaga, [
        ORGANIZATION_LIST_SEED_ACTIONS.REQUEST,
    ]));
    yield takeLatest(ORGANIZATION_LIST_TEAM_CREATE_ACTIONS.REQUEST, cancelSagaOn(organizationListTeamCreateSaga, [
        ORGANIZATION_LIST_SEED_ACTIONS.REQUEST,
        ORGANIZATION_LIST_TEAM_CLOSE_ACTIONS.REQUEST,
    ]));
    yield takeLatest(ORGANIZATION_LIST_TEAM_DELETE_ACTIONS.REQUEST, cancelSagaOn(organizationListTeamDeleteSaga, [
        ORGANIZATION_LIST_SEED_ACTIONS.REQUEST,
        ORGANIZATION_LIST_TEAM_CLOSE_ACTIONS.REQUEST,
    ]));
}
