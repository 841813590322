import * as yup from 'yup';
import { assertDefined } from 'src/utils/assertion';
import { CustomField } from 'src/types/entities/CustomField';
import { CustomFieldValue } from 'src/types/values/CustomFieldValue';
import { ItemFormValue } from 'src/react/itemForm/types/ItemFormValue';
import { ItemFormValidationContext } from 'src/react/itemForm/types/ItemFormValidationContext';

export const ITEM_FORM_SCHEMA = yup.object<ItemFormValue>({
    state: yup
        .mixed()
        .oneOf([0, 1], 'formError_invalid')
        .required('formError_required'),
    title: yup
        .string()
        .required('formError_required')
        .max(1000, 'formError_tooLong'),
    content: yup
        .string()
        .max(100000, 'formError_tooLong'),
    description: yup
        .string()
        .max(100000, 'formError_tooLong'),
    image: yup
        .string()
        .nullable(true)
        .max(1000, 'formError_tooLong'),
    url: yup
        .string()
        .required('formError_required')
        .matches(/^(http[s]*:\/\/|smb:\/\/)/, { message: 'formError_invalidUrl', excludeEmptyString: true }),
    linkText: yup
        .string()
        .max(200, 'formError_tooLong'),
    keyPhrases: yup
        .array(yup.string().required('formError_required')),
    customFields: yup
        .lazy((value: ItemFormValue['customFields'], context?) => {
            const { customFields }: ItemFormValidationContext = assertDefined(
                context!.context,
                'Please pass validation context'
            );

            return yup.object<ItemFormValue['customFields']>(
                customFields.reduce((fields, customField) => ({
                    ...fields,
                    [customField.id]: getCustomFieldSchema(customField),
                }), {})
            );
        }),
});

function getCustomFieldSchema(customField: CustomField): yup.Schema<CustomFieldValue | null> {
    switch (customField.type) {
        case 'price':
            return yup
                .number()
                .positive()
                .nullable(true);

        case 'numeric':
            return yup
                .number()
                .integer()
                .nullable(true);

        case 'keyword':
        case 'text':
            return yup
                .string()
                .nullable(true);

        case 'boolean':
            return yup
                .boolean()
                .nullable(true);

        case 'tags':
            return yup
                .array(yup.string())
                .nullable(true);
    }
}
