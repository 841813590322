import './ErrorMessage.scss';
import * as React from 'react';
import { BaseError } from 'src/errors/BaseError';
import { HttpError } from 'src/errors/HttpError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { LogicError } from 'src/errors/LogicError';

type Props = {
    error: BaseError;
    errorInfo: React.ErrorInfo | undefined;
};

export function ErrorMessage({ error, errorInfo }: Props): JSX.Element {
    return (
        <div className="xss-error-message">
            <ErrorBody error={error} errorInfo={errorInfo}/>
        </div>
    );
}

function ErrorBody({ error, errorInfo }: Props): JSX.Element {
    return (
        <div className="xss-error-message__block">
            <div className="xss-error-message__title">
                <b>{error.name}</b>. {error.message}
            </div>
            <ErrorDetails error={error} errorInfo={errorInfo}/>
            <StackTrace error={error} errorInfo={errorInfo}/>
            <ErrorInfo error={error} errorInfo={errorInfo}/>
            <PreviousError error={error} errorInfo={errorInfo}/>
        </div>
    );
}

function PreviousError({ error }: Props): JSX.Element | null {
    if (!error.previous) {
        return null;
    }

    return (
        <div className="xss-error-message__previous">
            <ErrorBody error={error} errorInfo={undefined}/>
        </div>
    );
}

function ErrorDetails({ error }: Props): JSX.Element | null {
    if (error instanceof LogicError) {
        return (
            <details open={false} className="xss-error-message__details">
                <summary>Context</summary>
                <pre className="xss-error-message__pre">{JSON.stringify(error.context, null, 2)}</pre>
            </details>
        );
    }

    if (error instanceof RuntimeError) {
        return (
            <details open={false} className="xss-error-message__details">
                <summary>Context</summary>
                <pre className="xss-error-message__pre">{JSON.stringify(error.context, null, 2)}</pre>
            </details>
        );
    }

    if (error instanceof HttpError) {
        return (
            <>
                <details open={false} className="xss-error-message__details">
                    <summary>Response</summary>
                    <pre className="xss-error-message__pre">{JSON.stringify(error.response, null, 2)}</pre>
                </details>
                <details open={false} className="xss-error-message__details">
                    <summary>Request</summary>
                    <pre className="xss-error-message__pre">{JSON.stringify(error.request, null, 2)}</pre>
                </details>
            </>
        );
    }

    return null;
}

function StackTrace({ error }: Props): JSX.Element | null {
    return (
        <details open={false} className="xss-error-message__details">
            <summary>Stacktrace</summary>
            <pre className="xss-error-message__pre">{error.stack}</pre>
        </details>
    );
}

function ErrorInfo({ errorInfo }: Props): JSX.Element | null {
    if (!errorInfo) {
        return null;
    }

    return (
        <details open={false} className="xss-error-message__details">
            <summary>Component stack</summary>
            <pre className="xss-error-message__pre">{errorInfo ? errorInfo.componentStack : ''}</pre>
        </details>
    );
}
