export function parseIntegerValue(value: unknown): number | null {
    const asNumber = typeof value === 'number'
        ? value
        : typeof value === 'string'
            ? parseInt(value, 10)
            : NaN;

    return isFinite(asNumber)
        ? asNumber | 0
        : null;
}
