import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { NativeError } from 'src/errors/NativeError';
import { OrganizationListData } from 'src/react/organization/state/OrganizationListData';
import { ORGANIZATION_LIST_SEED_ACTIONS } from 'src/react/organization/actions/OrganizationListSeedActions';

export function* organizationListSeedSaga(
    { data }: ActionRequest<OrganizationListData | null>,
): SagaIterator {
    try {
        yield put(ORGANIZATION_LIST_SEED_ACTIONS.pending());
        yield put(ORGANIZATION_LIST_SEED_ACTIONS.success(data));
    } catch (error) {
        yield put(ORGANIZATION_LIST_SEED_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
