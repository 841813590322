import { WrappedFieldMetaProps } from 'redux-form';
import { classList } from 'src/utils/classList';

type FieldState = {
    readonly disabled: boolean;
    readonly readOnly: boolean;
};
export function fieldMetaClass(meta: WrappedFieldMetaProps, state: FieldState): string {
    return classList('xss-field', {
        'xss-field--valid': meta.valid,
        'xss-field--invalid': meta.invalid,

        'xss-field--dirty': meta.dirty,
        'xss-field--pristine': meta.pristine,

        'xss-field--touched': meta.touched,
        'xss-field--visited': meta.visited,

        'xss-field--submitted': meta.submitFailed,

        'xss-field--disabled': state.disabled,
        'xss-field--readonly': state.readOnly,
    });
}
