import './SettingsPage.scss';
import * as React from 'react';
import * as ReduxForm from 'redux-form';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import { Overlay } from 'react-overlays';
import { createStructuredSelector } from 'reselect';
import { bind } from 'lodash-decorators/bind';
import { assertTypeIs } from 'src/utils/assertion';
import { BaseError } from 'src/errors/BaseError';
import { PROMISE_LISTENER } from 'src/react/root/constants/promiseListener';
import { RootState } from 'src/react/root/state/RootState';
import { connectDecorator } from 'src/decorators/connectDecorator';
import { FieldErrors } from 'src/types/values/FieldError';
import { flattenErrors, getSubmissionError, getValidationError } from 'src/utils/getFormErrors';
import { Widget } from 'src/types/entities/Widget';
import { CustomField } from 'src/types/entities/CustomField';
import { CrawlerTask } from 'src/types/entities/CrawlerTask';
import { HtmlButton } from 'src/react/common/components/Button';
import { PopoverFade } from 'src/react/common/components/PopoverFade';
import { TooltipBody } from 'src/react/common/components/TooltipBody';
import { SettingsForm } from 'src/react/settings/components/SettingsForm';
import { SettingsPageTab } from 'src/react/settings/types/SettingsPageTab';
import { SettingsTabs } from 'src/react/settings/components/SettingsTabs';
import { SettingsFormData } from 'src/react/settings/types/SettingsFormData';
import { SettingsCustomBoostFormData } from 'src/react/settings/types/SettingsCustomBoostFormData';
import { getActiveTab } from 'src/react/settings/selectors/getActiveTab';
import { getSnippetCode } from 'src/react/settings/selectors/getSnippetCode';
import { getSettingsFormValues } from 'src/react/settings/selectors/getSettingsFormValues';
import { settingsFormValidator } from 'src/react/settings/validators/settingsFormValidator';
import { getSettingsFormInitialValues } from 'src/react/settings/selectors/getSettingsFormInitialValues';
import { getSettingsCustomBoostFormValues } from 'src/react/settings/selectors/getSettingsCustomBoostFormValues';
import {
    SETTINGS_FORM_SAVE_ACTIONS,
    SettingsFormSaveRequestData,
} from 'src/react/settings/actions/SettingsFormSaveActions';
import {
    SETTINGS_FORM_VALIDATE_ACTIONS,
    SettingsFormValidateRequestData,
} from 'src/react/settings/actions/SettingsFormValidateActions';

type OwnProps = {
    readonly widget: Widget;
    readonly customFields: ReadonlyArray<CustomField>;
    readonly crawlerTask: CrawlerTask | null;
} & InjectedIntlProps;
type StateProps = {
    readonly activeTab: SettingsPageTab;
    readonly snippetCode: string;
    readonly formValues: SettingsFormData | undefined;
    readonly customBoostFormValues: SettingsCustomBoostFormData | undefined;
    readonly initialFormData: SettingsFormData;
    readonly isPristine: boolean;
    readonly isInvalid: boolean;
    readonly isSubmitting: boolean;
    readonly isAsyncValidating: boolean;
    readonly hasSubmitSucceeded: boolean;
    readonly hasSubmitFailed: boolean;
};
type DispatchProps = {
    readonly submitForm: () => void;
};
type AllProps =
    & OwnProps
    & StateProps
    & DispatchProps;

export class SettingsPageConnected extends React.Component<AllProps> {
    private readonly tooltipTargetRef: React.RefObject<HTMLDivElement> = React.createRef();
    private readonly tooltipContainerRef: React.RefObject<HTMLDivElement> = React.createRef();

    private readonly savePromise = PROMISE_LISTENER.createAsyncFunction<SettingsFormSaveRequestData, void>({
        start: SETTINGS_FORM_SAVE_ACTIONS.REQUEST,
        setPayload: (action, data) => SETTINGS_FORM_SAVE_ACTIONS.request(data),

        resolve: (action) => SETTINGS_FORM_SAVE_ACTIONS.isSuccess(action),
        getPayload: (action) => assertTypeIs(action, SETTINGS_FORM_SAVE_ACTIONS.isSuccess).data,

        reject: (action) => SETTINGS_FORM_SAVE_ACTIONS.isFailure(action),
        getError: (action) => assertTypeIs(action, SETTINGS_FORM_SAVE_ACTIONS.isFailure).data,
    });
    private readonly validatePromise = PROMISE_LISTENER.createAsyncFunction<SettingsFormValidateRequestData, void>({
        start: SETTINGS_FORM_VALIDATE_ACTIONS.REQUEST,
        setPayload: (action, data) => SETTINGS_FORM_VALIDATE_ACTIONS.request(data),

        resolve: (action) => SETTINGS_FORM_VALIDATE_ACTIONS.isSuccess(action),
        getPayload: (action) => assertTypeIs(action, SETTINGS_FORM_VALIDATE_ACTIONS.isSuccess).data,

        reject: (action) => SETTINGS_FORM_VALIDATE_ACTIONS.isFailure(action),
        getError: (action) => assertTypeIs(action, SETTINGS_FORM_VALIDATE_ACTIONS.isFailure).data,
    });

    public componentWillUnmount(): void {
        this.savePromise.unsubscribe();
        this.validatePromise.unsubscribe();
    }

    public render(): JSX.Element {
        const { isPristine, isSubmitting, submitForm } = this.props;
        const { activeTab, snippetCode, widget, crawlerTask, initialFormData, intl } = this.props;
        const { formValues = initialFormData, customBoostFormValues } = this.props;

        return (
            <div className="settings-page">
                <div className="top-block">
                    <div className="top-block__left">
                        <h1><FormattedMessage id="settings_title"/></h1>
                        <span className="top-block__subtitle">
                            <FormattedMessage id="settings_subtitle"/>
                        </span>
                    </div>
                    <div className="top-block__right">
                        <div ref={this.tooltipContainerRef} className="settings-page__submit">
                            <div ref={this.tooltipTargetRef}>
                                <HtmlButton block={false}
                                            type="button"
                                            intent={isPristine ? 'none' : 'secondary'}
                                            disabled={this.isSubmitDisabled()}
                                            onClick={submitForm}>
                                    <FormattedMessage id="settings_saveButton"/>

                                    {isSubmitting && (
                                        <span className="settings-page__spinner">
                                            <i className="fa fa-refresh fa-spin"/>
                                        </span>
                                    )}
                                </HtmlButton>
                            </div>
                        </div>

                        <Overlay show={this.isTooltipShown()}
                                 rootClose={false}
                                 placement="left"
                                 transition={PopoverFade}
                                 containerPadding={0}
                                 target={this.tooltipTargetRef.current}
                                 container={this.tooltipContainerRef.current}>
                            <TooltipBody className="settings-page__tooltip">
                                <FormattedMessage id="settings_savePopup"/>
                            </TooltipBody>
                        </Overlay>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-md-push-8">
                        <SettingsTabs activeTab={activeTab}/>
                    </div>
                    <div className="col-md-8 col-md-pull-4">
                        <SettingsForm form="settings"
                                      intl={intl}
                                      onSubmit={this.handleSubmit}
                                      destroyOnUnmount={true}
                                      initialValues={initialFormData}
                                      validate={this.validateFormSync}
                                      asyncValidate={this.validateFormAsync}
                                      asyncChangeFields={['widget.website']}
                                      activeTab={activeTab}
                                      snippetCode={snippetCode}
                                      formValues={formValues}
                                      customBoostFormValues={customBoostFormValues}
                                      widget={widget}
                                      crawlerTask={crawlerTask}/>
                    </div>
                </div>
            </div>
        );
    }

    private isSubmitDisabled(): boolean {
        const { isSubmitting, isPristine, isInvalid, isAsyncValidating, hasSubmitSucceeded } = this.props;

        return (
            isSubmitting ||
            isPristine ||
            isInvalid ||
            isAsyncValidating ||
            hasSubmitSucceeded
        );
    }

    private isTooltipShown(): boolean {
        const { isPristine, isSubmitting, hasSubmitSucceeded } = this.props;
        return !isPristine && !isSubmitting && !hasSubmitSucceeded;
    }

    @bind
    private handleSubmit(formData: SettingsFormData): Promise<void> {
        return this.savePromise.asyncFunction({ formData })
            .catch((error: BaseError) => { throw getSubmissionError(error); });
    }

    @bind
    private validateFormSync(
        formData: SettingsFormData,
    ): ReduxForm.FormErrors<SettingsFormData, FieldErrors> {
        const { formValues } = this.props;
        return formValues
            ? flattenErrors(settingsFormValidator(formData))
            : {};
    }

    @bind
    private validateFormAsync(
        formData: SettingsFormData,
        dispatch: never,
        formProps: never,
        blurredField: string | undefined,
    ): Promise<void> {
        return this.validatePromise.asyncFunction({
            formData,
            immediate: blurredField !== 'widget.website',
        }).catch((error: BaseError) => { throw getValidationError(error); });
    }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
    activeTab: getActiveTab,
    snippetCode: getSnippetCode,
    formValues: getSettingsFormValues,
    customBoostFormValues: getSettingsCustomBoostFormValues,
    initialFormData: getSettingsFormInitialValues,
    isPristine: (state) => ReduxForm.isPristine('settings')(state),
    isInvalid: ReduxForm.isInvalid('settings'),
    isSubmitting: ReduxForm.isSubmitting('settings'),
    isAsyncValidating: ReduxForm.isAsyncValidating('settings'),
    hasSubmitSucceeded: ReduxForm.hasSubmitSucceeded('settings'),
    hasSubmitFailed: ReduxForm.hasSubmitFailed('settings'),
});
const mapDispatchToProps: DispatchProps = {
    submitForm: () => ReduxForm.submit('settings'),
};

@connectDecorator<OwnProps, StateProps, DispatchProps>(SettingsPageConnected, mapStateToProps, mapDispatchToProps)
export class SettingsPage extends React.Component<OwnProps> {}
