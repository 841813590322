import './PopoverContext.scss';
import { bind } from 'lodash-decorators/bind';
import { memoize } from 'lodash';
import * as React from 'react';
import { findDOMNode } from 'react-dom';
import { Dispatch } from 'redux';
import { Overlay } from 'react-overlays/lib';
import { noop } from 'src/utils/noop';
import { isFunction } from 'src/utils/isFunction';
import { RootState } from 'src/react/root/state/RootState';
import { connectDecorator } from 'src/decorators/connectDecorator';
import { PopoverFade } from 'src/react/common/components/PopoverFade';
import { PopoverBody } from 'src/react/common/components/PopoverBody';
import { uiPopoverClose, uiPopoverOpen } from 'src/react/common/actions/UiPopoverActions';

type Placement =
    | 'top'
    | 'left'
    | 'bottom'
    | 'right';
type ContextChildren = (context: PopoverContext) => React.ReactNode;

export type PopoverPlacementData = {
    readonly clientHeight: number;
    readonly targetRef: React.RefObject<any>;
};

export type PopoverContext = {
    readonly isOpen: boolean;
    readonly placement: Placement;

    readonly targetRef: React.RefObject<any>;
    readonly contentRef: React.RefObject<any>;

    openPopover(): void;
    closePopover(): void;
    togglePopover(): void;
};
const { Consumer, Provider } = React.createContext<PopoverContext>({
    isOpen: false,
    placement: 'top',

    targetRef: React.createRef(),
    contentRef: React.createRef(),

    openPopover: noop,
    closePopover: noop,
    togglePopover: noop,
});

type WrapperOwnProps = {
    readonly id: string;
    readonly disabled?: boolean;
    readonly children: React.ReactNode | ContextChildren;
    readonly placement: Placement | ((data: PopoverPlacementData) => Placement);
};
type WrapperStateProps = {
    readonly isOpen: boolean;
    readonly clientHeight: number;
};
type WrapperDispatchProps = {
    openPopover(): void;
    closePopover(): void;
};
type WrapperProps =
    & WrapperOwnProps
    & WrapperStateProps
    & WrapperDispatchProps;

class PopoverWrapperWithState extends React.Component<WrapperProps> {
    private targetRef: React.RefObject<HTMLElement> = React.createRef();
    private contentRef: React.RefObject<HTMLDivElement> = React.createRef();

    public render(): JSX.Element {
        const context = this.getContext();
        const { children } = this.props;

        return (
            <Provider value={context}>
                {isFunction(children) ? children(context) : children}
            </Provider>
        );
    }

    public componentDidMount(): void {
        window.document.addEventListener('click', this.handleOutsideEvent, { passive: true });
        window.document.addEventListener('wheel', this.handleOutsideEvent, { passive: true });
        window.document.addEventListener('scroll', this.handleOutsideEvent, { passive: true });
        window.document.addEventListener('mousedown', this.handleOutsideEvent, { passive: true });
        window.document.addEventListener('touchstart', this.handleOutsideEvent, { passive: true });
    }
    public componentWillUnmount(): void {
        window.document.removeEventListener('click', this.handleOutsideEvent);
        window.document.removeEventListener('wheel', this.handleOutsideEvent);
        window.document.removeEventListener('scroll', this.handleOutsideEvent);
        window.document.removeEventListener('mousedown', this.handleOutsideEvent);
        window.document.removeEventListener('touchstart', this.handleOutsideEvent);

        this.closePopover();
    }

    private getContext(): PopoverContext {
        return {
            isOpen: this.props.isOpen,
            placement: typeof this.props.placement !== 'function'
                ? this.props.placement
                : this.props.placement({
                    clientHeight: this.props.clientHeight,
                    targetRef: this.targetRef,
                }),

            targetRef: this.targetRef,
            contentRef: this.contentRef,

            openPopover: this.openPopover,
            closePopover: this.closePopover,
            togglePopover: this.togglePopover,
        };
    }

    @bind
    private openPopover(): void {
        const { isOpen } = this.props;
        if (isOpen) {
            return;
        }

        const { disabled } = this.props;
        if (disabled) {
            return;
        }

        const { openPopover } = this.props;
        openPopover();
    }
    @bind
    private closePopover(): void {
        const { isOpen } = this.props;
        if (!isOpen) {
            return;
        }

        const { closePopover } = this.props;
        closePopover();
    }
    @bind
    private togglePopover(): void {
        const { isOpen } = this.props;
        if (isOpen) {
            this.closePopover();
        } else {
            this.openPopover();
        }
    }

    @bind
    private handleOutsideEvent(event: Event): void {
        const { isOpen } = this.props;
        if (!isOpen) {
            return;
        }

        if (!(event.target instanceof HTMLElement)) {
            return;
        }

        const targetElement = this.targetRef.current
            ? findDOMNode(this.targetRef.current)
            : null;
        if (
            targetElement instanceof HTMLElement &&
            targetElement.contains(event.target)
        ) {
            return;
        }

        const contentElement = this.contentRef.current
            ? findDOMNode(this.contentRef.current)
            : null;
        if (
            contentElement instanceof HTMLElement &&
            contentElement.contains(event.target)
        ) {
            return;
        }

        this.closePopover();
    }
}

type TargetProps = {
    children: React.ReactNode | ContextChildren;
};
export class PopoverTarget extends React.Component<TargetProps> {
    public render(): JSX.Element {
        return (
            <Consumer>
                {this.renderTarget}
            </Consumer>
        );
    }

    @bind
    private renderTarget(context: PopoverContext): React.ReactNode {
        const { children } = this.props;
        if (isFunction(children)) {
            return children(context);
        }

        return (
            <div className="xss-popover-target"
                 ref={context.targetRef}
                 onClick={context.togglePopover}>
                {children}
            </div>
        );
    }
}

type ContentProps = {
    children: React.ReactNode | ContextChildren;
};
export class PopoverContent extends React.Component<ContentProps> {
    public render(): JSX.Element {
        return (
            <Consumer>
                {this.renderContent}
            </Consumer>
        );
    }

    @bind
    private renderContent(context: PopoverContext): React.ReactNode {
        return (
            <Overlay show={context.isOpen}
                     rootClose={false}
                     target={context.targetRef.current}
                     transition={PopoverFade}
                     containerPadding={20}
                     container={document.body}
                     placement={context.placement}>
                {this.renderChildren(context)}
            </Overlay>
        );
    }

    @bind
    private renderChildren(context: PopoverContext): React.ReactNode {
        const { children } = this.props;
        if (isFunction(children)) {
            return children(context);
        }

        return (
            <PopoverBody>
                <div className="xss-popover-content" ref={context.contentRef}>
                    {children}
                </div>
            </PopoverBody>
        );
    }
}

function mapStateToProps(state: RootState, props: WrapperOwnProps): WrapperStateProps {
    return {
        isOpen: state.ui.openPopovers.includes(props.id),
        clientHeight: state.ui.clientHeight,
    };
}
function mapDispatchToProps(dispatch: Dispatch, props: WrapperOwnProps): WrapperDispatchProps {
    return {
        openPopover: () => dispatch(uiPopoverOpen(props.id)),
        closePopover: () => dispatch(uiPopoverClose(props.id)),
    };
}
function mapDispatchToPropsCacheResolver(dispatch: Dispatch, props: WrapperOwnProps): string {
    return props.id;
}

@connectDecorator<WrapperOwnProps, WrapperStateProps, WrapperDispatchProps>(
    PopoverWrapperWithState,
    mapStateToProps,
    memoize(mapDispatchToProps, mapDispatchToPropsCacheResolver),
)
export class PopoverWrapper extends React.Component<WrapperOwnProps> {}
