import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { Widget } from 'src/types/entities/Widget';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { getKeyPhrasesGroupEndpoint } from 'src/react/api/selectors/getEndpoint';
import { KeyPhraseGroup } from 'src/types/entities/KeyPhraseGroup';
import { IKeyPhrasesGroupEndpoint } from 'src/react/api/endpoints/KeyPhrasesGroupEndpoint';
import { Uuid } from 'src/types/values/Uuid';

export function* keyPhraseGroupsGet(ids: Uuid[]): SagaIterator {
    const widget: Widget = yield select(getSelectedWidget);
    const keyPhrasesGroupEndpoint: IKeyPhrasesGroupEndpoint = yield select(getKeyPhrasesGroupEndpoint);

    const { data }: ListWithTotal<KeyPhraseGroup> = yield call({
        fn: keyPhrasesGroupEndpoint.list,
        context: keyPhrasesGroupEndpoint,
    }, {
        query: '',
        exact: false,
        page:0,
        pageSize: 100,
        sort: [],
        id: ids,
    }, {
        widgetId: widget.id,
    });

    return data;
}
