import './StatisticsSearchTable.scss';
import * as React from 'react';
import { LocationDescriptor } from 'history';
import { Link } from 'react-router-dom';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { memoize } from 'lodash-decorators/memoize';
import { Widget } from 'src/types/entities/Widget';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { StatisticsPageFilter } from 'src/react/statistics/state/StatisticsPageFilter';
import { StatisticsLastSearch } from 'src/types/entities/StatisticsLastSearch';
import { DateFormat } from 'src/react/common/components/DateFormat';
import { encodeQueryForStatisticsLink } from 'src/utils/encodeQueryForStatisticsLink';

type Props = {
    readonly params: StatisticsPageFilter;
    readonly widget: Widget;
    readonly searches: ListWithTotal<StatisticsLastSearch>;
    readonly changeSort: (sort: string) => void;
};

export class StatisticsLastSearchTable extends React.Component<Props> {
    public render(): JSX.Element {
        return (
            <div className="statistics-search-table">
                {this.renderEmpty()}
                {this.renderTable()}
            </div>
        );
    }

    private renderEmpty(): JSX.Element | null {
        const { searches } = this.props;
        if (searches.total > 0) {
            return null;
        }
        return (
            <div className="statistics-search-table__empty">
                <FormattedMessage id="statistics_search_noData"/>
            </div>
        );
    }

    private renderTable(): JSX.Element | null {
        if (this.props.searches.total === 0) {
            return null;
        }

        const { searches: { data } } = this.props;

        return (
            <table className="statistics-search-table__table">
                <thead>
                <tr>
                    <th className="statistics-search-table__th">
                        <a className="statistics-search-table__a"
                           onClick={this.changeSortHandler('query')}>
                            {this.isSortActive('query')
                                ? <i className="si si-Sort"/>
                                : null
                            }
                            <FormattedMessage id="statistics_search_searchTerm"/>
                        </a>
                    </th>
                    <th className="statistics-search-table__th">
                        <a className="statistics-search-table__a"
                           onClick={this.changeSortHandler('found')}>
                            {this.isSortActive('found')
                                ? <i className="si si-Sort"/>
                                : null
                            }
                            <FormattedMessage id="statistics_search_resultsFound"/>
                        </a>
                    </th>
                    <th className="statistics-search-table__th">
                        <a className="statistics-search-table__a"
                           onClick={this.changeSortHandler('isSuccessful')}>
                            {this.isSortActive('isSuccessful')
                                ? <i className="si si-Sort"/>
                                : null
                            }
                            <FormattedMessage id="statistics_search_successful_search"/>
                        </a>
                    </th>
                    <th className="statistics-search-table__th">
                        <a className="statistics-search-table__a"
                           onClick={this.changeSortHandler('clickPosition')}>
                            {this.isSortActive('clickPosition')
                                ? <i className="si si-Sort"/>
                                : null
                            }
                            <FormattedMessage id="statistics_search_position_clicked"/>
                        </a>
                    </th>
                    <th className="statistics-search-table__th">
                        <a className="statistics-search-table__a"
                           onClick={this.changeSortHandler('date')}>
                            {this.isSortActive('date')
                                ? <i className="si si-Sort"/>
                                : null
                            }
                            <FormattedMessage id="statistics_search_time"/>
                        </a>
                    </th>
                </tr>
                </thead>

                <tbody>
                {data.map((search, index) => (
                    <tr key={index} className="statistics-search-table__tr">
                        <td className="statistics-search-table__td statistics-search-table__td--search-term">
                            <Link to={this.getSearchLocation(search)}>{search.query}</Link>
                        </td>
                        <td className="statistics-search-table__td statistics-search-table__td--found">
                            <FormattedNumber value={search.found}/>
                        </td>
                        <td className="statistics-search-table__td statistics-search-table__td--successful">
                            {search.isSuccessful ?
                                <FormattedMessage id="statistics_isSuccessful_true" /> :
                                <FormattedMessage id="statistics_isSuccessful_false" />}
                        </td>
                        <td className="statistics-search-table__td statistics-search-table__td--position">
                            {search.clickPosition !== null
                                ? <FormattedNumber value={search.clickPosition} />
                                : '–'}
                        </td>
                        <td className="statistics-search-table__td statistics-search-table__td--date">
                            <DateFormat date={search.date}/>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        );
    }

    private isSortActive(field: string): boolean {
        const { params: { params: { sort } } } = this.props;
        return sort === field || sort === `-${field}`;
    }

    private getSearchLocation(search: StatisticsLastSearch): LocationDescriptor {
        const { widget } = this.props;
        return (
            `/${encodeURIComponent(widget.id)}` +
            '/demo' +
            `?query=${encodeQueryForStatisticsLink(search.query)}`
        );
    }

    @memoize
    private changeSortHandler(sort: string): () => void {
        const { changeSort } = this.props;
        return () => changeSort(sort);
    }
}
