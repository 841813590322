import { IComponentOptions, IScope } from 'angular';
import { ItemPageController } from 'src/modules/item/ItemPageController';
import * as ItemPageTemplate from './ItemPageTemplate.html';

export const ITEM_PAGE_COMPONENT: IComponentOptions = {
    template: ItemPageTemplate,
    controller: ItemPageController,
    controllerAs: 'itemPageCtrl',
    bindings: {
        widget: '<',
        params: '<',
        customFields: '<',
        viewerMode: '<',
    },
};

export interface ItemPageScope extends IScope {
    readonly itemPageCtrl: ItemPageController;
}
