import { IComponentController } from 'angular';
import { SiteLanguages } from 'src/types/values/siteLanguage';
import { StateService } from '@uirouter/core';

export class IndexLayoutController implements IComponentController {
    public readonly version!: string;
    public readonly language!: SiteLanguages;
    public readonly logoutUrl!: string;

    public locationName = '';

    public static $inject = [
        '$state',
    ];

    public constructor(
        private $stateService: StateService,
    ) {}

    public $doCheck(): void {
        switch (this.$stateService.current.name) {
            case 'widgets':
                this.locationName = 'organizations';
                break;
            case 'organizations':
                this.locationName = 'widgets';
                break;
        }
    }

    public isOrganizations(): boolean {
        return this.locationName === 'organizations';
    }

    public changeLocation(): void {
        if (this.locationName) {
            this.$stateService.go(this.locationName);
        }
    }

    public canChangeLocation(): boolean {
        return this.locationName !== '';
    }

    public canLogout(): boolean {
        return this.$stateService.current.name !== 'unauthorized:logout';
    }

    public doLogout(): void {
        window.location.replace(this.logoutUrl);
    }
}
