import { IRootScopeService, module as ngModule } from 'angular';
import { ngReactComponent } from 'src/react/bridge/ngReactComponent';
import { DemoPageBridgeProps } from 'src/react/bridge/components/DemoPageBridge';
import { ItemFormBridgeProps } from 'src/react/bridge/components/ItemFormBridge';
import { SettingsPageBridgeProps } from 'src/react/bridge/components/SettingsPageBridge';
import { DashboardPageBridgeProps } from 'src/react/bridge/components/DashboardPageBridge';
import { ItemFormItemTypeLabelBridgeProps } from 'src/react/bridge/components/ItemFormItemTypeLabelBridge';
import { StatisticsPageBridgeProps } from 'src/react/bridge/components/StatisticsPageBridge';
import { KeyPhraseSynonymsBridgeProps } from 'src/react/bridge/components/KeyPhraseSynonymsBridge';
import { OrganizationListBridgeProps } from 'src/react/bridge/components/OrganizationListBridge';
import { ActivityPageBridgeProps } from 'src/react/bridge/components/ActivityPageBridge';
import { KeyPhraseDeletePopupBridgeProps } from 'src/react/bridge/components/KeyPhraseDeletePopupBridge';
import { KeyPhraseGroupBridgeProps } from 'src/react/bridge/components/KeyPhraseGroupBridge';
import { SiteLanguageProps } from 'src/react/bridge/components/SiteLanguageBridge';

export const NG_REACT_MODULE = ngModule('ngReactBridge', [])
    .run([
        '$rootScope',
        ($rootScope: IRootScopeService) => {
            if (!module.hot) {
                return;
            }

            module.hot.accept([
                './components/DemoPageBridge',
                './components/ItemFormBridge',
                './components/SettingsPageBridge',
                './components/ItemFormItemTypeLabelBridge',
                './components/DashboardPageBridge',
                './components/StatisticsPageBridge',
                './components/KeyPhraseSynonymsBridge',
                './components/OrganizationListBridge',
                './components/UnauthorizedPageBridge',
                './components/ForbiddenPageBridge',
                './components/NotFoundPageBridge',
                './components/InternalErrorPageBridge',
                './components/KeyPhraseGroupBridge',
                './components/SiteLanguageBridge',
            ], () => {
                $rootScope.$emit('hmr');
            });
        },
    ])
    /**
     * @ngdoc directive
     * @name demoPage
     * @restrict E
     *
     * @param {expression} widget
     * @param {expression} organization
     * @param {expression} customFields
     */
    .component(
        'demoPage',
        ngReactComponent<DemoPageBridgeProps>(
            () => require('src/react/bridge/components/DemoPageBridge').default,
            ['widget', 'organization', 'customFields'],
        ),
    )
    /**
     * @ngdoc directive
     * @name itemForm
     * @restrict E
     *
     * @param {expression} modalInstance
     * @param {expression} resolve
     */
    .component(
        'itemForm',
        ngReactComponent<ItemFormBridgeProps>(
            () => require('src/react/bridge/components/ItemFormBridge').default,
            ['resolve', 'modalInstance'],
        ),
    )
    /**
     * @ngdoc directive
     * @name settingsPage
     * @restrict E
     *
     * @param {expression} widget
     * @param {expression} organization
     * @param {expression} customFields
     * @param {expression} crawlerTask
     * @param {expression} statisticsFilter
     */
    .component(
        'settingsPage',
        ngReactComponent<SettingsPageBridgeProps>(
            () => require('src/react/bridge/components/SettingsPageBridge').default,
            ['widget', 'organization', 'customFields', 'crawlerTask', 'statisticsFilter'],
        ),
    )
    /**
     * @ngdoc directive
     * @name itemTypeLabel
     * @restrict E
     *
     * @param {expression} itemType
     * @param {expression} isHead
     */
    .component(
        'itemTypeLabel',
        ngReactComponent<ItemFormItemTypeLabelBridgeProps>(
            () => require('src/react/bridge/components/ItemFormItemTypeLabelBridge').default,
            ['itemType'],
        ),
    )
    .component(
        'dashboardPage',
        ngReactComponent<DashboardPageBridgeProps>(
            () => require('src/react/bridge/components/DashboardPageBridge').default,
            [
                'widget',
                'dateRange',
                'onDatesChange',
                'statisticsOverview',
                'statisticsLastSearches',
                'statisticsSuccessfulSearches',
                'statisticsUnsuccessfulSearches',
            ],
        ),
    )
    /**
     * @ngdoc directive
     * @name statisticsPage
     * @restrict E
     *
     * @param {expression} widget
     * @param {expression} organization
     * @param {expression} statisticsFilter
     * @param {expression} hasStatisticsData
     */
    .component(
        'statisticsPage',
        ngReactComponent<StatisticsPageBridgeProps>(
            () => require('src/react/bridge/components/StatisticsPageBridge').default,
            [
                'widget',
                'organization',
                'statisticsFilter',
                'hasStatisticsData',
            ],
        ),
    )
    /**
     * @ngdoc directive
     * @name keyPhraseSynonyms
     * @restrict E
     *
     * @param {expression} resolve
     * @param {expression} modalInstance
     */
    .component(
        'keyPhraseSynonyms',
        ngReactComponent<KeyPhraseSynonymsBridgeProps>(
            () => require('src/react/bridge/components/KeyPhraseSynonymsBridge').default,
            ['resolve', 'modalInstance'],
        ),
    )
    /**
     * @ngdoc directive
     * @name keyPhraseGroup
     * @restrict E
     *
     * @param {expression} resolve
     * @param {expression} modalInstance
     */
    .component(
        'keyPhraseGroup',
        ngReactComponent<KeyPhraseGroupBridgeProps>(
            () => require('src/react/bridge/components/KeyPhraseGroupBridge').default,
            ['resolve', 'modalInstance'],
        ),
    )
    .component(
        'crawlerLogPage',
        ngReactComponent(
            () => require('src/react/bridge/components/CrawlerLogPageBridge').default,
            ['resolve', 'crawlerTask', 'crawlerLog', 'page', 'query', 'onPageChange', 'onSearchChange'],
        ),
    )
    /**
     * @ngdoc directive
     * @name organizationList
     * @restrict E
     *
     * @param {expression} organizations
     */
    .component(
        'organizationList',
        ngReactComponent<OrganizationListBridgeProps>(
            () => require('src/react/bridge/components/OrganizationListBridge').default,
            ['organizations'],
        ),
    )
    /**
     * @ngdoc directive
     * @name unauthorizedPage
     * @restrict E
     */
    .component(
        'unauthorizedPage',
        ngReactComponent<{}>(
            () => require('src/react/bridge/components/UnauthorizedPageBridge').default,
            [],
        ),
    )
    .component(
        'keyPhraseDeletePopup',
        ngReactComponent<KeyPhraseDeletePopupBridgeProps>(
            () => require('src/react/bridge/components/KeyPhraseDeletePopupBridge').default,
            ['resolve', 'modalInstance'],
        ),
    )
    /**
     * @ngdoc directive
     * @name forbiddenPage
     * @restrict E
     */
    .component(
        'forbiddenPage',
        ngReactComponent<{}>(
            () => require('src/react/bridge/components/ForbiddenPageBridge').default,
            [],
        ),
    )
    /**
     * @ngdoc directive
     * @name notFoundPage
     * @restrict E
     */
    .component(
        'notFoundPage',
        ngReactComponent<{}>(
            () => require('src/react/bridge/components/NotFoundPageBridge').default,
            [],
        ),
    )
    /**
     * @ngdoc directive
     * @name internalErrorPage
     * @restrict E
     */
    .component(
        'internalErrorPage',
        ngReactComponent<{}>(
            () => require('src/react/bridge/components/InternalErrorPageBridge').default,
            [],
        ),
    )
    /**
     * @ngdoc directive
     * @name authErrorPage
     * @restrict E
     */
    .component(
        'authErrorPage',
        ngReactComponent<{}>(
            () => require('src/react/bridge/components/AuthErrorPageBridge').default,
            [],
        ),
    )
    /**
     * @ngdoc directive
     * @name logoutSuccessPage
     * @restrict E
     */
    .component(
        'logoutSuccessPage',
        ngReactComponent<{}>(
            () => require('src/react/bridge/components/LogoutSuccessPageBridge').default,
            [],
        ),
    )
    .component(
        'activityPage',
        ngReactComponent<ActivityPageBridgeProps>(
            () => require('src/react/bridge/components/ActivityPageBridge').default,
            [
                'widget',
                'activityLog',
                'dateRange',
                'category',
                'query',
                'page',
                'onCategoryChange',
                'onDatesChange',
                'onPageChange',
                'onSearchChange',
            ],
        ),
    )
    /**
     * @ngdoc directive
     * @name itemTypeLabel
     * @restrict E
     *
     * @param {expression} language
     */
    .component(
        'siteLanguage',
        ngReactComponent<SiteLanguageProps>(
            () => require('src/react/bridge/components/SiteLanguageBridge').default,
            ['language'],
        ),
    );
