import { Ng1StateDeclaration } from '@uirouter/angularjs';
import { WidgetListParams } from 'src/modules/widgetList/WidgetListParams';
import { widgetsOrganizationsResolver } from 'src/modules/routes/resolvers/widgetsOrganizationsResolver';
import { IOrganizationService } from 'src/modules/api/OrganizationService';

export const ROUTES_STATES_WIDGETS: ReadonlyArray<Ng1StateDeclaration> = [
    {
        name: 'widgets',
        url: '/?query&page&organizationId',
        parent: 'authorized:index',
        component: 'widgetList',
        reloadOnSearch: false,
        params: {
            query: {
                type: 'string',
                value: '',
                squash: true,
            },
            page: {
                type: 'int',
                value: 1,
                squash: true,
            },
            organizationId: {
                type: 'string',
                value: null,
                squash: true,
            },
        },
        resolve: {
            $title: () => {
                return 'Widget Selector';
            },
            organizations: widgetsOrganizationsResolver,
            params: [
                '$stateParams',
                (
                    $stateParams: any,
                ): WidgetListParams => ({
                    query: $stateParams.query,
                    page: $stateParams.page,
                    organizationId: $stateParams.organizationId
                        ? $stateParams.organizationId
                        : null,
                }),
            ],
            organization: [
                'params',
                'organizationService',
                (
                    params: WidgetListParams,
                    organizationService: IOrganizationService,
                ) => params.organizationId
                    ? organizationService.get(params.organizationId)
                    : null,
            ],
        },
    },
];
