import { SagaIterator } from 'redux-saga';
import { all, takeLatest } from 'redux-saga/effects';
import { cancelSagaOn } from 'src/utils/cancelSagaOn';
import { MANUAL_CRAWLER_START_ACTIONS } from 'src/react/manualCrawler/actions/ManualCrawlerStartActions';
import { manualCrawlerStartSaga } from 'src/react/manualCrawler/sagas/manualCrawlerStartSaga';
import { MANUAL_CRAWLER_ABORT_ACTIONS } from 'src/react/manualCrawler/actions/ManualCrawlerAbortActions';
import { manualCrawlerAbortSaga } from 'src/react/manualCrawler/sagas/manualCrawlerAbortSaga';
import { MANUAL_CRAWLER_WATCH_ACTIONS } from 'src/react/manualCrawler/actions/ManualCrawlerWatchActions';
import { manualCrawlerWatchSaga } from 'src/react/manualCrawler/sagas/manualCrawlerWatchSaga';

export function* manualCrawlerSaga(): SagaIterator {
    yield all([
        takeLatest(MANUAL_CRAWLER_START_ACTIONS.REQUEST, cancelSagaOn<[never]>(manualCrawlerStartSaga, [
            MANUAL_CRAWLER_ABORT_ACTIONS.REQUEST,
            MANUAL_CRAWLER_WATCH_ACTIONS.REQUEST,
        ])),
        takeLatest(MANUAL_CRAWLER_WATCH_ACTIONS.REQUEST, cancelSagaOn(manualCrawlerWatchSaga, [
            MANUAL_CRAWLER_ABORT_ACTIONS.REQUEST,
            MANUAL_CRAWLER_WATCH_ACTIONS.REQUEST,
        ])),
        takeLatest(MANUAL_CRAWLER_ABORT_ACTIONS.REQUEST, manualCrawlerAbortSaga),
    ]);
}
