import './ItemFormItemTypeLabel.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { ItemType } from 'src/types/values/ItemType';
import { classList } from 'src/utils/classList';

type Props = {
    readonly itemType: ItemType;
    readonly className?: string;
};

export function ItemFormItemTypeLabel({ itemType, className }: Props): JSX.Element | null {
    if (itemType === ItemType.Crawled) {
        return null;
    }

    const classNameLabel = classList(className, 'item-form-item-type-label', {
        'item-form-item-type-label--manual': itemType === ItemType.Manual,
        'item-form-item-type-label--edited': itemType === ItemType.Edited,
    });

    return (
        <div className={classNameLabel} >
            <FormattedMessage id="itemTypeLabel_itemType" values={{ type: itemType }}/>
        </div>
    );
}
