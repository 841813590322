import './TooltipBody.scss';
import * as React from 'react';
import { classList } from 'src/utils/classList';

type Placement =
    | 'top'
    | 'left'
    | 'bottom'
    | 'right';

interface Props {
    style?: React.CSSProperties;
    className?: string;
    placement?: Placement;
    children: React.ReactNode;

    arrowOffsetLeft?: number | string;
    arrowOffsetTop?: number | string;
}

export function TooltipBody({ style, className, placement, children, ...arrow }: Props): JSX.Element {
    const arrowStyles = {
        top: arrow.arrowOffsetTop,
        left: arrow.arrowOffsetLeft,
    };

    return (
        <div className={classList(className, 'xss-tooltip-body', `xss-tooltip-body--${placement}`)} style={style}>
            <div className="xss-tooltip-body__content">{children}</div>
            <div className="xss-tooltip-body__arrow" style={arrowStyles}/>
        </div>
    );
}
