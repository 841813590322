import { ActionAny, createActions } from 'src/utils/createActions';
import { Item } from 'src/types/entities/Item';
import { Uuid } from 'src/types/values/Uuid';
import { ItemType } from 'src/types/values/ItemType';

export type ItemFormRestoreRequestData = {
    readonly itemType: ItemType;
    readonly itemId: Uuid;
};

export type ItemFormRestoreActions =
    ActionAny<ItemFormRestoreRequestData, Item>;
export const ITEM_FORM_RESTORE_ACTIONS =
    createActions<ItemFormRestoreRequestData, Item>('ITEM_FORM_RESTORE');
