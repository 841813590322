import * as React from 'react';
import * as moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { DateRange } from 'src/types/values/DateRange';

export type DateRangeItem = {
    readonly label: JSX.Element;
    readonly dateRange: DateRange;
};

export const DATE_RANGES: ReadonlyArray<DateRangeItem> = [
    {
        label: <FormattedMessage id="datePicker_today"/>,
        dateRange: {
            from: moment().startOf('day'),
            to: moment().startOf('day'),
        },
    },
    {
        label: <FormattedMessage id="datePicker_pastWeek"/>,
        dateRange: {
            from: moment().subtract(7, 'days').startOf('day'),
            to: moment().startOf('day'),
        },
    },
    {
        label: <FormattedMessage id="datePicker_past2Weeks"/>,
        dateRange: {
            from: moment().subtract(14, 'days').startOf('day'),
            to: moment().startOf('day'),
        },
    },
    {
        label: <FormattedMessage id="datePicker_pastMonth"/>,
        dateRange: {
            from: moment().subtract(1, 'months').startOf('day'),
            to: moment().startOf('day'),
        },
    },
    {
        label: <FormattedMessage id="datePicker_past3Months"/>,
        dateRange: {
            from: moment().subtract(3, 'months').startOf('day'),
            to: moment().startOf('day'),
        }
    },
];

export const DEFAULT_DATE_RANGE = DATE_RANGES[3];
