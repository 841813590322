import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { Widget } from 'src/types/entities/Widget';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { KeyPhraseForm } from 'src/types/dto/KeyPhraseForm';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { getKeyPhraseEndpoint } from 'src/react/api/selectors/getEndpoint';
import { IKeyPhraseEndpoint } from 'src/react/api/endpoints/KeyPhraseEndpoint';

export function* keyPhraseUpdate(keyPhrase: KeyPhrase, formData: KeyPhraseForm): SagaIterator {
    const widget: Widget = yield select(getSelectedWidget);
    const keyPhraseEndpoint: IKeyPhraseEndpoint = yield select(getKeyPhraseEndpoint);

    return yield call({
        fn: keyPhraseEndpoint.update,
        context: keyPhraseEndpoint,
    }, keyPhrase.id, formData, { widgetId: widget.id });
}
