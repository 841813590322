import { get, set } from 'lodash';
import { FormErrors, SubmissionError } from 'redux-form';
import { BaseError } from 'src/errors/BaseError';
import { ValidationError } from 'src/errors/ValidationError';
import { FieldErrors } from 'src/types/values/FieldError';

const defaultValidationError: FormErrors<{}, FieldErrors> = {
    _error: [{
        code: 'formError_validationError',
        path: '_error',
        value: null,
        params: undefined,
        context: undefined,
    }],
};
const defaultSubmissionError: FormErrors<{}, FieldErrors> = {
    _error: [{
        code: 'formError_submissionError',
        path: '_error',
        value: null,
        params: undefined,
        context: undefined,
    }],
};

export function getValidationError(error: BaseError): FormErrors<{}, FieldErrors> {
    return error instanceof ValidationError
        ? flattenErrors(error.errors)
        : defaultValidationError;
}

export function getSubmissionError(error: BaseError): SubmissionError<{}, FieldErrors> {
    return error instanceof ValidationError
        ? new SubmissionError(flattenErrors(error.errors))
        : new SubmissionError(defaultSubmissionError);
}

export function flattenErrors<T>(errors: FieldErrors): FormErrors<T, FieldErrors> {
    const result = {};
    errors.forEach((error) => {
        const path = error.path ? error.path : '_error';

        const existing = get(result, path, []);
        set(result, path, [...existing, error]);
    });
    return result;
}
