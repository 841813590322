import { createSelector } from 'reselect';
import { getApiClient } from 'src/react/api/selectors/getApiClient';
import { createJsonEndpoint } from 'src/react/api/services/createJsonEndpoint';
import { IOrganizationEndpoint } from 'src/react/api/endpoints/OrganizationEndpoint';
import { IWidgetEndpoint } from 'src/react/api/endpoints/WidgetEndpoint';
import { IPermissionEndpoint } from 'src/react/api/endpoints/PermissionEndpoint';
import { ICustomFieldEndpoint } from 'src/react/api/endpoints/CustomFieldEndpoint';
import { ICrawlerTaskEndpoint } from 'src/react/api/endpoints/CrawlerTaskEndpoint';
import { IItemEndpoint } from 'src/react/api/endpoints/ItemEndpoint';
import { IRestoreItemEndpoint } from 'src/react/api/endpoints/RestoreItemEndpoint';
import { IKeyPhraseEndpoint } from 'src/react/api/endpoints/KeyPhraseEndpoint';
import { ISearchEndpoint } from 'src/react/api/endpoints/SearchEndpoint';
import { IStatisticsFilterEndpoint } from 'src/react/api/endpoints/StatisticsFilterEndpoint';
import {
    IStatisticsSuccessfulSearchesEndpoint
} from 'src/react/api/endpoints/StatisticsSuccessfulSearchesEndpoint';
import {
    IStatisticsUnsuccessfulSearchesEndpoint
} from 'src/react/api/endpoints/StatisticsUnsuccessfulSearchesEndpoint';
import { IStatisticsCombinedSearchesEndpoint } from 'src/react/api/endpoints/StatisticsCombinedSearchesEndpoint';
import { IStatisticsLastSearchesEndpoint } from 'src/react/api/endpoints/StatisticsLastSearchesEndpoint';
import { IKeyPhrasesGroupEndpoint } from 'src/react/api/endpoints/KeyPhrasesGroupEndpoint';

export const getOrganizationEndpoint = createSelector([
    getApiClient,
], (apiCient): IOrganizationEndpoint => createJsonEndpoint(apiCient, 'organizations'));

export const getWidgetEndpoint = createSelector([
    getApiClient,
], (apiCient): IWidgetEndpoint => createJsonEndpoint(apiCient, 'widgets'));

export const getPermissionEndpoint = createSelector([
    getApiClient,
], (apiCient): IPermissionEndpoint => createJsonEndpoint(apiCient, 'organizations/:organizationId/permissions'));

export const getCustomFieldEndpoint = createSelector([
    getApiClient,
], (apiCient): ICustomFieldEndpoint => createJsonEndpoint(apiCient, 'widgets/:widgetId/custom-fields'));

export const getStatisticsFilterEndpoint = createSelector([
    getApiClient,
], (apiCient): IStatisticsFilterEndpoint => createJsonEndpoint(apiCient, 'statistics/:widgetId/filter'));

export const getCrawlerTaskEndpoint = createSelector([
    getApiClient,
], (apiCient): ICrawlerTaskEndpoint => createJsonEndpoint(apiCient, 'widgets/:widgetId/crawler-tasks'));

export const getItemEndpoint = createSelector([
    getApiClient,
], (apiCient): IItemEndpoint => createJsonEndpoint(apiCient, 'widgets/:widgetId/items'));

export const getRestoreItemEndpoint = createSelector([
    getApiClient,
], (apiCient): IRestoreItemEndpoint => createJsonEndpoint(apiCient, 'widgets/:widgetId/:itemType/items'));

export const getKeyPhraseEndpoint = createSelector([
    getApiClient,
], (apiCient): IKeyPhraseEndpoint => createJsonEndpoint(apiCient, 'widgets/:widgetId/key-phrases'));

export const getKeyPhrasesGroupEndpoint = createSelector([
    getApiClient,
], (apiCient): IKeyPhrasesGroupEndpoint => createJsonEndpoint(apiCient, 'widgets/:widgetId/key-phrases-group'));

export const getSearchEndpoint = createSelector([
    getApiClient,
], (apiCient): ISearchEndpoint => createJsonEndpoint(apiCient, 'widgets/:widgetId/search'));

export const getStatisticsSuccessfulSearchesEndpoint = createSelector([
    getApiClient,
], (apiCient): IStatisticsSuccessfulSearchesEndpoint => createJsonEndpoint(
    apiCient,
    'statistics/:widgetId/successful-searches',
    ));

export const getStatisticsUnsuccessfulSearchesEndpoint = createSelector([
    getApiClient,
], (apiCient): IStatisticsUnsuccessfulSearchesEndpoint => createJsonEndpoint(
    apiCient,
    'statistics/:widgetId/unsuccessful-searches',
));
export const getStatisticsLastSearchesEndpoint = createSelector([
    getApiClient,
], (apiCient): IStatisticsLastSearchesEndpoint => createJsonEndpoint(
    apiCient,
    'statistics/:widgetId/last-searches',
));

export const getStatisticsCombinedSearchesEndpoint = createSelector([
    getApiClient,
], (apiCient): IStatisticsCombinedSearchesEndpoint => createJsonEndpoint(
    apiCient,
    'statistics/:widgetId/searches',
));
