import { IPromise } from 'angular';
import { Config } from 'src/types/constants/Config';
import { Widget } from 'src/types/entities/Widget';
import { IApiService } from 'src/modules/api/ApiService';
import { StatisticsOverview } from 'src/types/entities/StatisticsOverview';
import { StatisticsOverviewFilter } from 'src/types/dto/StatisticsOverviewFilter';

export interface IStatisticsOverviewService {
    get(
        widget: Readonly<Widget>,
        filter: StatisticsOverviewFilter,
    ): IPromise<StatisticsOverview>;
}

export class StatisticsOverviewService implements IStatisticsOverviewService {
    public static $inject = [
        'config',
        'apiService',
    ];
    public constructor(
        private config: Config,
        private apiService: IApiService,
    ) {
    }

    public get(
        widget: Readonly<Widget>,
        filter: StatisticsOverviewFilter,
    ): IPromise<StatisticsOverview> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'statistics',
            encodeURIComponent(widget.id),
            'overview',
        ].join('/');

        return this.apiService
            .makeGETRequest<StatisticsOverview>(requestUrl, { params: filter })
            .then(({ data }) => data);
    }
}
