import { Ng1StateDeclaration } from '@uirouter/angularjs';
import { Config } from 'src/types/constants/Config';

export const ROUTES_STATES_ERRORS: ReadonlyArray<Ng1StateDeclaration> = [
    {
        name: 'unauthorized:401',
        url: '/_private/401',
        parent: 'unauthorized',
        component: 'unauthorizedPage',
        resolve: {
            $title: () => 'Unauthorized',
            login: [
                'config',
                '$window',
                (config: Config, $window: Window) => {
                    const returnUrl = encodeURIComponent($window.location.href);
                    $window.location.href = `${config.services.adminApiUrl}/login?returnTo=${returnUrl}`;
                }
            ]
        },
    },
    {
        name: 'unauthorized:403',
        url: '/_private/403',
        parent: 'unauthorized',
        component: 'forbiddenPage',
        resolve: {
            $title: () => 'Forbidden',
        },
    },
    {
        name: 'unauthorized:404',
        url: '/_private/404',
        parent: 'unauthorized',
        component: 'notFoundPage',
        resolve: {
            $title: () => 'Not found',
        },
    },
    {
        name: 'unauthorized:5xx',
        url: '/_private/5xx',
        parent: 'unauthorized',
        component: 'internalErrorPage',
        resolve: {
            $title: () => 'Server Error',
        },
    },
    {
        name: 'unauthorized:auth:error',
        url: '/error/:type',
        parent: 'unauthorized',
        component: 'authErrorPage',
        resolve: {
            $title: () => 'Auth Error',
        },
    },
];
