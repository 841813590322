import './SiteLanguages.scss';
import { SiteLanguages } from 'src/types/values/siteLanguage';
import * as React from 'react';
import { Selectbox } from 'src/react/common/components/Selectbox';
import { FormattedMessage } from 'react-intl';
import { bind } from 'lodash-decorators';
import { SiteLanguageState } from 'src/react/siteLanguage/state/SiteLanguageState';
import { createStructuredSelector } from 'reselect';
import { RootState } from 'src/react/root/state/RootState';
import { connectDecorator } from 'src/decorators/connectDecorator';
import { SITE_LANGUAGES_CHANGE_LANGUAGE_ACTIONS }
    from 'src/react/siteLanguage/actions/SiteLanguagesChangeLanguageActions';
import { getLanguage } from 'src/utils/getLanguage';

type OwnProps = {
    readonly language: SiteLanguages;
};
type StateProps = {
    readonly state: SiteLanguageState;
};
type DispatchProps = {
    readonly changeLanguage: (lang: SiteLanguages) => void;
};
type Props = OwnProps & StateProps & DispatchProps;

type State = {
    readonly language: SiteLanguages | null;
};

const options = [
    SiteLanguages.German,
    SiteLanguages.English,
];

export class SiteLanguageConnected extends React.Component<Props, State> {
    public state: State = {
        language: this.props.language,
    };

    public componentDidMount(): void {
        const { language, changeLanguage } = this.props;
        changeLanguage(language);
    }

    public render(): JSX.Element {
        const { language } = this.state;

        return (
            <div className="siteLanguages__filter-container">
                <div className="siteLanguages__language">
                    <Selectbox
                        name="language"
                        className="xss-field"
                        clearable={false}
                        placeholder={<FormattedMessage id="language_siteLanguage" values={{ language }} />}
                        options={options}
                        value={language}
                        onChange={this.onLanguageChange}
                        renderOption={this.renderLanguageOption} />
                </div>
            </div>
        );
    }

    private renderLanguageOption(lang: SiteLanguages): JSX.Element {
        return <FormattedMessage id="language_siteLanguage" values={{ lang }} />;
    }

    @bind
    private onLanguageChange(value: SiteLanguages | null): void {
        this.setState({ language: value });

        const language = getLanguage(value);
        const { changeLanguage } = this.props;
        changeLanguage(language);
    }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
    state: (state) => state.locale,
});

const mapDispatchToProps: DispatchProps = {
    changeLanguage: (language) => SITE_LANGUAGES_CHANGE_LANGUAGE_ACTIONS.request(language),
};

@connectDecorator<OwnProps, StateProps, DispatchProps>(SiteLanguageConnected, mapStateToProps, mapDispatchToProps)
export class SiteLanguage extends React.Component<OwnProps> {}
