import { ItemFormSaveState } from 'src/react/itemForm/state/ItemFormSaveState';
import {
    ITEM_FORM_SAVE_RESTORED_ACTIONS,
    ItemFormSaveRestoredActions
} from 'src/react/itemForm/actions/ItemFormSaveRestoredActions';

export function itemFormSaveRestoredReducer(
    state: ItemFormSaveState,
    action: ItemFormSaveRestoredActions,
): ItemFormSaveState {
    switch (action.type) {
        case ITEM_FORM_SAVE_RESTORED_ACTIONS.PENDING:
            return { ...state, loading: true, error: null };

        case ITEM_FORM_SAVE_RESTORED_ACTIONS.SUCCESS:
            return { ...state, loading: false, data: action.data, error: null };

        case ITEM_FORM_SAVE_RESTORED_ACTIONS.FAILURE:
            return { ...state, loading: false, data: null, error: action.data };

        default:
            return state;
    }
}
