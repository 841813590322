import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { Item } from 'src/types/entities/Item';
import { Widget } from 'src/types/entities/Widget';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { KeyPhraseState } from 'src/types/values/KeyPhraseState';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { IKeyPhraseEndpoint } from 'src/react/api/endpoints/KeyPhraseEndpoint';
import { getKeyPhraseEndpoint } from 'src/react/api/selectors/getEndpoint';

export function* itemKeyPhraseList(item: Item): SagaIterator {
    const widget: Widget = yield select(getSelectedWidget);
    const keyPhraseEndpoint: IKeyPhraseEndpoint = yield select(getKeyPhraseEndpoint);

    const { data: keyPhrases }: ListWithTotal<KeyPhrase> = yield call({
        fn: keyPhraseEndpoint.list,
        context: keyPhraseEndpoint,
    }, {
        query: '',
        exact: false,
        state: [KeyPhraseState.Active],
        itemId: [item.id],
        sort: [],
        page: 0,
        pageSize: 10,
        groups: [],
    }, {
        widgetId: widget.id,
    });

    return keyPhrases;
}
