import { IComponentOptions, IScope } from 'angular';
import { LoadingWrapperController } from 'src/modules/loading/LoadingWrapperController';
import * as LoadingWrapperTemplate from './LoadingWrapperTemplate.html';

export const LOADING_WRAPPER_COMPONENT: IComponentOptions = {
    template: LoadingWrapperTemplate,
    controller: LoadingWrapperController,
    controllerAs: 'loadingWrapperCtrl',
    transclude: true,
    bindings: {
        isLoading: '<',
        hasContent: '<',
    },
};

export interface LoadingWrapperScope extends IScope {
    loadingWrapperCtrl: LoadingWrapperController;
}
