import './KeyPhrasePinInput.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { bind } from 'lodash-decorators/bind';
import { throttle } from 'lodash-decorators/throttle';
import { memoize } from 'lodash-decorators/memoize';
import { noop } from 'src/utils/noop';
import { classList } from 'src/utils/classList';
import { connectDecorator } from 'src/decorators/connectDecorator';
import { RootState } from 'src/react/root/state/RootState';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { HtmlButton } from 'src/react/common/components/Button';
import { InputGroup, InputGroupItem } from 'src/react/common/components/InputGroup';
import { getKeyPhrasePinInputData } from 'src/react/keyPhrasePinInput/selectors/getKeyPhrasePinInputData';
import {
    KEY_PHRASE_PIN_INPUT_SEARCH_ACTIONS,
} from 'src/react/keyPhrasePinInput/actions/KeyPhrasePinInputSearchActions';
import {
    KEY_PHRASE_PIN_INPUT_RESET_ACTIONS,
} from 'src/react/keyPhrasePinInput/actions/KeyPhrasePinInputResetActions';
import { Autocomplete } from 'src/react/common/components/Autocomplete';
import { MIN_KEY_PHRASE_LENGTH } from 'src/constants/general';

type OwnProps = {
    readonly name?: string;
    readonly className?: string;
    readonly placeholderid?: string;
    readonly disabled?: boolean;
    readonly readOnly?: boolean;
    readonly value: ReadonlyArray<string>;
    readonly hideAddButton?: true;
    readonly onBlur?: () => void;
    readonly onChange?: (value: ReadonlyArray<string>) => void;
    readonly onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
};
type StateProps = {
    readonly autoComplete: ReadonlyArray<KeyPhrase>;
};
type DispatchProps = {
    readonly updateAutoComplete: (query: string, exclude: ReadonlyArray<string>) => void;
    readonly resetAutoComplete: () => void;
};
type AllProps =
    & OwnProps
    & StateProps
    & DispatchProps;

type State = {
    readonly keyPhrase: string;
};

class Connected extends React.Component<AllProps, State> {
    public state: State = {
        keyPhrase: '',
    };

    public componentWillUnmount(): void {
        const { resetAutoComplete } = this.props;
        resetAutoComplete();
    }

    public render(): JSX.Element {
        const { keyPhrase } = this.state;
        const {
            name, className, disabled, readOnly, value, autoComplete, hideAddButton, placeholderid,
            onFocus, onBlur
        } = this.props;

        return (
            <div className={classList('keyphrase-pin-input', className)} data-field={name}>
                <InputGroup className="keyphrase-pin-input__input">
                    <InputGroupItem className="keyphrase-pin-input__col keyphrase-pin-input__col--input">
                        <Autocomplete className="keyphrase-pin-input__input-text"
                                      disabled={disabled}
                                      readOnly={readOnly}
                                      value={keyPhrase}
                                      placeholderid={placeholderid}
                                      items={autoComplete}
                                      getItemValue={this.getKeyPhraseText}
                                      onChange={this.handleInput}
                                      onSelect={this.handleSelect}
                                      onCreate={this.handleCreate}
                                      onFocus={onFocus}
                                      onBlur={onBlur}/>
                    </InputGroupItem>
                    {!hideAddButton && (
                        <InputGroupItem className="keyphrase-pin-input__col keyphrase-pin-input__col--button">
                            <HtmlButton block={true}
                                        intent="none"
                                        disabled={this.isAddDisabled(keyPhrase)}
                                        onClick={this.handleAddClick}
                                        className="keyphrase-pin-input__input-button">
                                <FormattedMessage id="itemForm_addKeyPhrase"/>
                            </HtmlButton>
                        </InputGroupItem>
                    )}
                </InputGroup>
                <div className="keyphrase-pin-input__value">
                    {value.map((item) => (
                        <span key={item} className="keyphrase-pin-input__value-item">
                            <a className="keyphrase-pin-input__value-remove"
                               onClick={this.getRemoveClickHandler(item)}><i className="fa fa-times" /></a>
                            <span className="keyphrase-pin-input__value-text">{item}</span>
                        </span>
                    ))}
                </div>
            </div>
        );
    }

    @bind
    private getKeyPhraseText(keyPhrase: KeyPhrase): string {
        return keyPhrase.text;
    }

    @bind
    private handleInput(keyPhrase: string): void {
        this.setState({ keyPhrase }, this.handleAutoComplete);
    }

    @bind
    private handleSelect(keyPhrase: KeyPhrase): void {
        this.addKeyPhrase(keyPhrase.text);
    }

    @bind
    private handleCreate(keyPhrase: string): void {
        const { hideAddButton } = this.props;
        if (hideAddButton) {
            return;
        }
        this.addKeyPhrase(keyPhrase);
    }

    @bind
    private handleAddClick(): void {
        const { keyPhrase } = this.state;
        this.addKeyPhrase(keyPhrase);
    }

    @throttle(500)
    private handleAutoComplete(): void {
        const { keyPhrase } = this.state;
        if (keyPhrase.length < MIN_KEY_PHRASE_LENGTH) {
            return;
        }

        const { updateAutoComplete, value } = this.props;
        updateAutoComplete(keyPhrase, value);
    }

    @memoize
    private getRemoveClickHandler(keyPhrase: string): () => void {
        return () => {
            this.removeKeyPhrase(keyPhrase);
        };
    }

    private isAddDisabled(keyPhrase: string): boolean {
        const { disabled, readOnly } = this.props;
        if (disabled || readOnly) {
            return true;
        }

        if (keyPhrase.length < MIN_KEY_PHRASE_LENGTH) {
            return true;
        }

        const { value } = this.props;
        return value.includes(keyPhrase);
    }

    private addKeyPhrase(keyPhrase: string): void {
        if (this.isAddDisabled(keyPhrase)) {
            return;
        }

        const { value, onChange = noop, onBlur = noop, resetAutoComplete } = this.props;

        this.setState({ keyPhrase: '' });
        resetAutoComplete();
        onChange([...value, keyPhrase]);
        onBlur();
    }

    private removeKeyPhrase(keyPhrase: string): void {
        const { value, onChange = noop, onBlur = noop, resetAutoComplete } = this.props;

        this.setState({ keyPhrase: '' });
        resetAutoComplete();
        onChange(value.filter((other) => other !== keyPhrase));
        onBlur();
    }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
    autoComplete: getKeyPhrasePinInputData,
});
const mapDispatchToProps: DispatchProps = {
    updateAutoComplete: (query, exclude) => KEY_PHRASE_PIN_INPUT_SEARCH_ACTIONS.request({ query, exclude }),
    resetAutoComplete: () => KEY_PHRASE_PIN_INPUT_RESET_ACTIONS.request(null),
};

@connectDecorator<OwnProps, StateProps, DispatchProps>(Connected, mapStateToProps, mapDispatchToProps)
export class KeyPhrasePinInput extends React.Component<OwnProps> {}
