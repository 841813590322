import { AssertionError } from 'src/errors/AssertionError';

export function assertDefined<TValue>(
    value: TValue | undefined,
    message?: string,
    context?: object,
): TValue {
    if (value === undefined) {
        throw new AssertionError(message || `Value "${value}" should not be undefined`, context);
    }

    return value;
}

export function assertNotNull<TValue>(
    value: TValue | null,
    message?: string,
    context?: object,
): TValue {
    if (value === null) {
        throw new AssertionError(message || `Value "${value}" should not be null`, context);
    }

    return value;
}

export function assertOneOf<TType>(
    value: any,
    allowed: ReadonlyArray<TType>,
    message?: string,
    context?: object,
): TType {
    if (allowed.indexOf(value) < 0) {
        throw new AssertionError(
            message || `Value "${value}" should be one of ${allowed.map(String).join(', ')}`,
            context,
        );
    }

    return value;
}

export function assertTypeIs<TType>(
    value: any,
    check: (value: any) => value is TType,
    message?: string,
    context?: object,
): TType {
    if (!check(value)) {
        throw new AssertionError(message || `Value "${value}" is not valid`, context);
    }

    return value;
}

export function assertInstanceOf<TType>(
    value: any,
    type: { new(...args: any[]): TType },
    message?: string,
    context?: object,
): TType {
    if (!(value instanceof type)) {
        throw new AssertionError(message || `Value "${value}" should be instanceof "${type.name}"`, context);
    }

    return value;
}

export function assertNever(
    message?: string,
    context?: object,
): never {
    throw new AssertionError(message || 'This code branch should never be reached', context);
}
