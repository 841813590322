import { UnsignedInteger } from 'src/types/values/UnsignedInteger';

export const SEARCH_INPUT_DEBOUNCE: {
    [pageKey: string]: UnsignedInteger;
} = {
    demoPage: 500,
    searchFilter: 500,
    urlValidation: 500,
};

export const DEFAULT_THROTTLE_INTERVAL = 500;
