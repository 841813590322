import './OrganizationModal.scss';
import * as React from 'react';
import { FormErrors } from 'redux-form';
import { bind } from 'lodash-decorators/bind';
import { flattenErrors } from 'src/utils/getFormErrors';
import { createSchemaValidator } from 'src/utils/createSchemaValidator';
import { FieldErrors } from 'src/types/values/FieldError';
import { OrganizationForm } from 'src/types/dto/OrganizationForm';
import { OrganizationModalForm } from 'src/react/organization/components/OrganizationModalForm';
import { OrganizationListItem } from 'src/react/organization/state/OrganizationListItem';
import { ORGANIZATION_FORM_SCHEMA } from 'src/react/organization/constants/OrganizationFormSchema';

type Props = {
    readonly organization: OrganizationListItem | null;
    readonly onCancel: () => void;
    readonly onSubmit: () => void;
};

const formValidator = createSchemaValidator(ORGANIZATION_FORM_SCHEMA);

export class OrganizationModal extends React.Component<Props> {
    private initialData: OrganizationForm = this.props.organization
        ? { name: this.props.organization.organization.name }
        : { name: '' };

    public render(): JSX.Element {
        const { organization, onCancel } = this.props;

        return (
            <OrganizationModalForm form="organization-form"
                                   initialValues={this.initialData}
                                   destroyOnUnmount={true}
                                   forceUnregisterOnUnmount={true}
                                   validate={this.validateForm}
                                   mode={organization ? 'update' : 'create'}
                                   onCancel={onCancel}
                                   onSubmit={this.handleSubmit}/>
        );
    }

    @bind
    private validateForm(formData: OrganizationForm): FormErrors<OrganizationForm, FieldErrors> {
        return flattenErrors(formValidator(formData));
    }

    @bind()
    private handleSubmit(): Promise<never> {
        const { onSubmit } = this.props;
        onSubmit();

        return new Promise<never>(() => { });
    }
}
