import { IHttpResponse, IPromise } from 'angular';
import { Config } from 'src/types/constants/Config';
import { Widget } from 'src/types/entities/Widget';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { IApiService } from 'src/modules/api/ApiService';
import { ActivityLogFilter } from 'src/types/dto/ActivityLogFilter';
import { ActivityLogItem } from 'src/types/entities/ActivityLogItem';

export interface IActivityLogService {
    list(
        widget: Readonly<Widget>,
        filter: Readonly<ActivityLogFilter> | {},
    ): IPromise<ListWithTotal<ActivityLogItem>>;
}

export class ActivityLogService implements IActivityLogService {
    public static $inject = [
        'config',
        'apiService',
    ];
    public constructor(
        private config: Config,
        private apiService: IApiService,
    ) {
    }

    public list(
        widget: Readonly<Widget>,
        filter: ActivityLogFilter,
    ): IPromise<ListWithTotal<ActivityLogItem>> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
            'activity-logs',
        ].join('/');

        return this.apiService
            .makeGETRequest<ListWithTotal<ActivityLogItem>>(requestUrl, { params: filter })
            .then(extractResponseData);
    }
}

function extractResponseData<T>(response: IHttpResponse<T>): T {
    return response.data;
}
