import * as moment from 'moment';
import { DateRange } from 'src/types/values/DateRange';
import { StatisticsDateFilter } from 'src/types/dto/StatisticsDateFilter';

export const dateFormat = 'YYYY-MM-DD';

export function parseDate(value: string | undefined): moment.Moment | null {
    if (!value) {
        return null;

    }
    const date = moment(value, dateFormat, true);
    return date.isValid() ? date.startOf('day') : null;

}
export function createDateFilter(dateRange: DateRange): StatisticsDateFilter {
    return {
        dateFrom: dateRange.from ? dateRange.from.toISOString() : null,
        dateTo: dateRange.to ? dateRange.to.clone().add(1, 'day').toISOString() : null
    };
}
