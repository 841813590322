import './OrganizationLeaveModal.scss';
import * as React from 'react';
import { OrganizationListItem } from 'src/react/organization/state/OrganizationListItem';
import { OrganizationLeaveForm } from 'src/react/organization/components/OrganizationLeaveForm';

type Props = {
    readonly organization: OrganizationListItem;
    readonly onCancel: () => void;
    readonly onSubmit: () => void;
};

export function OrganizationLeaveModal({ organization, onCancel, onSubmit }: Props): JSX.Element {
    return (
        <OrganizationLeaveForm form="organization-leave"
                               initialValues={{}}
                               destroyOnUnmount={true}
                               forceUnregisterOnUnmount={true}
                               organization={organization}
                               onCancel={onCancel}
                               onSubmit={onSubmit}/>
    );
}
