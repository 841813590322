import angular from 'angular';

angular.module('httpPrefix', []).directive('httpPrefix', () => ({
    restrict: 'A',
    require: 'ngModel',
    link(scope, element, attrs, controller) {
        function ensureHttpPrefix(value) {
            // Need to add prefix if URL starts with www
            if (value && /^www.*/i.test(value)) {
                controller.$setViewValue(`http://${value}`);
                controller.$render();
                return `http://${value}`;
            }
            return value;
        }

        controller.$formatters.push(ensureHttpPrefix);
        controller.$parsers.push(ensureHttpPrefix);
    },
}));
