import './DashboardLastSearchList.scss';
import * as React from 'react';
import { LocationDescriptor } from 'history';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Widget } from 'src/types/entities/Widget';
import { StatisticsLastSearch } from 'src/types/entities/StatisticsLastSearch';
import { DateFormat } from 'src/react/common/components/DateFormat';
import { encodeQueryForStatisticsLink } from 'src/utils/encodeQueryForStatisticsLink';

type Props = {
    readonly widget: Widget;
    readonly lastSearches: ReadonlyArray<StatisticsLastSearch>;
    readonly lastSearchesLocation: LocationDescriptor;
};

export class DashboardLastSearchList extends React.Component<Props> {
    public render(): JSX.Element {
        const { lastSearches, lastSearchesLocation } = this.props;

        return (
            <div className="dashboard-portlet dashboard-last-search-list">
                <div className="dashboard-portlet__title">
                    <FormattedMessage id="dashboard_lastSearch_title"/>
                    <Link to={lastSearchesLocation}
                          onClick={stopPropagation}
                          className="dashboard-portlet__action">
                        <i className="si si-External-link"/>
                    </Link>
                </div>

                {lastSearches.length > 0
                    ? this.renderList()
                    : this.renderEmpty()}
            </div>
        );
    }

    private renderEmpty(): JSX.Element {
        return (
            <div className="dashboard-last-search-list__empty">
                <FormattedMessage id="dashboard_lastSearch_noData"/>
            </div>
        );
    }

    private renderList(): JSX.Element {
        const { lastSearches } = this.props;

        return (
            <ul className="dashboard-last-search-list__list">
                {lastSearches.map((lastSearch, index) => (
                    <li key={index} className="dashboard-last-search-list__item">
                        <div className="dashboard-last-search-list__item-term">
                            <Link className="dashboard-last-search-list__item-link"
                                  to={this.getTopSearchLocation(lastSearch)}>{lastSearch.query}</Link>
                        </div>
                        <div className="dashboard-last-search-list__item-date">
                            <DateFormat date={lastSearch.date}/>
                        </div>
                    </li>
                ))}
            </ul>
        );
    }

    private getTopSearchLocation(lastSearch: StatisticsLastSearch): LocationDescriptor {
        const { widget } = this.props;
        return (
            `/${encodeURIComponent(widget.id)}` +
            '/demo' +
            `?query=${encodeQueryForStatisticsLink(lastSearch.query)}`
        );
    }
}

function stopPropagation(event: React.MouseEvent<unknown>): void {
    event.stopPropagation();
}
