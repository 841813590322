import { SelectedWidgetState } from 'src/react/selectedWidget/state/SelectedWidgetState';
import {
    SELECTED_WIDGET_DATA_ACTIONS,
    SelectedWidgetDataActions,
} from 'src/react/selectedWidget/actions/SelectedWidgetDataActions';

export function selectedWidgetDataReducer(
    state: SelectedWidgetState,
    action: SelectedWidgetDataActions,
): SelectedWidgetState {
    switch (action.type) {
        case SELECTED_WIDGET_DATA_ACTIONS.PENDING:
            return { ...state, loading: true, data: null, error: null };

        case SELECTED_WIDGET_DATA_ACTIONS.SUCCESS:
            return { ...state, loading: false, data: action.data, error: null };

        case SELECTED_WIDGET_DATA_ACTIONS.FAILURE:
            return { ...state, loading: false, data: null, error: action.data };

        default:
            return state;
    }
}
