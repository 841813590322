import { ItemFormLoadItemState } from 'src/react/itemForm/state/ItemFormLoadItemState';
import {
    ITEM_FORM_LOAD_ITEM_ACTIONS,
    ItemFormLoadItemActions
} from 'src/react/itemForm/actions/ItemFormLoadItemActions';

export function itemFormLoadItemReducer(
    state: ItemFormLoadItemState,
    action: ItemFormLoadItemActions,
): ItemFormLoadItemState {
    switch (action.type) {
        case ITEM_FORM_LOAD_ITEM_ACTIONS.PENDING:
            return { ...state, loading: true, error: null };

        case ITEM_FORM_LOAD_ITEM_ACTIONS.SUCCESS:
            return { ...state, loading: false, data: action.data, error: null };

        case ITEM_FORM_LOAD_ITEM_ACTIONS.FAILURE:
            return { ...state, loading: false, data: null, error: action.data };

        default:
            return state;
    }
}
