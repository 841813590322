import { FieldErrors } from 'src/types/values/FieldError';
import { createSchemaValidator } from 'src/utils/createSchemaValidator';
import { PermissionForm } from 'src/types/dto/PermissionForm';
import { PermissionFormData } from 'src/react/organization/types/PermissionFormData';
import { PERMISSION_FORM_SCHEMA } from 'src/react/organization/constants/PermissionFormSchema';

const validator = createSchemaValidator(PERMISSION_FORM_SCHEMA);

export const permissionFormValidator = {
    validate: (formData: PermissionFormData): FieldErrors => {
        return validator(formData);
    },
    isValid: (formData: PermissionFormData): formData is PermissionForm => {
        return validator(formData).length === 0;
    },
};
