import './StatisticsSearchTable.scss';
import * as React from 'react';
import { LocationDescriptor } from 'history';
import { Link } from 'react-router-dom';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import { memoize } from 'lodash-decorators/memoize';
import { Widget } from 'src/types/entities/Widget';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { StatisticsTopSearch } from 'src/types/entities/StatisticsTopSearch';
import { StatisticsPageFilter } from 'src/react/statistics/state/StatisticsPageFilter';
import { encodeQueryForStatisticsLink } from 'src/utils/encodeQueryForStatisticsLink';

type Props = {
    readonly params: StatisticsPageFilter;
    readonly widget: Widget;
    readonly searches: ListWithTotal<StatisticsTopSearch>;
    readonly changeSort: (sort: string) => void;
    readonly showCTR: boolean;
};

export class StatisticsSearchTable extends React.Component<Props> {
    public render(): JSX.Element {
        return (
            <div className="statistics-search-table">
                {this.renderEmpty()}
                {this.renderTable()}
            </div>
        );
    }

    private renderEmpty(): JSX.Element | null {
        const { searches } = this.props;
        if (searches.total > 0) {
            return null;
        }
        return (
            <div className="statistics-search-table__empty">
                <FormattedMessage id="statistics_search_noData"/>
            </div>
        );
    }

    private renderTable(): JSX.Element | null {
        if (this.props.searches.total === 0) {
            return null;
        }

        const { searches: { data }, showCTR } = this.props;

        return (
            <table className="statistics-search-table__table">
                <thead>
                <tr>
                    <th className="statistics-search-table__th">
                        <a className="statistics-search-table__a"
                           onClick={this.changeSortHandler('query')}>
                            {this.isSortActive('query')
                                ? <i className="si si-Sort"/>
                                : null
                            }
                            <FormattedMessage id="statistics_search_searchTerm"/>
                        </a>
                    </th>
                    <th className="statistics-search-table__th">
                        <a className="statistics-search-table__a"
                           onClick={this.changeSortHandler('searchCount')}>
                            {this.isSortActive('searchCount')
                                ? <i className="si si-Sort"/>
                                : null
                            }
                            <FormattedMessage id="statistics_search_searchCount"/>
                        </a>
                    </th>
                    <th className="statistics-search-table__th">
                        <a className="statistics-search-table__a"
                           onClick={this.changeSortHandler('clickCount')}>
                            {this.isSortActive('clickCount')
                                ? <i className="si si-Sort"/>
                                : null
                            }
                            <FormattedMessage id="statistics_search_clickCount"/>
                        </a>
                    </th>
                    {showCTR
                        ? (<th className="statistics-search-table__th">
                            <a className="statistics-search-table__a"
                               onClick={this.changeSortHandler('ctr')}>
                                {this.isSortActive('ctr')
                                    ? <i className="si si-Sort"/>
                                    : null
                                }
                                <FormattedMessage id="statistics_search_ctr"/>
                            </a>
                          </th>)
                        : null
                    }
                    <th className="statistics-search-table__th">
                        <a className="statistics-search-table__a"
                           onClick={this.changeSortHandler('averageClickPosition')}>
                            {this.isSortActive('averageClickPosition')
                                ? <i className="si si-Sort"/>
                                : null
                            }
                            <FormattedMessage id="statistics_search_avg_click_position"/>
                        </a>
                    </th>
                    <th className="statistics-search-table__th">
                        <a className="statistics-search-table__a"
                           onClick={this.changeSortHandler('averageResultCount')}>
                            {this.isSortActive('averageResultCount')
                                ? <i className="si si-Sort"/>
                                : null
                            }
                            <FormattedMessage id="statistics_search_avg_number_result"/>
                        </a>
                    </th>
                </tr>
                </thead>

                <tbody>
                {data.map((search) => (
                    <tr key={search.query} className="statistics-search-table__tr">
                        <td className="statistics-search-table__td statistics-search-table__td--search-term">
                            <Link to={this.getSearchLocation(search)}>{search.query}</Link>
                        </td>
                        <td className="statistics-search-table__td statistics-search-table__td--search-count">
                            <FormattedNumber value={search.searchCount}/>
                        </td>
                        <td className="statistics-search-table__td statistics-search-table__td--click-count">
                            <FormattedNumber value={search.clickCount}/>
                        </td>
                        {showCTR
                            ? (<td className="statistics-search-table__td statistics-search-table__td--ctr">
                                <FormattedNumber value={search.ctr}
                                                 style="percent"
                                                 maximumFractionDigits={2}
                                                 minimumFractionDigits={2}/>
                                </td>)
                            : null
                        }
                        <td className="statistics-search-table__td statistics-search-table__td--average">
                            {search.averageClickPosition !== null
                                ? <FormattedNumber value={search.averageClickPosition}
                                                   maximumFractionDigits={2}
                                                   minimumFractionDigits={2}/>
                                : '-'
                            }
                        </td>
                        <td className="statistics-search-table__td statistics-search-table__td--average">
                            <FormattedNumber value={this.getAverageResultCount(search)}
                                                   maximumFractionDigits={2}
                                                   minimumFractionDigits={2}/>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        );
    }

    private isSortActive(field: string): boolean {
        const { params: { params: { sort } } } = this.props;
        return sort === field || sort === `-${field}`;
    }

    private getSearchLocation(search: StatisticsTopSearch): LocationDescriptor {
        const { widget } = this.props;
        return (
            `/${encodeURIComponent(widget.id)}` +
            '/demo' +
            `?query=${encodeQueryForStatisticsLink(search.query)}`
        );
    }

    private getAverageResultCount(search: StatisticsTopSearch): number {
        return search.averageResultCount
            ? search.averageResultCount
            : 0;
    }

    @memoize
    private changeSortHandler(sort: string): () => void {
        const { changeSort } = this.props;
        return () => changeSort(sort);
    }
}
