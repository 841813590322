import { StatisticsPageState } from 'src/react/statistics/state/StatisticsPageState';
import {
    STATISTICS_LAST_UPDATE_ACTIONS, StatisticsLastUpdateActions,
} from 'src/react/statistics/actions/StatisticsLastUpdateActions';

export function statisticsLastUpdateReducer(
    state: StatisticsPageState,
    action: StatisticsLastUpdateActions,
): StatisticsPageState {
    switch (action.type) {
        case STATISTICS_LAST_UPDATE_ACTIONS.PENDING:
            return {
                ...state,
                lastSearches: {
                    loading: true,
                    error: null,
                    data: state.lastSearches.data
                }
            };
        case STATISTICS_LAST_UPDATE_ACTIONS.SUCCESS:
            return {
                ...state,
                lastSearches: {
                    loading: false,
                    error: null,
                    data: action.data
                }
            };
        case STATISTICS_LAST_UPDATE_ACTIONS.FAILURE:
            return {
                ...state,
                lastSearches: {
                    loading: false,
                    data: null,
                    error: action.data,
                }
            };

        default:
            return state;
    }
}
