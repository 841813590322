import { IComponentOptions, IScope } from 'angular';
import { WidgetPopupController } from 'src/modules/widgetPopup/WidgetPopupController';
import * as WidgetPopupTemplate from './WidgetPopupTemplate.html';

export const WIDGET_POPUP_COMPONENT: IComponentOptions = {
    controller: WidgetPopupController,
    controllerAs: 'widgetPopupCtrl',
    template: WidgetPopupTemplate,
    bindings: {
        resolve: '<',
        modalInstance: '<',
    },
};

export interface WidgetPopupScope extends IScope {
    readonly widgetPopupCtrl: WidgetPopupController;
}
