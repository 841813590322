import { RejectType, StateService, TransitionService } from '@uirouter/angularjs';
import { HttpError } from 'src/errors/HttpError';
import { BaseError } from 'src/errors/BaseError';

errorHandlingHooks.$inject = [
    '$state',
    '$transitions',
];
export function errorHandlingHooks(
    $state: StateService,
    $transitions: TransitionService,
): void {
    function isHttpError(status: number, error: unknown): boolean {
        if (error instanceof HttpError) {
            return error.status === status;
        }

        if (error instanceof BaseError) {
            return isHttpError(status, error.previous);
        }

        return false;
    }

    $transitions.onError({}, (transition) => {
        const rejection = transition.error();
        if (rejection.type !== RejectType.ERROR) {
            return;
        }

        if (isHttpError(401, transition.error().detail)) {
            $state.go('unauthorized:401', {}, { redirectedFrom: transition, location: false });
        } else if (isHttpError(403, transition.error().detail)) {
            $state.go('unauthorized:403', {}, { redirectedFrom: transition, location: false });
        } else if (isHttpError(404, transition.error().detail)) {
            $state.go('unauthorized:404', {}, { redirectedFrom: transition, location: false });
        } else {
            $state.go('unauthorized:5xx', {}, { redirectedFrom: transition, location: false });
        }
    });
}
