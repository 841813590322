import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { NativeError } from 'src/errors/NativeError';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import {
    StatisticsPageFilter
} from 'src/react/statistics/state/StatisticsPageFilter';
import { STATISTICS_LAST_UPDATE_ACTIONS } from 'src/react/statistics/actions/StatisticsLastUpdateActions';
import { getStatisticsLastSearches } from 'src/react/statistics/services/statisticsLastSearches';

import { statisticsUpdateQueryString } from 'src/react/statistics/services/statisticsUpdateQueryString';
import { StatisticsLastSearch } from 'src/types/entities/StatisticsLastSearch';

export function* statisticsLastUpdateSaga(
    { data: { filter, params } }: ActionRequest<StatisticsPageFilter>,
): SagaIterator {
    try {
        yield call(statisticsUpdateQueryString, params);
        yield put(STATISTICS_LAST_UPDATE_ACTIONS.pending());

        const searches: ListWithTotal<StatisticsLastSearch> =
            yield call(getStatisticsLastSearches, filter, params);

        yield put(STATISTICS_LAST_UPDATE_ACTIONS.success(searches));
    } catch (error) {
        yield put(STATISTICS_LAST_UPDATE_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
