import './Textarea.scss';
import * as React from 'react';
import { classList } from 'src/utils/classList';

type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export function Textarea({ className, name, rows = 5, ...inputProps }: Props): JSX.Element {
    return (
        <textarea {...inputProps}
                  name={name}
                  rows={rows}
                  data-field={name}
                  className={classList(className, 'xss-textarea')}/>
    );
}
