import { IComponentOptions, IScope } from 'angular';
import { KeyPhrasePageController } from 'src/modules/keyPhrase/KeyPhrasePageController';
import * as KeyPhrasePageTemplate from './KeyPhrasePageTemplate.html';

export const KEY_PHRASE_PAGE_COMPONENT: IComponentOptions = {
    template: KeyPhrasePageTemplate,
    controller: KeyPhrasePageController,
    controllerAs: 'keyPhrasePageCtrl',
    bindings: {
        widget: '<',
        organization: '<',
        params: '<',
        groups: '<',
        viewerMode: '<',
    },
};

export interface KeyPhrasePageScope extends IScope {
    readonly keyPhrasePageCtrl: KeyPhrasePageController;
}
