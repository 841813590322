import * as yup from 'yup';
import { SettingsCustomField } from 'src/react/settings/types/SettingsCustomField';
import { SettingsCustomFieldFormData } from 'src/react/settings/types/SettingsCustomFieldFormData';

export const SETTINGS_CUSTOM_FIELD_FORM_SCHEMA = yup.object<SettingsCustomFieldFormData>({
    fieldType: yup
        .mixed()
        .nullable(true)
        .oneOf([null, 'keyword', 'boolean', 'text', 'tags', 'price', 'numeric'], 'formError_invalid')
        .required('formError_required'),
    fieldName: yup.lazy((value: SettingsCustomFieldFormData['fieldName'], context?) => {
        const customFields: ReadonlyArray<SettingsCustomField> = context.context.customFields;

        return yup
            .string()
            .nullable(true)
            .required('formError_required')
            .matches(/^[a-z0-9]+([-_][a-z0-9]+)*$/, {
                excludeEmptyString: true,
                message: 'settings_error_customField_format',
            })
            .notOneOf(
                customFields.map((it) => it.fieldName),
                'settings_error_customField_duplicate',
            );
    }),
});
