import { DemoPageState } from 'src/react/demoPage/state/DemoPageState';
import {
    DEMO_SET_HIGHLIGHT_ACTIONS,
    DemoSetHighlightActions,
} from 'src/react/demoPage/actions/DemoSetHighlightActions';

export function demoSetHighlightReducer(
    state: DemoPageState,
    action: DemoSetHighlightActions,
): DemoPageState {
    switch (action.type) {
        case DEMO_SET_HIGHLIGHT_ACTIONS.SUCCESS:
            return { ...state, showHighlight: action.data };

        default:
            return state;
    }
}
