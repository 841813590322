import { IComponentController, IPromise } from 'angular';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { assertDefined } from 'src/utils/assertion';
import { BaseError } from 'src/errors/BaseError';
import { UserRole } from 'src/types/values/UserRole';
import { SearchMatchMode } from 'src/types/values/SearchMatchMode';
import { Organization } from 'src/types/entities/Organization';
import { Widget } from 'src/types/entities/Widget';
import { WidgetForm } from 'src/types/dto/WidgetForm';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { IWidgetService } from 'src/modules/api/WidgetService';
import { WIDGET_LANGUAGES } from 'src/constants/general';
import { FEEDBACK_DEFAULTS } from 'src/constants/feedbackDefaults';
import {
    DEFAULT_HTML_TEMPLATE_EMPTY,
    DEFAULT_HTML_TEMPLATE_LAYOUT,
    DEFAULT_HTML_TEMPLATE_NO_RESULT,
    DEFAULT_HTML_TEMPLATE_RESULT
} from 'src/constants/templates';

type ViewMode =
    | 'form'
    | 'success'
    | 'failure'
    | 'process';

export class WidgetPopupController implements IComponentController {
    public widget!: Readonly<Widget> | null;
    public organizations!: ReadonlyArray<Organization>;

    public form!: WidgetForm;
    public isEdit!: boolean;

    public resolve!: { organizations: ReadonlyArray<Organization>, widget: Readonly<Widget> | null };
    public modalInstance!: IModalInstanceService;

    public viewMode: ViewMode = 'form';
    public error: BaseError | undefined;

    public languages = WIDGET_LANGUAGES;
    public isCollapsed = true;

    public static $inject = [
        'generateKey',
        'widgetService',
    ];
    public constructor(
        private generateKey: (length: UnsignedInteger) => string,
        private widgetService: IWidgetService,
    ) {
    }

    public $onInit(): void {
        this.widget = this.resolve.widget;
        this.organizations = this.resolve.organizations;

        this.form = this.getWidgetForm();
        this.isEdit = this.widget !== null;
    }

    public deleteWidget(): void {
        if (!this.widget) {
            return;
        }

        this.viewMode = 'process';
        this.widgetService.delete(this.widget).then(() => {
            this.close();
        }, (error) => {
            this.viewMode = 'failure';
            this.error = error;
        });
    }

    public submitForm(): void {
        this.viewMode = 'process';
        this.saveWidget().then((widget) => {
            this.widget = widget;

            if (this.isEdit) {
                this.close();
            } else {
                this.viewMode = 'success';
            }
        }, (error) => {
            this.viewMode = 'failure';
            this.error = error;
        });
    }

    public createNewApiKey(): void {
        this.form.apiKey = this.generateKey(16);
    }

    public canEditOrganization(): boolean {
        const { widget } = this;
        if (widget) {
            const organization = this.getWidgetOrganization(widget);
            return organization.permission.userRole === UserRole.Admin;
        }

        const { organizations } = this;
        return organizations.filter((it) => this.isOrganizationAllowed(it)).length > 1;
    }

    public canDelete(): boolean {
        const { widget } = this;
        if (!widget) {
            return false;
        }

        const organization = this.getWidgetOrganization(widget);
        return organization.permission.userRole === UserRole.Admin;
    }

    public isOrganizationAllowed(organization: Organization): boolean {
        return (
            organization.permission.userRole === UserRole.Admin ||
            organization.permission.userRole === UserRole.Manager
        );
    }

    public close(): void {
        this.modalInstance.close();
    }

    public dismiss(): void {
        this.modalInstance.dismiss();
    }

    private saveWidget(): IPromise<Widget> {
        return this.widget
            ? this.widgetService.update(this.widget, this.form)
            : this.widgetService.create(this.form);
    }

    private getWidgetForm(): WidgetForm {
        const { widget } = this;
        return widget ? {
            organizationId: widget.organizationId,

            name: widget.name,
            website: widget.website,

            apiKey: widget.apiKey,
            isActive: widget.isActive,

            indexLanguage: widget.indexLanguage,
            includeToFacets: widget.includeToFacets,
            includeToRecommendations: widget.includeToRecommendations,

            defaultImage: widget.defaultImage,

            urlBoost: widget.urlBoost,
            fieldBoost: widget.fieldBoost,
            customBoosts: widget.customBoosts,

            autocomplete: widget.autocomplete,
            searchParams: widget.searchParams,
            htmlTemplates: widget.htmlTemplates,
            feedbackSettings: widget.feedbackSettings,

            crawlerLimit: widget.crawlerLimit,
            crawlerDepth: widget.crawlerDepth,
            crawlerEnabled: widget.crawlerEnabled,
            crawlerWhiteList: widget.crawlerWhiteList,
            crawlerExclude: widget.crawlerExclude,
            crawlerPages: widget.crawlerPages,
            crawlerDomains: widget.crawlerDomains,
            parserConfig: widget.parserConfig,

            ignoreRobotsTxt: widget.ignoreRobotsTxt,
        } : {
            organizationId: this.getDefaultOrganization().id,

            name: '',
            website: '',

            apiKey: this.generateKey(16),
            isActive: true,

            indexLanguage: 'de',

            defaultImage: '',

            urlBoost: [],
            fieldBoost: { title: 3, content: 2, description: 1 },
            customBoosts: [],
            includeToFacets: [],
            includeToRecommendations: [],
            autocomplete: {
                keyPhrase: false,
                itemTitle: true,
            },
            searchParams: {
                enableFuzziness: true,
                searchMatchMode: SearchMatchMode.Prefix,
            },
            htmlTemplates: {
                layoutTemplate: DEFAULT_HTML_TEMPLATE_LAYOUT,
                resultTemplate: DEFAULT_HTML_TEMPLATE_RESULT,
                noResultTemplate: DEFAULT_HTML_TEMPLATE_NO_RESULT,
                headerTemplate: DEFAULT_HTML_TEMPLATE_EMPTY,
                footerTemplate: DEFAULT_HTML_TEMPLATE_EMPTY,
            },
            feedbackSettings:{
                general: { ...FEEDBACK_DEFAULTS.general },
                noResults: { ...FEEDBACK_DEFAULTS.noResults },
            },

            crawlerLimit: 0,
            crawlerDepth: 10,
            crawlerEnabled: false,
            crawlerExclude: '',
            crawlerWhiteList: '',
            crawlerPages: '',
            crawlerDomains: '',
            parserConfig: null,

            ignoreRobotsTxt: false,
        };
    }

    private getDefaultOrganization(): Organization {
        const { organizations } = this;
        return assertDefined(
            organizations.find((it) => this.isOrganizationAllowed(it)),
            'User is not allowed to create widgets',
            { organizations },
        );
    }

    private getWidgetOrganization(widget: Widget): Organization {
        const { organizations } = this;
        return assertDefined(
            organizations.find((it) => it.id === widget.organizationId),
            `Unknown widget organization "${widget.organizationId}"`,
            { widget, organizations },
        );
    }
}
