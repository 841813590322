import angular from 'angular';

angular
    .module('backend.filters.string', [])
    .filter(
        'cutString',
        () => function (value, wordwise, max, tail) {
            if (!value) return '';

            max = parseInt(max, 10);
            if (!max) return value;
            if (value.length <= max) return value;

            value = value.substr(0, max);
            if (wordwise) {
                const lastspace = value.lastIndexOf(' ');
                if (lastspace != -1) {
                    value = value.substr(0, lastspace);
                }
            }

            return value + (tail || ' ...');
        },
    )
    .filter(
        'capFirstLetter',
        () => function capFirstLetterFilter(str) {
            const tmp = str || '';
            return tmp.charAt(0).toUpperCase() + tmp.slice(1);
        },
    );
