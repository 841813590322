import { ItemFormState } from 'src/react/itemForm/state/ItemFormState';

export const ITEM_FORM_DEFAULT_STATE: ItemFormState = {
    load: {
        loading: false,
        data: null,
        error: null,
    },
    save: {
        loading: false,
        data: null,
        error: null,
    },
    loadItem: {
        loading: false,
        data: null,
        error: null,
    },
    restore: {
        loading: false,
        data: null,
        error: null,
    },
    saveRestored: {
        loading: false,
        data: null,
        error: null,
    },
};
