import { module } from 'angular';
import { ApiService } from 'src/modules/api/ApiService';
import { UserService } from 'src/modules/api/UserService';
import { ItemService } from 'src/modules/api/ItemService';
import { WidgetService } from 'src/modules/api/WidgetService';
import { KeyPhraseService } from 'src/modules/api/KeyPhraseService';
import { CustomFieldService } from 'src/modules/api/CustomFieldService';
import { CrawlerTaskService } from 'src/modules/api/CrawlerTaskService';
import { CrawlerLogService } from 'src/modules/api/CrawlerLogService';
import { OrganizationService } from 'src/modules/api/OrganizationService';
import { PermissionService } from 'src/modules/api/PermissionService';
import { StatisticsFilterService } from 'src/modules/api/StatisticsFilterService';
import { StatisticsOverviewService } from 'src/modules/api/StatisticsOverviewService';
import { StatisticsLastSearchService } from 'src/modules/api/StatisticsLastSearchService';
import { StatisticsSuccessfulSearchService } from 'src/modules/api/StatisticsSuccessfulSearchService';
import { StatisticsUnsuccessfulSearchService } from 'src/modules/api/StatisticsUnsuccessfulSearchService';
import { StatisticsCombinedSearchService } from 'src/modules/api/StatisticsCombinedSearchService';
import { ActivityLogService } from 'src/modules/api/ActivityLogService';
import { KeyPhraseGroupService } from 'src/modules/api/KeyPhraseGroupService';

export const API_MODULE = module('api', [])
    .service(
        'apiService',
        ApiService,
    )
    .service(
        'userService',
        UserService,
    )
    .service(
        'organizationService',
        OrganizationService,
    )
    .service(
        'permissionService',
        PermissionService,
    )
    .service(
        'itemService',
        ItemService,
    )
    .service(
        'widgetService',
        WidgetService,
    )
    .service(
        'keyPhraseService',
        KeyPhraseService,
    )
    .service(
        'keyPhraseGroupService',
        KeyPhraseGroupService,
    )
    .service(
        'customFieldService',
        CustomFieldService,
    )
    .service(
        'crawlerTaskService',
        CrawlerTaskService,
    )
    .service(
        'crawlerLogService',
        CrawlerLogService,
    )
    .service(
        'statisticsFilterService',
        StatisticsFilterService,
    )
    .service(
        'statisticsOverviewService',
        StatisticsOverviewService,
    )
    .service(
        'statisticsLastSearchService',
        StatisticsLastSearchService,
    )
    .service(
        'statisticsCombinedSearchService',
        StatisticsCombinedSearchService,
    )
    .service(
        'statisticsSuccessfulSearchService',
        StatisticsSuccessfulSearchService,
    )
    .service(
        'statisticsUnsuccessfulSearchService',
        StatisticsUnsuccessfulSearchService,
    )
    .service(
        'activityLogService',
        ActivityLogService,
    );
