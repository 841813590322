import { DemoPageState } from 'src/react/demoPage/state/DemoPageState';
import { DEMO_RESET_ACTIONS, DemoResetActions } from 'src/react/demoPage/actions/DemoResetActions';

export function demoResetReducer(
    state: DemoPageState,
    action: DemoResetActions,
): DemoPageState {
    switch (action.type) {
        case DEMO_RESET_ACTIONS.SUCCESS:
            return {
                ...state,
                loading: false,
                saving: false,
                pinMode: false,
                data: null,
                error: null,
            };

        default:
            return state;
    }
}
