import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { SETTINGS_FORM_SAVE_ACTIONS } from 'src/react/settings/actions/SettingsFormSaveActions';
import { settingsFormSaveSaga } from 'src/react/settings/sagas/settingsFormSaveSaga';
import { SETTINGS_FORM_VALIDATE_ACTIONS } from 'src/react/settings/actions/SettingsFormValidateActions';
import { settingsFormValidateSaga } from 'src/react/settings/sagas/settingsFormValidateSaga';

export function* settingsFormSaga(): SagaIterator {
    yield takeLatest(SETTINGS_FORM_SAVE_ACTIONS.REQUEST, settingsFormSaveSaga);
    yield takeLatest(SETTINGS_FORM_VALIDATE_ACTIONS.REQUEST, settingsFormValidateSaga);
}
