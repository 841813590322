import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { Item } from 'src/types/entities/Item';
import { Widget } from 'src/types/entities/Widget';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { KeyPhraseState } from 'src/types/values/KeyPhraseState';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { getKeyPhraseEndpoint } from 'src/react/api/selectors/getEndpoint';
import { IKeyPhraseEndpoint } from 'src/react/api/endpoints/KeyPhraseEndpoint';
import { KeyPhraseForm } from 'src/types/dto/KeyPhraseForm';

export function* itemKeyPhraseDetach(item: Item, keyPhrase: KeyPhrase): SagaIterator {
    const widget: Widget = yield select(getSelectedWidget);
    const keyPhraseEndpoint: IKeyPhraseEndpoint = yield select(getKeyPhraseEndpoint);

    const pinnedIds = keyPhrase.itemIds.filter((id) => id !== item.id);
    if (pinnedIds.length > 0) {
        const formData: KeyPhraseForm = {
            text: keyPhrase.text,
            state: KeyPhraseState.Active,
            itemIds: pinnedIds,
            synonyms: keyPhrase.synonyms,
        };

        yield call({
            fn: keyPhraseEndpoint.update,
            context: keyPhraseEndpoint,
        }, keyPhrase.id, formData, { widgetId: widget.id });
    } else {
        yield call({
            fn: keyPhraseEndpoint.delete,
            context: keyPhraseEndpoint,
        }, keyPhrase.id, { widgetId: widget.id });
    }
}
