import * as React from 'react';
import { PropsType } from 'react-utils';
import { BaseFieldProps, Field, WrappedFieldProps } from 'redux-form';
import { Omit } from 'mapped-types-utils';
import { classList } from 'src/utils/classList';
import { fieldMetaClass } from 'src/utils/fieldMetaClass';
import { Range } from 'src/react/common/components/Range';

type RangeProps = PropsType<typeof Range>;
function RangeComponent(props: WrappedFieldProps & RangeProps): JSX.Element {
    const { input, meta, disabled = false, readOnly = false, ...inputProps } = props;
    const className = classList(props.className, fieldMetaClass(meta, { disabled, readOnly }));

    return (
        <Range {...inputProps}
                 className={className}
                 disabled={disabled}
                 readOnly={readOnly}
                 name={input.name}
                 value={input.value}
                 onBlur={input.onBlur}
                 onFocus={input.onFocus}
                 onChange={input.onChange}/>
    );
}

type RangeFieldProps = Omit<BaseFieldProps<RangeProps> & RangeProps, 'component'>;
export function RangeField(props: RangeFieldProps): JSX.Element {
    return <Field component={RangeComponent} {...props}/>;
}
