import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { Widget } from 'src/types/entities/Widget';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { getKeyPhrasesGroupEndpoint } from 'src/react/api/selectors/getEndpoint';
import { IKeyPhraseGroupEndpoint } from 'src/react/api/endpoints/KeyPhraseGroupEndpoint';
import { KeyPhraseGroup } from 'src/types/entities/KeyPhraseGroup';
import { Uuid } from 'src/types/values/Uuid';

export function* keyPhraseGroupList(query: string, exclude: Uuid | null): SagaIterator {
    const widget: Widget = yield select(getSelectedWidget);
    const keyPhraseGroupEndpoint: IKeyPhraseGroupEndpoint = yield select(getKeyPhrasesGroupEndpoint);

    const { data: keyPhraseGroups }: ListWithTotal<KeyPhraseGroup> = yield call({
        fn: keyPhraseGroupEndpoint.list,
        context: keyPhraseGroupEndpoint,
    }, {
        query,
        exact: true,
        page: 0,
        pageSize: 1,
        sort: [],
        id: [],
    }, {
        widgetId: widget.id,
    });
    return keyPhraseGroups.filter((keyPhraseGroup) => keyPhraseGroup.id !== exclude);
}
