import { DemoPageState } from 'src/react/demoPage/state/DemoPageState';
import {
    DEMO_PIN_MODE_ENTER_ACTIONS,
    DemoPinModeEnterActions,
} from 'src/react/demoPage/actions/DemoPinModeEnterActions';

export function demoPinModeEnterReducer(
    state: DemoPageState,
    action: DemoPinModeEnterActions,
): DemoPageState {
    switch (action.type) {
        case DEMO_PIN_MODE_ENTER_ACTIONS.SUCCESS:
            return {
                ...state,
                pinMode: true,
            };

        default:
            return state;
    }
}
