import './OrganizationUserRole.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { UserRole } from 'src/types/values/UserRole';

type Props = {
    readonly userRole: UserRole;
};

export function OrganizationUserRole({ userRole }: Props): JSX.Element {
    return (
        <span className={`organization-user-role organization-user-role--${userRole}`}>
            <FormattedMessage id="organizationList_userRole"
                              values={{ userRole }}/>
        </span>
    );
}
