import './DemoPageSearchItemGridView.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Item } from 'src/types/entities/Item';
import { Widget } from 'src/types/entities/Widget';
import { Highlight } from 'src/types/dto/Highlight';
import { DemoPageItemDescription } from 'src/react/demoPage/components/DemoPageItemDescription';
import { DemoPageItemImage } from 'src/react/demoPage/components/DemoPageItemImage';
import { DemoPageItemCustomFields } from 'src/react/demoPage/components/DemoPageItemCustomFields';
import { DemoPageHighlight } from 'src/react/demoPage/components/DemoPageHighlight';
import { CustomField } from 'src/types/entities/CustomField';
import { DemoPageViewMode } from 'src/react/demoPage/state/DemoPageViewMode';

type Props = {
    readonly item: Item;
    readonly widget: Widget;
    readonly highlight: Highlight;
    readonly viewerMode: boolean;
    readonly customFields: ReadonlyArray<CustomField>;
    readonly isPinned: boolean;
    readonly onPinItem: () => void;
    readonly onUnpinItem: () => void;
    readonly onEditItem: () => void;
};

export function DemoPageSearchItemGridView({
                                                item,
                                                widget,
                                                viewerMode,
                                                highlight,
                                                customFields,
                                                isPinned,
                                                onPinItem,
                                                onUnpinItem,
                                                onEditItem,
                                            }: Props): JSX.Element {
    return (
        <div className="demo-page-search-item-gridView">
            {!viewerMode &&
            <div className="demo-page-search-item-gridView__col demo-page-search-item-gridView__col--pin">
                {isPinned && (
                    <FormattedMessage id="searchDemo_unpinItem">
                        {(message) => (
                            <a className="demo-page-search-item-gridView__unpin"
                               title={message.toString()}
                               href="javascript:void(0)"
                               onClick={onUnpinItem}>
                                <i className="fa fa-thumb-tack"/>
                            </a>
                        )}
                    </FormattedMessage>
                )}
                {!isPinned && (
                    <FormattedMessage id="searchDemo_pinItem">
                        {(message) => (
                            <a className="demo-page-search-item-gridView__pin"
                               title={message.toString()}
                               href="javascript:void(0)"
                               onClick={onPinItem}>
                                <i className="fa fa-thumb-tack"/>
                            </a>
                        )}
                    </FormattedMessage>
                )}
            </div>}
            <div className="demo-page-search-item-gridView__col demo-page-search-item-gridView__col--image">
                <DemoPageItemImage item={item} widget={widget} viewMode={DemoPageViewMode.grid}/>
            </div>

            <div className="demo-page-search-item-gridView__col demo-page-search-item-gridView__col--content">
                <div className="demo-page-search-item-gridView__col demo-page-search-item-gridView__col--title">
                    <a className="demo-page-search-item-gridView__title"
                       href={item.url}
                       target="_blank"
                       rel="noreferrer">
                        <DemoPageHighlight text={item.title}
                                           html={highlight.title}/>
                    </a>
                </div>
                <div className="demo-page-search-item-gridView__col demo-page-search-item-gridView__col--content">
                    <DemoPageItemDescription item={item}
                                             highlight={highlight}
                                             viewMode={DemoPageViewMode.grid}/>
                </div>
                <div
                    className="demo-page-search-item-gridview__col demo-page-search-item-gridView__col--customFields">
                    <DemoPageItemCustomFields item={item}
                                              customFields={customFields}/>
                </div>
            </div>
            {!viewerMode &&
            <div className="demo-page-search-item-gridView__col demo-page-search-item-gridView__col--edit">
                <FormattedMessage id="searchDemo_editItem">
                    {(message) => (
                        <a className="demo-page-search-item-gridView__edit"
                           title={message.toString()}
                           onClick={onEditItem}>
                            <i className="fa fa-cog"/>
                        </a>
                    )}
                </FormattedMessage>
            </div>}
        </div>
    );
}
