import { Channel, SagaIterator } from 'redux-saga';
import { call, put, select, take } from 'redux-saga/effects';
import { instantiate } from 'src/utils/instantiate';
import { CrawlerTask } from 'src/types/entities/CrawlerTask';
import { CrawlerStatus } from 'src/types/values/CrawlerStatus';
import { CrawlerTaskState } from 'src/types/websocket/CrawlerTaskState';
import { MANUAL_CRAWLER_STATE_ACTIONS } from 'src/react/manualCrawler/actions/ManualCrawlerStateActions';
import { createWebsocketChannel } from 'src/react/manualCrawler/services/createWebsocketChannel';
import { getConfig } from 'src/react/root/selectors/getConfig';
import { Config } from 'src/types/constants/Config';

const RUNNING_STATUSES = [
    CrawlerStatus.Running,
    CrawlerStatus.Pending,
];

export function* watchCrawlerStatus(crawlerTask: CrawlerTask): SagaIterator {
    const config: Config = yield select(getConfig);
    const wsUrl = `${config.services.wsUrl}/crawler-status/${encodeURIComponent(crawlerTask.id)}`;
    const socket: WebSocket = yield call(instantiate, WebSocket, wsUrl);
    const channel: Channel<CrawlerTaskState> = yield call(createWebsocketChannel, socket);

    try {
        while (true) {
            const crawlerState: CrawlerTaskState = yield take(channel);
            yield put(MANUAL_CRAWLER_STATE_ACTIONS.success(crawlerState));

            const isStillRunning = RUNNING_STATUSES.includes(crawlerState.progress.status);
            if (!isStillRunning) {
                break;
            }
        }
    } finally {
        // NOTE: this code will be executed even if the task is cancelled
        yield call({
            fn: channel.close,
            context: channel,
        });
    }
}
