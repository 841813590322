import { KeyPhraseSynonymsState } from 'src/react/keyPhraseSynonyms/state/KeyPhraseSynonymsState';
import {
    KEY_PHRASE_SYNONYMS_RESET_ACTIONS,
    KeyPhraseSynonymsResetActions,
} from 'src/react/keyPhraseSynonyms/actions/KeyPhraseSynonymsResetActions';

export function keyPhraseSynonymsResetReducer(
    state: KeyPhraseSynonymsState,
    action: KeyPhraseSynonymsResetActions,
): KeyPhraseSynonymsState {
    switch (action.type) {
        case KEY_PHRASE_SYNONYMS_RESET_ACTIONS.SUCCESS:
            return { ...state, duplicates: [] };

        default:
            return state;
    }
}
