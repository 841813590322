import './Checkbox.scss';
import * as React from 'react';
import { classList } from 'src/utils/classList';

type Props = React.InputHTMLAttributes<HTMLInputElement>;

export function Checkbox({ type = 'checkbox', name, className, ...inputProps }: Props): JSX.Element {
    return (
        <input {...inputProps}
               className={classList(className, 'xss-checkbox')}
               type={type}
               name={name}
               data-field={name}/>
    );
}
