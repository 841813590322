import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { isUrlQuery } from 'src/utils/isUrlQuery';
import { Widget } from 'src/types/entities/Widget';
import { ItemState } from 'src/types/values/ItemState';
import { ItemFilter } from 'src/types/dto/ItemFilter';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { DemoFilterParams } from 'src/react/demoPage/state/DemoFilterParams';
import { DEMO_SEARCH_PAGE_SIZE } from 'src/react/demoPage/constants/DemoPageSize';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { getItemEndpoint } from 'src/react/api/selectors/getEndpoint';
import { IItemEndpoint } from 'src/react/api/endpoints/ItemEndpoint';
import { Item } from 'src/types/entities/Item';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';

export function* demoFilterQuery({ query, page }: DemoFilterParams): SagaIterator {
    const widget: Widget = yield select(getSelectedWidget);
    const itemEndpoint: IItemEndpoint = yield select(getItemEndpoint);

    const isUrl: boolean = yield call(isUrlQuery, query);
    const filter: ItemFilter = isUrl
        ? createUrlFilter(query)
        : createSearchFilter(query, page);

    const foundItems: ListWithTotal<Item> = yield call({
        fn: itemEndpoint.list,
        context: itemEndpoint,
    }, filter, { widgetId: widget.id });

    return {
        ...foundItems,
        data: foundItems.data.map((item) => ({ item, score: 0, highlight: {} }))
    };

}

function createUrlFilter(url: string): ItemFilter {
    return {
        url,
        exact: true,
        query: '',
        type: [],
        state: [ItemState.Active],
        id: [],

        sort: [],
        page: 0,
        pageSize: 1,
    };
}

function createSearchFilter(query: string, page: UnsignedInteger): ItemFilter {
    return {
        query,
        url: '',
        exact: false,
        type: [],
        state: [ItemState.Active],
        id: [],

        sort: ['-score'],
        page: page - 1,
        pageSize: DEMO_SEARCH_PAGE_SIZE,
    };
}
