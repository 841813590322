import { ActionAny, createActions } from 'src/utils/createActions';
import { StatisticsPageFilter } from 'src/react/statistics/state/StatisticsPageFilter';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { StatisticsTopSearch } from 'src/types/entities/StatisticsTopSearch';

export type StatisticsUnsuccessfulUpdateActions =
    ActionAny<
        StatisticsPageFilter,
        ListWithTotal<StatisticsTopSearch>>;
export const STATISTICS_UNSUCCESSFUL_UPDATE_ACTIONS =
    createActions<
        StatisticsPageFilter,
        ListWithTotal<StatisticsTopSearch>>('STATISTICS_UNSUCCESSFUL_UPDATE');
