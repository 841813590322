import { AxiosInstance } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { ValidationError } from 'src/errors/ValidationError';
import { getApiClient } from 'src/react/api/selectors/getApiClient';
import { SettingsFormData } from 'src/react/settings/types/SettingsFormData';

export function* settingsFormValidate({ widget: { website } }: SettingsFormData): SagaIterator {
    try {
        const apiClient: AxiosInstance = yield select(getApiClient);
        yield call(apiClient.get, 'utils/isUrlAccessible', { params: { url: website } });
    } catch {
        throw new ValidationError([{
            code: 'settings_error_websiteUnavailable',
            path: 'widget.website',
            value: website,
            params: undefined,
            context: undefined,
        }]);
    }
}
