import { SagaIterator } from 'redux-saga';
import { all, call } from 'redux-saga/effects';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { keyPhraseSynonymsCheckQuery } from 'src/react/keyPhraseSynonyms/services/keyPhraseSynonymsCheckQuery';

export function* keyPhraseSynonymsCheckSynonyms(synonyms: ReadonlyArray<string>): SagaIterator {
    const allKeyPhrases: KeyPhrase[][] = yield all(synonyms.map((query) => call(keyPhraseSynonymsCheckQuery, query)));
    return allKeyPhrases.reduce<KeyPhrase[]>((result, keyPhrases) => [
        ...result,
        ...keyPhrases.filter((keyPhrase) => !result.some((duplicate) => duplicate.id === keyPhrase.id)),
    ], []);
}
