import { chainReducers } from 'src/utils/chainReducers';
import { ManualCrawlerState } from 'src/react/manualCrawler/state/ManualCrawlerState';
import { manualCrawlerStartReducer } from 'src/react/manualCrawler/reducers/manualCrawlerStartReducer';
import { manualCrawlerAbortReducer } from 'src/react/manualCrawler/reducers/manualCrawlerAbortReducer';
import { manualCrawlerWatchReducer } from 'src/react/manualCrawler/reducers/manualCrawlerWatchReducer';
import { manualCrawlerStateReducer } from 'src/react/manualCrawler/reducers/manualCrawlerStateReducer';

export const manualCrawlerReducer = chainReducers<ManualCrawlerState>(
    manualCrawlerStartReducer,
    manualCrawlerAbortReducer,
    manualCrawlerWatchReducer,
    manualCrawlerStateReducer,
);
