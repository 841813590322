import { FieldErrors } from 'src/types/values/FieldError';
import { createSchemaValidator } from 'src/utils/createSchemaValidator';
import { SettingsCustomBoost } from 'src/react/settings/types/SettingsCustomBoost';
import { SettingsCustomField } from 'src/react/settings/types/SettingsCustomField';
import { SettingsCustomBoostFormData } from 'src/react/settings/types/SettingsCustomBoostFormData';
import { SETTINGS_CUSTOM_BOOST_FORM_SCHEMA } from 'src/react/settings/constants/SettingsCustomBoostFormSchema';

const validator = createSchemaValidator(SETTINGS_CUSTOM_BOOST_FORM_SCHEMA);

export const settingsCustomBoostFormValidator = {
    validate: (
        formData: SettingsCustomBoostFormData,
        customBoosts: ReadonlyArray<SettingsCustomBoost>,
        customFields: ReadonlyArray<SettingsCustomField>,
    ): FieldErrors => {
        return validator(formData, { customBoosts, customFields });
    },
    isValid: (
        formData: SettingsCustomBoostFormData,
        customBoosts: ReadonlyArray<SettingsCustomBoost>,
        customFields: ReadonlyArray<SettingsCustomField>,
    ): formData is SettingsCustomBoost => {
        return validator(formData, { customBoosts, customFields }).length === 0;
    },
};
