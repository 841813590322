import { IComponentOptions, IScope } from 'angular';
import { SnippetPageController } from 'src/modules/snippetPage/SnippetPageController';
import * as snippetPageTemplate from './SnippetPageTemplate.html';

export const SNIPPET_PAGE_COMPONENT: IComponentOptions = {
    template: snippetPageTemplate,
    controller: SnippetPageController,
    controllerAs: 'snippetPageCtrl',
    bindings: {
        widget: '<',
    },
};

export interface SnippetPageScope extends IScope {
    readonly snippetPageCtrl: SnippetPageController;
}
