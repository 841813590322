export abstract class BaseError implements Error {
    protected stackTrace: string | undefined;
    private readonly previousError: BaseError | undefined;

    protected constructor(previous?: BaseError) {
        this.stackTrace = (new Error()).stack;
        this.previousError = previous instanceof BaseError ? previous : undefined;
    }

    public get previous(): BaseError | undefined {
        return this.previousError;
    }

    public get stack(): string | undefined {
        return this.stackTrace;
    }

    public toJSON(): object {
        return {
            name: this.name,
            message: this.message,
            stack: this.stack,
            previousError: this.previousError,
        };
    }

    public abstract get name(): string;

    public abstract get message(): string;
}

Object.setPrototypeOf(
    BaseError.prototype,
    Error.prototype,
);
