import './DemoPagePinnedItem.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Item } from 'src/types/entities/Item';
import { Widget } from 'src/types/entities/Widget';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { DemoPageItemImage } from 'src/react/demoPage/components/DemoPageItemImage';
import { DemoPageItemDescription } from 'src/react/demoPage/components/DemoPageItemDescription';
import { DemoPageViewMode } from 'src/react/demoPage/state/DemoPageViewMode';
import { DEMO_PAGE_EMPTY_HIGHLIGHT } from 'src/react/demoPage/constants/DemoPageEmptyHighlight';

type SortableItemProps = {
    readonly item: Item;
    readonly widget: Widget;
    readonly dragHandle: React.ReactNode;
    readonly position: UnsignedInteger;
    readonly onEditItem: () => void;
    readonly onUnpinItem: () => void;
};
export function DemoPagePinnedItem({
                                       item,
                                       widget,
                                       dragHandle,
                                       position,
                                       onUnpinItem,
                                       onEditItem,
}: SortableItemProps): JSX.Element {
    return (
        <div className="demo-page-pinned-item">
            <div className="demo-page-pinned-item__col demo-page-pinned-item__col--pin">
                <FormattedMessage id="searchDemo_unpinItem">
                    {(message) => (
                        <a className="demo-page-pinned-item__pin"
                           title={message.toString()}
                           href="javascript:void(0)"
                           onClick={onUnpinItem}>
                            <i className="fa fa-thumb-tack"/>
                        </a>
                    )}
                </FormattedMessage>
            </div>
            <div className="demo-page-pinned-item__col demo-page-pinned-item__col--position">
                #{position}
            </div>
            <div className="demo-page-pinned-item__col demo-page-pinned-item__col--handle">
                {dragHandle}
            </div>
            <div className="demo-page-pinned-item__col demo-page-pinned-item__col--image">
                <DemoPageItemImage item={item} widget={widget} viewMode={DemoPageViewMode.table}/>
            </div>
            <div className="demo-page-pinned-item__col demo-page-pinned-item__col--title">
                <a className="demo-page-pinned-item__title"
                   href={item.url}
                   target="_blank"
                   rel="noreferrer">{item.title}</a>
            </div>
            <div className="demo-page-pinned-item__col demo-page-pinned-item__col--content">
                <DemoPageItemDescription item={item}
                                         highlight={DEMO_PAGE_EMPTY_HIGHLIGHT}
                                         viewMode={DemoPageViewMode.table}/>
            </div>
            <div className="demo-page-pinned-item__col demo-page-pinned-item__col--edit">
                <FormattedMessage id="searchDemo_editItem">
                    {(message) => (
                        <a className="demo-page-pinned-item__edit"
                           title={message.toString()}
                           onClick={onEditItem}>
                            <i className="fa fa-cog"/>
                        </a>
                    )}
                </FormattedMessage>
            </div>
        </div>
    );
}
