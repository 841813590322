import angular from 'angular';

angular
    .module('backend.filters.time', [])
    .filter(
        'timeToSeconds',
        () => function timeToSecondsFilter(value) {
            return value ? moment(value).format('YYYY-MM-DD [at] hh.mm a') : 'never';
        },
    );
