import * as React from 'react';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { Widget } from 'src/types/entities/Widget';
import { SelectedWidget } from 'src/react/selectedWidget/components/SelectedWidget';
import { KeyPhraseGroup } from 'src/types/entities/KeyPhraseGroup';
import { KeyPhraseGroupModal } from 'src/react/keyPhraseGroup/components/KeyPhraseGroupModal';
import { Organization } from 'src/types/entities/Organization';
import { Uuid } from 'src/types/values/Uuid';

export type KeyPhraseGroupBridgeProps = {
    readonly modalInstance: IModalInstanceService;
    readonly resolve: {
        readonly widget: Widget;
        readonly organization: Organization;
        readonly keyPhraseGroup: KeyPhraseGroup;
        readonly keyphrases: string[];
        readonly goToGroup: (groupId: Uuid) => void;
    };
};

export default function ({
                             resolve: { widget, organization, keyPhraseGroup, keyphrases, goToGroup },
                             modalInstance,
                         }: KeyPhraseGroupBridgeProps): JSX.Element {
    return (
        <SelectedWidget widget={widget}
                        customFields={[]}
                        organization={organization}
                        statisticsFilter={null}>
            <KeyPhraseGroupModal id={keyPhraseGroup === null ? null : keyPhraseGroup.id}
                                text={keyPhraseGroup === null ? '' : keyPhraseGroup.text}
                                keyphrases={keyphrases}
                                goToGroup={goToGroup}
                                onSave={modalInstance.close}
                                onCancel={modalInstance.dismiss}/>
        </SelectedWidget>
    );
}
