import { ItemFormLoadItemState } from 'src/react/itemForm/state/ItemFormLoadItemState';
import { ITEM_FORM_RESTORE_ACTIONS, ItemFormRestoreActions } from 'src/react/itemForm/actions/ItemFormRestoreActions';

export function itemFormRestoreReducer(
    state: ItemFormLoadItemState,
    action: ItemFormRestoreActions,
): ItemFormLoadItemState {
    switch (action.type) {
        case ITEM_FORM_RESTORE_ACTIONS.PENDING:
            return { ...state, loading: true, error: null };

        case ITEM_FORM_RESTORE_ACTIONS.SUCCESS:
            return { ...state, loading: false, data: action.data, error: null };

        case ITEM_FORM_RESTORE_ACTIONS.FAILURE:
            return { ...state, loading: false, data: null, error: action.data };

        default:
            return state;
    }
}
