import { Query } from 'src/types/dto/Query';
import { Sort } from 'src/types/dto/Sort';
import { Pagination } from 'src/types/dto/Pagination';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { StatisticsFilter } from 'src/types/entities/StatisticsFilter';
import { STATISTICS_SEARCH_PAGE_SIZE } from 'src/react/statistics/constants/StatisticsPageSize';
import { StatisticsTermFilter } from 'src/types/dto/StatisticsTermFilter';
import { StatisticsDateFilter } from 'src/types/dto/StatisticsDateFilter';
import { DateRange } from 'src/types/values/DateRange';
import { HasResults } from 'src/types/dto/hasResults';

export interface StatisticsSearchFilter extends
    Query,
    HasResults,
    Sort,
    Pagination,
    StatisticsTermFilter,
    StatisticsDateFilter {}

export function createSearchFilter(
    filter: StatisticsFilter | null,
    query: string,
    sort: string,
    page: UnsignedInteger,
    dateRange: DateRange,
    hasResults: string | null,
): StatisticsSearchFilter {
    return {
        query,
        hasResults: hasResults ? hasResults : null,
        sort: [sort],
        page: page - 1,
        pageSize: STATISTICS_SEARCH_PAGE_SIZE,
        filterId: filter ? filter.id : null,
        dateFrom: dateRange.from ? dateRange.from.toISOString() : null,
        dateTo: dateRange.to ? dateRange.to.clone().add(1, 'day').toISOString() : null,
    };
}
