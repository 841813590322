import './Autocomplete.scss';
import * as React from 'react';
import * as ReactAutocomplete from 'react-autocomplete';
import { bind } from 'lodash-decorators/bind';
import { noop } from 'src/utils/noop';
import { classList } from 'src/utils/classList';
import { FormattedMessage } from 'react-intl';

type Props<TItem> = {
    readonly name?: string;
    readonly block?: boolean;
    readonly className?: string;
    readonly disabled?: boolean;
    readonly readOnly?: boolean;
    readonly placeholderid?: string;

    readonly value: string;
    readonly onChange?: (value: string) => void;
    readonly onCreate?: (value: string) => void;
    readonly onSelect?: (item: TItem) => void;
    readonly onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    readonly onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;

    readonly items: ReadonlyArray<TItem>;
    readonly getItemValue: (item: TItem) => string;
    readonly renderItem?: (item: TItem, isHighlighted: boolean) => React.ReactNode;
};

export class Autocomplete<TItem> extends React.Component<Props<TItem>> {
    public render(): JSX.Element {
        const { name, value, items, getItemValue, placeholderid } = this.props;
        if (!placeholderid) {
            return (
                <ReactAutocomplete data-field={name}
                                   inputProps={{ placeholder: '' }}
                                   open={true}
                                   wrapperProps={{ className: 'xss-autocomplete' }}
                                   value={value}
                                   onChange={this.handleChange}
                                   onSelect={this.handleSelect}
                                   autoHighlight={true}
                                   selectOnBlur={false}
                                   items={items as TItem[]}
                                   getItemValue={getItemValue}
                                   renderInput={this.renderInput}
                                   renderMenu={this.renderMenu}
                                   renderItem={this.renderItem}/>
            );
        }
        return (
            <FormattedMessage id={placeholderid}>
                {(message) => (
                    <ReactAutocomplete data-field={name}
                                       inputProps={{ placeholder: message.toString() }}
                                       open={true}
                                       wrapperProps={{ className: 'xss-autocomplete' }}
                                       value={value}
                                       onChange={this.handleChange}
                                       onSelect={this.handleSelect}
                                       autoHighlight={true}
                                       selectOnBlur={false}
                                       items={items as TItem[]}
                                       getItemValue={getItemValue}
                                       renderInput={this.renderInput}
                                       renderMenu={this.renderMenu}
                                       renderItem={this.renderItem}/>
                )}
            </FormattedMessage>
        );
    }

    @bind
    private handleChange(event: React.ChangeEvent<HTMLInputElement>, value: string): void {
        const { onChange = noop } = this.props;
        onChange(value);
    }

    @bind
    private handleSelect(value: string, item: TItem): void {
        const { onChange = noop, onSelect } = this.props;
        onSelect ? onSelect(item) : onChange(value);
    }

    @bind
    private handleCreate(event: React.KeyboardEvent<HTMLInputElement>): void {
        const { items } = this.props;
        if (items.length > 0) {
            return;
        }

        if (event.keyCode !== 13) {
            return;
        }

        const { onCreate, value } = this.props;
        if (onCreate) {
            event.preventDefault();
            onCreate(value);
        }
    }

    @bind
    private renderInput(inputProps: React.HTMLProps<HTMLInputElement>): JSX.Element {
        const { className, disabled, readOnly, onFocus, onBlur } = this.props;

        return (
            <input {...inputProps}
                   type="text"
                   disabled={disabled}
                   readOnly={readOnly}
                   onFocus={chainEventHandlers(onFocus, inputProps.onFocus)}
                   onBlur={chainEventHandlers(onBlur, inputProps.onBlur)}
                   onKeyDown={chainEventHandlers(this.handleCreate, inputProps.onKeyDown)}
                   className={classList('xss-autocomplete__input', className)}/>
        );
    }

    @bind
    private renderMenu(items: ReadonlyArray<React.ReactNode>): JSX.Element {
        const { items: suggests } = this.props;

        return (
            <div className="xss-autocomplete__menu" hidden={!suggests.length}>
                {items}
            </div>
        );
    }

    @bind
    private renderItem(item: TItem, isHighlighted: boolean): JSX.Element {
        const { getItemValue, renderItem = this.renderItemDefault } = this.props;
        const className = classList('xss-autocomplete__item', {
            'xss-autocomplete__item--active': isHighlighted,
        });

        return (
            <div key={getItemValue(item)} className={className}>
                {renderItem(item, isHighlighted)}
            </div>
        );
    }

    @bind
    private renderItemDefault(item: TItem): React.ReactNode {
        const { getItemValue } = this.props;
        return getItemValue(item);
    }
}

type TEventHandler<TArgs extends any[]> = (...args: TArgs) => void;
function chainEventHandlers<TArgs extends any[]>(
    ...handlers: (TEventHandler<TArgs> | undefined)[]
): TEventHandler<TArgs> {
    return (...args) => {
        for (const handler of handlers) {
            if (handler) {
                handler(...args);
            }
        }
    };
}
