import './SettingsCustomFieldForm.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { bind } from 'lodash-decorators/bind';
import { InnerFormProps, OuterFormProps, withFormDecorator } from 'src/decorators/withFormDecorator';
import { CustomFieldType } from 'src/types/values/CustomFieldType';
import { TextboxField } from 'src/react/forms/components/TextboxField';
import { HtmlButton } from 'src/react/common/components/Button';
import { SettingsCustomFieldType } from 'src/react/settings/components/SettingsCustomFieldType';
import { SettingsCustomFieldFormData } from 'src/react/settings/types/SettingsCustomFieldFormData';
import { FormFieldError } from 'src/react/forms/components/FormFieldError';
import { SelectboxField } from 'src/react/forms/components/SelectboxField';

type Props = {
    readonly onSubmit: (formData: SettingsCustomFieldFormData) => void;
};

class SettingsCustomFieldFormConnected extends React.Component<InnerFormProps<SettingsCustomFieldFormData, Props>> {
    public render(): JSX.Element {
        const { handleSubmit } = this.props;

        return (
            <form className="settings-custom-field-form" onSubmit={handleSubmit(this.handleFormSubmit)}>
                <div className="settings-custom-field-form__row">
                    <div className="settings-custom-field-form__name">
                        <div className="settings-custom-field-form__label">
                            <FormattedMessage id="settings_field_customFieldName"/>
                        </div>
                        <div className="settings-custom-field-form__field">
                            <TextboxField name="fieldName"/>
                        </div>
                        <div className="settings-custom-field-form__hint">
                            <FormattedMessage id="settings_hint_customFieldName"/>
                        </div>
                        <FormFieldError className="settings-custom-field-form__error"
                                        field="fieldName"/>
                    </div>
                    <div className="settings-custom-field-form__type">
                        <div className="settings-custom-field-form__label">
                            <FormattedMessage id="settings_field_customFieldType"/>
                        </div>
                        <div className="settings-custom-field-form__field">
                            <SelectboxField name="fieldType"
                                            options={AVAILABLE_CUSTOM_FIELD_TYPES}
                                            placeholder={CUSTOM_FIELD_TYPE_PLACEHOLDER}
                                            renderOption={renderCustomFieldType}
                                            clearable={false}/>
                        </div>
                        <FormFieldError className="settings-custom-field-form__error"
                                        field="fieldType"/>
                    </div>
                    <div className="settings-custom-field-form__btn">
                        <div className="settings-custom-field-form__label">
                            &nbsp;
                        </div>
                        <div className="settings-custom-field-form__field">
                            <HtmlButton block={true}
                                        intent="info"
                                        type="submit"
                                        disabled={this.isSubmitDisabled()}>
                                <FormattedMessage id="settings_customField_create"/>
                            </HtmlButton>
                        </div>
                    </div>
                </div>
            </form>
        );
    }

    private isSubmitDisabled(): boolean {
        const { pristine, submitFailed, invalid } = this.props;

        return pristine
            ? true
            : submitFailed
                ? invalid
                : false;
    }

    @bind
    private handleFormSubmit(formData: SettingsCustomFieldFormData): void {
        const { onSubmit } = this.props;
        onSubmit(formData);

        const { reset } = this.props;
        reset();
    }
}

const AVAILABLE_CUSTOM_FIELD_TYPES: ReadonlyArray<CustomFieldType> = [
    'keyword',
    'tags',
    'text',
    'price',
    'numeric',
    'boolean',
];

const CUSTOM_FIELD_TYPE_PLACEHOLDER = <FormattedMessage id="settings_placeholder_customFieldType"/>;

function renderCustomFieldType(type: CustomFieldType): JSX.Element {
    return <SettingsCustomFieldType type={type}/>;
}

@withFormDecorator<SettingsCustomFieldFormData, Props>(SettingsCustomFieldFormConnected, {})
export class SettingsCustomFieldForm extends React.Component<OuterFormProps<SettingsCustomFieldFormData, Props>> {}
