import './SettingsForm.scss';
import * as React from 'react';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import { FormErrors } from 'redux-form';
import { bind } from 'lodash-decorators/bind';
import { memoize } from 'lodash-decorators/memoize';
import { InnerFormProps, OuterFormProps, withFormDecorator } from 'src/decorators/withFormDecorator';
import { flattenErrors } from 'src/utils/getFormErrors';
import { parseIntegerValue } from 'src/utils/parseIntegerValue';
import { FieldErrors } from 'src/types/values/FieldError';
import { Widget } from 'src/types/entities/Widget';
import { CrawlerTask } from 'src/types/entities/CrawlerTask';
import { SettingsPageTab } from 'src/react/settings/types/SettingsPageTab';
import { SettingsFormData } from 'src/react/settings/types/SettingsFormData';
import { DateFormat } from 'src/react/common/components/DateFormat';
import { Alert, AlertBody, AlertTitle } from 'src/react/common/components/Alert';
import { StaticField } from 'src/react/common/components/StaticField';
import { HtmlButton } from 'src/react/common/components/Button';
import { FormFieldError } from 'src/react/forms/components/FormFieldError';
import { TextboxField } from 'src/react/forms/components/TextboxField';
import { SwitcherField } from 'src/react/forms/components/SwitcherField';
import { TextareaField } from 'src/react/forms/components/TextareaField';
import { UrlInputField } from 'src/react/forms/components/UrlInputField';
import { ManualCrawler } from 'src/react/manualCrawler/components/ManualCrawler';
import { SettingsCollapsible } from 'src/react/settings/components/SettingsCollapsible';
import { SettingsUrlBoostForm } from 'src/react/settings/components/SettingsUrlBoostForm';
import { SettingsCustomFieldForm } from 'src/react/settings/components/SettingsCustomFieldForm';
import { SettingsCustomFieldType } from 'src/react/settings/components/SettingsCustomFieldType';
import { SettingsCustomFieldFormData } from 'src/react/settings/types/SettingsCustomFieldFormData';
import { SettingsUrlBoostFormData } from 'src/react/settings/types/SettingsUrlBoostFormData';
import { SettingsCustomBoostFormData } from 'src/react/settings/types/SettingsCustomBoostFormData';
import { settingsUrlBoostFormValidator } from 'src/react/settings/validators/settingsUrlBoostFormValidator';
import { settingsCustomFieldFormValidator } from 'src/react/settings/validators/settingsCustomFieldFormValidator';
import { SettingsCustomBoostForm } from 'src/react/settings/components/SettingsCustomBoostForm';
import { SettingsFormBoostField } from 'src/react/settings/components/SettingsFormBoostField';
import { SettingsCustomField } from 'src/react/settings/types/SettingsCustomField';
import { settingsCustomBoostFormValidator } from 'src/react/settings/validators/settingsCustomBoostFormValidator';
import { MonacoField } from 'src/react/forms/components/MonacoField';
import { SelectboxField } from 'src/react/forms/components/SelectboxField';

type Props = {
    readonly widget: Widget;
    readonly crawlerTask: CrawlerTask | null;
    readonly activeTab: SettingsPageTab;
    readonly snippetCode: string;
    readonly formValues: SettingsFormData;
    readonly customBoostFormValues: SettingsCustomBoostFormData | undefined;
} & InjectedIntlProps;

class SettingsFormConnected extends React.Component<InnerFormProps<SettingsFormData, Props>> {
    private readonly initialUrlBoostFormData: SettingsUrlBoostFormData = {
        urlPattern: '',
        urlBoost: null,
    };
    private readonly initialCustomFieldFormData: SettingsCustomFieldFormData = {
        fieldName: '',
        fieldType: null,
    };
    private readonly initialCustomBoostFormData: SettingsCustomBoostFormData = {
        fieldName: null,
        fieldBoost: 1,
    };

    public render(): JSX.Element {
        const { activeTab, widget, crawlerTask, snippetCode, intl } = this.props;
        const { formValues, customBoostFormValues = this.initialCustomBoostFormData } = this.props;

        return (
            <div className="settings-form">
                <div hidden={activeTab !== 'general'}>
                    <div className="settings-form__panel">
                        <div className="settings-form__panel-heading">
                            <FormattedMessage id="settings_panel_widget"/>
                        </div>
                        <div className="settings-form__panel-body">
                            <div className="settings-form__row">
                                <label className="settings-form__label"
                                       htmlFor="widgetName">
                                    <FormattedMessage id="settings_field_widgetName"/>
                                </label>
                                <div className="settings-form__field">
                                    <TextboxField id="widgetName"
                                                  name="widget.name"/>
                                </div>
                                <FormFieldError className="settings-form__error"
                                                field="widget.name"/>
                            </div>

                            <div className="settings-form__row">
                                <label className="settings-form__label"
                                       htmlFor="widgetApiKey">
                                    <FormattedMessage id="settings_field_widgetApiKey"/>
                                </label>
                                <div className="settings-form__field">
                                    <StaticField>{widget.apiKey}</StaticField>
                                </div>
                            </div>
                            <div className="settings-form__row has-feedback">
                                <label className="settings-form__label"
                                       htmlFor="widgetWebsite">
                                    <FormattedMessage id="settings_field_widgetWebsite"/>
                                </label>
                                <div className="settings-form__field">
                                    <UrlInputField id="widgetWebsite"
                                                   name="widget.website"/>
                                </div>
                                <FormFieldError className="settings-form__error"
                                                field="widget.website"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div hidden={activeTab !== 'crawler'}>
                    <div className="settings-form__panel">
                        <div className="settings-form__panel-heading">
                            <FormattedMessage id="settings_panel_crawler"/>

                            <span className="settings-form__panel-heading-note">
                                <FormattedMessage id="settings_crawler_lastUpdate" values={{
                                    date: crawlerTask
                                        ? <DateFormat date={crawlerTask.updatedAt}/>
                                        : '-'
                                }}/>
                            </span>
                        </div>
                        <div className="settings-form__panel-body">
                            <div className="settings-form__row">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-xs-12">
                                        <div className="settings-form__row">
                                            <label className="settings-form__label"
                                                   htmlFor="crawlerLimit">
                                                <FormattedMessage id="settings_field_crawlerLimit"/>
                                            </label>
                                            <div className="settings-form__field">
                                                <TextboxField id="crawlerLimit"
                                                              name="crawler.limit"
                                                              normalize={normalizeCrawlerLimit}
                                                              type="number"
                                                              min={1}/>
                                            </div>
                                            <div className="settings-form__hint">
                                                <FormattedMessage id="settings_hint_crawlerLimit"/>
                                            </div>
                                            <FormFieldError className="settings-form__error"
                                                            field="crawler.limit"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-xs-12">
                                        <div className="settings-form__row">
                                            <label className="settings-form__label"
                                                   htmlFor="crawlerDepth">
                                                <FormattedMessage id="settings_field_crawlerDepth"/>
                                            </label>
                                            <div className="settings-form__field">
                                                <TextboxField id="crawlerDepth"
                                                              name="crawler.depth"
                                                              normalize={normalizeCrawlerDepth}
                                                              type="number"
                                                              min={1}/>
                                            </div>
                                            <div className="settings-form__hint">
                                                <FormattedMessage id="settings_hint_crawlerDepth"/>
                                            </div>
                                            <FormFieldError className="settings-form__error"
                                                            field="crawler.depth"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings-form__row">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-xs-12">
                                        <div className="settings-form__row">
                                            <label className="settings-form__label"
                                                   htmlFor="defaultImage">
                                                <FormattedMessage id="settings_field_defaultImage"/>
                                            </label>
                                            <div className="settings-form__field">
                                                <TextboxField id="defaultImage"
                                                              name="crawler.defaultImage"/>
                                            </div>
                                            <div className="settings-form__hint">
                                                <FormattedMessage id="settings_hint_defaultImage"/>
                                            </div>
                                            <FormFieldError className="settings-form__error"
                                                            field="crawler.defaultImage"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings-form__row">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-xs-12">
                                        <div className="settings-form__row">
                                            <label className="settings-form__label"
                                                   htmlFor="crawlerWhiteList">
                                                <FormattedMessage id="settings_field_crawlerPages"/>
                                            </label>
                                            <div className="settings-form__field">
                                                <TextareaField id="crawlerPages"
                                                              name="crawler.pages"/>
                                            </div>
                                            <div className="settings-form__hint">
                                                <FormattedMessage id="settings_hint_crawlerPages"/>
                                            </div>
                                            <FormFieldError className="settings-form__error"
                                                            field="crawler.pages"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-xs-12">
                                        <div className="settings-form__row">
                                            <label className="settings-form__label"
                                                   htmlFor="crawlerDomains">
                                                <FormattedMessage id="settings_field_crawlerDomains"/>
                                            </label>
                                            <div className="settings-form__field">
                                                <TextareaField id="crawlerDomains"
                                                              name="crawler.domains"/>
                                            </div>
                                            <div className="settings-form__hint">
                                                <FormattedMessage id="settings_hint_crawlerDomains"/>
                                            </div>
                                            <FormFieldError className="settings-form__error"
                                                            field="crawler.domains"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings-form__row">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-xs-12">
                                        <div className="settings-form__row">
                                            <label className="settings-form__label"
                                                   htmlFor="crawlerWhiteList">
                                                <FormattedMessage id="settings_field_crawlerWhiteList"/>
                                            </label>
                                            <div className="settings-form__field">
                                                <TextareaField id="crawlerWhiteList"
                                                              name="crawler.whiteList"/>
                                            </div>
                                            <div className="settings-form__hint">
                                                <FormattedMessage id="settings_hint_crawlerWhiteList"/>
                                            </div>
                                            <FormFieldError className="settings-form__error"
                                                            field="crawler.whiteList"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-xs-12">
                                        <div className="settings-form__row">
                                            <label className="settings-form__label"
                                                   htmlFor="crawlerExclude">
                                                <FormattedMessage id="settings_field_crawlerExclude"/>
                                            </label>
                                            <div className="settings-form__field">
                                                <TextareaField id="crawlerExclude"
                                                              name="crawler.exclude"/>
                                            </div>
                                            <div className="settings-form__hint">
                                                <FormattedMessage id="settings_hint_crawlerExclude"/>
                                            </div>
                                            <FormFieldError className="settings-form__error"
                                                            field="crawler.exclude"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings-form__row settings-form__row--checkbox">
                                <label className="settings-form__label"
                                       htmlFor="ignoreRobotsTxt">
                                    <FormattedMessage id="settings_field_ignoreRobotsTxt"/>
                                </label>
                                <div className="settings-form__field">
                                    <SwitcherField id="ignoreRobotsTxt"
                                                  name="crawler.ignoreRobotsTxt"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="settings-form__panel">
                        <div className="settings-form__panel-heading">
                            <FormattedMessage id="settings_panel_cronjob"/>
                        </div>
                        <div className="settings-form__panel-body">
                            <div className="settings-form__row settings-form__row--checkbox">
                                <label className="settings-form__label"
                                       htmlFor="crawlerEnabled">
                                    <FormattedMessage id="settings_field_crawlerEnabled"/>
                                </label>
                                <div className="settings-form__field">
                                    <SwitcherField id="crawlerEnabled"
                                                   name="crawler.cronjobEnabled"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="settings-form__panel panel--has-btn">
                        <ManualCrawler crawlerTask={crawlerTask} intl={intl}/>
                    </div>

                    <div className="settings-form__panel panel--has-btn">
                        <div className="settings-form__panel-heading">
                            <FormattedMessage id="settings_panel_parser-config"/>
                        </div>
                        <div className="settings-form__panel-body">
                            <div className="settings-form__row">
                                <div className="settings-form__field">
                                    <MonacoField language="json"
                                                 height="600px"
                                                 name="crawler.parserConfig" />
                                </div>
                                <FormFieldError className="settings-form__error"
                                                field="crawler.parserConfig"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div hidden={activeTab !== 'custom'}>
                    <div className="settings-form__panel">
                        <div className="settings-form__panel-heading">
                            <FormattedMessage id="settings_panel_searchEngine"/>
                        </div>
                        <div className="settings-form__panel-body">
                            <div className="settings-form__row settings-form__row--checkbox">
                                <label className="settings-form__label"
                                       htmlFor="enableFuzziness">
                                    <FormattedMessage id="settings_field_enableFuzziness"/>
                                </label>
                                <div className="settings-form__field">
                                    <SwitcherField id="enableFuzziness"
                                                   name="searchParams.enableFuzziness"/>
                                </div>
                            </div>
                            <div className="settings-form__row settings-form__row--checkbox">
                                <label className="settings-form__label"
                                       htmlFor="searchMatchMode">
                                    <FormattedMessage id="settings_field_enablePrefixMatch"/>
                                </label>
                                <div className="settings-form__field">
                                    <SwitcherField id="enabledPrefixMatch"
                                                   name="searchParams.enablePrefixMatch"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="settings-form__panel">
                        <div className="settings-form__panel-heading">
                            <FormattedMessage id="settings_panel_fieldWeights"/>
                        </div>
                        <div className="settings-form__panel-body">
                            <div className="settings-form__nested-form">
                                <SettingsCustomBoostForm form="settings-custom-boost"
                                                         initialValues={this.initialCustomBoostFormData}
                                                         validate={this.validateCustomBoostForm}
                                                         onSubmit={this.handleCustomBoostCreate}
                                                         formData={customBoostFormValues}
                                                         customFields={this.getCustomBoostFields()}/>
                            </div>
                            <div className="settings-form__nested-divider"/>
                            <div className="settings-form__nested-list">
                                <div className="settings-form__rw">
                                    <div className="settings-form__rwField">
                                        <StaticField>
                                            <FormattedMessage id="settings_field_boostTitle"/>
                                        </StaticField>
                                    </div>
                                    <div className="settings-form__rwSlider">
                                        <SettingsFormBoostField name="fieldBoost.titleBoost"/>
                                    </div>
                                    <div className="settings-form__rwValue">
                                        <StaticField intent="info" className="settings-form__boost-value">
                                            {formValues.fieldBoost.titleBoost}
                                        </StaticField>
                                    </div>
                                    <div className="settings-form__rwBtn">
                                        <HtmlButton block={true}
                                                    intent="none"
                                                    type="button"
                                                    disabled={true}>
                                            <FormattedMessage id="settings_customBoost_remove"/>
                                        </HtmlButton>
                                    </div>
                                </div>
                                <div className="settings-form__rw">
                                    <div className="settings-form__rwField">
                                        <StaticField>
                                            <FormattedMessage id="settings_field_boostDescription"/>
                                        </StaticField>
                                    </div>
                                    <div className="settings-form__rwSlider">
                                        <SettingsFormBoostField name="fieldBoost.descriptionBoost"/>
                                    </div>
                                    <div className="settings-form__rwValue">
                                        <StaticField intent="info" className="settings-form__boost-value">
                                            {formValues.fieldBoost.descriptionBoost}
                                        </StaticField>
                                    </div>
                                    <div className="settings-form__rwBtn">
                                        <HtmlButton block={true}
                                                    intent="none"
                                                    type="button"
                                                    disabled={true}>
                                            <FormattedMessage id="settings_customBoost_remove"/>
                                        </HtmlButton>
                                    </div>
                                </div>
                                <div className="settings-form__rw">
                                    <div className="settings-form__rwField">
                                        <StaticField>
                                            <FormattedMessage id="settings_field_boostContent"/>
                                        </StaticField>
                                    </div>
                                    <div className="settings-form__rwSlider">
                                        <SettingsFormBoostField name="fieldBoost.contentBoost"/>
                                    </div>
                                    <div className="settings-form__rwValue">
                                        <StaticField intent="info" className="settings-form__boost-value">
                                            {formValues.fieldBoost.contentBoost}
                                        </StaticField>
                                    </div>
                                    <div className="settings-form__rwBtn">
                                        <HtmlButton block={true}
                                                    intent="none"
                                                    type="button"
                                                    disabled={true}>
                                            <FormattedMessage id="settings_customBoost_remove"/>
                                        </HtmlButton>
                                    </div>
                                </div>
                                {formValues.customBoosts.map((fieldBoost, index) => (
                                    <div key={fieldBoost.fieldName} className="settings-form__row">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <StaticField>
                                                    <FormattedMessage id="settings_customBoost_field"
                                                                      values={{ field: fieldBoost.fieldName }}/>
                                                </StaticField>
                                            </div>
                                            <div className="col-md-4">
                                                <SettingsFormBoostField name={`customBoosts[${index}].fieldBoost`}/>
                                            </div>
                                            <div className="col-md-1">
                                                <StaticField intent="info" className="settings-form__boost-value">
                                                    {fieldBoost.fieldBoost}
                                                </StaticField>
                                            </div>
                                            <div className="col-md-2 col-md-offset-1">
                                                <HtmlButton block={true}
                                                            intent="none"
                                                            type="button"
                                                            onClick={this.getRemoveCustomBoostHandler(index)}>
                                                    <FormattedMessage id="settings_customBoost_remove"/>
                                                </HtmlButton>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="settings-form__panel">
                        <div className="settings-form__panel-heading">
                            <FormattedMessage id="settings_panel_urlBoost"/>
                        </div>
                        <div className="settings-form__panel-body">
                            <div className="settings-form__nested-form">
                                <SettingsUrlBoostForm form="settings-url-boost"
                                                      initialValues={this.initialUrlBoostFormData}
                                                      validate={this.validateUrlBoostForm}
                                                      onSubmit={this.handleUrlBoostCreate}/>
                            </div>

                            {formValues.urlBoosts.length > 0
                                ? <div className="settings-form__nested-divider"/>
                                : null}

                            <div className="settings-form__nested-list">
                                {formValues.urlBoosts.map((urlBoost, index) => (
                                    <div key={urlBoost.urlPattern + index.toString()} className="settings-form__rw">

                                            <div className="settings-form__rwField">
                                                <StaticField>
                                                    {urlBoost.urlPattern}
                                                </StaticField>
                                            </div>
                                            <div className="settings-form__rwValue">
                                                <StaticField>
                                                    {urlBoost.urlBoost}
                                                </StaticField>
                                            </div>
                                            <div className="settings-form__rwBtn">
                                                <HtmlButton block={true}
                                                            intent="none"
                                                            type="button"
                                                            onClick={this.getRemoveUrlBoostHandler(index)}>
                                                    <FormattedMessage id="settings_urlBoost_remove"/>
                                                </HtmlButton>
                                            </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="settings-form__panel">
                        <div className="settings-form__panel-heading">
                            <FormattedMessage id="settings_panel_customFields"/>
                        </div>
                        <div className="settings-form__panel-body">
                            <div className="settings-form__nested-form">
                                <SettingsCustomFieldForm form="settings-custom-field"
                                                         initialValues={this.initialCustomFieldFormData}
                                                         validate={this.validateCustomFieldForm}
                                                         onSubmit={this.handleCustomFieldCreate}/>
                            </div>

                            {formValues.customFields.length > 0
                                ? <div className="settings-form__nested-divider"/>
                                : null}

                            <div className="settings-form__nested-list">
                                {formValues.customFields.map((customField, index) => (
                                    <div key={customField.fieldName} className="settings-form__rw">
                                        <div className="settings-form__rwField">
                                            <StaticField>
                                                {customField.fieldName}
                                            </StaticField>
                                        </div>
                                        <div className="settings-form__rwValue">
                                            <StaticField>
                                                <SettingsCustomFieldType type={customField.fieldType}/>
                                            </StaticField>
                                        </div>
                                        <div className="settings-form__rwBtn">
                                            <HtmlButton block={true}
                                                        intent="none"
                                                        type="button"
                                                        onClick={this.getRemoveCustomFieldHandler(index)}>
                                                <FormattedMessage id="settings_customField_remove"/>
                                            </HtmlButton>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="settings-form__panel">
                        <div className="settings-form__panel-heading">
                            <FormattedMessage id="settings_panel_includeToFacets"/>
                        </div>
                        <div className="settings-form__panel-body">
                            <div className="settings-form__field">
                                <SelectboxField
                                    isMulti={true}
                                    options={this.customFieldsOptions()}
                                    placeholder={INCLUDE_TO_FACETS_PLACEHOLDER}
                                    renderOption={renderCustomFieldName}
                                    name={'includeToFacets'}
                                />
                            </div>
                            <FormFieldError className="settings-form__error"
                                            field="includeToFacets"/>
                        </div>
                    </div>
                    <div className="settings-form__panel">
                        <div className="settings-form__panel-heading">
                            <FormattedMessage id="settings_panel_includeToRecommendations"/>
                        </div>
                        <div className="settings-form__panel-body">
                            <div className="settings-form__field">
                                <SelectboxField
                                    isMulti={true}
                                    options={this.customFieldsOptions()}
                                    placeholder={INCLUDE_TO_RECOMMENDATIONS_PLACEHOLDER}
                                    renderOption={renderCustomFieldName}
                                    name={'includeToRecommendations'}
                                />
                            </div>
                            <FormFieldError className="settings-form__error"
                                            field="includeToRecommendations"/>
                        </div>
                    </div>
                </div>
                <div hidden={activeTab !== 'integration'}>
                    <div className="settings-form__panel">
                        <div className="settings-form__panel-heading">
                            <FormattedMessage id="settings_panel_integrationAutocomplete"/>
                        </div>
                        <div className="settings-form__panel-body">
                            <div className="settings-form__row settings-form__row--checkbox">
                                <label className="settings-form__label"
                                       htmlFor="autocompleteKeyPhrase">
                                    <FormattedMessage id="settings_field_autocompleteKeyPhrase"/>
                                </label>
                                <div className="settings-form__field">
                                    <SwitcherField id="autocompleteKeyPhrase"
                                                   name="autocomplete.useKeyPhrase"/>
                                </div>
                            </div>
                            <div className="settings-form__row settings-form__row--checkbox">
                                <label className="settings-form__label"
                                       htmlFor="autocompleteItemTitle">
                                    <FormattedMessage id="settings_field_autocompleteItemTitle"/>
                                </label>
                                <div className="settings-form__field">
                                    <SwitcherField id="autocompleteItemTitle"
                                                   name="autocomplete.useItemTitle"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="settings-form__panel">
                        <div className="settings-form__panel-heading">
                            <FormattedMessage id="settings_panel_integrationWidgetCode"/>

                            <span className="settings-form__panel-heading-note">
                                <FormattedMessage id="settings_integration_helpText" values={{
                                    link: (
                                        <a target="_blank"
                                           href="https://doc.levo-app.ch/article/integration-guide/">
                                            <FormattedMessage id="settings_integration_helpLink"/>
                                        </a>
                                    ),
                                }}/>
                            </span>
                        </div>
                        <div className="settings-form__panel-body">
                            {widget.isActive || (
                                <div className="settings-form__row">
                                    <Alert intent="danger">
                                        <AlertTitle>
                                            <FormattedMessage id="settings_integration_alertTitle"/>
                                        </AlertTitle>
                                        <AlertBody>
                                            <FormattedMessage id="settings_integration_alertText"/>
                                        </AlertBody>
                                    </Alert>
                                </div>
                            )}

                            <div className="settings-form__row">
                                <label className="settings-form__label"
                                       htmlFor="snippetCode">
                                    <FormattedMessage id="settings_field_snippetCode"/>
                                </label>
                                <div className="settings-form__field">
                                    <StaticField>
                                        <div style={{ whiteSpace: 'pre-line' }}>{snippetCode}</div>
                                    </StaticField>
                                </div>
                            </div>
                            <div className="settings-form__row">
                                <label className="settings-form__label"
                                       htmlFor="templateLayout">
                                    <FormattedMessage id="settings_field_templateLayout"/>
                                </label>
                                <div className="settings-form__field">
                                    <MonacoField language="html"
                                                 height="600px"
                                                 name="integration.htmlTemplates.layoutTemplate" />
                                </div>
                                <FormFieldError className="settings-form__error"
                                                field="integration.htmlTemplates.layoutTemplate"/>
                            </div>
                            <div className="settings-form__row">
                                <label className="settings-form__label"
                                       htmlFor="templateHeader">
                                    <FormattedMessage id="settings_field_templateHeader"/>
                                </label>
                                <div className="settings-form__field">
                                    <MonacoField language="html"
                                                 height="300px"
                                                 name="integration.htmlTemplates.headerTemplate" />
                                </div>
                                <FormFieldError className="settings-form__error"
                                                field="integration.htmlTemplates.headerTemplate"/>
                            </div>
                            <div className="settings-form__row">
                                <label className="settings-form__label"
                                       htmlFor="templateResultItem">
                                    <FormattedMessage id="settings_field_templateResultItem"/>
                                </label>
                                <div className="settings-form__field">
                                    <MonacoField language="html"
                                                 height="300px"
                                                 name="integration.htmlTemplates.resultTemplate" />
                                </div>
                                <FormFieldError className="settings-form__error"
                                                field="integration.htmlTemplates.resultTemplate"/>
                            </div>
                            <div className="settings-form__row">
                                <label className="settings-form__label"
                                       htmlFor="templateNoResults">
                                    <FormattedMessage id="settings_field_templateNoResults"/>
                                </label>
                                <div className="settings-form__field">
                                    <MonacoField language="html"
                                                 height="300px"
                                                 name="integration.htmlTemplates.noResultTemplate" />
                                </div>
                                <FormFieldError className="settings-form__error"
                                                field="integration.htmlTemplates.noResultTemplate"/>
                            </div>
                            <div className="settings-form__row">
                                <label className="settings-form__label"
                                       htmlFor="templateFooter">
                                    <FormattedMessage id="settings_field_templateFooter"/>
                                </label>
                                <div className="settings-form__field">
                                    <MonacoField language="html"
                                                 height="300px"
                                                 name="integration.htmlTemplates.footerTemplate" />
                                </div>
                                <FormFieldError className="settings-form__error"
                                                field="integration.htmlTemplates.footerTemplate"/>
                            </div>
                        </div>
                    </div>
                    <div className="settings-form__panel">
                        <div className="settings-form__panel-heading">
                            <FormattedMessage id="settings_feedback_title"/>
                        </div>
                        <div className="settings-form__panel-body">
                            <div className="settings-form__row">
                                <SettingsCollapsible defaultExpanded={false} title={(
                                    <div className="settings-form__panel-subheading">
                                        <FormattedMessage id="settings_feedback_general_subtitle"/>
                                    </div>
                                )}>
                                    {[
                                        {
                                            msgid: 'settings_feedback_title_question_text',
                                            name: 'integration.feedbackSettings.general.titleQuestion',
                                        },
                                        {
                                            msgid: 'settings_feedback_true_button',
                                            name: 'integration.feedbackSettings.general.trueButton',
                                        },
                                        {
                                            msgid: 'settings_feedback_false_button',
                                            name: 'integration.feedbackSettings.general.falseButton',
                                        },
                                        {
                                            msgid: 'settings_feedback_true_comment_text',
                                            name: 'integration.feedbackSettings.general.trueComment',
                                        },
                                        {
                                            msgid: 'settings_feedback_false_comment_text',
                                            name: 'integration.feedbackSettings.general.falseComment',
                                        },
                                        {
                                            msgid: 'settings_feedback_submit_button_text',
                                            name: 'integration.feedbackSettings.general.submitButton',
                                        },
                                        {
                                            msgid: 'settings_feedback_thank_you_text',
                                            name: 'integration.feedbackSettings.general.thankYouText',
                                        },
                                        {
                                            msgid: 'settings_feedback_email',
                                            name: 'integration.feedbackSettings.general.email',
                                        },
                                    ].map(({ msgid, name }) => this.renderFeedbackField(name, msgid))}
                                </SettingsCollapsible>
                            </div>
                            <div className="settings-form__row">
                                <SettingsCollapsible defaultExpanded={false} title={(
                                    <div className="settings-form__panel-subheading">
                                        <FormattedMessage id="settings_feedback_no_results_subtitle"/>
                                    </div>
                                )}>
                                    {[
                                        {
                                            msgid: 'settings_feedback_title_question_text',
                                            name: 'integration.feedbackSettings.noResults.titleQuestion',
                                        },
                                        {
                                            msgid: 'settings_feedback_no_results_comment_text',
                                            name: 'integration.feedbackSettings.noResults.commentText',
                                        },
                                        {
                                            msgid: 'settings_feedback_submit_button_text',
                                            name: 'integration.feedbackSettings.noResults.submitButton',
                                        },
                                        {
                                            msgid: 'settings_feedback_thank_you_text',
                                            name: 'integration.feedbackSettings.noResults.thankYouText',
                                        },
                                        {
                                            msgid: 'settings_feedback_email',
                                            name: 'integration.feedbackSettings.noResults.email',
                                        },
                                    ].map(({ msgid, name }) => this.renderFeedbackField(name, msgid))}
                                </SettingsCollapsible>
                            </div>
                        </div>
                    </div>
                </div>
                <div hidden={activeTab !== 'statistics'}>
                    <div className="settings-form__panel">
                        <div className="settings-form__panel-heading">
                            <FormattedMessage id="settings_panel_statisticsExclude"/>
                        </div>
                        <div className="settings-form__panel-body">
                            <div className="settings-form__row">
                                <div className="settings-form__field">
                                    <FormattedMessage id="settings_placeholder_excludedSearchTerms">
                                        {(placeholder) => (
                                            <TextareaField name="statistics.excludedSearchTerms"
                                                           placeholder={placeholder.toString()}
                                                           rows={10}
                                                           spellCheck={false}
                                                           autoCapitalize="off"
                                                           autoComplete="off"/>
                                        )}
                                    </FormattedMessage>
                                </div>
                                <FormFieldError className="settings-form__error"
                                                field="statistics.excludedSearchTerms"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderFeedbackField(fieldName: string, fieldNameMsgId: string): JSX.Element {
        return (
            <div key={fieldName} className="settings-form__row">
                <label className="settings-form__label">
                    <FormattedMessage id={fieldNameMsgId}/>
                </label>
                <div className="settings-form__field">
                    <TextboxField name={fieldName}
                                  spellCheck={false}
                                  autoCapitalize="off"
                                  autoComplete="off"/>
                </div>
                <FormFieldError className="settings-form__error"
                                field={fieldName}/>
            </div>
        );
    }

    @memoize
    private getRemoveUrlBoostHandler(index: number): () => void {
        return () => {
            const { change, formValues: { urlBoosts } } = this.props;

            change('urlBoosts', [
                ...urlBoosts.slice(0, index),
                ...urlBoosts.slice(index + 1),
            ]);
        };
    }

    @bind
    private handleUrlBoostCreate(formData: SettingsUrlBoostFormData): void {
        const { formValues: { urlBoosts } } = this.props;
        if (!settingsUrlBoostFormValidator.isValid(formData, urlBoosts)) {
            return;
        }

        const { change } = this.props;
        change('urlBoosts', [...urlBoosts, formData]);
    }

    @bind
    private validateUrlBoostForm(
        formData: SettingsUrlBoostFormData,
    ): FormErrors<SettingsUrlBoostFormData, FieldErrors> {
        const { formValues: { urlBoosts } } = this.props;
        return flattenErrors(settingsUrlBoostFormValidator.validate(formData, urlBoosts));
    }

    @memoize
    private getRemoveCustomFieldHandler(index: number): () => void {
        return () => {
            const { change, formValues: { customBoosts, customFields } } = this.props;

            change('customFields', [
                ...customFields.slice(0, index),
                ...customFields.slice(index + 1),
            ]);
            change('customBoosts', customBoosts
                .filter((it) => it.fieldName !== customFields[index].fieldName)
            );
        };
    }

    @bind
    private handleCustomFieldCreate(formData: SettingsCustomFieldFormData): void {
        const { formValues: { customFields } } = this.props;
        if (!settingsCustomFieldFormValidator.isValid(formData, customFields)) {
            return;
        }

        const { change, formValues } = this.props;
        change('customFields', [...formValues.customFields, formData]);
    }

    @bind
    private validateCustomFieldForm(
        formData: SettingsCustomFieldFormData,
    ): FormErrors<SettingsCustomFieldFormData, FieldErrors> {
        const { formValues: { customFields } } = this.props;
        return flattenErrors(settingsCustomFieldFormValidator.validate(formData, customFields));
    }

    @memoize
    private getRemoveCustomBoostHandler(index: number): () => void {
        return () => {
            const { change, formValues: { customBoosts } } = this.props;

            change('customBoosts', [
                ...customBoosts.slice(0, index),
                ...customBoosts.slice(index + 1),
            ]);
        };
    }

    @bind
    private handleCustomBoostCreate(formData: SettingsCustomBoostFormData): void {
        const { formValues: { customBoosts, customFields } } = this.props;
        if (!settingsCustomBoostFormValidator.isValid(formData, customBoosts, customFields)) {
            return;
        }

        const { change } = this.props;
        change('customBoosts', [...customBoosts, formData]);
    }

    @bind
    private validateCustomBoostForm(
        formData: SettingsCustomBoostFormData,
    ): FormErrors<SettingsCustomBoostFormData, FieldErrors> {
        const { formValues: { customBoosts, customFields } } = this.props;
        return flattenErrors(settingsCustomBoostFormValidator.validate(formData, customBoosts, customFields));
    }

    private getCustomBoostFields(): SettingsCustomField[] {
        const { formValues: { customBoosts, customFields } } = this.props;
        const existingNames = customBoosts.map((it) => it.fieldName);

        return customFields
            .filter((it) => it.fieldType === 'text')
            .filter((it) => !existingNames.includes(it.fieldName));
    }

    private customFieldsOptions(): string[] {
        const { formValues } = this.props;
        return formValues.customFields.map((item) => item.fieldName);
    }
}

const INCLUDE_TO_FACETS_PLACEHOLDER = <FormattedMessage id="settings_placeholder_includeToFacets"/>;
const INCLUDE_TO_RECOMMENDATIONS_PLACEHOLDER = <FormattedMessage id="settings_placeholder_includeToRecommendations"/>;

function renderCustomFieldName(name: string): JSX.Element {
    return (<span>{name}</span>);
}

function normalizeCrawlerDepth(value: unknown): number | null {
    const n = parseIntegerValue(value);
    return n === null || n === 0 ? null : Math.max(Math.min(n, 20), 1);
}
function normalizeCrawlerLimit(value: unknown): number | null {
    const n = parseIntegerValue(value);
    return n === null || n === 0 ? null : Math.max(Math.min(n, 1000000000), 1);
}

@withFormDecorator<SettingsFormData, Props>(SettingsFormConnected, {})
export class SettingsForm extends React.Component<OuterFormProps<SettingsFormData, Props>> {}
