import * as React from 'react';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { Item } from 'src/types/entities/Item';
import { Uuid } from 'src/types/values/Uuid';
import { Widget } from 'src/types/entities/Widget';
import { CustomField } from 'src/types/entities/CustomField';
import { Organization } from 'src/types/entities/Organization';
import { SelectedWidget } from 'src/react/selectedWidget/components/SelectedWidget';
import { ItemFormModal } from 'src/react/itemForm/components/ItemFormModal';

export type ItemFormBridgeProps = {
    readonly modalInstance: IModalInstanceService;
    readonly resolve: {
        readonly itemId: Uuid | null;
        readonly widget: Widget;
        readonly customFields: ReadonlyArray<CustomField>;
        readonly organization: Organization;
        readonly onRestored: (item: Item) => void;
    };
};

export default function ({
                             resolve: { itemId, widget, customFields, organization, onRestored },
                             modalInstance,
}: ItemFormBridgeProps): JSX.Element {
    return (
        <SelectedWidget widget={widget}
                        organization={organization}
                        customFields={customFields}
                        statisticsFilter={null}>
            <ItemFormModal id={itemId}
                           onSaved={modalInstance.close}
                           onCancel={modalInstance.dismiss}
                           onRestored={onRestored}/>
        </SelectedWidget>
    );
}
