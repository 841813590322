import { IComponentController, IPromise } from 'angular';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { bind } from 'lodash-decorators/bind';
import { Widget } from 'src/types/entities/Widget';
import { IKeyPhraseService } from 'src/modules/api/KeyPhraseService';
import { ISafePromiseWrapper } from 'src/modules/core/utils/SafePromiseWrapper';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { StateService } from '@uirouter/core';

export class KeyPhrasePopupController implements IComponentController {
    public widget!: Readonly<Widget>;

    public text = '';

    public keywordExists: boolean = false;

    public resolve!: { widget: Readonly<Widget> };
    public modalInstance!: IModalInstanceService;

    public static $inject = ['keyPhraseService', '$state', 'safePromiseWrapper'];

    private safeSearchKeyword = this.safePromiseWrapper.wrapPromiseFunction(this.searchKeyword);

    public constructor(
        private keyPhraseService: IKeyPhraseService,
        private state: StateService,
        private safePromiseWrapper: ISafePromiseWrapper,
    ) {}

    public $onInit(): void {
        this.widget = this.resolve.widget;
    }

    public submitForm(): void {
        this.close();
        this.state.go('demo', {
            query: this.text,
            pinMode: true
        });
    }

    public keywordChange(text: string): void {
        this.safeSearchKeyword(text).then((data) => {
            this.keywordExists = data.total > 0;
            return data;
        });
    }
    @bind
    private searchKeyword(text: string): IPromise<ListWithTotal<KeyPhrase>>  {
        return this.keyPhraseService.list(this.widget, {
                exact: true,
                query: text,
                itemId: [],
                page: 1,
                pageSize: 1,
                sort: [],
                state: [],
                groups:[],
            });
    }

    public canSubmitForm(): boolean {
        return this.text !== undefined && this.text.length > 0 && !this.keywordExists;
    }

    public close(): void {
        this.modalInstance.close();
    }

    public dismiss(): void {
        this.modalInstance.dismiss();
    }
}
