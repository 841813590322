import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { Widget } from 'src/types/entities/Widget';
import { DemoPageParams } from 'src/react/demoPage/state/DemoPageParams';
import { DEMO_SEARCH_PAGE_SIZE } from 'src/react/demoPage/constants/DemoPageSize';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { getSearchEndpoint } from 'src/react/api/selectors/getEndpoint';
import { ISearchEndpoint } from 'src/react/api/endpoints/SearchEndpoint';

export function* demoSearchQuery({ query, page, mode, fuzzy }: DemoPageParams): SagaIterator {
    const widget: Widget = yield select(getSelectedWidget);
    const searchEndpoint: ISearchEndpoint = yield select(getSearchEndpoint);

    return yield call({
        fn: searchEndpoint.list,
        context: searchEndpoint,
    }, {
        query,
        mode,
        fuzzy,
        size: DEMO_SEARCH_PAGE_SIZE,
        from: DEMO_SEARCH_PAGE_SIZE * (page - 1),
    }, {
        widgetId: widget.id,
    });
}
