import { Ng1StateDeclaration } from '@uirouter/angularjs';

export const ROUTES_STATES_SNIPPET: ReadonlyArray<Ng1StateDeclaration> = [
    {
        name: 'snippet',
        parent: 'authorized:widget',
        url: '/snippet',
        component: 'snippetPage',
        resolve: {
            $title: () => {
                return 'Widget Demo';
            },
        }
    },
];
