import './SettingsCustomBoostForm.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { bind } from 'lodash-decorators/bind';
import { InnerFormProps, OuterFormProps, withFormDecorator } from 'src/decorators/withFormDecorator';
import { StaticField } from 'src/react/common/components/StaticField';
import { SelectboxField } from 'src/react/forms/components/SelectboxField';
import { HtmlButton } from 'src/react/common/components/Button';
import { FormFieldError } from 'src/react/forms/components/FormFieldError';
import { SettingsCustomField } from 'src/react/settings/types/SettingsCustomField';
import { SettingsCustomBoostFormData } from 'src/react/settings/types/SettingsCustomBoostFormData';
import { SettingsFormBoostField } from 'src/react/settings/components/SettingsFormBoostField';

type Props = {
    readonly formData: SettingsCustomBoostFormData;
    readonly customFields: ReadonlyArray<SettingsCustomField>;
    readonly onSubmit: (formData: SettingsCustomBoostFormData) => void;
};

class SettingsCustomBoostFormConnected extends React.Component<InnerFormProps<SettingsCustomBoostFormData, Props>> {
    public render(): JSX.Element {
        const { handleSubmit, formData } = this.props;

        return (
            <form className="settings-custom-boost-form" onSubmit={handleSubmit(this.handleFormSubmit)}>
                <div className="settings-form__rw">
                    <div className="settings-form__rwField">
                        <div className="settings-custom-boost-form__label">
                            <FormattedMessage id="settings_field_customBoostField"/>
                        </div>
                        <div className="settings-custom-boost-form__field">
                            <SelectboxField name="fieldName"
                                            options={this.getCustomFieldNames()}
                                            placeholder={CUSTOM_FIELD_NAME_PLACEHOLDER}
                                            renderOption={renderCustomFieldName}
                                            clearable={false}/>
                        </div>
                        <FormFieldError className="settings-custom-boost-form__error"
                                        field="fieldName"/>
                    </div>
                    <div className="settings-form__rwSlider">
                        <div className="settings-custom-boost-form__label">
                            &nbsp;
                        </div>
                        <div className="settings-custom-boost-form__field">
                            <SettingsFormBoostField name="fieldBoost"/>
                        </div>
                        <FormFieldError className="settings-custom-boost-form__error"
                                        field="fieldBoost"/>
                    </div>
                    <div className="settings-form__rwValue">
                        <div className="settings-custom-boost-form__label">
                            &nbsp;
                        </div>
                        <div className="settings-custom-boost-form__field">
                            <StaticField intent="info" className="settings-form__boost-value">
                                {formData.fieldBoost}
                            </StaticField>
                        </div>
                    </div>
                    <div className="settings-form__rwBtn">
                        <div className="settings-custom-boost-form__label">
                            &nbsp;
                        </div>
                        <div className="settings-custom-boost-form__field">
                            <HtmlButton block={true}
                                        intent="info"
                                        type="submit"
                                        disabled={this.isSubmitDisabled()}>
                                <FormattedMessage id="settings_customBoost_create"/>
                            </HtmlButton>
                        </div>
                    </div>
                </div>
            </form>
        );
    }

    private isSubmitDisabled(): boolean {
        const { pristine, submitFailed, invalid } = this.props;

        return pristine
            ? true
            : submitFailed
                ? invalid
                : false;
    }

    @bind
    private handleFormSubmit(formData: SettingsCustomBoostFormData): void {
        const { onSubmit } = this.props;
        onSubmit(formData);

        const { reset } = this.props;
        reset();
    }

    private getCustomFieldNames(): string[] {
        const { customFields } = this.props;
        return customFields.map((it) => it.fieldName);
    }
}

const CUSTOM_FIELD_NAME_PLACEHOLDER = <FormattedMessage id="settings_placeholder_customBoostField"/>;

function renderCustomFieldName(customFieldName: string): React.ReactNode {
    return customFieldName;
}

@withFormDecorator<SettingsCustomBoostFormData, Props>(SettingsCustomBoostFormConnected, {})
export class SettingsCustomBoostForm extends React.Component<OuterFormProps<SettingsCustomBoostFormData, Props>> {}
