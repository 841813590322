import { KeyPhraseSynonymsState } from 'src/react/keyPhraseSynonyms/state/KeyPhraseSynonymsState';
import {
    KEY_PHRASE_SYNONYMS_CHECK_ACTIONS,
    KeyPhraseSynonymsCheckActions
} from 'src/react/keyPhraseSynonyms/actions/KeyPhraseSynonymsCheckActions';

export function keyPhraseSynonymsCheckReducer(
    state: KeyPhraseSynonymsState,
    action: KeyPhraseSynonymsCheckActions,
): KeyPhraseSynonymsState {
    switch (action.type) {
        case KEY_PHRASE_SYNONYMS_CHECK_ACTIONS.SUCCESS:
            return { ...state, duplicates: action.data };

        default:
            return state;
    }
}
