import { StateService } from '@uirouter/core';
import { IComponentController } from 'angular';
import { Widget } from 'src/types/entities/Widget';
import { Organization } from 'src/types/entities/Organization';
import { SiteLanguages } from 'src/types/values/siteLanguage';
import { UserRole } from 'src/types/values/UserRole';

export class WidgetLayoutController implements IComponentController {
    public readonly widget!: Readonly<Widget>;
    public readonly organization!: Readonly<Organization>;
    public readonly language!: SiteLanguages;

    public static $inject: ReadonlyArray<string> = [
        '$state',
    ];
    public constructor(
        private $state: StateService,
    ) {
    }

    public canChangeSettings(): boolean {
        return ![UserRole.Viewer, UserRole.Editor].includes(this.organization.permission.userRole);
    }

    public isSettings(): boolean {
        return this.$state.$current.is('index.authorized.authorized:widget.settings');
    }
}
