import { createSelector } from 'reselect';
import { getSearchParams } from 'src/react/common/selectors/getSearchParams';
import { SettingsPageTab } from 'src/react/settings/types/SettingsPageTab';
import { isOneOf } from 'src/utils/isOneOf';

export const getActiveTab = createSelector([
    getSearchParams,
], (searchParams): SettingsPageTab => {
    const tab = searchParams.get('tab');
    return isOneOf(tab, AVAILABLE_TABS) ? tab : 'general';
});

const AVAILABLE_TABS: ReadonlyArray<SettingsPageTab> = [
    'general',
    'crawler',
    'custom',
    'integration',
    'statistics',
];
