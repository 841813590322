import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { NativeError } from 'src/errors/NativeError';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { keyPhraseSynonymsCheckSynonyms } from 'src/react/keyPhraseSynonyms/services/keyPhraseSynonymsCheckSynonyms';
import {
    KEY_PHRASE_SYNONYMS_CHECK_ACTIONS,
    KeyPhraseSynonymsCheckRequest,
} from 'src/react/keyPhraseSynonyms/actions/KeyPhraseSynonymsCheckActions';

export function* keyPhraseSynonymsCheckSaga({ data: synonyms }: KeyPhraseSynonymsCheckRequest): SagaIterator {
    try {
        yield put(KEY_PHRASE_SYNONYMS_CHECK_ACTIONS.pending());

        const duplicates: KeyPhrase[] = yield call(keyPhraseSynonymsCheckSynonyms, synonyms);

        yield put(KEY_PHRASE_SYNONYMS_CHECK_ACTIONS.success(duplicates));
    } catch (error) {
        yield put(KEY_PHRASE_SYNONYMS_CHECK_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
