import { getLanguage } from 'src/utils/getLanguage';
import { SiteLanguages } from 'src/types/values/siteLanguage';

export interface ILanguageService {
    get(): SiteLanguages;
}

export class LanguageService implements ILanguageService {
    public get(): SiteLanguages {
        return getLanguage(localStorage.getItem('locale'));
    }
}
