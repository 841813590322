import './ErrorBoundary.scss';
import * as React from 'react';
import { ErrorMessage } from 'src/react/common/components/ErrorMessage';
import { BaseError } from 'src/errors/BaseError';
import { NativeError } from 'src/errors/NativeError';

type Props = {

};
type State = {
    readonly error: BaseError | undefined;
    readonly errorInfo: React.ErrorInfo | undefined;
};

export class ErrorBoundary extends React.Component<Props, State> {
    public state: State = {
        error: undefined,
        errorInfo: undefined,
    };

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        this.setState(() => ({
            error: NativeError.wrapError(error),
            errorInfo,
        }));
    }
    public render(): React.ReactNode {
        const { error, errorInfo } = this.state;
        if (!error || !errorInfo) {
            return this.props.children;
        }

        return <ErrorMessage error={error} errorInfo={errorInfo}/>;
    }
}
