import * as yup from 'yup';
import { SettingsFormData } from 'src/react/settings/types/SettingsFormData';

export const SETTINGS_FORM_SCHEMA = yup.object<SettingsFormData>({
    widget: yup.object({
        name: yup
            .string()
            .nullable(false)
            .required('formError_required'),
        website: yup
            .string()
            .nullable(false)
            .required('formError_required'),
    }),

    urlBoosts: yup.array(yup.object({
        urlBoost: yup.number()
            .nullable(false)
            .min(0.1, 'formError_tooSmall')
            .max(10.0, 'formError_tooLarge'),
        urlPattern: yup.string()
            .nullable(false)
    })),
    fieldBoost: yup.object({
        titleBoost: yup.number().nullable(true),
        contentBoost: yup.number().nullable(true),
        descriptionBoost: yup.number().nullable(true),
    }),
    customBoosts: yup.array(yup.object({
        fieldName: yup.string()
            .nullable(false),
        fieldBoost: yup.number()
            .nullable(false)
            .min(0, 'formError_tooSmall')
            .max(10, 'formError_tooLarge'),
    })),
    includeToFacets: yup.array(yup.string().nullable(false)),
    includeToRecommendations: yup.array(yup.string().nullable(false)),
    customFields: yup.array(yup.object({
        fieldName: yup.string()
            .nullable(false),
        fieldType: yup.mixed()
            .nullable(false),
    })),

    autocomplete: yup.object({
        useItemTitle: yup.boolean(),
        useKeyPhrase: yup.boolean(),
    }),
    searchParams: yup.object({
        enableFuzziness: yup.boolean(),
        enablePrefixMatch: yup.boolean(),
    }),
    integration: yup.object({
        htmlTemplates: yup.object({
            layoutTemplate: yup.string().nullable(false),
            resultTemplate: yup.string().nullable(false),
            noResultTemplate: yup.string().nullable(false),
            headerTemplate: yup.string().nullable(false),
            footerTemplate: yup.string().nullable(false),
        }),
        feedbackSettings: yup.object({
            general: yup.object({
                email: yup.string().nullable(false),
                falseButton: yup.string().nullable(false),
                falseComment: yup.string().nullable(false),
                submitButton: yup.string().nullable(false),
                titleQuestion: yup.string().nullable(false),
                trueButton: yup.string().nullable(false),
                trueComment: yup.string().nullable(false),
                thankYouText: yup.string().nullable(false),
            }),
            noResults: yup.object({
                submitButton: yup.string().nullable(false),
                commentText: yup.string().nullable(false),
                titleQuestion: yup.string().nullable(false),
                thankYouText: yup.string().nullable(false),
                email: yup.string().nullable(false),
            }),
        }),
    }),
    crawler: yup.object({
        cronjobEnabled: yup.boolean(),
        ignoreRobotsTxt: yup.boolean(),

        defaultImage: yup.string()
            .url('formError_invalidUrl'),
        exclude: yup.string(),
        whiteList: yup.string(),
        pages: yup.string(),
        domains: yup.string(),

        limit: yup.number()
            .nullable(true)
            .integer('formError_invalid')
            .min(1, 'formError_tooSmall'),
        depth: yup.number()
            .nullable(true)
            .integer('formError_invalid')
            .min(1, 'formError_tooSmall')
            .max(20, 'formError_tooLarge'),
        parserConfig: yup.string().nullable(true),
    }),
    statistics: yup.object({
        excludedSearchTerms: yup.string(),
    }),
});
