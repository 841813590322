import { module } from 'angular';
import { WIDGET_POPUP_COMPONENT } from 'src/modules/widgetPopup/WidgetPopupComponent';

export const WIDGET_POPUP_MODULE = module('widgetPopup', [])
    /**
     * @ngdoc directive
     * @name widgetPopup
     * @restrict E
     *
     * @param {expression} resolve
     * @param {expression} modalInstance
     */
    .component(
        'widgetPopup',
        WIDGET_POPUP_COMPONENT,
    );
