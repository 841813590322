import { SagaIterator } from 'redux-saga';
import { all, call, select } from 'redux-saga/effects';
import { Item } from 'src/types/entities/Item';
import { ItemForm } from 'src/types/dto/ItemForm';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { Widget } from 'src/types/entities/Widget';
import { itemKeyPhraseList } from 'src/react/itemForm/services/itemKeyPhraseList';
import { itemKeyPhraseAttach } from 'src/react/itemForm/services/itemKeyPhraseAttach';
import { itemKeyPhraseDetach } from 'src/react/itemForm/services/itemKeyPhraseDetach';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { getItemEndpoint } from 'src/react/api/selectors/getEndpoint';
import { IItemEndpoint } from 'src/react/api/endpoints/ItemEndpoint';

export function* itemUpdate(
    item: Item,
    formData: ItemForm,
    keyPhraseTexts: ReadonlyArray<string>,
): SagaIterator {
    const widget: Widget = yield select(getSelectedWidget);
    const itemEndpoint: IItemEndpoint = yield select(getItemEndpoint);

    const result: Item = yield call({
        fn: itemEndpoint.update,
        context: itemEndpoint,
    }, item.id, formData, { widgetId: widget.id });

    const keyPhrases: KeyPhrase[] = yield call(itemKeyPhraseList, item);

    const keyPhrasesToPin = keyPhraseTexts
        .filter((keyPhraseText) => !keyPhrases.some((it) => it.text === keyPhraseText));
    const keyPhrasesToUnpin = keyPhrases
        .filter((keyPhrase) => !keyPhraseTexts.some((it) => it === keyPhrase.text));

    yield all([
        ...keyPhrasesToPin.map((keyPhraseText) => call(itemKeyPhraseAttach, result, keyPhraseText)),
        ...keyPhrasesToUnpin.map((keyPhrase) => call(itemKeyPhraseDetach, result, keyPhrase)),
    ]);

    return result;
}
