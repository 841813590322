import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { NativeError } from 'src/errors/NativeError';
import { Item } from 'src/types/entities/Item';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { SearchItem } from 'src/types/dto/SearchItem';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { DEMO_SEARCH_ACTIONS } from 'src/react/demoPage/actions/DemoSearchActions';
import { DEMO_PAGE_MIN_QUERY_LENGTH } from 'src/react/demoPage/constants/DemoPageMinQuery';
import { DemoPageParams } from 'src/react/demoPage/state/DemoPageParams';
import { demoSearchQuery } from 'src/react/demoPage/services/demoSearchQuery';
import { keyPhraseGet } from 'src/react/demoPage/services/keyPhraseGet';
import { keyPhraseItems } from 'src/react/demoPage/services/keyPhraseItems';
import { demoUpdateQueryString } from 'src/react/demoPage/services/demoUpdateQueryString';
import { keyPhraseGroupsGet } from 'src/react/demoPage/services/keyPhraseGroupsGet';
import { KeyPhraseGroup } from 'src/types/entities/KeyPhraseGroup';

export function* demoSearchSaga(
    { data: params }: ActionRequest<DemoPageParams>,
): SagaIterator {
    try {
        yield call(demoUpdateQueryString, params);

        if (params.query.length < DEMO_PAGE_MIN_QUERY_LENGTH) {
            yield put(DEMO_SEARCH_ACTIONS.success(null));
            return;
        }

        yield put(DEMO_SEARCH_ACTIONS.pending());

        const foundItems: ListWithTotal<SearchItem> = yield call(demoSearchQuery, params);
        const keyPhrase: KeyPhrase | null = yield call(keyPhraseGet, params.query);
        const synonyms = keyPhrase
            ? keyPhrase.synonyms
            : [];

        const pinnedItems: Item[] = keyPhrase
            ? yield call(keyPhraseItems, keyPhrase)
            : [];

        const groups: KeyPhraseGroup[] = keyPhrase
            ? yield call(keyPhraseGroupsGet, [keyPhrase.id])
            : [];

        yield put(DEMO_SEARCH_ACTIONS.success({ keyPhrase, synonyms, pinnedItems, foundItems, groups }));
    } catch (error) {
        yield put(DEMO_SEARCH_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
