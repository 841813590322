import { ManualCrawlerState } from 'src/react/manualCrawler/state/ManualCrawlerState';
import {
    MANUAL_CRAWLER_STATE_ACTIONS,
    ManualCrawlerStateActions,
} from 'src/react/manualCrawler/actions/ManualCrawlerStateActions';

export function manualCrawlerStateReducer(
    state: ManualCrawlerState,
    action: ManualCrawlerStateActions,
): ManualCrawlerState {
    switch (action.type) {
        case MANUAL_CRAWLER_STATE_ACTIONS.SUCCESS:
            return {
                ...state,
                progress: action.data.progress,
                fetchedUrls: action.data.fetchedUrls,
            };

        default:
            return state;
    }
}
