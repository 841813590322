import { module } from 'angular';
import { i18nFilter } from 'src/modules/core/filters/I18nFilter';
import { searchInputDebounceFilter } from 'src/modules/core/filters/SearchInputDebounceFilter';
import { logoFromNameFilter } from 'src/modules/core/filters/LogoFromNameFilter';

export const CORE_FILTERS_MODULE = module('coreFiltersModule', [])
    .filter(
        'i18n',
        i18nFilter,
    )
    .filter(
        'searchInputDebounce',
        searchInputDebounceFilter,
    )
    .filter(
        'logoFromName',
        logoFromNameFilter,
    );
