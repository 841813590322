export const CUSTOM_MESSAGE_FORMATS = {
    number: {
        integer: {
            useGrouping: false,
            minimumIntegerDigits: 1,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        },
    },
};
