import './DemoPageNoResults.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Widget } from 'src/types/entities/Widget';

type Props = {
    readonly query: string;
    readonly widget: Widget;
};

export function DemoPageNoResults({ query }: Props): JSX.Element {
    return (
        <div className="demo-page-no-results">
            <FormattedMessage id="searchDemo_noResultsFound" values={{ query }}/>
        </div>
    );
}
