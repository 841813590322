type Constructor<TInstance, TArgs extends any[]> = {
    new (...args: TArgs): TInstance;
};

export function instantiate<TInstance, TArgs extends any[]>(
    constructor: Constructor<TInstance, TArgs>,
    ...args: TArgs
): TInstance {
    return new constructor(...args);
}
