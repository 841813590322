import * as React from 'react';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { Widget } from 'src/types/entities/Widget';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { Organization } from 'src/types/entities/Organization';
import { SelectedWidget } from 'src/react/selectedWidget/components/SelectedWidget';
import { KeyPhraseSynonyms } from 'src/react/keyPhraseSynonyms/components/KeyPhraseSynonyms';

export type KeyPhraseSynonymsBridgeProps = {
    readonly modalInstance: IModalInstanceService;
    readonly resolve: {
        readonly widget: Widget;
        readonly organization: Organization;
        readonly keyPhrase: KeyPhrase;
    };
};

export default function ({
                             resolve: { widget, organization, keyPhrase },
                             modalInstance,
                         }: KeyPhraseSynonymsBridgeProps): JSX.Element {
    return (
        <SelectedWidget widget={widget}
                        organization={organization}
                        customFields={[]}
                        statisticsFilter={null}>
            <KeyPhraseSynonyms title={keyPhrase.text}
                               synonyms={keyPhrase.synonyms}
                               keyPhrase={keyPhrase}
                               onSubmit={modalInstance.close}
                               onCancel={modalInstance.dismiss}/>
        </SelectedWidget>
    );
}
