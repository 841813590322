import './DemoPagePinnedList.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SortableContainer, SortableElement, SortableHandle, SortEnd, arrayMove } from 'react-sortable-hoc';
import { bind } from 'lodash-decorators/bind';
import { classList } from 'src/utils/classList';
import { Item } from 'src/types/entities/Item';
import { Widget } from 'src/types/entities/Widget';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { DemoPagePinnedItem } from 'src/react/demoPage/components/DemoPagePinnedItem';

type Props = {
    readonly items: ReadonlyArray<Item>;
    readonly widget: Widget;
    readonly onEditItem: (item: Item) => void;
    readonly onUnpinItem: (item: Item) => void;
    readonly onChangeSort: (items: ReadonlyArray<Item>) => void;
};
type State = {
    readonly isMoving: boolean;
};

export class DemoPagePinnedList extends React.Component<Props> {
    public state: State = {
        isMoving: false,
    };

    public render(): React.ReactNode {
        const { items } = this.props;

        const { isMoving } = this.state;
        const className = classList('demo-page-pinned-list', {
            'demo-page-pinned-list--moving': isMoving,
        });

        return (
            <div className={className}>
                {items.length ? this.renderPinnedItems() : this.renderEmptyList()}
            </div>
        );
    }

    private renderPinnedItems(): JSX.Element {
        const { items, widget, onEditItem, onUnpinItem } = this.props;

        return (
            <SortableList axis="y"
                          lockAxis="y"
                          items={items}
                          widget={widget}
                          onSortEnd={this.handleSortEnd}
                          onSortStart={this.handleSortStart}
                          useDragHandle={true}
                          onEditItem={onEditItem}
                          onUnpinItem={onUnpinItem}/>
        );
    }

    private renderEmptyList(): JSX.Element {
        return (
            <div className="demo-page-pinned-list__empty">
                <FormattedMessage id="searchDemo_pinnedEmptyList"/>
            </div>
        );
    }

    @bind
    private handleSortEnd({ oldIndex, newIndex }: SortEnd): void {
        const { items, onChangeSort } = this.props;
        onChangeSort(arrayMove([...items], oldIndex, newIndex));
        this.setState({ isMoving: false });
    }

    @bind
    private handleSortStart(): void {
        this.setState({ isMoving: true });
    }
}

const DragHandle = SortableHandle(() => {
    return (
        <span className="demo-page-pinned-list__handle">
            <i className="fa fa-arrows"/>
        </span>
    );
});

type SortableItemProps = {
    readonly item: Item;
    readonly widget: Widget;
    readonly position: UnsignedInteger;
    readonly onEditItem: () => void;
    readonly onUnpinItem: () => void;
};
const SortableItem = SortableElement(({ item, widget, position, onEditItem, onUnpinItem }: SortableItemProps) => {
    return (
        <div className="demo-page-pinned-list__item">
            <DemoPagePinnedItem item={item}
                                widget={widget}
                                dragHandle={<DragHandle/>}
                                position={position}
                                onEditItem={onEditItem}
                                onUnpinItem={onUnpinItem}/>
        </div>
    );
});

type SortableListProps = {
    readonly items: ReadonlyArray<Item>;
    readonly widget: Widget;
    readonly onEditItem: (item: Item) => void;
    readonly onUnpinItem: (item: Item) => void;
};
const SortableList = SortableContainer(({ items, widget, onUnpinItem, onEditItem }: SortableListProps) => {
    return (
        <div className="demo-page-pinned-list__list">
            {items.map((item, index) => (
                <SortableItem key={item.id}
                              index={index}
                              item={item}
                              widget={widget}
                              position={index + 1}
                              onEditItem={onEditItem.bind(null, item)}
                              onUnpinItem={onUnpinItem.bind(null, item)}/>
            ))}
        </div>
    );
});
