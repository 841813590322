import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { NativeError } from 'src/errors/NativeError';
import { SelectedWidgetData } from 'src/react/selectedWidget/state/SelectedWidgetData';
import { SELECTED_WIDGET_DATA_ACTIONS } from 'src/react/selectedWidget/actions/SelectedWidgetDataActions';

export function* selectedWidgetDataSaga(
    { data }: ActionRequest<SelectedWidgetData | null>,
): SagaIterator {
    try {
        yield put(SELECTED_WIDGET_DATA_ACTIONS.pending());
        yield put(SELECTED_WIDGET_DATA_ACTIONS.success(data));
    } catch (error) {
        yield put(SELECTED_WIDGET_DATA_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
