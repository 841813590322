import { SagaIterator } from 'redux-saga';
import { all, takeLatest } from 'redux-saga/effects';
import { cancelSagaOn } from 'src/utils/cancelSagaOn';

import {
    statisticsResetSaga
} from 'src/react/statistics/sagas/statisticsResetSaga';

import {
    STATISTICS_RESET_ACTIONS
} from 'src/react/statistics/actions/StatisticsResetActions';
import {
    STATISTICS_COMBINED_UPDATE_ACTIONS
} from 'src/react/statistics/actions/StatisticsCombinedUpdateActions';
import {
    STATISTICS_SUCCESSFUL_UPDATE_ACTIONS
} from 'src/react/statistics/actions/StatisticsSuccessfulUpdateActions';
import {
    STATISTICS_UNSUCCESSFUL_UPDATE_ACTIONS
} from 'src/react/statistics/actions/StatisticsUnsuccessfulUpdateActions';
import {
    STATISTICS_LAST_UPDATE_ACTIONS
} from 'src/react/statistics/actions/StatisticsLastUpdateActions';

import { statisticsCombinedUpdateSaga } from 'src/react/statistics/sagas/statisticsCombinedUpdateSaga';
import { statisticsSuccessfulUpdateSaga } from 'src/react/statistics/sagas/statisticsSuccessfulUpdateSaga';
import { statisticsUnsuccessfulUpdateSaga } from 'src/react/statistics/sagas/statisticsUnsuccessfulUpdateSaga';
import { statisticsLastUpdateSaga } from 'src/react/statistics/sagas/statisticsLastUpdateSaga';

export function* statisticsPageSaga(): SagaIterator {
    yield all([
        takeLatest(STATISTICS_COMBINED_UPDATE_ACTIONS.REQUEST, cancelSagaOn(statisticsCombinedUpdateSaga, [
            STATISTICS_RESET_ACTIONS.REQUEST,
        ])),

        takeLatest(STATISTICS_SUCCESSFUL_UPDATE_ACTIONS.REQUEST, cancelSagaOn(statisticsSuccessfulUpdateSaga, [
            STATISTICS_RESET_ACTIONS.REQUEST,
        ])),

        takeLatest(STATISTICS_UNSUCCESSFUL_UPDATE_ACTIONS.REQUEST, cancelSagaOn(statisticsUnsuccessfulUpdateSaga, [
            STATISTICS_RESET_ACTIONS.REQUEST,
        ])),

        takeLatest(STATISTICS_LAST_UPDATE_ACTIONS.REQUEST, cancelSagaOn(statisticsLastUpdateSaga, [
            STATISTICS_RESET_ACTIONS.REQUEST,
        ])),

        takeLatest(STATISTICS_RESET_ACTIONS.REQUEST, statisticsResetSaga),
    ]);
}
