import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { cancelSagaOn } from 'src/utils/cancelSagaOn';
import { keyPhraseSynonymsResetSaga } from 'src/react/keyPhraseSynonyms/sagas/keyPhraseSynonymsResetSaga';
import { KEY_PHRASE_SYNONYMS_RESET_ACTIONS } from 'src/react/keyPhraseSynonyms/actions/KeyPhraseSynonymsResetActions';
import { keyPhraseSynonymsCheckSaga } from 'src/react/keyPhraseSynonyms/sagas/keyPhraseSynonymsCheckSaga';
import { KEY_PHRASE_SYNONYMS_CHECK_ACTIONS } from 'src/react/keyPhraseSynonyms/actions/KeyPhraseSynonymsCheckActions';

export function* keyPhraseSynonymsSaga(): SagaIterator {
    yield takeLatest(KEY_PHRASE_SYNONYMS_RESET_ACTIONS.REQUEST, keyPhraseSynonymsResetSaga);
    yield takeLatest(KEY_PHRASE_SYNONYMS_CHECK_ACTIONS.REQUEST, cancelSagaOn(keyPhraseSynonymsCheckSaga, [
        KEY_PHRASE_SYNONYMS_RESET_ACTIONS.REQUEST,
    ]));
}
