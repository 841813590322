export function parseNumberValue(value: unknown): number | null {
    const asNumber = typeof value === 'number'
        ? value
        : typeof value === 'string'
            ? parseFloat(value)
            : NaN;

    return isFinite(asNumber)
        ? asNumber
        : null;
}
