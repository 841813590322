import { AnyAction } from 'redux';
import { assertNotNull } from 'src/utils/assertion';
import { OrganizationListState } from 'src/react/organization/state/OrganizationListState';
import {
    ORGANIZATION_LIST_LEAVE_CANCEL_ACTIONS,
    ORGANIZATION_LIST_LEAVE_OPEN_ACTIONS,
    ORGANIZATION_LIST_LEAVE_SUBMIT_ACTIONS,
} from 'src/react/organization/actions/OrganizationListLeaveActions';

export function organizationListLeaveReducer(
    state: OrganizationListState,
    action: AnyAction,
): OrganizationListState {
    if (ORGANIZATION_LIST_LEAVE_OPEN_ACTIONS.isSuccess(action)) {
        return {
            ...state,
            modalState: {
                type: 'leave',
                organization: action.data,
            },
        };
    }

    if (ORGANIZATION_LIST_LEAVE_CANCEL_ACTIONS.isSuccess(action)) {
        return {
            ...state,
            modalState: {
                type: 'none',
            },
        };
    }

    if (ORGANIZATION_LIST_LEAVE_SUBMIT_ACTIONS.isSuccess(action)) {
        return {
            ...state,
            modalState: {
                type: 'none',
            },
            organizations: assertNotNull(
                state.organizations,
                'Organization list is not initialized yet',
                { state, action },
            ).filter((other) => other.organization.id !== action.data.organization.id),
        };
    }

    return state;
}
