import { ComponentClass, ComponentType } from 'react';
import { reduxForm, ConfigProps, InjectedFormProps } from 'redux-form';
import { FieldErrors } from 'src/types/values/FieldError';
// @ts-ignore
import defaultShouldAsyncValidate from 'redux-form/es/defaultShouldAsyncValidate';

export type InnerFormProps<TFormData, TOwnProps> =
    & TOwnProps
    & InjectedFormProps<TFormData, TOwnProps, FieldErrors>;

export type OuterFormProps<TFormData, TOwnProps> =
    & TOwnProps
    & ConfigProps<TFormData, TOwnProps, FieldErrors>;

export function withFormDecorator<TFormData extends {}, TOwnProps extends {}>(
    original: ComponentType<InnerFormProps<TFormData, TOwnProps>>,
    config: Partial<ConfigProps<TFormData, TOwnProps, FieldErrors>>,
): (
    target: ComponentClass<OuterFormProps<TFormData, TOwnProps>>
) => ComponentClass<OuterFormProps<TFormData, TOwnProps>> {
    return () => reduxForm<TFormData, TOwnProps, FieldErrors>({
        ...config,
        // workaround to run async validation on paste to empty field
        // from https://github.com/redux-form/redux-form/issues/3944
        shouldAsyncValidate: (params) => {
            return defaultShouldAsyncValidate({ ...params, syncValidationPasses: true });
        }
    })(original);
}
