import * as yup from 'yup';
import { OrganizationForm } from 'src/types/dto/OrganizationForm';

export const ORGANIZATION_FORM_SCHEMA = yup.object<OrganizationForm>({
    name: yup
        .string()
        .trim()
        .required('formError_required')
        .max(200, 'formError_tooLong'),
});
