export const UI_MODAL_OPEN = 'UI_MODAL_OPEN';
export const UI_MODAL_CLOSE = 'UI_MODAL_CLOSE';

export type UiModalAction =
    | UiModalOpen
    | UiModalClose;

export interface UiModalOpen {
    readonly type: typeof UI_MODAL_OPEN;
    readonly modalId: string;
}
export interface UiModalClose {
    readonly type: typeof UI_MODAL_CLOSE;
    readonly modalId: string;
}

export function uiModalOpen(modalId: string): UiModalOpen {
    return {
        type: UI_MODAL_OPEN,
        modalId,
    };
}
export function uiModalClose(modalId: string): UiModalClose {
    return {
        type: UI_MODAL_CLOSE,
        modalId,
    };
}
