import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { assertOneOf } from 'src/utils/assertion';
import { Item } from 'src/types/entities/Item';
import { ItemState } from 'src/types/values/ItemState';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { CustomField } from 'src/types/entities/CustomField';
import { getCustomFields } from 'src/react/selectedWidget/selectors/getCustomFields';
import { ItemFormValue } from 'src/react/itemForm/types/ItemFormValue';
import { itemKeyPhraseList } from 'src/react/itemForm/services/itemKeyPhraseList';

export function* itemFormMapToApp(item: Item | null): SagaIterator {
    if (!item) {
        return createEmptyForm(
            yield select(getCustomFields),
        );
    }

    return createItemForm(
        item,
        yield call(itemKeyPhraseList, item),
        yield select(getCustomFields)
    );
}

function createEmptyForm(customFields: ReadonlyArray<CustomField>): ItemFormValue {
    return {
        state: ItemState.Active,
        title: '',
        content: '',
        description: '',
        url: '',
        image: null,
        linkText: '',
        keyPhrases: [],
        customFields: customFields.reduce((result, customField) => ({
            ...result,
            [customField.id]: null,
        }), {}),
    };
}

function createItemForm(
    item: Item,
    keyPhrases: ReadonlyArray<KeyPhrase>,
    customFields: ReadonlyArray<CustomField>,
): ItemFormValue {
    return {
        state: assertOneOf(item.state, [ItemState.Active, ItemState.Inactive]),
        title: item.title,
        content: item.content,
        description: item.description,
        url: item.url,
        image: item.image === '' ? null : item.image,
        linkText: item.linkText,
        keyPhrases: keyPhrases.map(({ text }) => text),
        customFields: customFields.reduce((result, customField) => ({
            ...result,
            [customField.id]: item.customFields[customField.id] !== undefined
                ? item.customFields[customField.id]
                : null,
        }), {}),
    };
}
