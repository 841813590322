import { bind } from 'lodash-decorators/bind';
import { StateService } from '@uirouter/core';
import { IComponentController, IPromise, IQService } from 'angular';
import { BaseError } from 'src/errors/BaseError';
import { Widget } from 'src/types/entities/Widget';
import { Organization } from 'src/types/entities/Organization';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { KeyPhraseState } from 'src/types/values/KeyPhraseState';
import { ISafePromiseWrapper } from 'src/modules/core/utils/SafePromiseWrapper';
import { IKeyPhraseService } from 'src/modules/api/KeyPhraseService';
import { KeyPhrasePageParams } from 'src/modules/keyPhrase/KeyPhrasePageParams';
import { IKeyPhraseDialogService } from 'src/modules/keyPhrase/KeyPhraseDialogService';
import { KeyPhraseGroup } from 'src/types/entities/KeyPhraseGroup';
import { IKeyPhraseGroupService } from 'src/modules/api/KeyPhraseGroupService';
import { IKeyPhraseMappingService } from 'src/modules/keyPhrase/KeyPhraseMappingService';

type ApiData = {
    total: UnsignedInteger;
    data: KeyPhrase[];
    activeCount: UnsignedInteger;
    inactiveCount: UnsignedInteger;
};

export class KeyPhrasePageController implements IComponentController {
    public readonly pageSize = 10;

    public viewerMode!: Readonly<boolean>;
    public widget!: Readonly<Widget>;
    public organization!: Readonly<Organization>;
    public params!: Readonly<KeyPhrasePageParams>;
    public groups!: ReadonlyArray<KeyPhraseGroup>;

    public loading = false;
    public error: BaseError | undefined;

    public data: ReadonlyArray<KeyPhrase> = [];
    public total = 0;

    public activeCount: UnsignedInteger | undefined;
    public inactiveCount: UnsignedInteger | undefined;

    private safePerformRequest = this.safePromiseWrapper
        .wrapPromiseFunction(this.performSearchRequest);

    public static $inject = [
        '$q',
        '$state',
        'safePromiseWrapper',
        'keyPhraseService',
        'keyPhraseGroupService',
        'keyPhraseDialogService',
        'KeyPhraseMappingService',
    ];
    public constructor(
        private $q: IQService,
        private $stateService: StateService,
        private safePromiseWrapper: ISafePromiseWrapper,
        private keyPhraseService: IKeyPhraseService,
        private keyPhraseGroupService: IKeyPhraseGroupService,
        private keyPhraseDialogService: IKeyPhraseDialogService,
        private keyPhraseMappingService: IKeyPhraseMappingService,
    ) {
    }

    public $onInit(): void {
        this.updateSearchResult();
    }

    public toggleKeyPhrase(keyPhrase: Readonly<KeyPhrase>): void {
        this.loading = true;

        this.keyPhraseService.update(this.widget, keyPhrase, {
            text: keyPhrase.text,
            itemIds: keyPhrase.itemIds,
            synonyms: keyPhrase.synonyms,
            state: this.isKeyPhraseActive(keyPhrase)
                ? KeyPhraseState.Inactive
                : KeyPhraseState.Active,
        }).then(() => this.updateSearchResult(), () => {});
    }

    public deleteKeyPhrase(keyPhrase: Readonly<KeyPhrase>): void {
        this.keyPhraseDialogService.deleteKeyPhrase(this.widget, this.organization, keyPhrase).then(() =>
            this.keyPhraseService.delete(this.widget, keyPhrase))
              .then(() => this.updateSearchResult(), () => {});
    }

    public applyFilter(filter: Partial<KeyPhrasePageParams>): void {
        this.params = { ...this.params, ...filter };
        this.$stateService.go('keyPhrases', this.params);
        this.updateSearchResult();
    }
    public toggleSort(field: string): void {
        const { sort } = this.params;
        this.applyFilter({
            page: 1,
            sort: sort === field
                ? `-${field}`
                : sort === `-${field}`
                    ? ''
                    : field,
        });
    }

    public isSortActive(field: string): boolean {
        return (
            this.params.sort === field ||
            this.params.sort === `-${field}`
        );
    }
    public isKeyPhraseActive(keyPhrase: Readonly<KeyPhrase>): boolean {
        return keyPhrase.state === KeyPhraseState.Active;
    }

    public createKeyword(): void {
        this.keyPhraseDialogService.createPhrase(this.widget);
    }

    public manageSynonyms(keyPhrase: KeyPhrase): void {
        this.keyPhraseDialogService.manageSynonyms(this.widget, this.organization, keyPhrase).then((synonyms) => {
            return this.keyPhraseService.update(this.widget, keyPhrase, {
                synonyms,
                text: keyPhrase.text,
                itemIds: keyPhrase.itemIds,
                state: keyPhrase.state,
            });
        }).then(() => this.updateSearchResult(), () => {});
    }

    public manageGroup(): void {
        const keyPhraseGroup = this.getCurrentGroup();
        this.keyPhraseDialogService
            .manageGroup(
                this.widget,
                keyPhraseGroup,
                )
            .then((form) => this.keyPhraseMappingService
                .getIds(this.widget, form.keyphrases)
                .then((keyPhrasesIds) => ({ keyPhrasesIds, text: form.text }))
            )
            .then((form) => {
                if (keyPhraseGroup && form.keyPhrasesIds.length === 0) {
                    return this.keyPhraseGroupService.delete(this.widget, keyPhraseGroup)
                        .then(() =>  '');
                }
                return keyPhraseGroup === null
                    ? this.keyPhraseGroupService.create(this.widget, form).then((item) => item.id)
                    : this.keyPhraseGroupService.update(this.widget, keyPhraseGroup, form).then((item) => item.id);
            })
            .then((group) => {
                this.updateGroups();
                this.applyFilter({ group });
            }, () => {});
    }

    private getCurrentGroup(): KeyPhraseGroup | null {
        const keyPhraseGroup = this.groups.find((item) => item.id === this.params.group);
        return keyPhraseGroup ? keyPhraseGroup : null;
    }

    private updateGroups(): void {
        this.keyPhraseGroupService.list(this.widget, {
                pageSize: 1000,
                page: 0,
                query: '',
                sort: [],
                exact: false,
                id: []
            })
            .then((result) => {
                this.groups = result.data;
            });
    }

    @bind
    private updateSearchResult(): void {
        this.loading = true;

        this.safePerformRequest()
            .then(({ data, total, activeCount, inactiveCount }) => {
                this.data = data;
                this.total = total;
                this.activeCount = activeCount;
                this.inactiveCount = inactiveCount;
            })
            .then(() => {
                this.loading = false;
                this.error = undefined;
            }, (error) => {
                this.loading = false;
                this.error = error;
            });
    }

    @bind
    private performSearchRequest(): IPromise<ApiData> {
        return this.$q.all([
            this.keyPhraseService.list(this.widget, {
                query: this.params.query,
                state: [this.params.state],
                exact: false,
                itemId: [],
                sort: this.params.sort ? [this.params.sort] : [],
                page: Math.max(0, this.params.page - 1),
                pageSize: this.pageSize,
                groups: this.params.group ? [this.params.group] : [],
            }),
            this.keyPhraseService.list(this.widget, {
                query: this.params.query,
                state: [KeyPhraseState.Active],
                exact: false,
                itemId: [],
                sort: [],
                page: 0,
                pageSize: 0,
                groups: this.params.group ? [this.params.group] : [],
            }),
            this.keyPhraseService.list(this.widget, {
                query: this.params.query,
                state: [KeyPhraseState.Inactive],
                exact: false,
                itemId: [],
                sort: [],
                page: 0,
                pageSize: 0,
                groups: this.params.group ? [this.params.group] : [],
            }),
        ]).then(([{ data, total }, { total: activeCount }, { total: inactiveCount }]) => ({
            data,
            total,
            activeCount,
            inactiveCount,
        }));
    }
}
