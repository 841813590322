import './ManualCrawlerLog.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
    readonly fetchedUrls: ReadonlyArray<string>;
};

export function ManualCrawlerLog({ fetchedUrls }: Props): JSX.Element {
    // TODO: check duplicates on the crawler side!
    const uniqueUrls = fetchedUrls.filter((url, index, array) => array.indexOf(url) === index);

    return (
        <ul className="manual-crawler-log">
            {uniqueUrls.map((url) => (
                <li key={url} className="manual-crawler-log__item">
                    <FormattedMessage id="manualCrawler_logItem" values={{ url }}/>
                </li>
            ))}
        </ul>
    );
}
