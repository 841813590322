import './OrganizationTeamEditModal.scss';
import * as React from 'react';
import { FormErrors } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { bind } from 'lodash-decorators/bind';
import { memoize } from 'lodash-decorators/memoize';
import { flattenErrors } from 'src/utils/getFormErrors';
import { FieldErrors } from 'src/types/values/FieldError';
import { UserRole } from 'src/types/values/UserRole';
import { Permission } from 'src/types/entities/Permission';
import { ModalBody, ModalClose, ModalFooter, ModalHeader } from 'src/react/common/components/Modal';
import { HtmlButton } from 'src/react/common/components/Button';
import { TextboxField } from 'src/react/forms/components/TextboxField';
import { SelectboxField } from 'src/react/forms/components/SelectboxField';
import { FormFieldError } from 'src/react/forms/components/FormFieldError';
import { OrganizationListItem } from 'src/react/organization/state/OrganizationListItem';
import { PermissionFormData } from 'src/react/organization/types/PermissionFormData';
import { permissionFormValidator } from 'src/react/organization/validators/permissionFormValidator';
import { PermissionDeleteForm, PermissionDeleteProps } from 'src/react/organization/components/PermissionDeleteForm';
import { PermissionCreateForm, PermissionCreateProps } from 'src/react/organization/components/PermissionCreateForm';

type Props = {
    readonly organization: OrganizationListItem;
    readonly onClose: () => void;
    readonly onCreate: () => void;
    readonly onDelete: (permission: Permission) => void;
};

export class OrganizationTeamEditModal extends React.Component<Props> {
    private userRoles: ReadonlyArray<UserRole> = [
        UserRole.Viewer,
        UserRole.Editor,
        UserRole.Manager,
        UserRole.Admin,
    ];

    public render(): JSX.Element {
        const { organization, onClose } = this.props;

        return (
            <div className="organization-team-edit-modal">
                <ModalHeader className="organization-team-edit-modal__header">
                    <ModalClose className="organization-team-edit-modal__close"
                                onClick={onClose}/>
                    <div className="organization-team-edit-modal__title">
                        <FormattedMessage id="organizationTeam_title"/>
                    </div>
                </ModalHeader>
                <ModalBody className="organization-team-edit-modal__content">
                    <div className="organization-team-edit-modal__item">
                        <PermissionCreateForm form="permission-create"
                                              initialValues={{ userRole: null, userEmail: '' }}
                                              validate={this.validateForm}
                                              onSubmit={this.handleCreate}>
                            {this.renderCreateForm}
                        </PermissionCreateForm>
                    </div>
                    {organization.permissions.map((permission) => (
                        <div key={permission.id} className="organization-team-edit-modal__item">
                            <PermissionDeleteForm form={`permission-delete--${permission.id}`}
                                                  permission={permission}
                                                  onSubmit={this.getDeleteHandler(permission)}>
                                {this.renderPermission}
                            </PermissionDeleteForm>
                        </div>
                    ))}
                </ModalBody>
                <ModalFooter className="organization-team-edit-modal__footer">
                    <div className="organization-team-edit-modal__action">
                        <HtmlButton block={false}
                                    type="button"
                                    intent="none"
                                    onClick={onClose}>
                            <FormattedMessage id="organizationTeam_closeButton"/>
                        </HtmlButton>
                    </div>
                </ModalFooter>
            </div>
        );
    }

    @bind()
    private renderPermission({ permission, isPending }: PermissionDeleteProps): JSX.Element {
        const { organization: { organization } } = this.props;
        const isOwnPermission = organization.permission.id === permission.id;

        return (
            <div className="row">
                <div className="col-md-4">
                    <FormattedMessage id="organizationList_userRole"
                                      values={{ userRole: permission.userRole }}/>
                </div>
                <div className="col-md-6">
                    <div className="organization-team-edit-modal__email">
                        <a href={`mailto:${permission.userEmail}`}>{permission.userEmail}</a>
                    </div>
                    <div className="organization-team-edit-modal__name">
                        {permission.userFullName}
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="organization-team-edit-modal__action">
                        <HtmlButton className="organization-team-edit-modal__delete"
                                    disabled={isOwnPermission || isPending}
                                    type="submit"
                                    intent="none"
                                    shape="square"
                                    block={false}>&times;</HtmlButton>
                    </div>
                </div>
            </div>
        );
    }

    @bind()
    private renderCreateForm({ isPending, isDisabled }: PermissionCreateProps): JSX.Element {
        return (
            <div className="row">
                <div className="col-md-4">
                    <div className="organization-team-edit-modal__form-label">
                        <FormattedMessage id="permissionForm_userRole"/>
                    </div>
                    <div className="organization-team-edit-modal__form-input">
                        <SelectboxField name="userRole"
                                        disabled={isPending}
                                        clearable={false}
                                        options={this.userRoles}
                                        renderOption={this.renderUserRoleOption}/>
                        <FormFieldError field="userRole"/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="organization-team-edit-modal__form-label">
                        <FormattedMessage id="permissionForm_userEmail"/>
                    </div>
                    <div className="organization-team-edit-modal__form-input">
                        <TextboxField type="text"
                                      name="userEmail"
                                      disabled={isPending}/>
                        <FormFieldError field="userEmail"/>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="organization-team-edit-modal__form-label">
                        &nbsp;
                    </div>
                    <div className="organization-team-edit-modal__action">
                        <HtmlButton className="organization-team-edit-modal__create"
                                    disabled={isDisabled}
                                    type="submit"
                                    intent="info"
                                    shape="square"
                                    block={false}>+</HtmlButton>
                    </div>
                </div>
            </div>
        );
    }

    @bind()
    private renderUserRoleOption(userRole: UserRole): JSX.Element {
        return (
            <FormattedMessage id="organizationList_userRole"
                              values={{ userRole }}/>
        );
    }

    @memoize()
    private getDeleteHandler(permission: Permission): () => void {
        return () => {
            const { onDelete } = this.props;
            onDelete(permission);
        };
    }

    @bind()
    private handleCreate(): Promise<never> {
        const { onCreate } = this.props;
        onCreate();

        return new Promise<never>(() => { });
    }

    @bind()
    private validateForm(formData: PermissionFormData): FormErrors<PermissionFormData, FieldErrors> {
        return flattenErrors(permissionFormValidator.validate(formData));
    }
}
