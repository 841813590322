import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { Widget } from 'src/types/entities/Widget';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { getStatisticsFilterEndpoint } from 'src/react/api/selectors/getEndpoint';
import { IStatisticsFilterEndpoint } from 'src/react/api/endpoints/StatisticsFilterEndpoint';
import { StatisticsFilter } from 'src/types/entities/StatisticsFilter';
import { StatisticsFilterForm } from 'src/types/dto/StatisticsFilterForm';
import { getStatisticsFilter } from 'src/react/selectedWidget/selectors/getStatisticsFilter';
import { SettingsFormData } from 'src/react/settings/types/SettingsFormData';

export function* settingsFormSaveStatisticsFilter(
    formData: SettingsFormData,
): SagaIterator {
    const widget: Widget = yield select(getSelectedWidget);
    const statisticsFilter: StatisticsFilter | null = yield select(getStatisticsFilter);
    const statisticsFilterEndpoint: IStatisticsFilterEndpoint = yield select(getStatisticsFilterEndpoint);

    const statisticsFilterForm: StatisticsFilterForm = {
        excludedTerms: formData.statistics.excludedSearchTerms
            .trim()
            .split('\n')
            .map((line) => line.trim())
            .filter(Boolean),
    };

    if (statisticsFilter) {
        yield call({
            fn: statisticsFilterEndpoint.update,
            context: statisticsFilterEndpoint,
        }, statisticsFilter.id, statisticsFilterForm, { widgetId: widget.id });
    } else {
        yield call({
            fn: statisticsFilterEndpoint.create,
            context: statisticsFilterEndpoint,
        }, statisticsFilterForm, { widgetId: widget.id });
    }
}
