import { SagaIterator } from 'redux-saga';
import { put, select } from 'redux-saga/effects';
import { replace } from 'react-router-redux';
import { getSearchParams } from 'src/react/common/selectors/getSearchParams';

export function* updateQueryString(newSearchParams: URLSearchParams): SagaIterator {
    const oldSearchParams = yield select(getSearchParams);
    if (oldSearchParams.toString() === newSearchParams.toString()) {
        return;
    }

    yield put(replace({ search: newSearchParams.toString() }));
}
