import * as React from 'react';
import { connectDecorator } from 'src/decorators/connectDecorator';
import { Widget } from 'src/types/entities/Widget';
import { CustomField } from 'src/types/entities/CustomField';
import { Organization } from 'src/types/entities/Organization';
import { StatisticsFilter } from 'src/types/entities/StatisticsFilter';
import { RootState } from 'src/react/root/state/RootState';
import { SelectedWidgetData } from 'src/react/selectedWidget/state/SelectedWidgetData';
import { SELECTED_WIDGET_DATA_ACTIONS } from 'src/react/selectedWidget/actions/SelectedWidgetDataActions';

type OwnProps = {
    readonly widget: Widget;
    readonly organization: Organization;
    readonly customFields: ReadonlyArray<CustomField>;
    readonly statisticsFilter: StatisticsFilter | null;
};
type StateProps = {
    readonly selectedWidgetData: SelectedWidgetData | null;
};
type DispatchProps = {
    readonly setWidgetData: (widget: SelectedWidgetData | null) => void;
};
type AllProps =
    & OwnProps
    & StateProps
    & DispatchProps;

class Connected extends React.Component<AllProps> {
    public componentDidMount(): void {
        const { setWidgetData, widget, organization, customFields, statisticsFilter } = this.props;
        setWidgetData({ widget, organization, customFields, statisticsFilter });
    }

    public componentWillUnmount(): void {
        const { setWidgetData } = this.props;
        setWidgetData(null);
    }

    public render(): React.ReactNode {
        const { selectedWidgetData } = this.props;
        return selectedWidgetData ? this.props.children : null;
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    selectedWidgetData: state.selectedWidget.data,
});
const mapDispatchToProps: DispatchProps = {
    setWidgetData: (widget) => SELECTED_WIDGET_DATA_ACTIONS.request(widget),
};

@connectDecorator<OwnProps, StateProps, DispatchProps>(Connected, mapStateToProps, mapDispatchToProps)
export class SelectedWidget extends React.Component<OwnProps> {}
