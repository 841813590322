import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { StatisticsPageParams } from 'src/react/statistics/state/StatisticsPageParams';
import { updateQueryString } from 'src/react/common/services/updateQueryString';

export function* statisticsUpdateQueryString(params: StatisticsPageParams): SagaIterator {
    const queryString = new URLSearchParams({
        query: params.query,
        page: params.page.toString(),
        sort: params.sort,
        tab: params.tab.toString(),
        dateFrom: params.dateRange.from ? params.dateRange.from.format('YYYY-MM-DD') : '',
        dateTo: params.dateRange.to ? params.dateRange.to.format('YYYY-MM-DD') : '',
    });
    if (params.hasResults !== null) {
        queryString.set('hasResults', params.hasResults);
    }
    yield call(updateQueryString, queryString);
}
