import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { Organization } from 'src/types/entities/Organization';
import { Permission } from 'src/types/entities/Permission';
import { getPermissionEndpoint } from 'src/react/api/selectors/getEndpoint';
import { IPermissionEndpoint } from 'src/react/api/endpoints/PermissionEndpoint';

export function* permissionDelete(
    organization: Organization,
    permission: Permission,
): SagaIterator {
    const permissionEndpoint: IPermissionEndpoint = yield select(getPermissionEndpoint);

    return yield call({
        fn: permissionEndpoint.delete,
        context: permissionEndpoint,
    }, permission.id, { organizationId: organization.id });
}
