import './SettingsTabs.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { memoize } from 'lodash-decorators/memoize';
import { SettingsPageTab } from 'src/react/settings/types/SettingsPageTab';

type Props = {
    readonly activeTab: SettingsPageTab;
};

export class SettingsTabs extends React.Component<Props> {
    public render(): JSX.Element {
        return (
            <ul className="settings-tabs">
                <li className="settings-tabs__tab">
                    <NavLink to="?tab=general"
                             isActive={this.isTabActive('general')}
                             className="settings-tabs__link"
                             activeClassName="settings-tabs__link--active">
                        <div className="settings-tabs__icon">
                            <i className="si si-Website"/>
                        </div>
                        <div className="settings-tabs__title">
                            <FormattedMessage id="settings_tabName_general"/>
                        </div>
                        <div className="settings-tabs__desc">
                            <FormattedMessage id="settings_tabDesc_general"/>
                        </div>
                    </NavLink>
                </li>
                <li className="settings-tabs__tab">
                    <NavLink to="?tab=crawler"
                             isActive={this.isTabActive('crawler')}
                             className="settings-tabs__link"
                             activeClassName="settings-tabs__link--active">
                        <div className="settings-tabs__icon">
                            <i className="si si-Crawler"/>
                        </div>
                        <div className="settings-tabs__title">
                            <FormattedMessage id="settings_tabName_crawler"/>
                        </div>
                        <div className="settings-tabs__desc">
                            <FormattedMessage id="settings_tabDesc_crawler"/>
                        </div>
                    </NavLink>
                </li>
                <li className="settings-tabs__tab">
                    <NavLink to="?tab=custom"
                             isActive={this.isTabActive('custom')}
                             className="settings-tabs__link"
                             activeClassName="settings-tabs__link--active">
                        <div className="settings-tabs__icon">
                            <i className="si si-Rules"/>
                        </div>
                        <div className="settings-tabs__title">
                            <FormattedMessage id="settings_tabName_custom"/>
                        </div>
                        <div className="settings-tabs__desc">
                            <FormattedMessage id="settings_tabDesc_custom"/>
                        </div>
                    </NavLink>
                </li>
                <li className="settings-tabs__tab">
                    <NavLink to="?tab=integration"
                             isActive={this.isTabActive('integration')}
                             className="settings-tabs__link"
                             activeClassName="settings-tabs__link--active">
                        <div className="settings-tabs__icon">
                            <i className="si si-Tools"/>
                        </div>
                        <div className="settings-tabs__title">
                            <FormattedMessage id="settings_tabName_integration"/>
                        </div>
                        <div className="settings-tabs__desc">
                            <FormattedMessage id="settings_tabDesc_integration"/>
                        </div>
                    </NavLink>
                </li>
                <li className="settings-tabs__tab">
                    <NavLink to="?tab=statistics"
                             isActive={this.isTabActive('statistics')}
                             className="settings-tabs__link"
                             activeClassName="settings-tabs__link--active">
                        <div className="settings-tabs__icon">
                            <i className="si si-Statistics"/>
                        </div>
                        <div className="settings-tabs__title">
                            <FormattedMessage id="settings_tabName_statistics"/>
                        </div>
                        <div className="settings-tabs__desc">
                            <FormattedMessage id="settings_tabDesc_statistics"/>
                        </div>
                    </NavLink>
                </li>
            </ul>
        );
    }

    @memoize
    private isTabActive(tab: SettingsPageTab): () => boolean {
        return () => {
            const { activeTab } = this.props;
            return tab === activeTab;
        };
    }
}
