import './ManualCrawlerProgress.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { classList } from 'src/utils/classList';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { CrawlerProgress } from 'src/types/dto/CrawlerProgress';
import { CrawlerStatus } from 'src/types/values/CrawlerStatus';

type Props = {
    readonly progress: CrawlerProgress;
    readonly info: string;
};

export function ManualCrawlerProgress({ progress, info }: Props): JSX.Element {
    const done = progress.status === CrawlerStatus.Success;
    const count = progress.downloaded + progress.failed + progress.redirected + progress.notfound + progress.noindex;
    const width = done
        ? 100
        : progress.total > 0
            ? Math.round(count / progress.total * 100)
            : 0;

    return (
        <div className="manual-crawler-progress">
            <div className="manual-crawler-progress__head">
                <div className="manual-crawler-progress__status" title={info}>
                    <FormattedMessage id="manualCrawler_progressStatus" values={{
                        count,
                        status: progress.status,
                        total: progress.total,
                    }}/>
                </div>
                <div className="manual-crawler-progress__percent">
                    <FormattedMessage id="manualCrawler_progressPercent"
                                      values={{ value: width }} />
                </div>
            </div>

            <div className="manual-crawler-progress__bar-outer">
                <ManualCrawlerProgressBar width={width}
                                          status={progress.status}/>
            </div>
        </div>
    );
}

type BarProps = {
    readonly width: UnsignedInteger;
    readonly status: CrawlerStatus;
};
function ManualCrawlerProgressBar({ width, status }: BarProps): JSX.Element {
    const className = classList('manual-crawler-progress__bar-inner', {
        'manual-crawler-progress__bar-inner--success': status === CrawlerStatus.Success,
        'manual-crawler-progress__bar-inner--failure': status === CrawlerStatus.Failure,
    });

    return (
        <div className={className}
             style={{ width: `${width}%` }}/>
    );
}
