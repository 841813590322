import './KeyPhraseDeletePopup.scss';
import * as React from 'react';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { ModalBody, ModalClose, ModalFooter, ModalHeader } from 'src/react/common/components/Modal';
import { FormattedMessage } from 'react-intl';
import { HtmlButton } from 'src/react/common/components/Button';

type Props = {
    readonly keyPhrase: KeyPhrase;
    readonly onCancel: () => void;
    readonly onSubmit: () => void;
    readonly fromDemoPinMode?: boolean;
};

export function KeyPhraseDeletePopup({ keyPhrase, onCancel, onSubmit, fromDemoPinMode }: Props): JSX.Element {
    return (
        <div className="keyphrase-delete">
            <ModalHeader className="keyphrase-delete__header">
                <ModalClose className="keyphrase-delete__close"
                            onClick={onCancel}/>
                <div className="keyphrase-delete__title">
                    <FormattedMessage id="keyPhraseDelete_title"/>
                </div>
            </ModalHeader>
            <ModalBody className="keyphrase-delete__content">
                {fromDemoPinMode
                    ? <FormattedMessage id="keyPhraseDelete_textFromPinmode"
                                        values={{ keyPhrase: keyPhrase.text }}/>
                    : null
                }
                {keyPhrase.synonyms.length
                    ? <FormattedMessage id="keyPhraseDelete_textWithSynonyms"
                                        values={{ keyPhrase: keyPhrase.text }}/>
                    : <FormattedMessage id="keyPhraseDelete_textNoSynonyms"
                                        values={{ keyPhrase: keyPhrase.text }}/>
                }
            </ModalBody>
            <ModalFooter className="keyphrase-delete__footer">
                <div className="keyphrase-delete__action">
                    <HtmlButton block={false}
                                type="button"
                                intent="none"
                                onClick={onCancel}>
                        <FormattedMessage id="keyPhraseDelete_cancelButton"/>
                    </HtmlButton>
                </div>
                <div className="keyphrase-delete__action">
                    <HtmlButton block={false}
                                type="button"
                                intent="secondary"
                                onClick={onSubmit}>
                        <FormattedMessage id="keyPhraseDelete_confirmButton"/>
                    </HtmlButton>
                </div>
            </ModalFooter>
        </div>
    );
}
