import { ItemImageUploadState } from 'src/react/itemImageUpload/state/ItemImageUploadState';
import {
    ITEM_IMAGE_UPLOAD_UPLOAD_ACTIONS,
    ItemImageUploadUploadActions,
} from 'src/react/itemImageUpload/actions/ItemImageUploadUploadActions';

export function itemImageUploadUploadReducer(
    state: ItemImageUploadState,
    action: ItemImageUploadUploadActions,
): ItemImageUploadState {
    switch (action.type) {
        case ITEM_IMAGE_UPLOAD_UPLOAD_ACTIONS.PENDING:
            return { ...state, loading: true, error: null };

        case ITEM_IMAGE_UPLOAD_UPLOAD_ACTIONS.SUCCESS:
            return { ...state, loading: false, image: action.data, error: null };

        case ITEM_IMAGE_UPLOAD_UPLOAD_ACTIONS.FAILURE:
            return { ...state, loading: false, image: null, error: action.data };

        default:
            return state;
    }
}
