import { SagaIterator } from 'redux-saga';
import { all, takeLatest } from 'redux-saga/effects';
import { selectedWidgetDataSaga } from 'src/react/selectedWidget/sagas/SelectedWidgetDataSaga';
import { SELECTED_WIDGET_DATA_ACTIONS } from 'src/react/selectedWidget/actions/SelectedWidgetDataActions';

export function* selectedWidgetSaga(): SagaIterator {
    yield all([
        takeLatest(SELECTED_WIDGET_DATA_ACTIONS.REQUEST, selectedWidgetDataSaga),
    ]);
}
