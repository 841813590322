import { IPromise } from 'angular';
import { Organization } from 'src/types/entities/Organization';
import { Permission } from 'src/types/entities/Permission';
import { PermissionForm } from 'src/types/dto/PermissionForm';
import { PermissionFilter } from 'src/types/dto/PermissionFilter';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { Config } from 'src/types/constants/Config';
import { IApiService } from 'src/modules/api/ApiService';

export interface IPermissionService {
    list(
        organization: Readonly<Organization>,
        filter: Readonly<PermissionFilter>,
    ): IPromise<ListWithTotal<Permission>>;

    create(
        organization: Readonly<Organization>,
        form: Readonly<PermissionForm>,
    ): IPromise<Permission>;

    delete(
        organization: Readonly<Organization>,
        permission: Readonly<Permission>,
    ): IPromise<void>;
}

export class PermissionService implements IPermissionService {
    public static $inject = [
        'config',
        'apiService',
    ];
    public constructor(
        private config: Config,
        private apiService: IApiService,
    ) {
    }

    public list(
        organization: Readonly<Organization>,
        filter: Readonly<PermissionFilter>,
    ): IPromise<ListWithTotal<Permission>> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'organizations',
            encodeURIComponent(organization.id),
            'permissions',
        ].join('/');

        return this.apiService
            .makeGETRequest<ListWithTotal<Permission>>(requestUrl, { params: filter })
            .then(({ data }) => data);
    }

    public create(
        organization: Readonly<Organization>,
        formData: Readonly<PermissionForm>,
    ): IPromise<Permission> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'organizations',
            encodeURIComponent(organization.id),
            'permissions',
        ].join('/');

        return this.apiService
            .makePOSTRequest<PermissionForm, Permission>(requestUrl, formData)
            .then(({ data }) => data);
    }

    public delete(
        organization: Readonly<Organization>,
        permission: Readonly<Permission>,
    ): IPromise<void> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'organizations',
            encodeURIComponent(organization.id),
            'permissions',
            encodeURIComponent(permission.id),
        ].join('/');

        return this.apiService
            .makeDELETERequest<void>(requestUrl)
            .then(({ data }) => data);
    }
}
