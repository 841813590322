import { AnyAction } from 'redux';
import { OrganizationListState } from 'src/react/organization/state/OrganizationListState';
import {
    ORGANIZATION_LIST_UPDATE_CANCEL_ACTIONS,
    ORGANIZATION_LIST_UPDATE_OPEN_ACTIONS,
    ORGANIZATION_LIST_UPDATE_SUBMIT_ACTIONS,
} from 'src/react/organization/actions/OrganizationListUpdateActions';
import { assertNotNull } from 'src/utils/assertion';

export function organizationListUpdateReducer(
    state: OrganizationListState,
    action: AnyAction,
): OrganizationListState {
    if (ORGANIZATION_LIST_UPDATE_OPEN_ACTIONS.isSuccess(action)) {
        return {
            ...state,
            modalState: {
                type: 'update',
                organization: action.data,
            },
        };
    }

    if (ORGANIZATION_LIST_UPDATE_CANCEL_ACTIONS.isSuccess(action)) {
        return {
            ...state,
            modalState: {
                type: 'none',
            },
        };
    }

    if (ORGANIZATION_LIST_UPDATE_SUBMIT_ACTIONS.isSuccess(action)) {
        return {
            ...state,
            modalState: {
                type: 'none',
            },
            organizations: assertNotNull(
                state.organizations,
                'Organization list is not initialized yet',
                { state, action },
            ).map((other) => (
                other.organization.id === action.data.organization.id
                    ? action.data
                    : other
            )),
        };
    }

    return state;
}
