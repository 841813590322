import { mapValues } from 'lodash';
import { MapOf } from 'src/utils/MapOf';

import enPo from 'src/translations/en.po';
import dePo from 'src/translations/de.po';
import { SiteLanguages } from 'src/types/values/siteLanguage';

export type I18nMessages = {
    readonly [key in SiteLanguages]: MapOf<string>;
};

export const I18N_MESSAGES: I18nMessages = {
    en: mapValues(enPo, ([, value]) => value),
    de: mapValues(dePo, ([, value]) => value),
};
