import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { Widget } from 'src/types/entities/Widget';
import { CustomField } from 'src/types/entities/CustomField';
import { CustomFieldForm } from 'src/types/dto/CustomFieldForm';
import { ICustomFieldEndpoint } from 'src/react/api/endpoints/CustomFieldEndpoint';
import { getCustomFieldEndpoint } from 'src/react/api/selectors/getEndpoint';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { getCustomFields } from 'src/react/selectedWidget/selectors/getCustomFields';
import { SettingsFormData } from 'src/react/settings/types/SettingsFormData';

export function* settingsFormSaveCustomFields(
    formData: SettingsFormData,
): SagaIterator {
    const updatedCustomFields: CustomField[] = [];

    const widget: Widget = yield select(getSelectedWidget);
    const currentCustomFields: ReadonlyArray<CustomField> = yield select(getCustomFields);
    const customFieldEndpoint: ICustomFieldEndpoint = yield select(getCustomFieldEndpoint);

    for (const customField of currentCustomFields) {
        if (formData.customFields.some((it) => it.fieldName === customField.name)) {
            continue;
        }

        yield call({
            fn: customFieldEndpoint.delete,
            context: customFieldEndpoint,
        }, customField.id, { widgetId: widget.id });
    }

    for (const customField of formData.customFields) {
        const existingCustomField = currentCustomFields.find((it) => it.name === customField.fieldName);
        if (existingCustomField) {
            updatedCustomFields.push(existingCustomField);
        } else {
            const customFieldForm: CustomFieldForm = {
                name: customField.fieldName,
                type: customField.fieldType,
            };
            const createdCustomField: CustomField = yield call({
                fn: customFieldEndpoint.create,
                context: customFieldEndpoint,
            }, customFieldForm, { widgetId: widget.id });

            updatedCustomFields.push(createdCustomField);
        }
    }

    return updatedCustomFields;
}
