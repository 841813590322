import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomFieldType } from 'src/types/values/CustomFieldType';

type Props = {
    readonly type: CustomFieldType;
};

export function SettingsCustomFieldType({ type }: Props): JSX.Element {
    return (
        <FormattedMessage id="settings_customField_type"
                          values={{ type }}/>
    );
}
