import * as React from 'react';
import { Widget } from 'src/types/entities/Widget';
import { CustomField } from 'src/types/entities/CustomField';
import { Organization } from 'src/types/entities/Organization';
import { DemoPage } from 'src/react/demoPage/components/DemoPage';
import { DemoPageWrapper } from 'src/react/demoPage/components/DemoPageWrapper';
import { SelectedWidget } from 'src/react/selectedWidget/components/SelectedWidget';

export type DemoPageBridgeProps = {
    readonly widget: Widget;
    readonly organization: Organization;
    readonly customFields: ReadonlyArray<CustomField>;
};

export default function ({ widget, customFields, organization }: DemoPageBridgeProps): JSX.Element {
    return (
        <SelectedWidget widget={widget}
                        organization={organization}
                        customFields={customFields}
                        statisticsFilter={null}>
            <DemoPageWrapper>
                <DemoPage/>
            </DemoPageWrapper>
        </SelectedWidget>
    );
}
