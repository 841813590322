import * as React from 'react';
import * as ReduxForm from 'redux-form';
import { FormattedMessage, MessageValue } from 'react-intl';
import { createSelector } from 'reselect';
import { AnyAction, Dispatch } from 'redux';
import { get, isEqual, mapKeys } from 'lodash';
import { MapOf } from 'src/utils/MapOf';
import { identity } from 'src/utils/identity';
import { classList } from 'src/utils/classList';
import { connectDecorator } from 'src/decorators/connectDecorator';
import { RootState } from 'src/react/root/state/RootState';
import { FieldError, FieldErrors } from 'src/types/values/FieldError';
import { FormError } from 'src/react/common/components/FormError';

type SomeObject = MapOf<any>;
type SomeFieldMeta = Partial<Record<'active' | 'visited' | 'touched' | 'autofilled', boolean>>;
type SomeFormMeta = MapOf<SomeFieldMeta>;
type SomeFormErrors = ReduxForm.FormErrors<SomeObject, FieldErrors>;
type SomeFormWarnings = ReduxForm.FormWarnings<SomeObject, FieldErrors>;

type ErrorTypes =
    | 'syncErrors'
    | 'asyncErrors'
    | 'submitErrors';
type RenderProps = {
    readonly meta: ReduxForm.WrappedFieldMetaProps;
    readonly value: any;
    readonly errors: Record<ErrorTypes, FieldErrors>;
};

type OwnProps = React.HTMLAttributes<HTMLDivElement> & {
    readonly field: string;
    readonly children?: (errors: RenderProps) => React.ReactNode;
};
type FormProps = {
    readonly formName: string;
};
type StateProps = {
    readonly formMeta: SomeFormMeta;
    readonly formWarnings: SomeFormWarnings | undefined;
    readonly formSyncErrors: SomeFormErrors | undefined;
    readonly formAsyncErrors: SomeFormErrors | undefined;
    readonly formSubmitErrors: SomeFormErrors | undefined;
    readonly initialFormValues: SomeObject;
    readonly currentFormValues: SomeObject;
    readonly isValidating: boolean;
    readonly isSubmitting: boolean;
    readonly isSubmitFailed: boolean;
};
type DispatchProps = {
    readonly dispatch: Dispatch<AnyAction>;
};

function Connected({
                       className,
                       formName,
                       dispatch,
                       formMeta,
                       formSyncErrors,
                       formAsyncErrors,
                       formSubmitErrors,
                       formWarnings,
                       initialFormValues,
                       currentFormValues,
                       isValidating,
                       isSubmitting,
                       isSubmitFailed,
                       field,
                       children = defaultRender,
                       ...divProps
                   }: OwnProps & FormProps & StateProps & DispatchProps): JSX.Element | null {

    const fieldMeta: SomeFieldMeta = get(formMeta, field, {});
    const fieldSyncErrors = formSyncErrors ? getFieldsErrors(field, formSyncErrors) : [];
    const fieldAsyncErrors = formAsyncErrors ? getFieldsErrors(field, formAsyncErrors) : [];
    const fieldSubmitErrors = formSubmitErrors ? getFieldsErrors(field, formSubmitErrors) : [];

    const fieldInitialValue = get(initialFormValues, field);
    const fieldCurrentValue = get(currentFormValues, field);
    const isPristine = isEqual(fieldInitialValue, fieldCurrentValue);

    const formattedErrors = children({
        value: fieldCurrentValue,
        errors: {
            syncErrors: fieldSyncErrors,
            asyncErrors: fieldAsyncErrors,
            submitErrors: fieldSubmitErrors,
        },
        meta: {
            dispatch,
            form: formName,
            initial: fieldInitialValue,
            valid: (
                fieldSyncErrors.length === 0 &&
                fieldAsyncErrors.length === 0 &&
                fieldSubmitErrors.length === 0
            ),
            invalid: (
                fieldSyncErrors.length !== 0 ||
                fieldAsyncErrors.length !== 0 ||
                fieldSubmitErrors.length !== 0
            ),

            pristine: isPristine,
            dirty: !isPristine,

            asyncValidating: isValidating,
            submitting: isSubmitting,
            submitFailed: isSubmitFailed,

            autofilled: fieldMeta.autofilled ? true : false,
            active: fieldMeta.active ? true : false,
            touched: fieldMeta.touched ? true : false,
            visited: fieldMeta.visited ? true : false,

            error: fieldSyncErrors[0] || fieldAsyncErrors[0] || fieldSubmitErrors[0],
            warning: get(formWarnings, field),
        },
    });

    if (!React.Children.count(formattedErrors)) {
        return null;
    }

    return (
        <div {...divProps}
             className={classList('xss-form-field-errors', className)}
             data-field-error={field}>
            {formattedErrors}
        </div>
    );
}

function defaultRender({ meta, errors }: RenderProps): React.ReactNode {
    if (meta.valid) {
        return null;
    }
    if (!meta.touched && !meta.submitFailed) {
        return null;
    }

    return [
        ...errors.syncErrors,
        ...errors.asyncErrors,
        ...errors.submitErrors,
    ].map((error) => (
        <FormError key={error.code}>
            <FormattedMessage id={error.code} values={getTranslationMessageParams(error)}/>
        </FormError>
    ));
}
function getTranslationMessageParams(error: FieldError): MapOf<MessageValue> {
    return {
        value: error.value,
        path: error.path,
        ...mapKeys(error.params, (_, key) => `params_${key}`),
        ...mapKeys(error.context, (_, key) => `context_${key}`),
    };
}

function getFieldsErrors(
    field: string,
    formErrors: SomeFormErrors,
): FieldErrors {
    const fieldErrors = get(formErrors, field);
    return Array.isArray(fieldErrors) ? fieldErrors : [];
}

const mapStateToProps = createSelector([
    (state: RootState, props: OwnProps & FormProps) => props.formName,
    (state: RootState) => state.form,
], (formName, formState): StateProps => ({
    initialFormValues: ReduxForm.getFormInitialValues(formName, identity)(formState),
    currentFormValues: ReduxForm.getFormValues(formName, identity)(formState),

    isValidating: ReduxForm.isAsyncValidating(formName, identity)(formState),
    isSubmitting: ReduxForm.isSubmitting(formName, identity)(formState),
    isSubmitFailed: ReduxForm.hasSubmitFailed(formName, identity)(formState),

    formMeta: ReduxForm.getFormMeta(formName, identity)(formState),
    formWarnings: ReduxForm.getFormSyncWarnings(formName, identity)(formState),
    formSyncErrors: ReduxForm.getFormSyncErrors(formName, identity)(formState),
    formAsyncErrors: ReduxForm.getFormAsyncErrors(formName, identity)(formState),
    formSubmitErrors: ReduxForm.getFormSubmitErrors(formName, identity)(formState),
}));
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => ({
    dispatch,
});

@connectDecorator(Connected, mapStateToProps, mapDispatchToProps)
class WithFormName extends React.Component<OwnProps & FormProps> {}

export function FormFieldError(props: OwnProps): JSX.Element {
    return (
        <ReduxForm.FormName>{({ form }) => <WithFormName formName={form} {...props}/>}</ReduxForm.FormName>
    );
}
