export type AllowedPreviewSizes =
    | '50'
    | '100'
    | '150'
    | '200'
    | '250'
    | '300'
    | '350'
    | '400'
    | '450'
    | '500'
    | '1000'
    | '1500'
    | '2000'
    | '2500'
    | '3000'
    | '-';
export type AllowedPreviewMethods =
    | 'crop'
    | 'preview';

export function transformImageUrl(
    domain: string,
    image: string,
    widgetId: string,
    height: AllowedPreviewSizes = '100',
    width: AllowedPreviewSizes = '-',
    mode: AllowedPreviewMethods = 'preview',
): string {
    const prefix = [
        domain,
        mode,
        encodeURIComponent(widgetId),
        width,
        height,
    ].join('/');
    return image.replace(/^storage:\/\//, `${prefix}/`);
}
