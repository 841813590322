import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { demoPageSaga } from 'src/react/demoPage/sagas/demoPageSaga';
import { selectedWidgetSaga } from 'src/react/selectedWidget/sagas/SelectedWidgetSaga';
import { keyPhrasePinInputSaga } from 'src/react/keyPhrasePinInput/sagas/keyPhrasePinInputSaga';
import { itemImageUploadSaga } from 'src/react/itemImageUpload/sagas/itemImageUploadSaga';
import { itemFormSaga } from 'src/react/itemForm/sagas/itemFormSaga';
import { manualCrawlerSaga } from 'src/react/manualCrawler/sagas/manualCrawlerSaga';
import { statisticsPageSaga } from 'src/react/statistics/sagas/statisticsPageSaga';
import { settingsFormSaga } from 'src/react/settings/sagas/settingsFormSaga';
import { keyPhraseSynonymsSaga } from 'src/react/keyPhraseSynonyms/sagas/keyPhraseSynonymsSaga';
import { organizationListSaga } from 'src/react/organization/sagas/OrganizationListSaga';
import { keyPhraseGroupSaga } from 'src/react/keyPhraseGroup/sagas/keyPhraseGroupSaga';
import { siteLanguageChangeLanguageSaga } from 'src/react/siteLanguage/sagas/siteLanguageChangeLanguageSaga';

export function* rootSaga(): SagaIterator {
    yield all([
        fork(siteLanguageChangeLanguageSaga),
        fork(demoPageSaga),
        fork(selectedWidgetSaga),
        fork(keyPhrasePinInputSaga),
        fork(statisticsPageSaga),
        fork(itemImageUploadSaga),
        fork(itemFormSaga),
        fork(manualCrawlerSaga),
        fork(settingsFormSaga),
        fork(keyPhraseSynonymsSaga),
        fork(organizationListSaga),
        fork(keyPhraseGroupSaga),
    ]);
}
