import * as React from 'react';
import { PropsType } from 'react-utils';
import { BaseFieldProps, Field, WrappedFieldProps } from 'redux-form';
import { Omit } from 'mapped-types-utils';
import { classList } from 'src/utils/classList';
import { fieldMetaClass } from 'src/utils/fieldMetaClass';
import { Textbox } from 'src/react/common/components/Textbox';

type TextboxProps = PropsType<typeof Textbox>;
function TextboxComponent(props: WrappedFieldProps & TextboxProps): JSX.Element {
    const { input, meta, disabled = false, readOnly = false, ...inputProps } = props;
    const className = classList(props.className, fieldMetaClass(meta, { disabled, readOnly }));

    return (
        <Textbox {...inputProps}
                 className={className}
                 disabled={disabled}
                 readOnly={readOnly}
                 name={input.name}
                 value={input.value}
                 onBlur={input.onBlur}
                 onFocus={input.onFocus}
                 onChange={input.onChange}/>
    );
}

type TextboxFieldProps = Omit<BaseFieldProps<TextboxProps> & TextboxProps, 'component'>;
export function TextboxField(props: TextboxFieldProps): JSX.Element {
    return <Field component={TextboxComponent} {...props}/>;
}
