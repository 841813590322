import { StatisticsPageState } from 'src/react/statistics/state/StatisticsPageState';
import { STATISTICS_RESET_ACTIONS, StatisticsResetActions } from 'src/react/statistics/actions/StatisticsResetActions';

export function statisticsResetReducer(
    state: StatisticsPageState,
    action: StatisticsResetActions,
): StatisticsPageState {
    switch (action.type) {
        case STATISTICS_RESET_ACTIONS.SUCCESS:
            return {
                combinedSearches: {
                    loading: false,
                    error: null,
                    data: null,
                },
                successfulSearches: {
                    loading: false,
                    error: null,
                    data: null,
                },
                unsuccessfulSearches: {
                    loading: false,
                    error: null,
                    data: null,
                },
                lastSearches: {
                    loading: false,
                    error: null,
                    data: null,
                }
            };

        default:
            return state;
    }
}
