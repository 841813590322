import { IComponentController } from 'angular';
import { Widget } from 'src/types/entities/Widget';
import { Config } from 'src/types/constants/Config';

export class SnippetPageController implements IComponentController {
    public readonly widget!: Readonly<Widget>;

    public static $inject = [
        'config',
    ];
    public constructor(
        private config: Config,
    ) {
    }

    public getIframeSrc(): string {
        const { apiKey } = this.widget;
        const scriptUrl = `${this.config.services.publicApiUrl}/${apiKey}/widget.js?dnt=true`;
        const queryString = new URLSearchParams({ apiKey, scriptUrl });

        return `/demo.html?${queryString}`;
    }
}
