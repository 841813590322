import { SagaIterator } from 'redux-saga';
import { all, takeLatest } from 'redux-saga/effects';
import { cancelSagaOn } from 'src/utils/cancelSagaOn';
import {
    KEY_PHRASE_PIN_INPUT_SEARCH_ACTIONS,
} from 'src/react/keyPhrasePinInput/actions/KeyPhrasePinInputSearchActions';
import {
    KEY_PHRASE_PIN_INPUT_RESET_ACTIONS,
} from 'src/react/keyPhrasePinInput/actions/KeyPhrasePinInputResetActions';
import { keyPhrasePinInputSearchSaga } from 'src/react/keyPhrasePinInput/sagas/keyPhrasePinInputSearchSaga';
import { keyPhrasePinInputResetSaga } from 'src/react/keyPhrasePinInput/sagas/keyPhrasePinInputResetSaga';

export function* keyPhrasePinInputSaga(): SagaIterator {
    yield all([
        takeLatest(KEY_PHRASE_PIN_INPUT_RESET_ACTIONS.REQUEST, cancelSagaOn(keyPhrasePinInputResetSaga, [
            KEY_PHRASE_PIN_INPUT_RESET_ACTIONS.REQUEST,
            KEY_PHRASE_PIN_INPUT_SEARCH_ACTIONS.REQUEST,
        ])),
        takeLatest(KEY_PHRASE_PIN_INPUT_SEARCH_ACTIONS.REQUEST, cancelSagaOn(keyPhrasePinInputSearchSaga, [
            KEY_PHRASE_PIN_INPUT_RESET_ACTIONS.REQUEST,
            KEY_PHRASE_PIN_INPUT_SEARCH_ACTIONS.REQUEST,
        ])),
    ]);
}
