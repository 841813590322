import { module } from 'angular';
import { KEY_PHRASE_PAGE_COMPONENT } from 'src/modules/keyPhrase/KeyPhrasePageComponent';
import { KeyPhraseDialogService } from 'src/modules/keyPhrase/KeyPhraseDialogService';
import { KeyPhraseMappingService } from 'src/modules/keyPhrase/KeyPhraseMappingService';

export const KEY_PHRASE_MODULE = module('keyPhrase', [])
    /**
     * @ngdoc directive
     * @name keyPhrasePage
     * @restrict E
     *
     * @param {expression} widget
     * @param {expression} organization
     * @param {expression} params
     */
    .component(
        'keyPhrasePage',
        KEY_PHRASE_PAGE_COMPONENT,
    )
    .service(
        'KeyPhraseMappingService',
        KeyPhraseMappingService,
    )
    .service(
        'keyPhraseDialogService',
        KeyPhraseDialogService,
    );
