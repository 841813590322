import { WidgetFeedbackSettings } from 'src/types/values/WidgetFeedbackSettings';

export const FEEDBACK_DEFAULTS: WidgetFeedbackSettings = {
    general: {
        email: '',
        falseButton: 'No',
        falseComment: 'What can we do to improve this page?',
        submitButton: 'Submit',
        titleQuestion: 'Were those search results helpful?',
        trueButton: 'Yes',
        trueComment: 'What did you like about this page? (optional)',
        thankYouText: 'Thank you for your feedback!',
    },
    noResults: {
        submitButton: 'Submit',
        commentText: 'What would you like to see on this page?',
        titleQuestion: 'What can we do to improve this page?',
        thankYouText: 'Thank you for your feedback!',
        email: '',
    }
};
