import { ItemType } from 'src/types/values/ItemType';

const typeNames: Record<ItemType, string> = {
    1: 'Crawled',
    2: 'Manual',
    3: 'Edited',
};

export function itemTypeNameFilter(): (type: ItemType) => string {
    return (type) => typeNames[type];
}
