import { IHttpResponse, IPromise } from 'angular';
import { Config } from 'src/types/constants/Config';
import { Widget } from 'src/types/entities/Widget';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { IApiService } from 'src/modules/api/ApiService';
import { CrawlerLog } from 'src/types/entities/CrawlerLog';
import { CrawlerLogFilter } from 'src/types/dto/CrawlerLogFilter';

export interface ICrawlerLogService {
    list(
        widget: Readonly<Widget>,
        filter: Readonly<CrawlerLogFilter>,
    ): IPromise<ListWithTotal<CrawlerLog>>;
}

export class CrawlerLogService implements ICrawlerLogService {
    public static $inject = [
        'config',
        'apiService',
    ];
    public constructor(
        private config: Config,
        private apiService: IApiService,
    ) {
    }

    public list(
        widget: Readonly<Widget>,
        filter: CrawlerLogFilter,
    ): IPromise<ListWithTotal<CrawlerLog>> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
            'crawler-logs',
        ].join('/');

        return this.apiService
            .makeGETRequest<ListWithTotal<CrawlerLog>>(requestUrl, { params: filter })
            .then(extractResponseData);
    }
}

function extractResponseData<T>(response: IHttpResponse<T>): T {
    return response.data;
}
