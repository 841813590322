import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { isDefined } from 'src/utils/isDefined';
import { Item } from 'src/types/entities/Item';
import { ItemState } from 'src/types/values/ItemState';
import { Widget } from 'src/types/entities/Widget';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { KeyPhraseState } from 'src/types/values/KeyPhraseState';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { getItemEndpoint } from 'src/react/api/selectors/getEndpoint';
import { IItemEndpoint } from 'src/react/api/endpoints/ItemEndpoint';
import { DEMO_MAX_ITEMS_FILTER_COUNT } from 'src/react/demoPage/constants/DemoPageSize';

export function* keyPhraseItems(keyPhrase: KeyPhrase): SagaIterator {
    if (keyPhrase.state !== KeyPhraseState.Active) {
        return [];
    }
    if (!keyPhrase.itemIds.length) {
        return [];
    }

    const widget: Widget = yield select(getSelectedWidget);
    const itemEndpoint: IItemEndpoint = yield select(getItemEndpoint);
    const count = Math.ceil(keyPhrase.itemIds.length / DEMO_MAX_ITEMS_FILTER_COUNT);

    const itemsP: Item[] = [];
    for (let i = 0; i < count; i = i + 1) {
        const { data: items }: ListWithTotal<Item> = yield call({
            fn: itemEndpoint.list,
            context: itemEndpoint,
        }, {
            query: '',
            url: '',
            exact: false,
            type: [],
            state: [ItemState.Active],
            id: keyPhrase.itemIds,
            sort: [],
            page: i,
            pageSize: DEMO_MAX_ITEMS_FILTER_COUNT,
        }, {
            widgetId: widget.id,
        });
        itemsP.push(...items);
    }

    return keyPhrase.itemIds
        .map((id) => itemsP.find((it) => it.id === id))
        .filter(isDefined);
}
