import { Ng1StateDeclaration, StateService } from '@uirouter/angularjs';
import { Widget } from 'src/types/entities/Widget';
import { ICrawlerLogService } from 'src/modules/api/CrawlerLogService';
import { ICrawlerTaskService } from 'src/modules/api/CrawlerTaskService';
import { CrawlerTask } from 'src/types/entities/CrawlerTask';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { CRAWLER_LOG_PAGE_SIZE } from 'src/react/crawlerLog/constants/CrawlerLogPageSize';
import { debounce } from 'lodash';

export const ROUTES_STATES_CRAWLER_LOG: ReadonlyArray<Ng1StateDeclaration> = [
    {
        name: 'crawlerLog',
        parent: 'authorized:widget',
        url: '/crawlerLog?taskId&page&query',
        params: {
            query: {
                type: 'string',
                value: '',
                squash: false,
            },
            page: {
                type: 'int',
                value: 1,
                squash: true,
            },
            taskId: {
                type: 'string',
                squash: true,
            },
        },
        component: 'crawlerLogPage',
        resolve: {
            $title: () => {
                return 'Crawler Report';
            },
            crawlerTask: [
                'widget',
                'crawlerTaskService',
                'taskId',
                (
                    widget: Widget,
                    crawlerTaskService: ICrawlerTaskService,
                    taskId: string | undefined,
                ) => {
                    if (taskId) {
                        return crawlerTaskService.get(widget, taskId)
                            .then((crawlerTask) => crawlerTask);
                    }
                    return crawlerTaskService.list(widget, {
                        sort: ['-createdAt'],
                        page: 0,
                        pageSize: 1,
                    }).then(({ data: [crawlerTask = null] }) => crawlerTask);
                },
            ],
            taskId: [
                '$stateParams',
                ({ taskId }: any) => taskId,
            ],
            query: [
                '$stateParams',
                ({ query }: any) => query,
            ],
            page: [
                '$stateParams',
                ({ page }: any) => page,
            ],
            crawlerLog: [
                'widget',
                'crawlerLogService',
                'crawlerTask',
                'page',
                'query',
                (
                    widget: Widget,
                    crawlerLogService: ICrawlerLogService,
                    crawlerTask: CrawlerTask | null,
                    page: UnsignedInteger,
                    query: string,
                ) => {
                    if (!crawlerTask) {
                        return null;
                    }
                    const filter = {
                        taskId: crawlerTask.id,
                        sort: ['-createdAt'],
                        pageSize: CRAWLER_LOG_PAGE_SIZE,
                        page: page - 1
                    };
                    return crawlerLogService.list(widget, {
                        ...filter,
                        ...(query ? { query } : {})
                    }).then((data) => data || null);
                },
            ],
            onPageChange: [
                '$state',
                ($state: StateService) => (page: UnsignedInteger) => {
                    $state.go('crawlerLog', { page });
                }
            ],
            onSearchChange: [
                '$state',
                ($state: StateService) => debounce((query: string) => {
                    $state.go('crawlerLog', { query, page: 1 });
                }, 500)
            ]
        },
    },
];
