import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { CrawlerTask } from 'src/types/entities/CrawlerTask';
import { MANUAL_CRAWLER_WATCH_ACTIONS } from 'src/react/manualCrawler/actions/ManualCrawlerWatchActions';
import { watchCrawlerStatus } from 'src/react/manualCrawler/services/watchCrawlerStatus';
import { isManualCrawlerRunning } from 'src/react/manualCrawler/services/isManualCrawlerRunning';

export function* manualCrawlerWatchSaga(
    { data: crawlerTask }: ActionRequest<CrawlerTask | null>
): SagaIterator {
    yield put(MANUAL_CRAWLER_WATCH_ACTIONS.success(crawlerTask));

    if (!crawlerTask) {
        // NOTE: current watching task should be cancelled automatically
        // @see https://redux-saga.js.org/docs/advanced/ForkModel.html
        // @see https://redux-saga.js.org/docs/advanced/TaskCancellation.html
        return;
    }

    const isRunning: boolean = yield call(isManualCrawlerRunning);
    if (isRunning) {
        yield call(watchCrawlerStatus, crawlerTask);
    }
}
