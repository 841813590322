import './ItemImageUpload.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { default as ReactDropzone } from 'react-dropzone';
import { createStructuredSelector } from 'reselect';
import { bind } from 'lodash-decorators/bind';
import { noop } from 'src/utils/noop';
import { classList } from 'src/utils/classList';
import { connectDecorator } from 'src/decorators/connectDecorator';
import { RootState } from 'src/react/root/state/RootState';
import { LoadingWrapper } from 'src/react/common/components/LoadingWrapper';
import { ItemImageUploadState } from 'src/react/itemImageUpload/state/ItemImageUploadState';
import { getItemImageUploadState } from 'src/react/itemImageUpload/selectors/getItemImageUploadState';
import { ITEM_IMAGE_UPLOAD_UPLOAD_ACTIONS } from 'src/react/itemImageUpload/actions/ItemImageUploadUploadActions';
import { ITEM_IMAGE_UPLOAD_RESET_ACTIONS } from 'src/react/itemImageUpload/actions/ItemImageUploadResetActions';
import { MAX_IMAGE_SIZE, IMAGE_SUPPORTED_TYPES } from 'src/constants/image';
import { FormError } from 'src/react/common/components/FormError';
import { transformImageUrl } from 'src/utils/transformImageUrl';
import { getSelectedWidgetId } from 'src/react/selectedWidget/selectors/getSelectedWidgetId';
import { getConfig } from 'src/react/root/selectors/getConfig';
import { Config } from 'src/types/constants/Config';

type OwnProps = {
    readonly name?: string;
    readonly className?: string;
    readonly disabled?: boolean;
    readonly value: string | null;
    readonly onChange?: (value: string | null) => void;
};
type StateProps = {
    readonly uploadState: ItemImageUploadState;
    readonly widgetId: string;
    readonly config: Config;
};
type DispatchProps = {
    readonly uploadImage: (file: File) => void;
    readonly cancelUpload: () => void;
};
type AllProps =
    & OwnProps
    & StateProps
    & DispatchProps;

type State = {
    readonly oversize: boolean;
    readonly isSupportedImageType: boolean;
};

class Connected extends React.Component<AllProps, State> {
    private dropzoneRef = React.createRef<ReactDropzone>();
    public state: State = {
        oversize: false,
        isSupportedImageType: true,
    };

    public componentWillUnmount(): void {
        const { cancelUpload } = this.props;
        cancelUpload();
    }

    public componentDidUpdate(prevProps: AllProps): void {
        const { value, uploadState: { image } } = this.props;
        if (image === null || image === value) {
            return;
        }

        const { onChange = noop } = this.props;
        onChange(image);
    }

    public render(): JSX.Element {
        const { name, className } = this.props;
        const { uploadState: { loading } } = this.props;

        return (
            <div className={classList('item-image-upload', className)} data-field={name}>
                <LoadingWrapper loading={loading}>
                    {this.renderPreview()}
                    {this.renderDropzone()}
                </LoadingWrapper>
                {this.renderError()}
            </div>
        );
    }

    private renderPreview(): JSX.Element | null {
        const { value, widgetId, config } = this.props;
        if (!value) {
            return null;
        }

        const previewUrl = transformImageUrl(config.services.imagesDomain, value, widgetId);

        return (
            <div className="item-image-upload__preview">
                <div className="item-image-upload__preview-image">
                    <div className="item-image-upload__image"
                         style={{ backgroundImage: `url(${previewUrl})` }}/>
                </div>
                <div className="item-image-upload__preview-actions">
                    <a onClick={this.handleRemoveImage}><FormattedMessage id="itemImageUpload_remove"/></a>
                    <br/>
                    <a onClick={this.handleUploadDialog}><FormattedMessage id="itemImageUpload_upload"/></a>
                </div>
            </div>
        );
    }

    private renderDropzone(): JSX.Element {
        const { disabled, value, uploadState: { error } } = this.props;
        const className = classList('item-image-upload__dropzone', {
            'item-image-upload__dropzone--error': !!error,
        });

        return (
            <div hidden={!!value}>
                <ReactDropzone ref={this.dropzoneRef as any}
                               accept={IMAGE_SUPPORTED_TYPES.join(',')}
                               onDropAccepted={this.handleUploadImage}
                               maxSize={MAX_IMAGE_SIZE}
                               onDropRejected={this.handleRejectUploadImage}
                               disabled={disabled}
                               className={className}
                               activeClassName="item-image-upload__dropzone--active"
                               acceptClassName="item-image-upload__dropzone--accept"
                               rejectClassName="item-image-upload__dropzone--reject">
                    <div className="item-image-upload__dropzone-image">
                        <i className="fa fa-upload"/>
                    </div>
                    <div className="item-image-upload__dropzone-text">
                        <FormattedMessage id="itemImageUpload_dropzone"/>
                    </div>
                </ReactDropzone>
            </div>
        );
    }

    private renderError(): JSX.Element | null {
        const { oversize, isSupportedImageType } = this.state;

        if (!isSupportedImageType) {
            return (
                <FormError className="item-image-upload--error'">
                    <FormattedMessage id="itemImageUpload_notSupportedType"/>
                </FormError>
            );
        }
        if (oversize) {
            return (
                <FormError className="item-image-upload--error'">
                    <FormattedMessage id="itemImageUpload_oversize"/>
                </FormError>
            );
        }
        return null;
    }

    @bind
    private handleRemoveImage(): void {
        const { onChange = noop, cancelUpload } = this.props;
        onChange(null);
        cancelUpload();
    }

    @bind
    private handleUploadDialog(): void {
        if (this.dropzoneRef.current) {
            this.dropzoneRef.current.open();
        }
    }

    @bind
    private handleUploadImage([imageFile]: File[]): void {
        if (!imageFile) {
            return;
        }
        this.setState({ oversize: false, isSupportedImageType: true });
        const { uploadImage } = this.props;
        uploadImage(imageFile);
    }

    @bind
    private handleRejectUploadImage([imageFile]: File[]): void {
        if (!imageFile) {
            return;
        }

        if (imageFile.type && IMAGE_SUPPORTED_TYPES.indexOf(imageFile.type) === -1) {
            this.setState({  oversize: false, isSupportedImageType: false });
            return;
        }
        if (imageFile.size && imageFile.size > MAX_IMAGE_SIZE) {
            this.setState({ oversize: true, isSupportedImageType: true  });
        }
    }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
    uploadState: getItemImageUploadState,
    widgetId: getSelectedWidgetId,
    config: getConfig,
});
const mapDispatchToProps: DispatchProps = {
    uploadImage: (file) => ITEM_IMAGE_UPLOAD_UPLOAD_ACTIONS.request(file),
    cancelUpload: () => ITEM_IMAGE_UPLOAD_RESET_ACTIONS.request(null),
};

@connectDecorator<OwnProps, StateProps, DispatchProps>(Connected, mapStateToProps, mapDispatchToProps)
export class ItemImageUpload extends React.Component<OwnProps> {}
