import './DemoPageSearchMode.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { bind } from 'lodash-decorators/bind';
import { memoize } from 'lodash-decorators/memoize';
import { debounce } from 'lodash-decorators/debounce';
import { KeyPhraseState } from 'src/types/values/KeyPhraseState';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { DemoPageViewMode } from 'src/react/demoPage/state/DemoPageViewMode';
import { Highlight } from 'src/types/dto/Highlight';
import { Item } from 'src/types/entities/Item';
import { Widget } from 'src/types/entities/Widget';
import { CustomField } from 'src/types/entities/CustomField';
import { ErrorMessage } from 'src/react/common/components/ErrorMessage';
import { LoadingWrapper } from 'src/react/common/components/LoadingWrapper';
import { Pager } from 'src/react/common/components/Pager';
import { HtmlButton } from 'src/react/common/components/Button';
import { Textbox } from 'src/react/common/components/Textbox';
import { InputStatus } from 'src/react/common/components/InputStatus';
import { DemoPageParams } from 'src/react/demoPage/state/DemoPageParams';
import { DemoPageState } from 'src/react/demoPage/state/DemoPageState';
import { DemoPageNoResults } from 'src/react/demoPage/components/DemoPageNoResults';
import { DEMO_SEARCH_PAGE_SIZE } from 'src/react/demoPage/constants/DemoPageSize';
import { DEMO_PAGE_MIN_QUERY_LENGTH } from 'src/react/demoPage/constants/DemoPageMinQuery';
import { DEMO_PAGE_EMPTY_HIGHLIGHT } from 'src/react/demoPage/constants/DemoPageEmptyHighlight';
import { DemoPageSearchItemTableView } from 'src/react/demoPage/components/DemoPageSearchItemTableView';
import { DemoPageSearchItemGridView } from 'src/react/demoPage/components/DemoPageSearchItemGridView';
import { classList } from 'src/utils/classList';

type Props = {
    readonly widget: Widget;
    readonly viewerMode: boolean;
    readonly params: DemoPageParams;
    readonly customFields: ReadonlyArray<CustomField>
    readonly searchResult: DemoPageState;
    readonly updateResult: (params: DemoPageParams) => void;
    readonly onEditItem: (item: Item) => void;
    readonly onPinItem: (item: Item) => void;
    readonly onUnpinItem: (item: Item) => void;
    readonly onEnterPinMode: () => void;
    readonly viewMode: DemoPageViewMode;
    readonly handleChangeViewMode: (mode: DemoPageViewMode) => () => void;
    readonly synonyms: JSX.Element | null;

};
type State = {
    readonly query: string;
    readonly page: UnsignedInteger;
};

export class DemoPageSearchMode extends React.Component<Props, State> {
    public state: State = {
        query: this.props.params.query,
        page: this.props.params.page,
    };

    public render(): React.ReactNode {
        const { query } = this.state;
        const { onEnterPinMode, viewerMode } = this.props;

        return (
            <div className="demo-page-search-mode">
                <div className="demo-page-search-mode__content">
                    <div className="demo-page-search-mode__form">
                        <div className="demo-page-search-mode__input">
                            <FormattedMessage id="searchDemo_searchModePlaceholder">
                                {(message) => (
                                    <InputStatus prefix={<i className="fa fa-search"/>}>
                                        <Textbox type="text"
                                                 autoFocus={true}
                                                 placeholder={message.toString()}
                                                 value={query}
                                                 onChange={this.handleSearchChange}/>
                                    </InputStatus>
                                )}
                            </FormattedMessage>
                        </div>
                        {!viewerMode && <div className="demo-page-search-mode__button">
                            <HtmlButton onClick={onEnterPinMode}
                                        disabled={this.isPinModeDisabled()}
                                        intent="secondary"
                                        block={true}>
                                <i className="fa fa-thumb-tack"/>{' '}
                                <FormattedMessage id="searchDemo_enterPinMode"/>
                            </HtmlButton>
                        </div>}
                    </div>

                    {this.renderInfoBar()}

                    <div className="demo-page-search-mode__result">
                        {this.renderError()}
                        {this.renderResult()}
                    </div>
                </div>

                {this.renderPager()}
            </div>
        );
    }

    private renderError(): JSX.Element | null {
        const { searchResult: { error } } = this.props;
        if (!error) {
            return null;
        }

        return (
            <div className="demo-page-search-mode__error">
                <ErrorMessage error={error} errorInfo={undefined}/>
            </div>
        );
    }

    private renderResult(): JSX.Element | null {
        const { searchResult: { error, loading } } = this.props;
        if (error) {
            return null;
        }

        return (
            <LoadingWrapper loading={loading}>
                {this.renderFoundItems()}
            </LoadingWrapper>
        );
    }

    private renderFoundItems(): JSX.Element | null {
        const { viewerMode, params, customFields, widget, searchResult: { data, viewMode } } = this.props;
        if (!data) {
            return null;
        }

        const { foundItems: { data: foundItems } } = data;
        if (!foundItems.length) {
            return <DemoPageNoResults query={params.query} widget={widget}/>;
        }

        const isTableViewMode = viewMode === DemoPageViewMode.table;

        return (
            <div className="demo-page-search-mode__found">
                {foundItems.map(({ item, highlight }) => (
                    <div className="demo-page-search-mode__item" key={item.id}>
                        {isTableViewMode
                            ? <DemoPageSearchItemTableView item={item}
                                                           widget={widget}
                                                           viewerMode={viewerMode}
                                                           highlight={this.formatHighlight(highlight)}
                                                           isPinned={this.isItemPinned(item)}
                                                           onPinItem={this.getPinItemHandler(item)}
                                                           onUnpinItem={this.getUnpinItemHandler(item)}
                                                           onEditItem={this.getEditItemHandler(item)}/>
                            : <DemoPageSearchItemGridView item={item}
                                                          widget={widget}
                                                          viewerMode={viewerMode}
                                                          highlight={this.formatHighlight(highlight)}
                                                          customFields={customFields}
                                                          isPinned={this.isItemPinned(item)}
                                                          onPinItem={this.getPinItemHandler(item)}
                                                          onUnpinItem={this.getUnpinItemHandler(item)}
                                                          onEditItem={this.getEditItemHandler(item)}/>
                        }

                    </div>
                ))}
            </div>
        );
    }

    private formatHighlight(highlight: Highlight): Highlight {
        const { searchResult: { showHighlight } } = this.props;
        return showHighlight
            ? highlight
            : DEMO_PAGE_EMPTY_HIGHLIGHT;
    }

    private renderPager(): JSX.Element | null {
        const { searchResult: { data } } = this.props;
        if (!data) {
            return null;
        }

        const { page } = this.state;
        const { foundItems: { total } } = data;
        if (total <= DEMO_SEARCH_PAGE_SIZE) {
            return null;
        }

        return (
            <div className="demo-page-search-mode__pager">
                <Pager currentPage={page}
                       pageCount={Math.ceil(total / DEMO_SEARCH_PAGE_SIZE)}
                       maxSize={5}
                       onChangePage={this.handlePageChange}/>
            </div>
        );
    }

    private renderInfoBar (): JSX.Element | null {
        const { searchResult, handleChangeViewMode, viewMode, synonyms } = this.props;
        if (!searchResult.data) {
            return null;
        }

        const classNameTableViewMode = classList('demo-page__viewMode', {
            'demo-page__viewMode--active': viewMode === DemoPageViewMode.table,
        });
        const classNameGridViewMode = classList('demo-page__viewMode', {
            'demo-page__viewMode--active': viewMode === DemoPageViewMode.grid,
        });

        return (
            <div className="demo-page-search-mode__info-bar">
                <div>
                    <FormattedMessage
                        id="searchDemo_itemsTotalCount"
                        values={{ count: searchResult.data.foundItems.total }} />
                    <span>|</span>
                    <FormattedMessage
                        id="searchDemo_pinnedItemsTotalCount"
                        values={{ count: searchResult.data.pinnedItems.length }} />
                    {synonyms ?
                        <>
                            <span>|</span>
                            {synonyms}
                        </>
                        : '' }
                    {searchResult.data.groups.length ?
                        <>
                            <span>|</span>
                            <FormattedMessage
                                id="searchDemo_groups"
                                values={{ groups: searchResult.data.groups.map((group) => group.text).join(', ') }} />
                        </>
                        : '' }
                </div>
                <div>
                    <a className={classNameTableViewMode}
                       href="javascript:void(0)"
                       onClick={handleChangeViewMode(DemoPageViewMode.table)}>
                        <span className="fa fa-align-justify"/>
                    </a>
                    <span className="demo-page__viewMode">|</span>
                    <a className={classNameGridViewMode}
                       href="javascript:void(0)"
                       onClick={handleChangeViewMode(DemoPageViewMode.grid)}>
                        <span className="fa fa-th-large" />
                    </a>
                </div>
            </div>
        );
    }

    private isPinModeDisabled(): boolean {
        const { query } = this.state;
        if (query.length < DEMO_PAGE_MIN_QUERY_LENGTH) {
            return true;
        }

        const { params } = this.props;
        if (query !== params.query) {
            return true;
        }

        const { searchResult: { loading, error, data } } = this.props;
        if (loading || error || !data) {
            return true;
        }

        return false;
    }

    private isItemPinned(item: Item): boolean {
        const { searchResult: { data } } = this.props;
        if (!data) {
            return false;
        }

        const { keyPhrase, pinnedItems } = data;
        if (!keyPhrase || keyPhrase.state !== KeyPhraseState.Active) {
            return false;
        }

        return pinnedItems.some((other) => other.id === item.id);
    }

    @memoize
    private getEditItemHandler(item: Item): () => void {
        return () => {
            const { onEditItem } = this.props;
            onEditItem(item);
        };
    }

    @memoize
    private getPinItemHandler(item: Item): () => void {
        return () => {
            const { onPinItem } = this.props;
            onPinItem(item);
        };
    }

    @memoize
    private getUnpinItemHandler(item: Item): () => void {
        return () => {
            const { onUnpinItem } = this.props;
            onUnpinItem(item);
        };
    }

    @bind
    private handlePageChange(page: UnsignedInteger): void {
        this.setState({ page }, this.updateResultFromState);
    }

    @bind
    private handleSearchChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({ query: event.target.value }, this.handleSearchDebounce);
    }

    @debounce(500)
    private handleSearchDebounce(): void {
        this.setState({ page: 1 }, this.updateResultFromState);
    }

    @bind
    private updateResultFromState(): void {
        const { query, page } = this.state;
        const { params, updateResult } = this.props;
        updateResult({ ...params, query, page });
    }
}
