import { FieldErrors } from 'src/types/values/FieldError';
import { createSchemaValidator } from 'src/utils/createSchemaValidator';
import { SettingsUrlBoost } from 'src/react/settings/types/SettingsUrlBoost';
import { SettingsUrlBoostFormData } from 'src/react/settings/types/SettingsUrlBoostFormData';
import { SETTINGS_URL_BOOST_FORM_SCHEMA } from 'src/react/settings/constants/SettingsUrlBoostFormSchema';

const validator = createSchemaValidator(SETTINGS_URL_BOOST_FORM_SCHEMA);

export const settingsUrlBoostFormValidator = {
    validate: (
        formData: SettingsUrlBoostFormData,
        urlBoosts: ReadonlyArray<SettingsUrlBoost>,
    ): FieldErrors => {
        return validator(formData, { urlBoosts });
    },
    isValid: (
        formData: SettingsUrlBoostFormData,
        urlBoosts: ReadonlyArray<SettingsUrlBoost>,
    ): formData is SettingsUrlBoost => {
        return validator(formData, { urlBoosts }).length === 0;
    },
};
