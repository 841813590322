import 'moment/locale/de-ch';
import * as moment from 'moment';
import { addLocaleData } from 'react-intl';
import * as enLocale from 'react-intl/locale-data/en';
import * as deLocale from 'react-intl/locale-data/de';

addLocaleData([...deLocale, ...enLocale]);

if (localStorage.getItem('locale') === 'de') {
    moment.locale('de-ch');
} else {
    moment.locale('en-us');
}
