import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { NativeError } from 'src/errors/NativeError';
import { Widget } from 'src/types/entities/Widget';
import { CrawlerTask } from 'src/types/entities/CrawlerTask';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { getCrawlerTaskEndpoint } from 'src/react/api/selectors/getEndpoint';
import { ICrawlerTaskEndpoint } from 'src/react/api/endpoints/CrawlerTaskEndpoint';
import { isManualCrawlerRunning } from 'src/react/manualCrawler/services/isManualCrawlerRunning';
import { MANUAL_CRAWLER_START_ACTIONS } from 'src/react/manualCrawler/actions/ManualCrawlerStartActions';
import { watchCrawlerStatus } from 'src/react/manualCrawler/services/watchCrawlerStatus';

export function* manualCrawlerStartSaga(): SagaIterator {
    const isRunning: boolean = yield call(isManualCrawlerRunning);
    if (isRunning) {
        return;
    }

    try {
        yield put(MANUAL_CRAWLER_START_ACTIONS.pending());

        const widget: Widget = yield select(getSelectedWidget);
        const crawlerTaskEndpoint: ICrawlerTaskEndpoint = yield select(getCrawlerTaskEndpoint);

        const crawlerTask: CrawlerTask = yield call({
            fn: crawlerTaskEndpoint.create,
            context: crawlerTaskEndpoint,
        }, {}, { widgetId: widget.id });

        yield put(MANUAL_CRAWLER_START_ACTIONS.success(crawlerTask));
        yield call(watchCrawlerStatus, crawlerTask);
    } catch (error) {
        yield put(MANUAL_CRAWLER_START_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
