import { ManualCrawlerState } from 'src/react/manualCrawler/state/ManualCrawlerState';
import {
    MANUAL_CRAWLER_WATCH_ACTIONS,
    ManualCrawlerWatchActions,
} from 'src/react/manualCrawler/actions/ManualCrawlerWatchActions';

export function manualCrawlerWatchReducer(
    state: ManualCrawlerState,
    action: ManualCrawlerWatchActions,
): ManualCrawlerState {
    switch (action.type) {
        case MANUAL_CRAWLER_WATCH_ACTIONS.SUCCESS:
            return {
                ...state,
                fetchedUrls: [],
                crawlerTask: action.data,
                progress: action.data ? {
                    total: action.data.total,
                    notfound: action.data.notfound,
                    redirected: action.data.redirected,
                    failed: action.data.failed,
                    downloaded: action.data.downloaded,
                    processed: action.data.processed,
                    queued: action.data.queued,
                    noindex: action.data.noindex,
                    status: action.data.status,
                } : null,
            };

        default:
            return state;
    }
}
