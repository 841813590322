import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { Widget } from 'src/types/entities/Widget';
import { StatisticsFilter } from 'src/types/entities/StatisticsFilter';
import { StatisticsPageParams } from 'src/react/statistics/state/StatisticsPageParams';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { createSearchFilter, StatisticsSearchFilter } from 'src/types/dto/StatisticsSearchFilter';
import { getStatisticsLastSearchesEndpoint } from 'src/react/api/selectors/getEndpoint';
import { IStatisticsLastSearchesEndpoint } from 'src/react/api/endpoints/StatisticsLastSearchesEndpoint';

export function* getStatisticsLastSearches(
    filter: StatisticsFilter | null,
    { query, page, sort, dateRange  }: StatisticsPageParams
): SagaIterator {
    const widget: Widget = yield select(getSelectedWidget);
    const statisticsSearchFilter: StatisticsSearchFilter =
        yield call(createSearchFilter, filter, query, sort, page, dateRange, null);

    const lastSearchesEndpoint: IStatisticsLastSearchesEndpoint =
        yield select(getStatisticsLastSearchesEndpoint);

    return yield call({
        fn: lastSearchesEndpoint.list,
        context: lastSearchesEndpoint,
    }, statisticsSearchFilter, {
        widgetId: widget.id,
    });
}
