import * as React from 'react';
import { PropsType } from 'react-utils';
import { BaseFieldProps, Field, WrappedFieldProps } from 'redux-form';
import { Omit } from 'mapped-types-utils';
import { ControlledEditor } from '@monaco-editor/react';

type MonacoProps = PropsType<typeof ControlledEditor>;
function renderMonaco(props: WrappedFieldProps & MonacoProps): JSX.Element {
    const { input, meta, language = 'html', height, ...inputProps } = props;
    return (
        <ControlledEditor
                {...inputProps}
                theme="dark"
                options={{
                    minimap: { enabled: false },
                }}
                height={height}
                language={language}
                value={input.value}
                onChange={onChange}/>
    );
    function onChange(event: any, value: string = ''): undefined {
        input.onChange(value);
        return;
    }

}

type MonacoFieldProps = Omit<BaseFieldProps<MonacoProps> & MonacoProps, 'component'>;
export function MonacoField(props: MonacoFieldProps): JSX.Element {
    return <Field component={renderMonaco} {...props}/>;
}
