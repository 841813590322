import * as yup from 'yup';
import { SettingsUrlBoost } from 'src/react/settings/types/SettingsUrlBoost';
import { SettingsUrlBoostFormData } from 'src/react/settings/types/SettingsUrlBoostFormData';

export const SETTINGS_URL_BOOST_FORM_SCHEMA = yup.object<SettingsUrlBoostFormData>({
    urlBoost: yup
        .number()
        .nullable(true)
        .required('formError_required')
        .min(0.1, 'formError_tooSmall')
        .max(10, 'formError_tooLarge'),
    urlPattern: yup.lazy((value: SettingsUrlBoostFormData['urlPattern'], context?) => {
        const urlBoosts: ReadonlyArray<SettingsUrlBoost> = context.context.urlBoosts;

        return yup
            .string()
            .nullable(true)
            .required('formError_required')
            .notOneOf(
                urlBoosts.map((it) => it.urlPattern),
                'settings_error_urlBoost_duplicate',
            );
    }),
});
