import { FormErrors, getFormValues, startSubmit, stopSubmit } from 'redux-form';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { NativeError } from 'src/errors/NativeError';
import { FieldErrors } from 'src/types/values/FieldError';
import { Organization } from 'src/types/entities/Organization';
import { OrganizationForm } from 'src/types/dto/OrganizationForm';
import { OrganizationListItem } from 'src/react/organization/state/OrganizationListItem';
import {
    ORGANIZATION_LIST_CREATE_CANCEL_ACTIONS,
    ORGANIZATION_LIST_CREATE_OPEN_ACTIONS,
    ORGANIZATION_LIST_CREATE_SUBMIT_ACTIONS,
} from 'src/react/organization/actions/OrganizationListCreateActions';
import { organizationCreate } from 'src/react/organization/services/organizationCreate';
import { handleFormError } from 'src/react/organization/services/handleFormError';

export function* organizationListCreateOpenSaga(): SagaIterator {
    yield put(ORGANIZATION_LIST_CREATE_OPEN_ACTIONS.success());
}

export function* organizationListCreateCancelSaga(): SagaIterator {
    yield put(ORGANIZATION_LIST_CREATE_CANCEL_ACTIONS.success());
}

export function* organizationListCreateSubmitSaga(): SagaIterator {
    try {
        yield put(ORGANIZATION_LIST_CREATE_SUBMIT_ACTIONS.pending());
        yield put(startSubmit('organization-form'));

        const selector = yield call(getFormValues, 'organization-form');
        const formData: OrganizationForm = yield select(selector);
        const organization: Organization = yield call(organizationCreate, formData);

        const created: OrganizationListItem = {
            organization,
            permissions: [organization.permission],
            widgets: [],
        };

        yield put(ORGANIZATION_LIST_CREATE_SUBMIT_ACTIONS.success(created));
        yield put(stopSubmit('organization-form'));
    } catch (error) {
        const handledError = NativeError.wrapError(error);
        const formErrors: FormErrors<{}, FieldErrors> = yield call(handleFormError, handledError);

        yield put(ORGANIZATION_LIST_CREATE_SUBMIT_ACTIONS.failure(handledError));
        yield put(stopSubmit('organization-form', formErrors));
    }
}
