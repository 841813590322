import { ComponentClass, ComponentType } from 'react';
import { connect, MapDispatchToPropsParam, MapStateToPropsParam } from 'react-redux';
import { RootState } from 'src/react/root/state/RootState';

export function connectDecorator<TOwnProps extends {}, TStateProps extends {}, TDispatchProps extends {}>(
    original: ComponentType<TOwnProps & TStateProps & TDispatchProps>,
    stateToProps: MapStateToPropsParam<TStateProps, TOwnProps, RootState>,
    dispatchToProps: MapDispatchToPropsParam<TDispatchProps, TOwnProps>,
): (target: ComponentClass<TOwnProps>) => ComponentClass<TOwnProps> {
    return () => connect<TStateProps, TDispatchProps, TOwnProps, RootState>(
        stateToProps,
        dispatchToProps
    )(original as any);
}
