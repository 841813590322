import { IPromise } from 'angular';
import { Config } from 'src/types/constants/Config';
import { IApiService } from 'src/modules/api/ApiService';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { Widget } from 'src/types/entities/Widget';
import { StatisticsLastSearch } from 'src/types/entities/StatisticsLastSearch';
import { StatisticsLastSearchFilter } from 'src/types/dto/StatisticsLastSearchFilter';

export interface IStatisticsLastSearchService {
    list(
        widget: Readonly<Widget>,
        filter: Readonly<StatisticsLastSearchFilter>,
    ): IPromise<ListWithTotal<StatisticsLastSearch>>;
}

export class StatisticsLastSearchService implements IStatisticsLastSearchService {
    public static $inject = [
        'config',
        'apiService',
    ];
    public constructor(
        private config: Config,
        private apiService: IApiService,
    ) {
    }

    public list(
        widget: Readonly<Widget>,
        filter: Readonly<StatisticsLastSearchFilter>,
    ): IPromise<ListWithTotal<StatisticsLastSearch>> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'statistics',
            encodeURIComponent(widget.id),
            'last-searches',
        ].join('/');

        return this.apiService
            .makeGETRequest<ListWithTotal<StatisticsLastSearch>>(requestUrl, { params: filter })
            .then(({ data }) => data);
    }
}
