import * as React from 'react';
import { ItemType } from 'src/types/values/ItemType';
import { ItemFormItemTypeLabel } from 'src/react/itemForm/components/ItemFormItemTypeLabel';

export type ItemFormItemTypeLabelBridgeProps = {
    readonly itemType: ItemType;
};

export default function ({ itemType }: ItemFormItemTypeLabelBridgeProps): JSX.Element {
    return (
            <ItemFormItemTypeLabel itemType={itemType}/>
    );
}
