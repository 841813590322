import { module } from 'angular';
import { WIDGET_LIST_COMPONENT } from 'src/modules/widgetList/WidgetListComponent';
import { WidgetListDialogService } from 'src/modules/widgetList/WidgetListDialogService';

export const WIDGET_LIST_MODULE = module('widgetList', [])
    /**
     * @ngdoc directive
     * @name widgetList
     * @restrict E
     *
     * @param {expression} params
     * @param {expression} organizations
     */
    .component(
        'widgetList',
        WIDGET_LIST_COMPONENT,
    )
    .service(
        'widgetListDialogService',
        WidgetListDialogService,
    );
