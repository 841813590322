import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { NativeError } from 'src/errors/NativeError';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { DemoFilterParams } from 'src/react/demoPage/state/DemoFilterParams';
import { DEMO_FILTER_ACTIONS } from 'src/react/demoPage/actions/DemoFilterActions';
import { demoSearchQuery } from 'src/react/demoPage/services/demoSearchQuery';
import { demoFilterQuery } from 'src/react/demoPage/services/demoFilterQuery';
import { DemoPageData } from 'src/react/demoPage/state/DemoPageState';
import { DemoPageParams } from 'src/react/demoPage/state/DemoPageParams';
import { getDemoPageResult } from 'src/react/demoPage/selectors/getDemoPageResult';
import { getDemoPageParams } from 'src/react/demoPage/selectors/getDemoPageParams';
import { SearchItem } from 'src/types/dto/SearchItem';

export function* demoFilterSaga(
    { data: filter }: ActionRequest<DemoFilterParams>,
): SagaIterator {
    try {
        yield put(DEMO_FILTER_ACTIONS.pending());

        const params: DemoPageParams = yield select(getDemoPageParams);

        const foundItems: ListWithTotal<SearchItem> = !filter.query
            ? yield call(demoSearchQuery, { ...params, page: filter.page })
            : yield call(demoFilterQuery, filter);
        const foundResult: DemoPageData = yield select(getDemoPageResult);

        yield put(DEMO_FILTER_ACTIONS.success({
            ...foundResult,
            foundItems
        }));
    } catch (error) {
        yield put(DEMO_FILTER_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
