import './PermissionDeleteForm.scss';
import * as React from 'react';
import { InnerFormProps, OuterFormProps, withFormDecorator } from 'src/decorators/withFormDecorator';
import { Permission } from 'src/types/entities/Permission';

export type PermissionDeleteProps = {
    readonly permission: Permission;
    readonly isPending: boolean;
};
type Props = {
    readonly permission: Permission;
    readonly children: (props: PermissionDeleteProps) => React.ReactNode;
    readonly onSubmit: () => void;
};

class Connected extends React.Component<InnerFormProps<{}, Props>> {
    public render(): JSX.Element {
        const { children, permission, onSubmit } = this.props;
        const { handleSubmit, submitting, submitSucceeded, asyncValidating } = this.props;

        const isPending = submitting || submitSucceeded || asyncValidating === true;

        return (
            <form className="permission-delete-form" onSubmit={handleSubmit(onSubmit)}>
                {children({ permission, isPending })}
            </form>
        );
    }
}

@withFormDecorator<{}, Props>(Connected, {})
export class PermissionDeleteForm extends React.Component<OuterFormProps<{}, Props>> {}
