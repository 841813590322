import * as React from 'react';
import { SiteLanguages } from 'src/types/values/siteLanguage';
import { SiteLanguage } from 'src/react/siteLanguage/components/SiteLanguage';

export type SiteLanguageProps = {
    readonly language: SiteLanguages;
};

export default function (props: SiteLanguageProps): JSX.Element {
    const { language } = props;
    return (
        <SiteLanguage language={language} />
    );
}
