import './Selectbox.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { default as ReactSelect, FocusEventHandler } from 'react-select';
import { bind } from 'lodash-decorators/bind';
import { classList } from 'src/utils/classList';

const defaultCompare: <T>(value: T, option: T) => boolean = (v, o) => v === o;
const defaultPlaceholder = <FormattedMessage id="formField_input_placeholder"/>;

type Props<TValue> = {
    readonly name?: string;
    readonly className?: string;
    readonly value: TValue | TValue[] | null;
    readonly options: ReadonlyArray<TValue>;
    readonly disabled?: boolean;
    readonly clearable?: boolean;
    readonly placeholder?: React.ReactNode;
    readonly onBlur?: FocusEventHandler;
    readonly onFocus?: FocusEventHandler;
    readonly onChange?: (value: TValue | null) => void;
    readonly renderOption: (option: TValue) => React.ReactNode;
    readonly compareValue?: (value: TValue, option: TValue) => boolean;
    readonly isMulti?: boolean;
};
type Option<TOption> = {
    readonly label: TOption;
    readonly value: TOption;
};

export class Selectbox<TValue> extends React.Component<Props<TValue>> {
    public render(): JSX.Element {
        const {
            name,
            className,
            onBlur,
            onFocus,
            disabled = false,
            clearable = false,
            isMulti = false,
            placeholder = defaultPlaceholder,
        } = this.props;

        return (
            <ReactSelect className={classList(className, 'xss-selectbox')}
                         classNamePrefix="xss-selectbox"
                         name={name}
                         data-field={name}
                         isMulti={isMulti}
                         value={isMulti ? this.getSelectboxMultiValue() : this.getSelectboxValue()}
                         options={this.getSelectboxOptions()}
                         onChange={this.handleChange}
                         onBlur={onBlur}
                         onFocus={onFocus}
                         placeholder={placeholder}
                         isOptionSelected={this.isOptionSelected}
                         formatOptionLabel={this.renderOption}
                         controlShouldRenderValue={true}
                         isDisabled={disabled}
                         isClearable={clearable}
                         isSearchable={false}/>
        );
    }

    @bind
    private handleChange(value: any): void {
        const { onChange, isMulti } = this.props;
        if (!onChange) {
            return;
        }

        onChange(
            value === undefined || value === null
                ? isMulti ? [] : null
                : isMulti
                    ? value.map((item: any) => item.value)
                    : (Array.isArray(value) ? value[0].value : value.value)
        );
    }

    @bind
    private renderOption(option: Option<TValue>): React.ReactNode {
        const { renderOption } = this.props;
        return renderOption(option.value);
    }

    @bind
    private isOptionSelected(option: Option<TValue>): boolean {
        const { value } = this.props;
        if (value === null) {
            return false;
        }

        const { compareValue = defaultCompare } = this.props;
        if (Array.isArray(value)) {
            return value.filter((item) => compareValue(item, option.value)).length > 0;
        }
        return compareValue(option.value, value);
    }

    private getSelectboxMultiValue(): Option<TValue>[] {
        const { value } = this.props;
        if (value === null) {
            return [];
        }
        if (Array.isArray(value)) {
            return value.map((item) => ({ value: item, label: item }));
        }
        return [{ value, label: value }];
    }

    private getSelectboxValue(): Option<TValue> | null {
        const { value } = this.props;
        if (value === null) {
            return null;
        }
        if (Array.isArray(value)) {
            return value.length > 0 ? { value: value[0], label: value[0] } : null;
        }
        return { value, label: value };
    }

    private getSelectboxOptions(): Option<TValue>[] {
        const { options } = this.props;
        return options.map((value) => ({ value, label: value }));
    }
}
