import { SiteLanguageState } from 'src/react/siteLanguage/state/SiteLanguageState';
import { SITE_LANGUAGES_CHANGE_LANGUAGE_ACTIONS, SiteLanguagesChangeLanguageActions }
    from 'src/react/siteLanguage/actions/SiteLanguagesChangeLanguageActions';

export function siteLanguageChangeLanguageReducer(
    state: SiteLanguageState,
    action: SiteLanguagesChangeLanguageActions,
): SiteLanguageState {
    switch (action.type) {
        case SITE_LANGUAGES_CHANGE_LANGUAGE_ACTIONS.SUCCESS:
            return { ...state, language: action.data };
        default:
            return state;
    }
}
