import { IComponentOptions, IScope } from 'angular';
import { WidgetListController } from 'src/modules/widgetList/WidgetListController';
import * as WidgetListTemplate from './WidgetListTemplate.html';

export const WIDGET_LIST_COMPONENT: IComponentOptions = {
    controller: WidgetListController,
    controllerAs: 'widgetListCtrl',
    template: WidgetListTemplate,
    bindings: {
        params: '<',
        organizations: '<',
    },
};

export interface WidgetListScope extends IScope {
    readonly widgetListCtrl: WidgetListController;
}
