import { Ng1StateDeclaration } from '@uirouter/angularjs';
import { Widget } from 'src/types/entities/Widget';
import { ICrawlerTaskService } from 'src/modules/api/CrawlerTaskService';
import { IStatisticsFilterService } from 'src/modules/api/StatisticsFilterService';
import { monaco } from '@monaco-editor/react';
import { PARSER_CONFIG_JSON_SCHEMA } from 'src/constants/parserConfigJsonSchema';

export const ROUTES_STATES_SETTINGS: ReadonlyArray<Ng1StateDeclaration> = [
    {
        name: 'settings',
        parent: 'authorized:widget',
        url: '/settings?tab',
        component: 'settingsPage',
        reloadOnSearch: false,
        params: {
            tab: {
                type: 'string',
                value: 'general',
                squash: false,
            },
        },
        resolve: {
            $title: () => {
                return 'Settings';
            },
            monaco: () => monaco.init().then((monacoGlobal) => {
                monacoGlobal.languages.json.jsonDefaults.setDiagnosticsOptions({
                    validate: true,
                    schemas: [{
                        uri: 'http://levo-app.ch/parser-config-json-schema.json',
                        fileMatch: ['*'],
                        // @TODO load from backend-api via fetch and use autogenerated json schema from parser config
                        schema: PARSER_CONFIG_JSON_SCHEMA
                    }]
                });
                return monacoGlobal;
            }).catch((error) => console.error('An error occurred during initialization of Monaco: ', error)),
            crawlerTask: [
                'widget',
                'crawlerTaskService',
                (
                    widget: Widget,
                    crawlerTaskService: ICrawlerTaskService,
                ) => crawlerTaskService.list(widget, {
                    sort: ['-createdAt'],
                    page: 0,
                    pageSize: 1,
                }).then(({ data: [crawlerTask = null] }) => crawlerTask),
            ],
            statisticsFilter: [
                'widget',
                'statisticsFilterService',
                (
                    widget: Widget,
                    statisticsFilterService: IStatisticsFilterService,
                ) => statisticsFilterService.list(widget).then(({ data: [filter] }) => filter || null),
            ],
        },
    },
];
