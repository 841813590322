import './LoadingWrapper.scss';
import * as React from 'react';
import { classList } from 'src/utils/classList';
import { LoadingSpinner } from 'src/react/common/components/LoadingSpinner';

type Props = {
    readonly loading: boolean;
    readonly children: React.ReactNode;
};

export function LoadingWrapper({ loading, children }: Props): JSX.Element {
    return (
        <div className="xss-loading-wrapper">
            <Spinner loading={loading}>{children}</Spinner>
            <Content loading={loading}>{children}</Content>
        </div>
    );
}

function Spinner({ loading, children }: Props): JSX.Element | null {
    if (!loading) {
        return null;
    }

    const className = classList('xss-loading-wrapper__spinner', {
        'xss-loading-wrapper__spinner--overlay': !!children,
    });

    return (
        <div className={className}>
            <LoadingSpinner/>
        </div>
    );
}

function Content({ children }: Props): JSX.Element | null {
    if (!children) {
        return null;
    }

    return (
        <div className="xss-loading-wrapper__content">
            {children}
        </div>
    );
}
