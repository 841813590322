import { module } from 'angular';
import { LOADING_SPINNER_COMPONENT } from 'src/modules/loading/LoadingSpinnerComponent';
import { LOADING_WRAPPER_COMPONENT } from 'src/modules/loading/LoadingWrapperComponent';

export const LOADING_MODULE = module('loading', [])
    /**
     * @ngdoc directive
     * @name loadingSpinner
     * @restrict E
     */
    .component(
        'loadingSpinner',
        LOADING_SPINNER_COMPONENT,
    )
    /**
     * @ngdoc directive
     * @name loadingWrapper
     * @restrict E
     *
     * @param {expression} isLoading
     * @param {expression} hasContent
     */
    .component(
        'loadingWrapper',
        LOADING_WRAPPER_COMPONENT,
    );
