import './Pager.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { range } from 'lodash';
import { bind } from 'lodash-decorators/bind';
import { memoize } from 'lodash-decorators/memoize';
import { noop } from 'src/utils/noop';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { classList } from 'src/utils/classList';

type Props = {
    readonly currentPage: UnsignedInteger;
    readonly pageCount: UnsignedInteger;
    readonly maxSize: UnsignedInteger;
    readonly onChangePage: (page: UnsignedInteger) => void;
};

export class Pager extends React.Component<Props> {
    public render(): JSX.Element {
        return (
            <ul className="xss-pager">
                <Page key="btn-first-page"
                      type="first"
                      active={false}
                      disabled={this.isFirstPage()}
                      onClick={this.handleFirstPage}>
                    <FormattedMessage id="pager_first"/>
                </Page>

                <Page key="btn-prev-page"
                      type="prev"
                      active={false}
                      disabled={this.isFirstPage()}
                      onClick={this.handlePreviousPage}>
                    <FormattedMessage id="pager_prev"/>
                </Page>

                {this.renderPages()}

                <Page key="btn-next-page"
                      type="next"
                      active={false}
                      disabled={this.isLastPage()}
                      onClick={this.handleNextPage}>
                    <FormattedMessage id="pager_next"/>
                </Page>

                <Page key="btn-last-page"
                      type="last"
                      active={false}
                      disabled={this.isLastPage()}
                      onClick={this.handleLastPage}>
                    <FormattedMessage id="pager_last"/>
                </Page>
            </ul>
        );
    }

    private renderPages(): JSX.Element[] {
        const [from, to] = this.getVisibleRange();
        const { currentPage } = this.props;

        return range(from, to).map((page) => {
            return (
                <Page key={`btn-page-${page}`}
                      type="digit"
                      active={currentPage === page}
                      disabled={false}
                      onClick={this.getPageChangeHandler(page)}>{page}</Page>
            );
        });
    }

    private getVisibleRange(): [UnsignedInteger, UnsignedInteger] {
        const { currentPage, pageCount, maxSize } = this.props;

        const maxPage = currentPage > pageCount ? pageCount : currentPage;
        const block = Math.ceil(maxPage / maxSize) - 1;
        const start = block * maxSize;
        const delta = pageCount - start;
        const end = start + ((delta > maxSize) ? maxSize : delta);

        return [start + 1, end + 1];
    }

    @memoize
    private getPageChangeHandler(page: UnsignedInteger): () => void {
        return () => {
            const { onChangePage = noop } = this.props;
            onChangePage(page);
        };
    }

    private handlePageChanged(page: UnsignedInteger): void {
        const { onChangePage = noop } = this.props;
        onChangePage(page);
    }

    private isFirstPage(): boolean {
        return this.props.currentPage <= 1;
    }

    private isLastPage(): boolean {
        return this.props.currentPage >= this.props.pageCount;
    }

    @bind
    private handleFirstPage(): void {
        if (!this.isFirstPage()) {
            this.handlePageChanged(1);
        }
    }

    @bind
    private handlePreviousPage(): void {
        if (!this.isFirstPage()) {
            this.handlePageChanged(this.props.currentPage - 1);
        }
    }

    @bind
    private handleNextPage(): void {
        if (!this.isLastPage()) {
            this.handlePageChanged(this.props.currentPage + 1);
        }
    }

    @bind
    private handleLastPage(): void {
        if (!this.isLastPage()) {
            this.handlePageChanged(this.props.pageCount);
        }
    }
}

type PageProps = {
    readonly type: string;
    readonly active: boolean;
    readonly disabled: boolean;
    readonly children: React.ReactNode;
    readonly onClick: () => void;
};
function Page({ type, active, disabled, children, onClick }: PageProps): JSX.Element {
    const pageClassName = classList('xss-pager__page', `xss-pager__page--${type}`, {
        'xss-pager__page--active': active,
        'xss-pager__page--disabled': disabled,
    });
    const linkClassName = classList('xss-pager__link', `xss-pager__link--${type}`, {
        'xss-pager__link--active': active,
        'xss-pager__link--disabled': disabled,
    });

    return (
        <li className={pageClassName}>
            <a className={linkClassName} onClick={onClick}>{children}</a>
        </li>
    );
}
