import { Ng1StateDeclaration, StateParams, StateService } from '@uirouter/angularjs';
import { Widget } from 'src/types/entities/Widget';
import { StatisticsFilter } from 'src/types/entities/StatisticsFilter';
import { IStatisticsFilterService } from 'src/modules/api/StatisticsFilterService';
import { IStatisticsOverviewService } from 'src/modules/api/StatisticsOverviewService';
import { IStatisticsLastSearchService } from 'src/modules/api/StatisticsLastSearchService';
import { IStatisticsSuccessfulSearchService } from 'src/modules/api/StatisticsSuccessfulSearchService';
import { IStatisticsUnsuccessfulSearchService } from 'src/modules/api/StatisticsUnsuccessfulSearchService';
import { DEFAULT_DATE_RANGE } from 'src/react/common/constants/dateRanges.tsx';
import { DateRange } from 'src/types/values/DateRange';
import { createDateFilter, dateFormat, parseDate } from 'src/utils/dateFunctions';

export const ROUTES_STATES_DASHBOARD: ReadonlyArray<Ng1StateDeclaration> = [
    {
        name: 'dashboard',
        parent: 'authorized:widget',
        url: '/?dateFrom&dateTo',
        params: {
            dateFrom: {
                type: 'string',
                value: '',
                squash: true,
            },
            dateTo: {
                type: 'string',
                value: '',
                squash: true,
            },
        },
        component: 'dashboardPage',
        resolve: {
            $title: () => {
                return 'Dashboard';
            },
            onDatesChange: [
                '$state',
                ($state: StateService) => (dateRange: DateRange) => {
                    $state.go('dashboard', {
                        dateFrom: dateRange.from ? dateRange.from.format(dateFormat) : '',
                        dateTo: dateRange.to ? dateRange.to.format(dateFormat) : ''
                    });
                },
            ],
            dateRange: [
                '$stateParams',
                ($stateParams: StateParams): DateRange => ({
                    from: parseDate($stateParams.dateFrom) || DEFAULT_DATE_RANGE.dateRange.from,
                    to: parseDate($stateParams.dateTo) || DEFAULT_DATE_RANGE.dateRange.to,
                }),
            ],
            statisticsFilter: [
                'widget',
                'statisticsFilterService',
                (
                    widget: Widget,
                    statisticsFilterService: IStatisticsFilterService,
                ) => statisticsFilterService.list(widget).then(({ data: [filter] }) => filter || null),
            ],
            statisticsOverview: [
                'widget',
                'statisticsFilter',
                'statisticsOverviewService',
                'dateRange',
                (
                    widget: Widget,
                    statisticsFilter: StatisticsFilter | null,
                    statisticsOverviewService: IStatisticsOverviewService,
                    dateRange: DateRange,
                ) => statisticsOverviewService.get(widget, {
                    filterId: statisticsFilter ? statisticsFilter.id : null,
                    ...createDateFilter(dateRange)
                }),
            ],
            statisticsLastSearches: [
                'widget',
                'statisticsFilter',
                'statisticsLastSearchService',
                'dateRange',
                (
                    widget: Widget,
                    statisticsFilter: StatisticsFilter | null,
                    statisticsLastSearchService: IStatisticsLastSearchService,
                    dateRange: DateRange,
                ) => statisticsLastSearchService.list(widget, {
                    page: 0,
                    pageSize: 5,
                    sort: ['-date'],
                    filterId: statisticsFilter ? statisticsFilter.id : null,
                    ...createDateFilter(dateRange)
                }),
            ],
            statisticsSuccessfulSearches: [
                'widget',
                'statisticsFilter',
                'statisticsSuccessfulSearchService',
                'dateRange',
                (
                    widget: Widget,
                    statisticsFilter: StatisticsFilter | null,
                    statisticsSuccessfulSearchService: IStatisticsSuccessfulSearchService,
                    dateRange: DateRange,
                ) => statisticsSuccessfulSearchService.list(widget, {
                    query: '',
                    sort: ['-searchCount'],
                    page: 0,
                    pageSize: 10,
                    filterId: statisticsFilter ? statisticsFilter.id : null,
                    ...createDateFilter(dateRange)
                }),
            ],
            statisticsUnsuccessfulSearches: [
                'widget',
                'statisticsFilter',
                'statisticsUnsuccessfulSearchService',
                'dateRange',
                (
                    widget: Widget,
                    statisticsFilter: StatisticsFilter | null,
                    statisticsUnsuccessfulSearchService: IStatisticsUnsuccessfulSearchService,
                    dateRange: DateRange,
                ) => statisticsUnsuccessfulSearchService.list(widget, {
                    query: '',
                    sort: ['-searchCount'],
                    page: 0,
                    pageSize: 10,
                    filterId: statisticsFilter ? statisticsFilter.id : null,
                    ...createDateFilter(dateRange)
                }),
            ],
        },
    },
];
