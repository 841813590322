import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { NativeError } from 'src/errors/NativeError';
import { StatisticsTopSearch } from 'src/types/entities/StatisticsTopSearch';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import {
    StatisticsPageFilter
} from 'src/react/statistics/state/StatisticsPageFilter';
import {
    STATISTICS_UNSUCCESSFUL_UPDATE_ACTIONS
} from 'src/react/statistics/actions/StatisticsUnsuccessfulUpdateActions';
import { getStatisticsUnsuccessfulSearches } from 'src/react/statistics/services/statisticsUnsuccessfulSearches';

import { statisticsUpdateQueryString } from 'src/react/statistics/services/statisticsUpdateQueryString';

export function* statisticsUnsuccessfulUpdateSaga(
    { data: { filter, params } }: ActionRequest<StatisticsPageFilter>,
): SagaIterator {
    try {
        yield call(statisticsUpdateQueryString, params);
        yield put(STATISTICS_UNSUCCESSFUL_UPDATE_ACTIONS.pending());

        const searches: ListWithTotal<StatisticsTopSearch> =
            yield call(getStatisticsUnsuccessfulSearches, filter, params);

        yield put(STATISTICS_UNSUCCESSFUL_UPDATE_ACTIONS.success(searches));
    } catch (error) {
        yield put(STATISTICS_UNSUCCESSFUL_UPDATE_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
