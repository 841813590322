import * as React from 'react';
import { ActivityPage } from 'src/react/activity/components/ActivityPage';
import { Widget } from 'src/types/entities/Widget';
import { ActivityLogCategory, ActivityLogItem } from 'src/types/entities/ActivityLogItem';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { DateRange } from 'src/types/values/DateRange';

export type ActivityPageBridgeProps = {
    readonly widget: Widget;
    readonly activityLog: ListWithTotal<ActivityLogItem>;
    readonly query: string;
    readonly category: ActivityLogCategory;
    readonly page: UnsignedInteger;
    readonly dateRange: DateRange;
    readonly onCategoryChange: (category: string | null) => void;
    readonly onSearchChange: (query: string) => void;
    readonly onDatesChange: (dateRange: DateRange) => void;
    readonly onPageChange: (page: UnsignedInteger) => void;
};

export default function (props: ActivityPageBridgeProps): JSX.Element {
    return (
        <ActivityPage {...props} />
    );
}
