import { IPromise, IQService } from 'angular';

type PromiseFunction<TResult, TArgs extends any[]> = (...args: TArgs) => IPromise<TResult>;

export interface ISafePromiseWrapper {
    wrapPromiseFunction<TResult, TArgs extends any[]>(
        promiseFunction: PromiseFunction<TResult, TArgs>,
    ): PromiseFunction<TResult, TArgs>;
}

export class SafePromiseWrapper implements ISafePromiseWrapper {
    public static $inject = [
        '$q',
    ];
    public constructor(
        private $q: IQService
    ) {
    }

    public wrapPromiseFunction<TResult, TArgs extends any[]>(
        promiseFunction: PromiseFunction<TResult, TArgs>,
    ): PromiseFunction<TResult, TArgs> {
        let lastResult: IPromise<TResult> | undefined;

        return (...args: TArgs) => {
            const currResult = promiseFunction(...args);
            lastResult = currResult;

            const deferred = this.$q.defer<TResult>();
            currResult.then((data) => {
                if (lastResult === currResult) {
                    lastResult = undefined;
                    deferred.resolve(data);
                }
            }, (error) => {
                if (lastResult === currResult) {
                    lastResult = undefined;
                    deferred.reject(error);
                }
            });

            return deferred.promise;
        };
    }
}
