import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { NativeError } from 'src/errors/NativeError';
import { ItemForm } from 'src/types/dto/ItemForm';
import { ITEM_FORM_SAVE_ACTIONS, ItemFormSaveRequestData } from 'src/react/itemForm/actions/ItemFormSaveActions';
import { itemUpdate } from 'src/react/itemForm/services/itemUpdate';
import { itemCreate } from 'src/react/itemForm/services/itemCreate';
import { itemFormMapToApi } from 'src/react/itemForm/services/itemFormMapToApi';

export function* itemFormSaveSaga(
    { data: { item, formData } }: ActionRequest<ItemFormSaveRequestData>,
): SagaIterator {
    try {
        yield put(ITEM_FORM_SAVE_ACTIONS.pending());

        const mappedForm: ItemForm = yield call(itemFormMapToApi, formData);
        const saved = item
            ? yield call(itemUpdate, item, mappedForm, formData.keyPhrases)
            : yield call(itemCreate, mappedForm, formData.keyPhrases);

        yield put(ITEM_FORM_SAVE_ACTIONS.success(saved));
    } catch (error) {
        yield put(ITEM_FORM_SAVE_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
