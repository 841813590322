import './SettingsUrlBoostForm.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { bind } from 'lodash-decorators/bind';
import { parseNumberValue } from 'src/utils/parseNumberValue';
import { InnerFormProps, OuterFormProps, withFormDecorator } from 'src/decorators/withFormDecorator';
import { TextboxField } from 'src/react/forms/components/TextboxField';
import { HtmlButton } from 'src/react/common/components/Button';
import { SettingsUrlBoostFormData } from 'src/react/settings/types/SettingsUrlBoostFormData';
import { FormFieldError } from 'src/react/forms/components/FormFieldError';

type Props = {
    readonly onSubmit: (formData: SettingsUrlBoostFormData) => void;
};

class SettingsUrlBoostFormConnected extends React.Component<InnerFormProps<SettingsUrlBoostFormData, Props>> {
    public render(): JSX.Element {
        const { handleSubmit } = this.props;

        return (
            <form className="settings-url-boost-form" onSubmit={handleSubmit(this.handleFormSubmit)}>
                <div className="settings-url-boost-form__row">
                    <div className="settings-url-boost-form__name">
                        <div className="settings-url-boost-form__label">
                            <FormattedMessage id="settings_field_urlBoostPattern"/>
                        </div>
                        <div className="settings-url-boost-form__field">
                            <TextboxField name="urlPattern"/>
                        </div>
                        <div className="settings-url-boost-form__hint">
                            <FormattedMessage id="settings_hint_urlBoostPattern"/>
                        </div>
                        <FormFieldError className="settings-url-boost-form__error"
                                        field="urlPattern"/>
                    </div>
                    <div className="settings-url-boost-form__type">
                        <div className="settings-url-boost-form__label">
                            <FormattedMessage id="settings_field_urlBoostBoost"/>
                        </div>
                        <div className="settings-url-boost-form__field">
                            <TextboxField name="urlBoost"
                                          normalize={normalizeBoostValue}
                                          type="number"
                                          min={0}
                                          max={10}/>
                        </div>
                        <div className="settings-url-boost-form__hint">
                            <FormattedMessage id="settings_hint_urlBoostBoost"/>
                        </div>
                        <FormFieldError className="settings-url-boost-form__error"
                                        field="urlBoost"/>
                    </div>
                    <div className="settings-url-boost-form__btn">
                        <div className="settings-url-boost-form__label">
                            &nbsp;
                        </div>
                        <div className="settings-url-boost-form__field">
                            <HtmlButton block={true}
                                        intent="info"
                                        type="submit"
                                        disabled={this.isSubmitDisabled()}>
                                <FormattedMessage id="settings_urlBoost_create"/>
                            </HtmlButton>
                        </div>
                    </div>
                </div>
            </form>
        );
    }

    private isSubmitDisabled(): boolean {
        const { pristine, submitFailed, invalid } = this.props;

        return pristine
            ? true
            : submitFailed
                ? invalid
                : false;
    }

    @bind
    private handleFormSubmit(formData: SettingsUrlBoostFormData): void {
        const { onSubmit } = this.props;
        onSubmit(formData);

        const { reset } = this.props;
        reset();
    }
}

function normalizeBoostValue(value: unknown): number | null {
    const n = parseNumberValue(value);
    return n === null ? null : Math.max(Math.min(n, 10), 0);
}

@withFormDecorator<SettingsUrlBoostFormData, Props>(SettingsUrlBoostFormConnected, {})
export class SettingsUrlBoostForm extends React.Component<OuterFormProps<SettingsUrlBoostFormData, Props>> {}
