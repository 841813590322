import './DashboardOverview.scss';
import * as React from 'react';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import { Widget } from 'src/types/entities/Widget';
import { StatisticsOverview } from 'src/types/entities/StatisticsOverview';
import { OverviewArrow } from 'src/react/common/components/OverviewArrow';

type Props = {
    readonly widget: Widget;
    readonly overview: StatisticsOverview
};

export class DashboardOverview extends React.Component<Props> {
    public render(): JSX.Element {
        const { overview } = this.props;

        return (
            <div className="dashboard-overview">
                <div className="dashboard-overview__item">
                    <div className="dashboard-portlet dashboard-portlet--has-trend">
                        {this.getTotalSearchesTrend() !== 0 && (
                        <div className={getTrendsClass(this.getTotalSearchesTrend())}>
                            <div>
                                {this.getTotalSearchesTrend() > 0 ? '+' : ''}<FormattedNumber
                                value={this.getTotalSearchesTrend()}/>
                            </div>
                        </div>
                        )}
                        <div className="dashboard-overview__value">
                            <FormattedNumber value={overview.totalSearchCount}/>
                            {this.getTotalSearchesTrend() !== 0 && (
                                <OverviewArrow direction={this.getTotalSearchesTrend() > 0 ? 'up' : 'down'} />
                            )}
                        </div>
                        <div className="dashboard-overview__title dashboard-overview__title--1">
                            <FormattedMessage id="dashboard_overview_totalSearchCount"/>
                        </div>
                    </div>
                </div>

                <div className="dashboard-overview__item">
                    <div className="dashboard-portlet dashboard-portlet--has-trend">
                        {this.getSuccessRateTrend() !== 0 && (
                        <div className={getTrendsClass(this.getSuccessRateTrend())}>
                            <div>
                            {this.getSuccessRateTrend() >= 0 ? '+' : ''}<FormattedNumber
                            value={this.getSuccessRateTrend()}
                            style="percent"
                            maximumFractionDigits={2}
                            minimumFractionDigits={2}/>
                            </div>
                        </div>
                        )}
                        <div className="dashboard-overview__value">
                            <FormattedNumber value={this.getSuccessRate()}
                                             style="percent"
                                             maximumFractionDigits={2}
                                             minimumFractionDigits={2}/>
                            {this.getSuccessRateTrend() !== 0 && (
                            <OverviewArrow direction={this.getSuccessRateTrend() >= 0 ? 'up' : 'down'} />
                            )}
                        </div>
                        <div className="dashboard-overview__title dashboard-overview__title--3">
                            <FormattedMessage id="dashboard_overview_searchSuccessRate"/>
                        </div>
                    </div>
                </div>

                <div className="dashboard-overview__item">
                    <div className="dashboard-portlet">
                        <div className="dashboard-overview__value">
                            <FormattedNumber value={overview.successfulSearchCount}/>
                        </div>
                        <div className="dashboard-overview__title dashboard-overview__title--2">
                            <FormattedMessage id="dashboard_overview_successfulSearchCount"/>
                        </div>
                    </div>
                </div>

                <div className="dashboard-overview__item">
                    <div className="dashboard-portlet">
                        <div className="dashboard-overview__value">
                            <FormattedNumber value={overview.unsuccessfulSearchCount}/>
                        </div>
                        <div className="dashboard-overview__title dashboard-overview__title--4">
                            <FormattedMessage id="dashboard_overview_unsuccessfulSearchCount"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private getTotalSearchesTrend(): number {
        const { overview } = this.props;
        return overview.totalSearchCount - overview.totalSearchCountPreviousPeriod;
    }

    private getSuccessRate(): number {
        const { overview } = this.props;

        return overview.totalSearchCount > 0
            ? overview.successfulSearchCount / overview.totalSearchCount
            : 0;
    }

    private getSuccessRateTrend(): number {
        const { overview } = this.props;

        const successRatePreviousPeriod =  overview.totalSearchCountPreviousPeriod > 0
            ? overview.successfulSearchCountPreviousPeriod / overview.totalSearchCountPreviousPeriod
            : 0;
        return this.getSuccessRate() - successRatePreviousPeriod;
    }
}

function getTrendsClass(value: number): string {
    return `dashboard-overview__trend dashboard-overview__trend--${value >= 0 ? 'up' : 'down'}`;
}
