import { IHttpResponse, IPromise } from 'angular';
import { Config } from 'src/types/constants/Config';
import { Uuid } from 'src/types/values/Uuid';
import { Widget } from 'src/types/entities/Widget';
import { KeyPhraseGroup } from 'src/types/entities/KeyPhraseGroup';
import { KeyPhraseGroupForm } from 'src/types/dto/KeyPhraseGroupForm';
import { KeyPhraseGroupFilter } from 'src/types/dto/KeyPhraseGroupFilter';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { IApiService } from 'src/modules/api/ApiService';

export interface IKeyPhraseGroupService {
    get(
        widget: Readonly<Widget>,
        id: Uuid,
    ): IPromise<KeyPhraseGroup>;

    list(
        widget: Readonly<Widget>,
        filter: Readonly<KeyPhraseGroupFilter>,
    ): IPromise<ListWithTotal<KeyPhraseGroup>>;

    create(
        widget: Readonly<Widget>,
        formData: Readonly<KeyPhraseGroupForm>,
    ): IPromise<KeyPhraseGroup>;

    update(
        widget: Readonly<Widget>,
        keyPhrase: Readonly<KeyPhraseGroup>,
        formData: Readonly<KeyPhraseGroupForm>,
    ): IPromise<KeyPhraseGroup>;

    delete(
        widget: Readonly<Widget>,
        keyPhrase: Readonly<KeyPhraseGroup>,
    ): IPromise<void>;
}

export class KeyPhraseGroupService implements IKeyPhraseGroupService {
    public static $inject = [
        'config',
        'apiService',
    ];
    public constructor(
        private config: Config,
        private apiService: IApiService,
    ) {
    }

    public get(
        widget: Readonly<Widget>,
        id: Uuid,
    ): IPromise<KeyPhraseGroup> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
            'key-phrases-group',
            encodeURIComponent(id),
        ].join('/');

        return this.apiService
            .makeGETRequest<KeyPhraseGroup>(requestUrl)
            .then(extractResponseData);
    }

    public list(
        widget: Readonly<Widget>,
        filter: Readonly<KeyPhraseGroupFilter>,
    ): IPromise<ListWithTotal<KeyPhraseGroup>> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
            'key-phrases-group',
        ].join('/');

        return this.apiService.makeGETRequest<ListWithTotal<KeyPhraseGroup>>(
            requestUrl,
            { params: filter },
        ).then(extractResponseData);
    }

    public create(
        widget: Readonly<Widget>,
        formData: Readonly<KeyPhraseGroupForm>,
    ): IPromise<KeyPhraseGroup> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
            'key-phrases-group',
        ].join('/');

        return this.apiService.makePOSTRequest<KeyPhraseGroupForm, KeyPhraseGroup>(
            requestUrl,
            formData,
        ).then(extractResponseData);
    }

    public update(
        widget: Readonly<Widget>,
        keyPhrase: Readonly<KeyPhraseGroup>,
        formData: Readonly<KeyPhraseGroupForm>,
    ): IPromise<KeyPhraseGroup> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
            'key-phrases-group',
            encodeURIComponent(keyPhrase.id),
        ].join('/');

        return this.apiService.makePUTRequest<KeyPhraseGroupForm, KeyPhraseGroup>(
            requestUrl,
            formData,
        ).then(extractResponseData);
    }

    public delete(
        widget: Readonly<Widget>,
        keyPhrase: Readonly<KeyPhraseGroup>,
    ): IPromise<void> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
            'key-phrases-group',
            encodeURIComponent(keyPhrase.id),
        ].join('/');

        return this.apiService.makeDELETERequest<void>(
            requestUrl,
        ).then(extractResponseData);
    }
}

function extractResponseData<T>(response: IHttpResponse<T>): T {
    return response.data;
}
