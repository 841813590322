import './DemoPageHighlight.scss';
import * as React from 'react';

type Props = {
    readonly text: string;
    readonly html: ReadonlyArray<string> | undefined;
};

export function DemoPageHighlight({ text, html }: Props): JSX.Element {
    return html && html.length
        ? <span className="demo-page-highlight" dangerouslySetInnerHTML={{ __html: html.join('') }}/>
        : <span className="demo-page-highlight">{text}</span>;
}
