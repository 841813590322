import { DemoPageState } from 'src/react/demoPage/state/DemoPageState';
import { DEMO_SEARCH_ACTIONS, DemoSearchActions } from 'src/react/demoPage/actions/DemoSearchActions';

export function demoSearchReducer(
    state: DemoPageState,
    action: DemoSearchActions,
): DemoPageState {
    switch (action.type) {
        case DEMO_SEARCH_ACTIONS.PENDING:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case DEMO_SEARCH_ACTIONS.SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data,
                error: null,
            };

        case DEMO_SEARCH_ACTIONS.FAILURE:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.data,
            };

        default:
            return state;
    }
}
