import './ModalBodyFade.scss';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import { TransitionProps } from 'react-transition-group/Transition';
import { MAX_ANIMATION_TIMEOUT } from 'src/constants/animation';
import { transitionEndListener } from 'src/utils/transitionEndListener';

export function ModalBodyFade(props: TransitionProps): JSX.Element {
    return (
        <CSSTransition {...props}
                       classNames="xss-modal-body-fade"
                       appear={true}
                       timeout={MAX_ANIMATION_TIMEOUT}
                       addEndListener={transitionEndListener}/>
    );
}
