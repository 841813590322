import * as React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Router } from 'react-router';
import { IComponentController, IComponentOptions, IOnChangesObject, IRootScopeService } from 'angular';
import { ErrorBoundary } from 'src/react/common/components/ErrorBoundary';

import { STORE } from 'src/react/root/constants/store';
import { HISTORY } from 'src/react/root/constants/history';
import { I18N_LOCALES } from 'src/react/root/constants/i18nLocales';
import { I18N_MESSAGES } from 'src/react/root/constants/i18nMessages';
import { CUSTOM_MESSAGE_FORMATS } from 'src/react/root/constants/customMessageFormats';

export function ngReactComponent<TProps extends {}>(
    factory: () => React.ComponentType<TProps>,
    bindings: ReadonlyArray<keyof TProps>,
): IComponentOptions {
    class Controller implements IComponentController {
        private $$props: TProps | undefined;
        private $$component: React.ComponentType<TProps> = factory();

        private unsubscribeHmr = this.$rootScope.$on('hmr', () => {
            this.$$component = factory();
            this.mountComponent();
        });

        public static $inject = [
            '$element',
            '$rootScope',
        ];
        public constructor(
            private $element: JQLite,
            private $rootScope: IRootScopeService,
        ) {}

        public $onChanges(changes: IOnChangesObject): void {
            this.updateComponentProps(changes);
            this.mountComponent();
        }

        public $onDestroy(): void {
            this.unsubscribeHmr();
            this.destroyComponentProps();
            this.unmountComponent();
        }

        private updateComponentProps(changes: IOnChangesObject): void {
            this.$$props = bindings.reduce((result, binding) => (
                changes[binding as string]
                    ? Object.assign({}, result, { [binding]: changes[binding as string].currentValue })
                    : result
            ), this.$$props || {} as TProps);
        }

        private destroyComponentProps(): void {
            this.$$props = undefined;
        }

        private mountComponent(): void {
            const { $$props: props, $$component: Component } = this;
            if (!props || !Component) {
                return;
            }
            const locale = STORE.getState().locale.language;
            const i18nLocale = I18N_LOCALES[locale];
            const i18nMessages = I18N_MESSAGES[locale];

            render((
                <Provider store={STORE}>
                    <IntlProvider locale={i18nLocale} messages={i18nMessages} formats={CUSTOM_MESSAGE_FORMATS}>
                        <Router history={HISTORY}>
                            <ErrorBoundary>
                                <Component {...props}/>
                            </ErrorBoundary>
                        </Router>
                    </IntlProvider>
                </Provider>
            ), this.$element[0]);
        }

        private unmountComponent(): void {
            unmountComponentAtNode(this.$element[0]);
        }
    }

    return {
        controller: Controller,
        controllerAs: 'never',
        template: '',
        transclude: false,
        bindings: bindings.reduce((result, binding) => ({ ...result, [binding]: '<' }), {}),
    };
}
