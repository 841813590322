import { createActions } from 'src/utils/createActions';
import { OrganizationListItem } from 'src/react/organization/state/OrganizationListItem';

export const ORGANIZATION_LIST_UPDATE_OPEN_ACTIONS =
    createActions<OrganizationListItem, OrganizationListItem>('ORGANIZATION_LIST_UPDATE_OPEN');

export const ORGANIZATION_LIST_UPDATE_CANCEL_ACTIONS =
    createActions<void, void>('ORGANIZATION_LIST_UPDATE_CANCEL');

export const ORGANIZATION_LIST_UPDATE_SUBMIT_ACTIONS =
    createActions<void, OrganizationListItem>('ORGANIZATION_LIST_UPDATE_SUBMIT');
