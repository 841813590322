import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { NativeError } from 'src/errors/NativeError';
import { Item } from 'src/types/entities/Item';
import { Uuid } from 'src/types/values/Uuid';
import { ITEM_FORM_LOAD_ITEM_ACTIONS } from 'src/react/itemForm/actions/ItemFormLoadItemActions';
import { itemLoad } from 'src/react/itemForm/services/itemLoad';

export function* itemFormLoadItemSaga(
    { data: itemId }: ActionRequest<Uuid | null>,
): SagaIterator {
    try {
        yield put(ITEM_FORM_LOAD_ITEM_ACTIONS.pending());

        const item: Item = itemId ? yield call(itemLoad, itemId) : null;

        yield put(ITEM_FORM_LOAD_ITEM_ACTIONS.success(item));
    } catch (error) {
        yield put(ITEM_FORM_LOAD_ITEM_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
