import { chainReducers } from 'src/utils/chainReducers';
import { organizationListSeedReducer } from 'src/react/organization/reducers/OrganizationListSeedReducer';
import { organizationListLeaveReducer } from 'src/react/organization/reducers/OrganizationListLeaveReducer';
import { organizationListDeleteReducer } from 'src/react/organization/reducers/OrganizationListDeleteReducer';
import { organizationListCreateReducer } from 'src/react/organization/reducers/OrganizationListCreateReducer';
import { organizationListUpdateReducer } from 'src/react/organization/reducers/OrganizationListUpdateReducer';
import { organizationListTeamReducer } from 'src/react/organization/reducers/OrganizationListTeamReducer';

export const organizationListReducer = chainReducers(
    organizationListSeedReducer,
    organizationListLeaveReducer,
    organizationListDeleteReducer,
    organizationListCreateReducer,
    organizationListUpdateReducer,
    organizationListTeamReducer,
);
