import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { Organization } from 'src/types/entities/Organization';
import { OrganizationForm } from 'src/types/dto/OrganizationForm';
import { getOrganizationEndpoint } from 'src/react/api/selectors/getEndpoint';
import { IOrganizationEndpoint } from 'src/react/api/endpoints/OrganizationEndpoint';

export function* organizationUpdate(
    organization: Organization,
    formData: OrganizationForm,
): SagaIterator {
    const organizationEndpoint: IOrganizationEndpoint = yield select(getOrganizationEndpoint);

    return yield call({
        fn: organizationEndpoint.update,
        context: organizationEndpoint,
    }, organization.id, formData, {});
}
