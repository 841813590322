import './KeyPhraseSynonymsAlert.scss';
import * as React from 'react';

type Props = {
    readonly children: React.ReactNode;
};

export function KeyPhraseSynonymsAlert({ children }: Props): JSX.Element {
    return (
        <div className="keyphrase-synonyms-alert">
            <div className="keyphrase-synonyms-alert__icon">
                <i className="si si-info"/>
            </div>
            <div className="keyphrase-synonyms-alert__text">
                {children}
            </div>
        </div>
    );
}
