import angular from 'angular';

angular.module('backend.widgets.generateKey', []).factory('generateKey', [
    function () {
        return function (length) {
            let apiKey = '',
                possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

            for (let i = 0; i < length; i++) {
                apiKey += possible.charAt(Math.floor(Math.random() * possible.length));
            }

            return apiKey;
        };
    },
]);
