import './KeyPhraseSynonyms.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { bind } from 'lodash-decorators/bind';
import { RootState } from 'src/react/root/state/RootState';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { connectDecorator } from 'src/decorators/connectDecorator';
import { LoadingWrapper } from 'src/react/common/components/LoadingWrapper';
import { HtmlButton } from 'src/react/common/components/Button';
import { ModalBody, ModalClose, ModalFooter, ModalHeader } from 'src/react/common/components/Modal';
import { KEY_PHRASE_SYNONYMS_CHECK_ACTIONS } from 'src/react/keyPhraseSynonyms/actions/KeyPhraseSynonymsCheckActions';
import { KEY_PHRASE_SYNONYMS_RESET_ACTIONS } from 'src/react/keyPhraseSynonyms/actions/KeyPhraseSynonymsResetActions';
import { KeyPhraseSynonymsInput } from 'src/react/keyPhraseSynonyms/components/KeyPhraseSynonymsInput';
import { KeyPhraseSynonymsAlert } from 'src/react/keyPhraseSynonyms/components/KeyPhraseSynonymsAlert';

type OwnProps = {
    readonly title: string;
    readonly synonyms: ReadonlyArray<string>;
    readonly keyPhrase: KeyPhrase | null;

    readonly onSubmit: (synonyms: ReadonlyArray<string>) => void;
    readonly onCancel: () => void;
};
type StateProps = {
    readonly duplicates: ReadonlyArray<KeyPhrase>;
};
type DispatchProps = {
    readonly checkDuplicates: (synonyms: ReadonlyArray<string>) => void;
    readonly resetDuplicates: () => void;
};
type Props =
    & OwnProps
    & StateProps
    & DispatchProps;
type State = {
    readonly synonyms: ReadonlyArray<string>;
    readonly isSaving: boolean;
};

class KeyPhraseSynonymsConnected extends React.Component<Props, State> {
    public readonly state: State = {
        synonyms: this.props.synonyms,
        isSaving: false,
    };

    public componentDidMount(): void {
        const { checkDuplicates, synonyms } = this.props;
        checkDuplicates(synonyms);
    }

    public componentWillUnmount(): void {
        const { resetDuplicates } = this.props;
        resetDuplicates();
    }

    public render(): JSX.Element {
        const { title } = this.props;
        const { synonyms, isSaving } = this.state;

        const removedSynonyms = this.getRemovedSynonyms();
        const duplicateSynonyms = this.getDuplicateSynonyms();

        return (
            <form className="keyphrase-synonyms" onSubmit={this.handleSubmit}>
                <ModalHeader>
                    <ModalClose onClick={this.handleCancel}/>
                    <FormattedMessage id="keyPhraseSynonyms_modalTitle"/>
                </ModalHeader>
                <ModalBody className="keyphrase-synonyms__content">
                    <LoadingWrapper loading={isSaving}>
                        <div className="keyphrase-synonyms__title">
                            {title}
                        </div>
                        <label className="keyphrase-synonyms__label">
                            <FormattedMessage id="keyPhraseSynonyms_synonymsField"/>
                        </label>
                        <div className="keyphrase-synonyms__field">
                            <KeyPhraseSynonymsInput value={synonyms}
                                                    onChange={this.handleChange}/>
                        </div>

                        {removedSynonyms.length > 0 && (
                            <div className="keyphrase-synonyms__warning">
                                <KeyPhraseSynonymsAlert>
                                    <div className="keyphrase-synonyms__alert-text">
                                        <FormattedMessage id="keyPhraseSynonyms_removedWarning"/>
                                    </div>
                                    <ul className="keyphrase-synonyms__alert-list">
                                        {removedSynonyms.map((synonym) => <li key={synonym}>{synonym}</li>)}
                                    </ul>
                                </KeyPhraseSynonymsAlert>
                            </div>
                        )}
                        {duplicateSynonyms.length > 0 && (
                            <div className="keyphrase-synonyms__warning">
                                <KeyPhraseSynonymsAlert>
                                    <div className="keyphrase-synonyms__alert-text">
                                        <FormattedMessage id="keyPhraseSynonyms_duplicateWarning"/>
                                    </div>
                                    <ul className="keyphrase-synonyms__list">
                                        {duplicateSynonyms.map((synonym) => <li key={synonym.id}>{synonym.text}</li>)}
                                    </ul>
                                </KeyPhraseSynonymsAlert>
                            </div>
                        )}
                    </LoadingWrapper>
                </ModalBody>
                <ModalFooter className="keyphrase-synonyms__footer">
                    <HtmlButton block={false}
                                type="button"
                                intent="none"
                                onClick={this.handleCancel}>
                        <FormattedMessage id="keyPhraseSynonyms_cancelButton"/>
                    </HtmlButton>
                    <HtmlButton block={false}
                                type="submit"
                                intent="secondary"
                                disabled={isSaving}>
                        <FormattedMessage id="keyPhraseSynonyms_submitButton"/>
                    </HtmlButton>
                </ModalFooter>
            </form>
        );
    }

    @bind
    private handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
        event.preventDefault();

        const { synonyms } = this.state;
        const { onSubmit } = this.props;
        onSubmit(synonyms);

        this.setState({ isSaving: true });
    }

    @bind
    private handleCancel(): void {
        const { onCancel } = this.props;
        onCancel();
    }

    @bind
    private handleChange(synonyms: ReadonlyArray<string>): void {
        const { checkDuplicates } = this.props;
        checkDuplicates(synonyms);

        this.setState({ synonyms });
    }

    private getRemovedSynonyms(): string[] {
        const { keyPhrase } = this.props;
        if (!keyPhrase) {
            return [];
        }

        const { synonyms } = this.state;
        return keyPhrase.synonyms.filter((it) => !synonyms.includes(it));
    }

    private getDuplicateSynonyms(): KeyPhrase[] {
        const { keyPhrase, duplicates } = this.props;
        return keyPhrase
            ? duplicates.filter((it) => it.id !== keyPhrase.id)
            : duplicates.slice();
    }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
    duplicates: (state) => state.keyPhraseSynonyms.duplicates,
});

const mapDispatchToProps: DispatchProps = {
    checkDuplicates: (synonyms) => KEY_PHRASE_SYNONYMS_CHECK_ACTIONS.request(synonyms),
    resetDuplicates: () => KEY_PHRASE_SYNONYMS_RESET_ACTIONS.request(),
};

@connectDecorator<OwnProps, StateProps, DispatchProps>(KeyPhraseSynonymsConnected, mapStateToProps, mapDispatchToProps)
export class KeyPhraseSynonyms extends React.Component<OwnProps> {}
