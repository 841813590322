import { createSelector } from 'reselect';
import { assertNever } from 'src/utils/assertion';
import { getModalState } from 'src/react/organization/selectors/getModalState';

export const getSelectedOrganization = createSelector([
    getModalState,
], (modalState) => {
    switch (modalState.type) {
        case 'update':
        case 'delete':
        case 'leave':
        case 'team':
            return modalState.organization;

        default:
            return assertNever(
                'Organization modal has to be opened first',
                { modalState },
            );
    }
});
