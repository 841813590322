import './DemoPageSettings.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { bind } from 'lodash-decorators/bind';
import { Checkbox } from 'src/react/common/components/Checkbox';
import { SearchMatchMode } from 'src/types/values/SearchMatchMode';
import { SearchFuzziness } from 'src/types/values/SearchFuzziness';

type Props = {
    readonly matchMode: SearchMatchMode;
    readonly setMatchMode: (matchMode: SearchMatchMode) => void;

    readonly fuzziness: SearchFuzziness;
    readonly setFuzziness: (fuzziness: SearchFuzziness) => void;

    readonly highlight: boolean;
    readonly setHighlight: (highlight: boolean) => void;
};

export class DemoPageSettings extends React.Component<Props> {
    public render(): React.ReactNode {
        const { highlight, fuzziness, matchMode } = this.props;

        return (
            <div className="demo-page-settings">
                <div className="demo-page-settings__column">
                    <div className="demo-page-settings__header">
                        <FormattedMessage id="searchDemo_settingsGroup_highlight"/>
                    </div>
                    <div className="demo-page-settings__control">
                        <label className="demo-page-settings__checkbox">
                            <Checkbox className="demo-page-settings__checkbox-input"
                                      onChange={this.toggleHighlight}
                                      checked={highlight}/>

                            <div className="demo-page-settings__checkbox-label">
                                <FormattedMessage id="searchDemo_enableHighlight"/>
                            </div>
                        </label>
                    </div>
                </div>
                <div className="demo-page-settings__column">
                    <div className="demo-page-settings__header">
                        <FormattedMessage id="searchDemo_settingsGroup_searchLogic"/>
                    </div>
                    <div className="demo-page-settings__control">
                        <label className="demo-page-settings__checkbox">
                            <Checkbox className="demo-page-settings__checkbox-input"
                                      onChange={this.toggleFuzziness}
                                      checked={fuzziness !== 0}/>

                            <div className="demo-page-settings__checkbox-label">
                                <FormattedMessage id="searchDemo_enableFuzziness"/>
                            </div>
                        </label>
                    </div>
                    <div className="demo-page-settings__control">
                        <label className="demo-page-settings__checkbox">
                            <Checkbox className="demo-page-settings__checkbox-input"
                                      onChange={this.togglePrefixMatch}
                                      checked={matchMode === SearchMatchMode.Prefix}/>

                            <div className="demo-page-settings__checkbox-label">
                                <FormattedMessage id="searchDemo_enablePrefixMatch"/>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        );
    }

    @bind
    private toggleHighlight(event: React.ChangeEvent<HTMLInputElement>): void {
        const { setHighlight } = this.props;
        setHighlight(event.target.checked);
    }

    @bind
    private toggleFuzziness(event: React.ChangeEvent<HTMLInputElement>): void {
        const { setFuzziness } = this.props;
        setFuzziness(event.target.checked ? 'auto' : 0);
    }

    @bind
    private togglePrefixMatch(event: React.ChangeEvent<HTMLInputElement>): void {
        const { setMatchMode } = this.props;
        setMatchMode(event.target.checked ? SearchMatchMode.Prefix : SearchMatchMode.FullText);
    }
}
