import { DemoPageState } from 'src/react/demoPage/state/DemoPageState';
import { DEMO_FILTER_ACTIONS, DemoFilterActions } from 'src/react/demoPage/actions/DemoFilterActions';

export function demoFilterReducer(
    state: DemoPageState,
    action: DemoFilterActions,
): DemoPageState {
    switch (action.type) {
        case DEMO_FILTER_ACTIONS.PENDING:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case DEMO_FILTER_ACTIONS.SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data,
                error: null,
            };

        case DEMO_FILTER_ACTIONS.FAILURE:
            return {
                ...state,
                loading: false,
                error: action.data,
                data: null,
            };

        default:
            return state;
    }
}
