import { ActionAny, createActions } from 'src/utils/createActions';
import { KeyPhraseGroup } from 'src/types/entities/KeyPhraseGroup';
import { Uuid } from 'src/types/values/Uuid';

export type KeyPhraseGroupIsExistRequestData = {
    readonly groupName: string;
    readonly groupId: Uuid | null;
};
export type KeyPhraseGroupIsExistActions =
    ActionAny<KeyPhraseGroupIsExistRequestData, KeyPhraseGroup | null>;
export const KEY_PHRASE_GROUP_IS_EXIST_ACTIONS =
    createActions<KeyPhraseGroupIsExistRequestData, KeyPhraseGroup | null>(
            'KEY_PHRASE_GROUP_IS_EXIST_ACTIONS'
    );
