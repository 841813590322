import './DashboardPage.scss';
import * as React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { bind } from 'lodash-decorators/bind';
import { Widget } from 'src/types/entities/Widget';
import { StatisticsOverview } from 'src/types/entities/StatisticsOverview';
import { DashboardOverview } from 'src/react/dashboard/components/DashboardOverview';
import { DashboardLastSearchList } from 'src/react/dashboard/components/DashboardLastSearchList';
import { DashboardTopSearchTable } from 'src/react/dashboard/components/DashboardTopSearchTable';
import { DateRangePicker } from 'src/react/common/components/DateRangePicker';
import { StatisticsLastSearch } from 'src/types/entities/StatisticsLastSearch';
import { StatisticsTopSearch } from 'src/types/entities/StatisticsTopSearch';
import { DateRange } from 'src/types/values/DateRange';

type Props = {
    readonly widget: Widget;
    readonly overview: StatisticsOverview;
    readonly lastSearches: ReadonlyArray<StatisticsLastSearch>;
    readonly successfulSearches: ReadonlyArray<StatisticsTopSearch>;
    readonly unsuccessfulSearches: ReadonlyArray<StatisticsTopSearch>;
    readonly dateRange: DateRange;
    readonly onDatesChange: (dateRange: DateRange) => void;
};

type State = {};

export class DashboardPage extends React.Component<Props, State> {
    public render(): JSX.Element {
        const {
            widget,
            overview,
            dateRange,
            lastSearches,
            successfulSearches,
            unsuccessfulSearches,
        } = this.props;

        return (
            <div className="dashboard-page">
                <div className="top-block">
                    <div className="top-block__left">
                        <h1><FormattedMessage id="dashboard_pageTitle"/></h1>
                        <span className="top-block__subtitle">
                            <FormattedMessage id="dashboard_subtitle"/>
                        </span>
                    </div>
                    <div className="top-block__right">
                        <DateRangePicker id="dashboard-date-range"
                                         clearable={false}
                                         value={dateRange}
                                         onChange={this.handleDatesChange}/>
                    </div>
                </div>

                <div className="dashboard-page__content">
                    <div className="dashboard-page__overview">
                        <DashboardOverview widget={widget}
                                           overview={overview}/>
                    </div>
                    <div className="dashboard-page__statistics">
                        <div className="dashboard-page__top-searches">
                            <div className="dashboard-portlet">
                                <div className="dashboard-portlet__title">
                                    <FormattedMessage
                                        id="dashboard_successfulSearches"/>

                                    <Link to={this.getSuccessfulSearchLocation()}
                                          onClick={stopPropagation}
                                          className="dashboard-portlet__action">
                                        <i className="si si-External-link"/>
                                    </Link>
                                </div>
                                <div className="dashboard-portlet__description">
                                    <FormattedMessage
                                        id="dashboard_successfulSearches_description"
                                        values={{
                                            successfulSearches: (
                                                <FormattedNumber value={overview.successfulSearchCount}/>
                                            ),
                                            successRate: (
                                                <FormattedNumber value={this.getSuccessRate()}
                                                                 style="percent"
                                                                 maximumFractionDigits={2}
                                                                 minimumFractionDigits={2}/>
                                            ),
                                        }}
                                    />
                                </div>
                                <DashboardTopSearchTable widget={widget}
                                                         topSearches={successfulSearches}/>
                            </div>
                        </div>
                        <div className="dashboard-page__last-searches">
                            <DashboardLastSearchList widget={widget}
                                                     lastSearchesLocation={this.getLastSearchesLocation()}
                                                     lastSearches={lastSearches}/>
                        </div>
                    </div>
                    <div className="dashboard-page__statistics">
                        <div className="dashboard-portlet">
                            <div className="dashboard-portlet__title">
                                <FormattedMessage
                                    id="dashboard_unsuccessfulSearches"/>
                                <Link to={this.getUnsuccessfulSearchLocation()}
                                      onClick={stopPropagation}
                                      className="dashboard-portlet__action">
                                    <i className="si si-External-link"/>
                                </Link>
                            </div>
                            <div className="dashboard-portlet__description">
                                <FormattedMessage
                                    id="dashboard_unsuccessfulSearches_description"
                                    values={{
                                        unsuccessfulSearches: (
                                            <FormattedNumber value={overview.unsuccessfulSearchCount}/>
                                        ),
                                        unsuccessfulRate: (
                                            <FormattedNumber value={this.getUnsuccessRate()}
                                                             style="percent"
                                                             maximumFractionDigits={2}
                                                             minimumFractionDigits={2}/>
                                        ),
                                        emptySearches: (
                                            <FormattedNumber value={overview.emptySearchCount}/>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="dashboard-page__blocks-container">
                                <DashboardTopSearchTable widget={widget}
                                                         topSearches={unsuccessfulSearches}/>

                                <div className="dashboard-page__block-explanation">
                                    <h4><FormattedMessage id="dashboard_unsuccessfulSearches_explanation_header" /></h4>
                                    <p>
                                        <FormattedMessage
                                            id="dashboard_unsuccessfulSearches_explanation_description" />
                                    </p>
                                    <hr/>
                                    <dt>
                                        <h4>
                                            <FormattedMessage
                                                id="dashboard_unsuccessfulSearches_explanation_solutions_header" />
                                        </h4>
                                    </dt>
                                    <dd>
                                        <i className="si si-Items" />
                                        <FormattedMessage
                                            id="dashboard_unsuccessfulSearches_explanation_solutions_item1" />
                                    </dd>
                                    <dd>
                                        <i className="si si-Tag" />
                                        <FormattedMessage
                                            id="dashboard_unsuccessfulSearches_explanation_solutions_item2" />
                                    </dd>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private getSuccessfulSearchLocation(): LocationDescriptor {
        const { widget, dateRange } = this.props;

        return (
            `/${encodeURIComponent(widget.id)}/statistics` +
            `?tab=successful&${this.getDateParams(dateRange)}`
        );
    }

    private getUnsuccessfulSearchLocation(): LocationDescriptor {
        const { widget, dateRange } = this.props;

        return (
            `/${encodeURIComponent(widget.id)}/statistics` +
            `?tab=unsuccessful&${this.getDateParams(dateRange)}`
        );
    }

    private getLastSearchesLocation(): LocationDescriptor {
        const { widget, dateRange } = this.props;

        return (
            `/${encodeURIComponent(widget.id)}/statistics` +
            `?tab=last&${this.getDateParams(dateRange)}&sort=-date`
        );
    }

    private getDateParams({ from, to }: DateRange): string {
        if (!from || !to) {
            return '';
        }

        const dateFormat = 'YYYY-MM-DD';
        return `&dateFrom=${from.format(dateFormat)}&dateTo=${to.format(dateFormat)}`;
    }

    @bind
    private handleDatesChange(dateRange: DateRange): void {
        const { onDatesChange } = this.props;
        onDatesChange(dateRange);
    }

    private getSuccessRate(): number {
        const { overview } = this.props;

        return overview.totalSearchCount > 0
            ? overview.successfulSearchCount / overview.totalSearchCount
            : 0;
    }

    private getUnsuccessRate(): number {
        const { overview } = this.props;

        return overview.totalSearchCount > 0
            ? overview.unsuccessfulSearchCount / overview.totalSearchCount
            : 0;
    }
}

function stopPropagation(event: React.MouseEvent<unknown>): void {
    event.stopPropagation();
}
