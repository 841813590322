import { Uuid } from 'src/types/values/Uuid';
import { DateTime } from 'src/types/values/DateTime';

export enum ActivityLogCategory {
    Crawler = 'crawler',
    Item = 'item',
    Keyword = 'keyword',
    KeywordGroup = 'keywordGroup',
}

export enum ActivityLogKeywordGroupEvent {
    ADD_KEYWORD_GROUP = 'keyword_group_add',
    DELETE_KEYWORD_GROUP = 'keyword_group_delete',
    UPDATE_KEYWORD_GROUP = 'keyword_group_update',
}

export enum ActivityLogCrawlerEvent {
    CRAWLER_STARTED = 'crawler_started',
    CRAWLER_STOPPED = 'crawler_stopped',
    CRAWLER_SUCCESS = 'crawler_success',
    CRAWLER_PROBLEM = 'crawler_problem',
}

export enum ActivityLogKeywordEvent {
    ADD_KEYWORD = 'keyword_add',
    HIDE_KEYWORD = 'keyword_hide',
    DELETE_KEYWORD = 'keyword_delete',
    REACTIVATE_KEYWORD = 'keyword_reactivate',
    CHANGE_KEYWORD_ORDER = 'keyword_change_order',
}

export enum ActivityLogItemEvent {
    ADD_ITEM = 'item_add',
    EDIT_ITEM = 'item_edit',
    HIDE_ITEM = 'item_hide',
    REACTIVATE_ITEM = 'item_reactivate',
    DELETE_ITEM = 'item_delete',
}

type ActivityLogUser = {
    email: string;
} | null;

type BaseActivityLogRecord = {
    readonly id: Uuid;
    readonly widgetId: Uuid;
    readonly createdAt: DateTime;
    readonly category: ActivityLogCategory;
    readonly user: ActivityLogUser;
};

export type ActivityLogCrawlerRecord = BaseActivityLogRecord & {
    readonly category: ActivityLogCategory.Crawler;
    readonly event: ActivityLogCrawlerEvent;
    readonly meta: {
        taskId: Uuid;
    };
};
export type ActivityLogItemRecord = BaseActivityLogRecord & {
    readonly category: ActivityLogCategory.Item;
    readonly event: ActivityLogItemEvent;
    readonly meta: {
        itemId: Uuid,
        itemName: string,
    };
};
export type ActivityLogKeywordRecord = BaseActivityLogRecord & {
    readonly category: ActivityLogCategory.Keyword;
    readonly event: ActivityLogKeywordEvent;
    readonly meta: {
        keyphraseId: Uuid,
        keyphraseName: string,
    };
};
export type ActivityLogKeywordGroupRecord = BaseActivityLogRecord & {
    readonly category: ActivityLogCategory.KeywordGroup;
    readonly event: ActivityLogKeywordGroupEvent;
    readonly meta: {
        keyphraseGroupId: Uuid,
        keyphraseGroupName: string,
    };
};

export type ActivityLogItem = ActivityLogCrawlerRecord
    | ActivityLogKeywordRecord
    | ActivityLogKeywordGroupRecord
    | ActivityLogItemRecord;
