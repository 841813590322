import { createActions } from 'src/utils/createActions';
import { OrganizationListItem } from 'src/react/organization/state/OrganizationListItem';
import { Permission } from 'src/types/entities/Permission';

export const ORGANIZATION_LIST_TEAM_OPEN_ACTIONS =
    createActions<OrganizationListItem, OrganizationListItem>('ORGANIZATION_LIST_TEAM_OPEN');

export const ORGANIZATION_LIST_TEAM_CLOSE_ACTIONS =
    createActions<void, void>('ORGANIZATION_LIST_TEAM_CLOSE');

export const ORGANIZATION_LIST_TEAM_CREATE_ACTIONS =
    createActions<void, OrganizationListItem>('ORGANIZATION_LIST_TEAM_CREATE');

export const ORGANIZATION_LIST_TEAM_DELETE_ACTIONS =
    createActions<Permission, OrganizationListItem>('ORGANIZATION_LIST_TEAM_DELETE');
