import * as yup from 'yup';
import { UserRole } from 'src/types/values/UserRole';
import { PermissionFormData } from 'src/react/organization/types/PermissionFormData';

export const PERMISSION_FORM_SCHEMA = yup.object<PermissionFormData>({
    userEmail: yup
        .string()
        .trim()
        .required('formError_required')
        .max(200, 'formError_tooLong')
        .email('formError_invalid'),
    userRole: yup
        .mixed()
        .nullable(true)
        .required('formError_required')
        .oneOf([
            null,
            UserRole.Admin,
            UserRole.Manager,
            UserRole.Editor,
            UserRole.Viewer,
        ], 'formError_invalid')
});
