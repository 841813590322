import { IPromise } from 'angular';
import { Organization } from 'src/types/entities/Organization';
import { IOrganizationService } from 'src/modules/api/OrganizationService';

widgetsOrganizationsResolver.$inject = [
    'organizationService',
];
export function widgetsOrganizationsResolver(
    organizationService: IOrganizationService,
): IPromise<Organization[]> {
    return organizationService.list({
        query: '',
        sort: ['createdAt'],
        page: 0,
        pageSize: 100,
    }).then(({ data: organizations }) => {
        if (organizations.length > 0) {
            return organizations;
        }

        return organizationService
            .create({ name: 'Default' })
            .then((organization) => [organization]);
    });
}
