import './OrganizationItem.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { LocationDescriptor } from 'history';
import { noop } from 'src/utils/noop';
import { classList } from 'src/utils/classList';
import { UserRole } from 'src/types/values/UserRole';
import { OrganizationListItem } from 'src/react/organization/state/OrganizationListItem';
import { OrganizationUserRole } from 'src/react/organization/components/OrganizationUserRole';
import { PopoverContent, PopoverTarget, PopoverWrapper } from 'src/react/common/components/PopoverContext';

type Props = {
    readonly item: OrganizationListItem;

    readonly onTeam: () => void;
    readonly onLeave: () => void;
    readonly onDelete: () => void;
    readonly onUpdate: () => void;
};

export class OrganizationItem extends React.Component<Props> {
    public render(): JSX.Element {
        const { item: { organization, permissions, widgets }, onTeam, onLeave, onDelete, onUpdate } = this.props;

        return (
            <div className="organization-item" key={organization.id}>
                <div className="organization-item__role">
                    <OrganizationUserRole userRole={organization.permission.userRole}/>
                </div>
                <div className="organization-item__header">
                    {organization.name}
                </div>
                <div className="organization-item__actions">
                    <span className="organization-item__action">
                        <Link to={this.getWidgetListLocation()}
                              className={this.getActionClassName(false)}>
                            <FormattedMessage id="organizationList_openWidgets"
                                              values={{ count: widgets.length }}/>
                        </Link>
                    </span>

                    {this.canManageMemebers() ? (
                        <span className="organization-item__action">
                            <a onClick={onTeam}
                               className={this.getActionClassName(false)}>
                                <FormattedMessage id="organizationList_manageMembers"
                                                  values={{ count: permissions.length }}/>
                            </a>
                        </span>
                    ) : null}

                    {this.canUpdateOrganization() ? (
                        <span className="organization-item__action">
                            <a onClick={onUpdate}
                               className={this.getActionClassName(false)}>
                                <FormattedMessage id="organizationList_updateOrganization"/>
                            </a>
                        </span>
                    ) : null}

                    {this.canLeaveOrganization() ? (<span className="organization-item__action">
                        <PopoverWrapper id={`organization-leave-${organization.id}`}
                                        placement="top"
                                        disabled={!this.isLeaveButtonDisabled()}>
                            <PopoverTarget>
                                <a onClick={this.isLeaveButtonDisabled() ? noop : onLeave}
                                   className={this.getActionClassName(this.isLeaveButtonDisabled())}>
                                    <FormattedMessage id="organizationList_leaveOrganization"/>
                                </a>
                            </PopoverTarget>
                            <PopoverContent>
                                <div className="organization-item__popover">
                                    <FormattedMessage id="organizationList_leaveOrganizationPopover"/>
                                </div>
                            </PopoverContent>
                        </PopoverWrapper>
                    </span>) : null}

                    {this.canDeleteOrganization() ? (
                        <span className="organization-item__action">
                            <PopoverWrapper id={`organization-delete-${organization.id}`}
                                            placement="top"
                                            disabled={!this.isDeleteButtonDisabled()}>
                                <PopoverTarget>
                                    <a onClick={this.isDeleteButtonDisabled() ? noop : onDelete}
                                       className={this.getActionClassName(this.isDeleteButtonDisabled())}>
                                        <FormattedMessage id="organizationList_deleteOrganization"/>
                                    </a>
                                </PopoverTarget>
                                <PopoverContent>
                                    <div className="organization-item__popover">
                                        <FormattedMessage id="organizationList_deleteOrganizationPopover"/>
                                    </div>
                                </PopoverContent>
                            </PopoverWrapper>
                        </span>
                    ) : null}
                </div>
            </div>
        );
    }

    private isAdministrator(): boolean {
        const { item: { organization: { permission } } } = this.props;
        return permission.userRole === UserRole.Admin;
    }

    private canManageMemebers(): boolean {
        const { item: { organization: { permission } } } = this.props;
        return (
            permission.userRole === UserRole.Admin ||
            permission.userRole === UserRole.Manager
        );
    }

    private canUpdateOrganization(): boolean {
        return this.isAdministrator();
    }

    private canLeaveOrganization(): boolean {
        const { item: { organization: { permission } } } = this.props;
        return permission.id !== null;
    }

    private canDeleteOrganization(): boolean {
        return this.isAdministrator();
    }

    private isLeaveButtonDisabled(): boolean {
        if (!this.isAdministrator()) {
            return false;
        }

        const { item: { organization: { permission }, permissions } } = this.props;
        return !permissions.some((other) => (
            other.id !== permission.id &&
            other.userRole === UserRole.Admin
        ));
    }

    private isDeleteButtonDisabled(): boolean {
        if (!this.isAdministrator()) {
            return true;
        }

        const { item: { widgets } } = this.props;
        return widgets.length > 0;
    }

    private getWidgetListLocation(): LocationDescriptor {
        const { item: { organization } } = this.props;
        return `/?organizationId=${encodeURIComponent(organization.id)}`;
    }

    private getActionClassName(disabled: boolean): string {
        return classList('organization-item__link', {
            'organization-item__link--disabled': disabled,
        });
    }
}
