import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { assertDefined } from 'src/utils/assertion';
import { SEARCH_INPUT_DEBOUNCE } from 'src/constants/intervals';

export function searchInputDebounceFilter(): (pageKey: string) => UnsignedInteger {
    return (pageKey) => assertDefined(
        SEARCH_INPUT_DEBOUNCE[pageKey],
        `Unknown page key "${pageKey}"`,
        { pageKey, SEARCH_INPUT_DEBOUNCE },
    );
}
