import './OrganizationDeleteModal.scss';
import * as React from 'react';
import { OrganizationListItem } from 'src/react/organization/state/OrganizationListItem';
import { OrganizationDeleteForm } from 'src/react/organization/components/OrganizationDeleteForm';

type Props = {
    readonly organization: OrganizationListItem;
    readonly onCancel: () => void;
    readonly onSubmit: () => void;
};

export function OrganizationDeleteModal({ organization, onCancel, onSubmit }: Props): JSX.Element {
    return (
        <OrganizationDeleteForm form="organization-delete"
                                initialValues={{}}
                                destroyOnUnmount={true}
                                forceUnregisterOnUnmount={true}
                                organization={organization}
                                onCancel={onCancel}
                                onSubmit={onSubmit}/>
    );
}
