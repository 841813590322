import { SagaIterator } from 'redux-saga';
import { all, call, select } from 'redux-saga/effects';
import { ItemForm } from 'src/types/dto/ItemForm';
import { Item } from 'src/types/entities/Item';
import { Widget } from 'src/types/entities/Widget';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { IItemEndpoint } from 'src/react/api/endpoints/ItemEndpoint';
import { getItemEndpoint } from 'src/react/api/selectors/getEndpoint';
import { itemKeyPhraseAttach } from 'src/react/itemForm/services/itemKeyPhraseAttach';

export function* itemCreate(
    formData: ItemForm,
    keyPhraseTexts: ReadonlyArray<string>,
): SagaIterator {
    const widget: Widget = yield select(getSelectedWidget);
    const itemEndpoint: IItemEndpoint = yield select(getItemEndpoint);

    const result: Item = yield call({
        fn: itemEndpoint.create,
        context: itemEndpoint,
    }, formData, { widgetId: widget.id });

    yield all(
        keyPhraseTexts.map((keyPhraseText) => call(
            itemKeyPhraseAttach,
            result,
            keyPhraseText,
        )),
    );

    return result;
}
