import * as React from 'react';
import { PropsType } from 'react-utils';
import { BaseFieldProps, Field, WrappedFieldProps } from 'redux-form';
import { Omit } from 'mapped-types-utils';
import { classList } from 'src/utils/classList';
import { fieldMetaClass } from 'src/utils/fieldMetaClass';
import { Switcher } from 'src/react/common/components/Switcher';

type SwitcherProps = PropsType<typeof Switcher>;
function SwitcherComponent(props: WrappedFieldProps & SwitcherProps): JSX.Element {
    const { input, meta, disabled = false, readOnly = false, ...inputProps } = props;
    const className = classList(props.className, fieldMetaClass(meta, { disabled, readOnly }));

    return (
        <Switcher {...inputProps}
                  className={className}
                  disabled={disabled}
                  readOnly={readOnly}
                  name={input.name}
                  checked={input.value || false}
                  onBlur={input.onBlur}
                  onFocus={input.onFocus}
                  onChange={input.onChange}/>
    );
}

type SwitcherFieldProps = Omit<BaseFieldProps<SwitcherProps> & SwitcherProps, 'component' | 'checked'>;
export function SwitcherField(props: SwitcherFieldProps): JSX.Element {
    return <Field component={SwitcherComponent} {...props}/>;
}
