import { IPromise } from 'angular';
import { Config } from 'src/types/constants/Config';
import { IApiService } from 'src/modules/api/ApiService';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { Widget } from 'src/types/entities/Widget';
import { StatisticsTopSearch } from 'src/types/entities/StatisticsTopSearch';
import { StatisticsSearchFilter } from 'src/types/dto/StatisticsSearchFilter';

export interface IStatisticsCombinedSearchService {
    list(
        widget: Readonly<Widget>,
        filter: Readonly<StatisticsSearchFilter>,
    ): IPromise<ListWithTotal<StatisticsTopSearch>>;
}

export class StatisticsCombinedSearchService implements IStatisticsCombinedSearchService {
    public static $inject = [
        'config',
        'apiService',
    ];
    public constructor(
        private config: Config,
        private apiService: IApiService,
    ) {
    }

    public list(
        widget: Readonly<Widget>,
        filter: Readonly<StatisticsSearchFilter>,
    ): IPromise<ListWithTotal<StatisticsTopSearch>> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'statistics',
            encodeURIComponent(widget.id),
            'searches',
        ].join('/');

        return this.apiService
            .makeGETRequest<ListWithTotal<StatisticsTopSearch>>(requestUrl, { params: filter })
            .then(({ data }) => data);
    }
}
