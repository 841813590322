import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { Uuid } from 'src/types/values/Uuid';
import { ItemType } from 'src/types/values/ItemType';
import { Widget } from 'src/types/entities/Widget';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { getRestoreItemEndpoint } from 'src/react/api/selectors/getEndpoint';
import { IRestoreItemEndpoint } from 'src/react/api/endpoints/RestoreItemEndpoint';

export function* itemRestore(
    itemType: ItemType, itemId: Uuid
): SagaIterator {
    const widget: Widget = yield select(getSelectedWidget);
    const itemEndpoint: IRestoreItemEndpoint = yield select(getRestoreItemEndpoint);

    return yield call({
        fn: itemEndpoint.get,
        context: itemEndpoint,
    }, itemId, { widgetId: widget.id, itemType });

}
