import { IPromise } from 'angular';
import { Config } from 'src/types/constants/Config';
import { IApiService } from 'src/modules/api/ApiService';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { Widget } from 'src/types/entities/Widget';
import { StatisticsFilter } from 'src/types/entities/StatisticsFilter';

export interface IStatisticsFilterService {
    list(
        widget: Readonly<Widget>,
    ): IPromise<ListWithTotal<StatisticsFilter>>;
}

export class StatisticsFilterService implements IStatisticsFilterService {
    public static $inject = [
        'config',
        'apiService',
    ];
    public constructor(
        private config: Config,
        private apiService: IApiService,
    ) {
    }

    public list(
        widget: Readonly<Widget>,
    ): IPromise<ListWithTotal<StatisticsFilter>> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'statistics',
            encodeURIComponent(widget.id),
            'filter',
        ].join('/');

        return this.apiService
            .makeGETRequest<ListWithTotal<StatisticsFilter>>(requestUrl)
            .then(({ data }) => data);
    }
}
