import * as React from 'react';
import { Widget } from 'src/types/entities/Widget';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { StatisticsOverview } from 'src/types/entities/StatisticsOverview';
import { StatisticsLastSearch } from 'src/types/entities/StatisticsLastSearch';
import { StatisticsTopSearch } from 'src/types/entities/StatisticsTopSearch';
import { DashboardPage } from 'src/react/dashboard/components/DashboardPage';
import { DateRange } from 'src/types/values/DateRange';

export type DashboardPageBridgeProps = {
    readonly widget: Widget;
    readonly statisticsOverview: StatisticsOverview;
    readonly statisticsLastSearches: ListWithTotal<StatisticsLastSearch>;
    readonly statisticsSuccessfulSearches: ListWithTotal<StatisticsTopSearch>;
    readonly statisticsUnsuccessfulSearches: ListWithTotal<StatisticsTopSearch>;
    readonly dateRange: DateRange;
    readonly onDatesChange: (dateRange: DateRange) => void;
};

export default function (props: DashboardPageBridgeProps): JSX.Element {
    return (
        <DashboardPage widget={props.widget}
                       overview={props.statisticsOverview}
                       lastSearches={props.statisticsLastSearches.data}
                       successfulSearches={props.statisticsSuccessfulSearches.data}
                       unsuccessfulSearches={props.statisticsUnsuccessfulSearches.data}
                       dateRange={props.dateRange}
                       onDatesChange={props.onDatesChange}/>
    );
}
