import { DemoPageState } from 'src/react/demoPage/state/DemoPageState';
import {
    DEMO_PIN_MODE_LEAVE_ACTIONS,
    DemoPinModeLeaveActions,
} from 'src/react/demoPage/actions/DemoPinModeLeaveActions';

export function demoPinModeLeaveReducer(
    state: DemoPageState,
    action: DemoPinModeLeaveActions,
): DemoPageState {
    switch (action.type) {
        case DEMO_PIN_MODE_LEAVE_ACTIONS.PENDING:
            return {
                ...state,
                saving: true,
            };

        case DEMO_PIN_MODE_LEAVE_ACTIONS.SUCCESS:
            return {
                ...state,
                saving: false,
                loading: false,
                pinMode: false,
                error: null,
                data: action.data,
            };

        case DEMO_PIN_MODE_LEAVE_ACTIONS.FAILURE:
            return {
                ...state,
                saving: false,
                data: null,
                error: action.data,
            };

        default:
            return state;
    }
}
