import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { DemoPageViewMode } from 'src/react/demoPage/state/DemoPageViewMode';
import { DEMO_SET_VIEW_MODE_ACTIONS } from 'src/react/demoPage/actions/DemoSetViewModeActions';

export function* demoSetViewModeSaga(
    { data: viewMode }: ActionRequest<DemoPageViewMode>,
): SagaIterator {
    yield call({
        fn: localStorage.setItem,
        context: localStorage,
    }, 'demoPageViewMode', viewMode);

    yield put(DEMO_SET_VIEW_MODE_ACTIONS.success(viewMode));
}
