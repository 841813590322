import './ErrorPage.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export function UnauthorizedPage(): JSX.Element {
    return (
        <div className="error-page">
            <h1 className="error-page__header">
                <FormattedMessage id="errorPage_401_header"/>
            </h1>
            <div className="error-page__text">
                <FormattedMessage id="errorPage_401_text"/>
            </div>
        </div>
    );
}
