import { BaseError } from 'src/errors/BaseError';

export class NativeError extends BaseError {
    public static wrapError(error: any): BaseError {
        if (error instanceof BaseError) {
            return error;
        }
        if (error instanceof Error) {
            return new NativeError(error);
        }

        return new NativeError(new Error(`Unknown error value '${error}'`));
    }

    public constructor(public readonly error: Error) {
        super(undefined);
        this.stackTrace = error.stack;
    }

    public get name(): string {
        return this.error.name;
    }

    public get message(): string {
        return this.error.message;
    }
}
