import { IPromise } from 'angular';
import { IModalService } from 'angular-ui-bootstrap';
import { Organization } from 'src/types/entities/Organization';
import { Widget } from 'src/types/entities/Widget';

export interface IWidgetListDialogService {
    createWidget(
        organizations: ReadonlyArray<Organization>,
    ): IPromise<Widget>;
    updateWidget(
        organizations: ReadonlyArray<Organization>,
        widget: Readonly<Widget>,
    ): IPromise<Widget>;
}

export class WidgetListDialogService implements IWidgetListDialogService {
    public static $inject = [
        '$uibModal',
    ];
    public constructor(
        private $uibModal: IModalService,
    ) {
    }

    public createWidget(organizations: ReadonlyArray<Organization>): IPromise<Widget> {
        return this.$uibModal.open({
            animation: true,
            backdrop: 'static',
            component: 'widgetPopup',
            resolve: {
                organizations: () => organizations,
                widget: () => null,
            },
        }).result;
    }

    public updateWidget(organizations: ReadonlyArray<Organization>, widget: Readonly<Widget>): IPromise<Widget> {
        return this.$uibModal.open({
            animation: true,
            backdrop: 'static',
            component: 'widgetPopup',
            resolve: {
                organizations: () => organizations,
                widget: () => widget,
            },
        }).result;
    }
}
