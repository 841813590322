import { IComponentOptions, IScope } from 'angular';
import { KeyPhrasePopupController } from 'src/modules/keyPhrasePopup/KeyPhrasePopupController';
import * as KeyPhrasePopupTemplate from './KeyPhrasePopupTemplate.html';

export const KEYPHRASE_POPUP_COMPONENT: IComponentOptions = {
    controller: KeyPhrasePopupController,
    controllerAs: 'keyPhrasePopupCtrl',
    template: KeyPhrasePopupTemplate,
    bindings: {
        resolve: '<',
        modalInstance: '<',
    },
};

export interface KeyPhrasePopupComponent extends IScope {
    readonly keyPhrasePopupCtrl: KeyPhrasePopupController;
}
