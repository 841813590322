import { chainReducers } from 'src/utils/chainReducers';
import { StatisticsPageState } from 'src/react/statistics/state/StatisticsPageState';

import { statisticsCombinedUpdateReducer } from 'src/react/statistics/reducers/statisticsCombinedUpdateReducer';
import { statisticsSuccessfulUpdateReducer } from 'src/react/statistics/reducers/statisticsSuccessfulUpdateReducer';
import { statisticsUnsuccessfulUpdateReducer } from 'src/react/statistics/reducers/statisticsUnsuccessfulUpdateReducer';
import { statisticsLastUpdateReducer } from 'src/react/statistics/reducers/statisticsLastUpdateReducer';

import { statisticsResetReducer } from 'src/react/statistics/reducers/statisticsResetReducer';

export const statisticsPageReducer = chainReducers<StatisticsPageState>(
    statisticsCombinedUpdateReducer,
    statisticsSuccessfulUpdateReducer,
    statisticsUnsuccessfulUpdateReducer,
    statisticsLastUpdateReducer,

    statisticsResetReducer,
);
