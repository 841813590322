import { IHttpResponse, IPromise } from 'angular';
import { Config } from 'src/types/constants/Config';
import { Uuid } from 'src/types/values/Uuid';
import { Widget } from 'src/types/entities/Widget';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';
import { KeyPhraseForm } from 'src/types/dto/KeyPhraseForm';
import { KeyPhraseFilter } from 'src/types/dto/KeyPhraseFilter';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { IApiService } from 'src/modules/api/ApiService';

export interface IKeyPhraseService {
    get(
        widget: Readonly<Widget>,
        id: Uuid,
    ): IPromise<KeyPhrase>;

    list(
        widget: Readonly<Widget>,
        filter: Readonly<KeyPhraseFilter>,
    ): IPromise<ListWithTotal<KeyPhrase>>;

    create(
        widget: Readonly<Widget>,
        formData: Readonly<KeyPhraseForm>,
    ): IPromise<KeyPhrase>;

    update(
        widget: Readonly<Widget>,
        keyPhrase: Readonly<KeyPhrase>,
        formData: Readonly<KeyPhraseForm>,
    ): IPromise<KeyPhrase>;

    delete(
        widget: Readonly<Widget>,
        keyPhrase: Readonly<KeyPhrase>,
    ): IPromise<void>;
}

export class KeyPhraseService implements IKeyPhraseService {
    public static $inject = [
        'config',
        'apiService',
    ];
    public constructor(
        private config: Config,
        private apiService: IApiService,
    ) {
    }

    public get(
        widget: Readonly<Widget>,
        id: Uuid,
    ): IPromise<KeyPhrase> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
            'key-phrases',
            encodeURIComponent(id),
        ].join('/');

        return this.apiService
            .makeGETRequest<KeyPhrase>(requestUrl)
            .then(extractResponseData);
    }

    public list(
        widget: Readonly<Widget>,
        filter: Readonly<KeyPhraseFilter>,
    ): IPromise<ListWithTotal<KeyPhrase>> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
            'key-phrases',
        ].join('/');

        return this.apiService.makeGETRequest<ListWithTotal<KeyPhrase>>(
            requestUrl,
            { params: filter },
        ).then(extractResponseData);
    }

    public create(
        widget: Readonly<Widget>,
        formData: Readonly<KeyPhraseForm>,
    ): IPromise<KeyPhrase> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
            'key-phrases',
        ].join('/');

        return this.apiService.makePOSTRequest<KeyPhraseForm, KeyPhrase>(
            requestUrl,
            formData,
        ).then(extractResponseData);
    }

    public update(
        widget: Readonly<Widget>,
        keyPhrase: Readonly<KeyPhrase>,
        formData: Readonly<KeyPhraseForm>,
    ): IPromise<KeyPhrase> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
            'key-phrases',
            encodeURIComponent(keyPhrase.id),
        ].join('/');

        return this.apiService.makePUTRequest<KeyPhraseForm, KeyPhrase>(
            requestUrl,
            formData,
        ).then(extractResponseData);
    }

    public delete(
        widget: Readonly<Widget>,
        keyPhrase: Readonly<KeyPhrase>,
    ): IPromise<void> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
            'key-phrases',
            encodeURIComponent(keyPhrase.id),
        ].join('/');

        return this.apiService.makeDELETERequest<void>(
            requestUrl,
        ).then(extractResponseData);
    }
}

function extractResponseData<T>(response: IHttpResponse<T>): T {
    return response.data;
}
