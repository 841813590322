import angular from 'angular';

import 'locales';
import 'bootstrap-sass';
import '@uirouter/angularjs';
import 'angular-ui-bootstrap';
import 'angular-bootstrap-toggle-switch';

import 'styles/main.scss';

import 'pages/widgets/generateKey';

import 'services/image-upload.service';

import 'filters/string';
import 'filters/time';

import 'directives/http-prefix';
import 'directives/confirm-click';

import { NG_REACT_MODULE } from 'src/react/bridge/ngReactModule';
import { ITEM_MODULE } from 'src/modules/item/ItemModule';
import { KEY_PHRASE_MODULE } from 'src/modules/keyPhrase/KeyPhraseModule';
import { KEY_PHRASE_POPUP_MODULE } from 'src/modules/keyPhrasePopup/KeyPhrasePopupModule';
import { CORE_MODULE } from 'src/modules/core/CoreModule';
import { ROUTES_MODULE } from 'src/modules/routes/RoutesModule';
import { LOADING_MODULE } from 'src/modules/loading/LoadingModule';
import { CRAWLER_STATUS_MODULE } from 'src/modules/crawlerStatus/CrawlerStatusModule';
import { WIDGET_LIST_MODULE } from 'src/modules/widgetList/WidgetListModule';
import { WIDGET_POPUP_MODULE } from 'src/modules/widgetPopup/WidgetPopupModule';
import { API_MODULE } from 'src/modules/api/ApiModule';
import { LAYOUT_MODULE } from 'src/modules/layout/LayoutModule';
import { SNIPPET_PAGE_MODULE } from 'src/modules/snippetPage/SnippetPageModule';
import { TITLE_MODULE } from 'src/modules/title/TitleModule';
import { initSentry, configureSentry } from 'src/utils/sentry';
import { SiteLanguages } from 'src/types/values/siteLanguage';
import { LanguageService } from './services/languageService';

const dependencies = [
    'ui.router',
    'ui.bootstrap',
    'backend.filters.string',
    'backend.filters.time',
    'backend.widgets.generateKey',
    'backend.widgets.imageUploadService',
    'httpPrefix',
    'ngConfirmClick',
    'toggle-switch',
    NG_REACT_MODULE.name,
    ITEM_MODULE.name,
    KEY_PHRASE_MODULE.name,
    KEY_PHRASE_POPUP_MODULE.name,
    CORE_MODULE.name,
    ROUTES_MODULE.name,
    LOADING_MODULE.name,
    CRAWLER_STATUS_MODULE.name,
    WIDGET_LIST_MODULE.name,
    WIDGET_POPUP_MODULE.name,
    API_MODULE.name,
    LAYOUT_MODULE.name,
    SNIPPET_PAGE_MODULE.name,
    TITLE_MODULE.name,
];

initSentry();

// drop query params
if (window.location.search.length > 0) {
    window.history.replaceState({}, document.title, '/' + window.location.hash);
}

$.getJSON('/config.json').then((config) => {

    configureSentry(config);

    angular
        .module('backend', dependencies)
        .constant('config', config)
        .service('languageService', LanguageService)
        .config([
            '$compileProvider',
            $compileProvider => $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|smb|mailto|javascript):/),
        ])
        .config([
            '$httpProvider',
            $httpProvider => $httpProvider.defaults.withCredentials = true,
        ]);
    angular.bootstrap(document, ['backend'], { strictDi: true });

}, () => {
    throw new Error('Could not load config.json file');
}).catch((e) => {
    console.error(e);
});
