import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { NativeError } from 'src/errors/NativeError';
import {
    SETTINGS_FORM_SAVE_ACTIONS,
    SettingsFormSaveRequestData,
} from 'src/react/settings/actions/SettingsFormSaveActions';
import { settingsFormSave } from 'src/react/settings/services/settingsFormSave';
import { settingsFormReload } from 'src/react/settings/services/settingsFormReload';

export function* settingsFormSaveSaga(
    { data: { formData } }: ActionRequest<SettingsFormSaveRequestData>,
): SagaIterator {
    try {
        yield put(SETTINGS_FORM_SAVE_ACTIONS.pending());

        yield call(settingsFormSave, formData);
        yield call(settingsFormReload);

        yield put(SETTINGS_FORM_SAVE_ACTIONS.success());
    } catch (error) {
        yield put(SETTINGS_FORM_SAVE_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
