import './Alert.scss';
import * as React from 'react';
import { classList } from 'src/utils/classList';

type Props = React.HTMLAttributes<HTMLDivElement> & {
    readonly intent: 'danger' | 'info' | 'success';
};

export function Alert({ intent, className, ...restProps }: Props): JSX.Element {
    return (
        <div {...restProps} className={classList(className, 'xss-alert', `xss-alert--${intent}`)}/>
    );
}

export function AlertTitle({ className, ...restProps }: JSX.IntrinsicElements['div']): JSX.Element {
    return (
        <div {...restProps} className={classList(className, 'xss-alert__title')}/>
    );
}

export function AlertBody({ className, ...restProps }: JSX.IntrinsicElements['div']): JSX.Element {
    return (
        <div {...restProps} className={classList(className, 'xss-alert__body')}/>
    );
}
