import * as React from 'react';
import { FormErrors } from 'redux-form';
import { bind } from 'lodash-decorators/bind';
import { assertTypeIs } from 'src/utils/assertion';
import { flattenErrors, getSubmissionError, getValidationError } from 'src/utils/getFormErrors';
import { BaseError } from 'src/errors/BaseError';
import { FieldErrors } from 'src/types/values/FieldError';
import { createSchemaValidator } from 'src/utils/createSchemaValidator';
import { Item } from 'src/types/entities/Item';
import { CustomField } from 'src/types/entities/CustomField';
import { LoadingWrapper } from 'src/react/common/components/LoadingWrapper';
import { ItemFormForm } from 'src/react/itemForm/components/ItemFormForm';
import { ItemFormValue } from 'src/react/itemForm/types/ItemFormValue';
import { PROMISE_LISTENER } from 'src/react/root/constants/promiseListener';
import { ITEM_FORM_SCHEMA } from 'src/react/itemForm/constants/ItemFormSchema';
import { ITEM_FORM_SAVE_ACTIONS } from 'src/react/itemForm/actions/ItemFormSaveActions';
import {
    ITEM_FORM_VALIDATE_ACTIONS,
    ItemFormValidateRequestData,
} from 'src/react/itemForm/actions/ItemFormValidateActions';

type Props = {
    readonly item: Item | null;
    readonly onCancel: () => void;
    readonly onSaved: (item: Item) => void;
    readonly restore: () => void;
    readonly isRestoring: boolean;
    readonly itemForm: ItemFormValue | null;
    readonly isSaving: boolean;
    readonly saveRestored: () => void;
    readonly customFields: ReadonlyArray<CustomField>;
    readonly loadFormData: (item: Item | null) => void;
};

const syncFromValidator = createSchemaValidator(ITEM_FORM_SCHEMA);

export class ItemFormItemModal extends React.Component<Props> {
    private savePromise = PROMISE_LISTENER.createAsyncFunction<ItemFormValue, Item>({
        start: ITEM_FORM_SAVE_ACTIONS.REQUEST,
        setPayload: (action, formData) => ITEM_FORM_SAVE_ACTIONS.request({ item: this.props.item, formData }),

        resolve: (action) => ITEM_FORM_SAVE_ACTIONS.isSuccess(action),
        getPayload: (action) => assertTypeIs(action, ITEM_FORM_SAVE_ACTIONS.isSuccess).data,

        reject: (action) => ITEM_FORM_SAVE_ACTIONS.isFailure(action),
        getError: (action) => assertTypeIs(action, ITEM_FORM_SAVE_ACTIONS.isFailure).data,
    });
    private validatePromise = PROMISE_LISTENER.createAsyncFunction<ItemFormValidateRequestData, null>({
        start: ITEM_FORM_VALIDATE_ACTIONS.REQUEST,
        setPayload: (action, data) => ITEM_FORM_VALIDATE_ACTIONS.request(data),

        resolve: (action) => ITEM_FORM_VALIDATE_ACTIONS.isSuccess(action),
        getPayload: (action) => assertTypeIs(action, ITEM_FORM_VALIDATE_ACTIONS.isSuccess).data,

        reject: (action) => ITEM_FORM_VALIDATE_ACTIONS.isFailure(action),
        getError: (action) => assertTypeIs(action, ITEM_FORM_VALIDATE_ACTIONS.isFailure).data,
    });

    public componentDidMount(): void {
        const { item, loadFormData } = this.props;
        loadFormData(item);
    }

    public componentDidUpdate(prevProps: Props): void {
        const { item } = this.props;
        if (prevProps.item !== item) {
            const { loadFormData } = this.props;
            loadFormData(item);
        }

    }

    public componentWillUnmount(): void {
        this.savePromise.unsubscribe();
        this.validatePromise.unsubscribe();
    }

    public render(): JSX.Element {
        const { itemForm } = this.props;

        return (
                <LoadingWrapper loading={itemForm === null}>
                    {this.renderForm()}
                </LoadingWrapper>
        );
    }

    private renderForm(): JSX.Element | null {
        const { item, itemForm, customFields, onSaved, onCancel, restore, isRestoring, saveRestored } = this.props;

        if (!itemForm) {
            return null;
        }

        const itemType = item && item.type ? item.type : null;

        return (
            <ItemFormForm isNewItem={item === null}
                          customFields={customFields}
                          form="itemForm"
                          destroyOnUnmount={true}
                          forceUnregisterOnUnmount={true}
                          enableReinitialize={true}
                          onCancel={onCancel}
                          restore={restore}
                          saveRestored={saveRestored}
                          isRestoring={isRestoring}
                          initialValues={itemForm}
                          itemType={itemType}
                          validate={this.validateFormSync}
                          asyncChangeFields={['url']}
                          asyncValidate={this.validateFormAsync}
                          onSubmit={this.handleSubmit}
                          onSubmitSuccess={onSaved}/>
        );
    }

    @bind
    private handleSubmit(itemForm: ItemFormValue): Promise<Item> {
        return this.savePromise.asyncFunction(itemForm)
            .catch((error: BaseError) => { throw getSubmissionError(error); });
    }

    @bind
    private validateFormAsync(
        itemForm: ItemFormValue,
        dispatch: never,
        formProps: never,
        blurredField: string | undefined,
    ): Promise<null> {
        return this.validatePromise.asyncFunction({
            formData: itemForm,
            item: this.props.item,
            immediate: blurredField !== 'url',
        }).catch((error: BaseError) => { throw getValidationError(error); });
    }

    @bind
    private validateFormSync(itemForm: ItemFormValue): FormErrors<ItemFormValue, FieldErrors> {
        const { customFields } = this.props;
        return flattenErrors(syncFromValidator(itemForm, { customFields }));
    }
}
