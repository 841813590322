import { KeyPhrasePinInputState } from 'src/react/keyPhrasePinInput/state/KeyPhrasePinInputState';
import {
    KEY_PHRASE_PIN_INPUT_RESET_ACTIONS,
    KeyPhrasePinInputResetActions,
} from 'src/react/keyPhrasePinInput/actions/KeyPhrasePinInputResetActions';

export function keyPhrasePinInputResetReducer(
    state: KeyPhrasePinInputState,
    action: KeyPhrasePinInputResetActions,
): KeyPhrasePinInputState {
    switch (action.type) {
        case KEY_PHRASE_PIN_INPUT_RESET_ACTIONS.SUCCESS:
            return { ...state, loading: false, data: null, error: null };

        default:
            return state;
    }
}
