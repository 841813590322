import { AxiosInstance } from 'axios';
import { MapOf } from 'src/utils/MapOf';
import { HttpError } from 'src/errors/HttpError';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { IJsonEndpoint } from 'src/react/api/services/JsonEndpoint';
import { ErrorResponse } from 'src/react/api/types/Response';

export function createJsonEndpoint<TView extends {}, TForm extends {}, TFilter extends {}, TParams extends {}>(
    apiCient: AxiosInstance,
    endpoint: string,
    ): IJsonEndpoint<TView, TForm, TFilter, TParams> {
    return {
        get: (id: string, params: TParams) => apiCient
            .get<TView>(createItemUrl(endpoint, params, id))
            .then((response) => response.data)
            .catch((error: ErrorResponse) => processError(error)),
        list: (filter: TFilter, params: TParams) => apiCient
            .get<ListWithTotal<TView>>(createListUrl(endpoint, params), { params: filter })
            .then((response) => response.data)
            .catch((error: ErrorResponse) => processError(error)),
        create: (form: TForm, params: TParams) => apiCient
            .post<TView>(createListUrl(endpoint, params), form)
            .then((response) => response.data)
            .catch((error: ErrorResponse) => processError(error)),
        update: (id: string, form: TForm, params: TParams) => apiCient
            .put<TView>(createItemUrl(endpoint, params, id), form)
            .then((response) => response.data)
            .catch((error: ErrorResponse) => processError(error)),
        delete: (id: string, params: TParams) => apiCient
            .delete(createItemUrl(endpoint, params, id))
            .then(() => {})
            .catch((error: ErrorResponse) => processError(error)),
    };
}

function createListUrl(endpoint: string, params: MapOf<string>): string {
    return Object.keys(params).reduce(
        (url, key) => url.replace(`:${key}`, encodeURIComponent(params[key])),
        endpoint
    );
}

function createItemUrl(endpoint: string, params: MapOf<string>, id: string): string {
    return `${createListUrl(endpoint, params)}/${encodeURIComponent(id)}`;
}

function processError(error: ErrorResponse): Promise<never> {
    return Promise.reject(new HttpError(error));
}
