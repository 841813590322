import { IHttpResponse, IPromise } from 'angular';
import { Config } from 'src/types/constants/Config';
import { Widget } from 'src/types/entities/Widget';
import { CustomField } from 'src/types/entities/CustomField';
import { CustomFieldFilter } from 'src/types/dto/CustomFieldFilter';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { IApiService } from 'src/modules/api/ApiService';

export interface ICustomFieldService {
    list(
        widget: Readonly<Widget>,
        filter: Readonly<CustomFieldFilter>,
    ): IPromise<ListWithTotal<CustomField>>;
}

export class CustomFieldService implements ICustomFieldService {
    public static $inject = [
        'config',
        'apiService',
    ];
    public constructor(
        private config: Config,
        private apiService: IApiService,
    ) {
    }

    public list(
        widget: Readonly<Widget>,
        filter: Readonly<CustomFieldFilter>,
    ): IPromise<ListWithTotal<CustomField>> {
        const requestUrl = [
            this.config.services.adminApiUrl,
            'widgets',
            encodeURIComponent(widget.id),
            'custom-fields',
        ].join('/');

        return this.apiService
            .makeGETRequest<ListWithTotal<CustomField>>(requestUrl, { params: filter })
            .then(extractResponseData);
    }
}

function extractResponseData<T>(response: IHttpResponse<T>): T {
    return response.data;
}
