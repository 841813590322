import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ActionRequest } from 'src/utils/createActions';
import { NativeError } from 'src/errors/NativeError';
import { Item } from 'src/types/entities/Item';
import {
    ITEM_FORM_RESTORE_ACTIONS,
    ItemFormRestoreRequestData
} from 'src/react/itemForm/actions/ItemFormRestoreActions';
import { itemRestore } from 'src/react/itemForm/services/itemRestore';

export function* itemFormRestoreSaga(
    { data: { itemType, itemId } }: ActionRequest<ItemFormRestoreRequestData>,

): SagaIterator {
    try {
        yield put(ITEM_FORM_RESTORE_ACTIONS.pending());

        const restored: Item = yield call(itemRestore, itemType, itemId);

        yield put(ITEM_FORM_RESTORE_ACTIONS.success(restored));
    } catch (error) {
        yield put(ITEM_FORM_RESTORE_ACTIONS.failure(NativeError.wrapError(error)));
    }
}
