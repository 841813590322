import { module } from 'angular';
import { StateProvider, UrlRouterProvider } from '@uirouter/angularjs';
import { ROUTES_STATES_LAYOUTS } from 'src/modules/routes/states/StatesLayouts';
import { ROUTES_STATES_ERRORS } from 'src/modules/routes/states/StatesErrors';
import { ROUTES_STATES_WIDGETS } from 'src/modules/routes/states/StatesWidgets';
import { ROUTES_STATES_DEMO } from 'src/modules/routes/states/StatesDemo';
import { ROUTES_STATES_SNIPPET } from 'src/modules/routes/states/StatesSnippet';
import { ROUTES_STATES_SETTINGS } from 'src/modules/routes/states/StatesSettings';
import { ROUTES_STATES_ITEMS } from 'src/modules/routes/states/StatesItems';
import { ROUTES_STATES_KEY_PHRASES } from 'src/modules/routes/states/StatesKeyPhrases';
import { ROUTES_STATES_DASHBOARD } from 'src/modules/routes/states/StatesDashboard';
import { ROUTES_STATES_CRAWLER_LOG } from 'src/modules/routes/states/StatesCrawlerLog';
import { ROUTES_STATES_ACTIVITY } from 'src/modules/routes/states/StatesActivity';
import { ROUTES_STATES_STATISTICS } from 'src/modules/routes/states/StatesStatistics';
import { ROUTES_STATES_ORGANIZATIONS } from 'src/modules/routes/states/StatesOrganizations';
import { errorHandlingHooks } from 'src/modules/routes/hooks/errorHandlingHooks';

const STATES = [
    ...ROUTES_STATES_LAYOUTS,
    ...ROUTES_STATES_ERRORS,
    ...ROUTES_STATES_WIDGETS,
    ...ROUTES_STATES_DEMO,
    ...ROUTES_STATES_SNIPPET,
    ...ROUTES_STATES_SETTINGS,
    ...ROUTES_STATES_ITEMS,
    ...ROUTES_STATES_KEY_PHRASES,
    ...ROUTES_STATES_DASHBOARD,
    ...ROUTES_STATES_CRAWLER_LOG,
    ...ROUTES_STATES_STATISTICS,
    ...ROUTES_STATES_ORGANIZATIONS,
    ...ROUTES_STATES_ACTIVITY,
];

export const ROUTES_MODULE = module('routes', [])
    .config([
        '$stateProvider',
        ($stateProvider: StateProvider) => STATES.forEach((state) => $stateProvider.state(state)),
    ])
    .config([
        '$urlRouterProvider',
        ($urlRouterProvider: UrlRouterProvider) => $urlRouterProvider.otherwise('/'),
    ])
    .run(errorHandlingHooks);
