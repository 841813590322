import { SagaIterator } from 'redux-saga';
import { SITE_LANGUAGES_CHANGE_LANGUAGE_ACTIONS }
    from 'src/react/siteLanguage/actions/SiteLanguagesChangeLanguageActions';

import { all, takeLatest } from 'redux-saga/effects';
import { siteLanguageChangeLanguageDataSaga }
    from 'src/react/siteLanguage/sagas/siteLanguageChangeLanguageDataSaga';

export function* siteLanguageChangeLanguageSaga(): SagaIterator {
    yield all([
        takeLatest(SITE_LANGUAGES_CHANGE_LANGUAGE_ACTIONS.REQUEST, siteLanguageChangeLanguageDataSaga),
    ]);
}
