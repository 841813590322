import { UiState } from 'src/react/common/state/UiState';
import { UI_POPOVER_CLOSE, UI_POPOVER_OPEN, UiPopoverAction } from 'src/react/common/actions/UiPopoverActions';

export function uiPopoverReducer(
    state: UiState,
    action: UiPopoverAction,
): UiState {
    switch (action.type) {
        case UI_POPOVER_OPEN:
            return state.openPopovers.includes(action.popoverId)
                ? state
                : { ...state, openPopovers: [...state.openPopovers, action.popoverId] };

        case UI_POPOVER_CLOSE:
            return state.openPopovers.includes(action.popoverId)
                ? { ...state, openPopovers: state.openPopovers.filter((id) => id !== action.popoverId) }
                : state;

        default:
            return state;
    }
}
