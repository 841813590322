import { assertNotNull } from 'src/utils/assertion';
import { DemoPageState } from 'src/react/demoPage/state/DemoPageState';
import {
    DEMO_UPDATE_PINNED_ACTIONS,
    DemoUpdatePinnedActions,
} from 'src/react/demoPage/actions/DemoUpdatePinnedActions';

export function demoUpdatePinnedReducer(
    state: DemoPageState,
    action: DemoUpdatePinnedActions,
): DemoPageState {
    switch (action.type) {
        case DEMO_UPDATE_PINNED_ACTIONS.SUCCESS:
            return {
                ...state,
                pinMode: true,
                data: {
                    ...assertNotNull(state.data, 'Demo page data has to be fetched first', { state, action }),
                    pinnedItems: action.data,
                },
            };

        default:
            return state;
    }
}
