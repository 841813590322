import { bind } from 'lodash-decorators/bind';
import { StateService } from '@uirouter/core';
import { IComponentController, IPromise, IQService } from 'angular';
import { assertDefined } from 'src/utils/assertion';
import { BaseError } from 'src/errors/BaseError';
import { UserRole } from 'src/types/values/UserRole';
import { Organization } from 'src/types/entities/Organization';
import { Widget } from 'src/types/entities/Widget';
import { ItemState } from 'src/types/values/ItemState';
import { CrawlerTask } from 'src/types/entities/CrawlerTask';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { ISafePromiseWrapper } from 'src/modules/core/utils/SafePromiseWrapper';
import { IItemService } from 'src/modules/api/ItemService';
import { IWidgetService } from 'src/modules/api/WidgetService';
import { ICrawlerTaskService } from 'src/modules/api/CrawlerTaskService';
import { IWidgetListDialogService } from 'src/modules/widgetList/WidgetListDialogService';
import { WidgetListParams } from 'src/modules/widgetList/WidgetListParams';

export interface WidgetWithData extends Widget {
    readonly itemCount: UnsignedInteger;
    readonly crawlerTask: CrawlerTask | null;
}

export class WidgetListController implements IComponentController {
    public readonly pageSize = 6;

    public organizations!: ReadonlyArray<Organization>;
    public params!: Readonly<WidgetListParams>;

    public loading = false;
    public error: BaseError | undefined;

    public data: ReadonlyArray<WidgetWithData> = [];
    public total: UnsignedInteger = 0;

    private safePerformRequest = this.safePromiseWrapper
        .wrapPromiseFunction(this.performSearchRequest);

    public static $inject = [
        '$q',
        '$state',
        'safePromiseWrapper',
        'widgetService',
        'widgetListDialogService',
        'itemService',
        'crawlerTaskService',
    ];
    public constructor(
        private $q: IQService,
        private $stateService: StateService,
        private safePromiseWrapper: ISafePromiseWrapper,
        private widgetService: IWidgetService,
        private dialogService: IWidgetListDialogService,
        private itemService: IItemService,
        private crawlerTaskService: ICrawlerTaskService,
    ) {
    }

    public $onInit(): void {
        this.updateSearchResult();
    }

    public applyFilter(filter: Partial<WidgetListParams>): void {
        this.params = { ...this.params, ...filter };
        this.$stateService.go('widgets', this.params);

        this.updateSearchResult();
    }

    public createWidget(): void {
        const { params, organizations } = this;
        const allowedOrganizations = params.organizationId
            ? organizations.filter((it) => it.id === params.organizationId)
            : organizations;

        this.dialogService.createWidget(allowedOrganizations).then(
            () => this.updateSearchResult(),
            () => {},
        );
    }
    public updateWidget(widget: Readonly<Widget>): void {
        this.dialogService.updateWidget(this.organizations, widget).then(
            () => this.updateSearchResult(),
            () => {},
        );
    }

    public canEdit(widget: Widget): boolean {
        const { organizations } = this;
        const organization = assertDefined(
            organizations.find((it) => it.id === widget.organizationId),
            `Unknow widget organization "${widget.organizationId}"`,
            { widget, organizations },
        );

        return (
            organization.permission.userRole === UserRole.Manager ||
            organization.permission.userRole === UserRole.Admin
        );
    }

    public canCreate(): boolean {
        const { params } = this;
        if (!params.organizationId) {
            return this.organizations.some((it) => (
                it.permission.userRole === UserRole.Manager ||
                it.permission.userRole === UserRole.Admin
            ));
        }

        const { organizations } = this;
        const organization = assertDefined(
            organizations.find((it) => it.id === params.organizationId),
            `Unknow widget organization "${params.organizationId}"`,
            { params, organizations },
        );
        return (
            organization.permission.userRole === UserRole.Manager ||
            organization.permission.userRole === UserRole.Admin
        );
    }

    public activate(widget: Readonly<Widget>): void {
        this.loading = true;
        this.widgetService.update(widget, {
            organizationId: widget.organizationId,

            name: widget.name,
            website: widget.website,

            apiKey: widget.apiKey,
            isActive: true,
            includeToFacets: widget.includeToFacets,
            includeToRecommendations: widget.includeToRecommendations,
            indexLanguage: widget.indexLanguage,

            defaultImage: widget.defaultImage,

            urlBoost: widget.urlBoost,
            fieldBoost: widget.fieldBoost,
            customBoosts: widget.customBoosts,

            autocomplete: widget.autocomplete,
            searchParams: widget.searchParams,
            htmlTemplates: widget.htmlTemplates,
            feedbackSettings: widget.feedbackSettings,

            crawlerLimit: widget.crawlerLimit,
            crawlerDepth: widget.crawlerDepth,
            crawlerEnabled: widget.crawlerEnabled,
            crawlerExclude: widget.crawlerExclude,
            crawlerWhiteList: widget.crawlerWhiteList,
            crawlerPages: widget.crawlerPages,
            crawlerDomains: widget.crawlerDomains,
            parserConfig: widget.parserConfig,

            ignoreRobotsTxt: widget.ignoreRobotsTxt,
        }).then(
            () => this.updateSearchResult(),
            () => {},
        );
    }

    private updateSearchResult(): void {
        this.loading = true;

        this.safePerformRequest()
            .then(({ data, total }) => {
                this.data = data;
                this.total = total;
            })
            .then(() => {
                this.loading = false;
                this.error = undefined;
            }, (error) => {
                this.loading = false;
                this.error = error;
            });
    }

    private extendWidgetWithMetadata(widget: Readonly<Widget>): IPromise<WidgetWithData> {
        return this.$q.all([
            this.itemService.list(widget, {
                query: '',
                url: '',
                exact: false,
                type: [],
                state: [ItemState.Active],
                id: [],
                sort: [],
                page: 0,
                pageSize: 0,
            }).then(({ total }) => total),
            this.crawlerTaskService.list(widget, {
                sort: ['-createdAt'],
                page: 0,
                pageSize: 1,
            }).then(({ data: [crawlerTask = null] }) => crawlerTask),
        ]).then(([itemCount, crawlerTask]) => ({
            ...widget,
            itemCount,
            crawlerTask,
        }));
    }

    @bind
    private performSearchRequest(): IPromise<ListWithTotal<WidgetWithData>> {
        return this.widgetService.list({
            organizationId: this.params.organizationId,
            query: this.params.query,
            state: [],
            sort: ['-createdAt'],
            page: Math.max(0, this.params.page - 1),
            pageSize: this.pageSize,
        }).then(({ data, total }) => this.$q
            .all(data.map((widget) => this.extendWidgetWithMetadata(widget)))
            .then((widgets) => ({ data: widgets, total }))
        );
    }
}
