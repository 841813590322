import { BaseError } from 'src/errors/BaseError';

export class LogicError extends BaseError {
    public constructor(
        private errorMessage: string,
        private contextData?: object,
        previous?: BaseError
    ) {
        super(previous);
    }

    public get name(): string {
        return 'LogicError';
    }

    public get message(): string {
        return this.errorMessage;
    }

    public get context(): object | undefined {
        return this.contextData;
    }

    public toJSON(): object {
        return {
            ...super.toJSON(),
            contextData: this.contextData,
        };
    }
}
