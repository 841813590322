import * as yup from 'yup';
import { SettingsCustomField } from 'src/react/settings/types/SettingsCustomField';
import { SettingsCustomBoostFormData } from 'src/react/settings/types/SettingsCustomBoostFormData';
import { SettingsCustomBoost } from 'src/react/settings/types/SettingsCustomBoost';

export const SETTINGS_CUSTOM_BOOST_FORM_SCHEMA = yup.object<SettingsCustomBoostFormData>({
    fieldBoost: yup.number()
        .nullable(false)
        .min(0, 'formError_tooSmall')
        .max(10, 'formError_tooLarge'),
    fieldName: yup.lazy((value: string, context?) => {
        const customFields: ReadonlyArray<SettingsCustomField> = context.context.customFields;
        const customBoosts: ReadonlyArray<SettingsCustomBoost> = context.context.customBoosts;

        const allowedFieldNames = customFields
            .filter((it) => it.fieldType === 'text')
            .map((it) => it.fieldName);
        const disallowedFieldNames = customBoosts
            .map((it) => it.fieldName);

        return yup
            .string()
            .nullable(true)
            .required('formError_required')
            .oneOf([null, ...allowedFieldNames], 'formError_invalid')
            .notOneOf(disallowedFieldNames, 'settings_error_customBoost_duplicate');
    }),
});
