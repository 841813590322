import { Reducer } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';
import { partialReducers } from 'src/utils/partialReducers';
import { RootState } from 'src/react/root/state/RootState';
import { uiReducer } from 'src/react/common/reducers/uiReducer';
import { demoPageReducer } from 'src/react/demoPage/reducers/demoPageReducer';
import { selectedWidgetReducer } from 'src/react/selectedWidget/reducers/selectedWidgetReducer';
import { keyPhrasePinInputReducer } from 'src/react/keyPhrasePinInput/reducers/keyPhrasePinInputReducer';
import { itemImageUploadReducer } from 'src/react/itemImageUpload/reducers/itemImageUploadReducer';
import { itemFormReducer } from 'src/react/itemForm/reducers/itemFormReducer';
import { manualCrawlerReducer } from 'src/react/manualCrawler/reducers/manualCrawlerReducer';
import { statisticsPageReducer } from 'src/react/statistics/reducers/statisticsPageReducer';
import { keyPhraseSynonymsReducer } from 'src/react/keyPhraseSynonyms/reducers/KeyPhraseSynonymsReducer';
import { organizationListReducer } from 'src/react/organization/reducers/OrganizationListReducer';
import { keyPhraseGroupIsExistReducer } from 'src/react/keyPhraseGroup/reducers/keyPhraseGroupIsExistReducer';
import { siteLanguageChangeLanguageReducer } from 'src/react/siteLanguage/reducers/siteLanguageChangeLanguageReducer';

export const rootReducer = partialReducers<RootState>({
    form: formReducer,
    routing: routerReducer,
    ui: uiReducer,
    locale: siteLanguageChangeLanguageReducer,
    demoPage: demoPageReducer,
    statisticsPage: statisticsPageReducer,
    selectedWidget: selectedWidgetReducer,
    keyPhrasePinInput: keyPhrasePinInputReducer,
    itemItemUpload: itemImageUploadReducer,
    itemForm: itemFormReducer,
    manualCrawler: manualCrawlerReducer,
    keyPhraseSynonyms: keyPhraseSynonymsReducer,
    organizationList: organizationListReducer,
    keyPhraseGroupIsExist: keyPhraseGroupIsExistReducer,
}) as Reducer<RootState>;
