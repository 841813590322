import './KeyPhraseGroupModal.scss';
import { ModalBody, ModalClose, ModalFooter, ModalHeader } from 'src/react/common/components/Modal';
import { FormattedMessage } from 'react-intl';
import { HtmlButton } from 'src/react/common/components/Button';
import * as React from 'react';
import { bind } from 'lodash-decorators/bind';
import { Textbox } from 'src/react/common/components/Textbox';
import { Uuid } from 'src/types/values/Uuid';
import { KeyPhrasePinInput } from 'src/react/keyPhrasePinInput/components/KeyPhrasePinInput';
import { debounce } from 'lodash-decorators/debounce';
import { createStructuredSelector } from 'reselect';
import { RootState } from 'src/react/root/state/RootState';
import { connectDecorator } from 'src/decorators/connectDecorator';
import { getKeyPhraseGroupIsExistData } from 'src/react/keyPhraseGroup/selectors/getKeyPhraseGroupIsExistData';
import { KEY_PHRASE_GROUP_IS_EXIST_ACTIONS } from 'src/react/keyPhraseGroup/actions/keyPhraseGroupIsExistActions';
import { KeyPhraseGroup } from 'src/types/entities/KeyPhraseGroup';
import {
    getKeyPhraseGroupIsExistIsLoading,
} from 'src/react/keyPhraseGroup/selectors/getKeyPhraseGroupIsExistIsLoading';

type OwnProps = {
    readonly id: Uuid | null;
    readonly text: string;
    readonly keyphrases: ReadonlyArray<string>;
    readonly onSave: (form: { text: string; keyphrases: ReadonlyArray<string> }) => void;
    readonly onCancel: () => void;
    readonly goToGroup: (groupId: Uuid) => void;
};
type StateProps = {
    readonly isExistData: KeyPhraseGroup | null;
    readonly isLoading: boolean;
};
type DispatchProps = {
    readonly isExist: (groupName: string, groupId: Uuid | null) => void;
};
type AllProps =
    & OwnProps
    & StateProps
    & DispatchProps;

type State = {
    readonly text: string;
    readonly keyphrases: ReadonlyArray<string>;
};

class Connected extends React.Component<AllProps, State> {
    public readonly state: State = {
        keyphrases: this.props.keyphrases,
        text: this.props.text,
    };

    public render(): JSX.Element {
        const { text, keyphrases } = this.state;
        const { id, text: originalText, isLoading, isExistData } = this.props;
        return (
            <form className="keyphrase-group" onSubmit={this.handleSubmit}>
                <ModalHeader>
                    <ModalClose onClick={this.handleCancel}/>
                    <FormattedMessage id={this.getTitleMessageId()}/>
                </ModalHeader>
                <ModalBody className="keyphrase-group__content">

                        <div className="keyphrase-group__row">
                            <label htmlFor="text" className="keyphrase-group__label">
                                <FormattedMessage id="keyPhraseGroup_text"/>
                            </label>
                            <div className="keyphrase-group__value">
                                <Textbox type="text"
                                         value={text}
                                         autoComplete="off"
                                         required={true}
                                         onChange={this.handleChangeText}
                                />
                            </div>
                        </div>
                        <div className="keyphrase-group__row">
                            <label htmlFor="keyphrases" className="keyphrase-group__label">
                                <FormattedMessage id="keyPhraseGroup_keyphrases"/>
                            </label>
                            <div className="keyphrase-group__value">
                                <KeyPhrasePinInput hideAddButton={true}
                                                   placeholderid="keyPhraseGroup_add_keyword"
                                                   value={keyphrases}
                                                   onChange={this.handleChangeKeyphrases}/>
                            </div>
                            { keyphrases.length === 0 && id !== null && (
                                <label className="keyphrase-group__notice">
                                    <i className="si si-info keyphrase-group__icon"/>
                                    <FormattedMessage id="keyPhraseGroup_notice"
                                        values={{ groupName: originalText !== text ? ` (${originalText})` : '' }}
                                    />
                                </label>
                            )}
                            { isExistData !== null && text.length > 0 && (
                                <label className="keyphrase-group__notice">
                                    <i className="si si-info keyphrase-group__icon"/>
                                    <FormattedMessage id="keyPhraseGroup_duplicate_notice" values={{
                                      link: (
                                          <a onClick={this.goToGroupHandle}>
                                            <FormattedMessage id="keyPhraseGroup_duplicate_notice_link"/>
                                          </a>)
                                    }}/>

                                </label>
                            )}
                        </div>

                </ModalBody>
                <ModalFooter className="keyphrase-group__footer">
                    <HtmlButton block={false}
                                type="button"
                                intent="none"
                                onClick={this.handleCancel}>
                        <FormattedMessage id="keyPhraseGroup_cancelButton"/>
                    </HtmlButton>
                    <HtmlButton block={false}
                                type="submit"
                                intent="secondary"
                                disabled={!this.isValid() || isLoading || this.isKeyphrasesEmpty()}>
                        {isLoading && (<i className="si si-Crawler" />)}
                        <FormattedMessage id="keyPhraseGroup_submitButton"/>
                    </HtmlButton>
                </ModalFooter>
            </form>
        );
    }

    @bind
    private goToGroupHandle(): void {
        const { goToGroup, onCancel, isExistData } = this.props;
        if (isExistData) {
            goToGroup(isExistData.id);
        }
        onCancel();
    }

    @bind
    private handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
        event.preventDefault();
        const { onSave } = this.props;
        const { text, keyphrases } = this.state;
        onSave({
            text,
            keyphrases,
        });
    }

    @bind
    private handleCancel(): void {
        const { onCancel } = this.props;
        onCancel();
    }

    @bind
    private handleChangeText(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({ text: event.target.value }, () => {
            this.validate();
        });
    }

    @bind
    private handleChangeKeyphrases(keyphrases: ReadonlyArray<string>): void {
        this.setState({ keyphrases });
    }

    private getTitleMessageId(): string {
        const { id } = this.props;
        return id === null ? 'keyPhraseGroup_modalTitle_create' : 'keyPhraseGroup_modalTitle_update';
    }

    private isKeyphrasesEmpty(): boolean {
        const { keyphrases } = this.state;
        const { id } = this.props;
        return id === null && keyphrases.length === 0;
    }

    private isValid(): boolean {
        const { isExistData } = this.props;
        const { text } = this.state;
        return text !== '' && isExistData === null;
    }

    @debounce(200)
    private validate(): void {
        const { isExist, text: originalText, id } = this.props;
        const { text } = this.state;
        if (originalText !== text) {
            isExist(text, id);
            return;
        }
    }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
    isExistData: getKeyPhraseGroupIsExistData,
    isLoading: getKeyPhraseGroupIsExistIsLoading,
});
const mapDispatchToProps: DispatchProps = {
    isExist: (groupName, groupId) => KEY_PHRASE_GROUP_IS_EXIST_ACTIONS
        .request({ groupName, groupId }),
};

@connectDecorator<OwnProps, StateProps, DispatchProps>(Connected, mapStateToProps, mapDispatchToProps)
export class KeyPhraseGroupModal extends React.Component<OwnProps> {}
