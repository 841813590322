export const PARSER_CONFIG_JSON_SCHEMA = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    definitions: {
        extractor: {
            type: 'array',
            items: [{
                type: 'object',
                required: [
                    'selector',
                    'target',
                    'type'
                ],
                properties: {
                    type: {
                        type: 'string',
                        enum: [
                            'attr',
                            'attr-link',
                            'text',
                            'text-link',
                            'ld-json-link',
                            'ld-json',
                            'style-background-url-link',
                        ],
                    },
                    selector: {
                        type: 'string'
                    },
                    target: {
                        type: 'string'
                    }
                }
            }]
        }
    },
    type: 'array',
    items: {
        type: 'object',
        properties: {
            condition: {
                type: 'string'
            },
            sections: {
                type: 'object',
                required: [
                    'title',
                    'description',
                    'image'
                ],
                additionalProperties: {
                    $ref: '#/definitions/extractor'
                }
            }
        },
        required: [
            'condition',
            'sections'
        ]
    }
};
