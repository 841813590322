import { IPromise } from 'angular';
import { IModalService } from 'angular-ui-bootstrap';
import { Item } from 'src/types/entities/Item';
import { Uuid } from 'src/types/values/Uuid';
import { Widget } from 'src/types/entities/Widget';
import { CustomField } from 'src/types/entities/CustomField';

export interface IItemDialogService {
    createItem(
        widget: Readonly<Widget>,
        customFields: ReadonlyArray<CustomField>,
    ): IPromise<Item>;

    updateItem(
        widget: Readonly<Widget>,
        customFields: ReadonlyArray<CustomField>,
        itemId: Uuid,
    ): IPromise<Item>;
}

export class ItemDialogService implements IItemDialogService {
    public static $inject = [
        '$uibModal',
    ];
    public constructor(
        private $uibModal: IModalService,
    ) {
    }

    public createItem(
        widget: Readonly<Widget>,
        customFields: ReadonlyArray<CustomField>,
    ): IPromise<Item> {
        return this.$uibModal.open({
            animation: true,
            backdrop: 'static',
            component: 'itemForm',
            resolve: {
                itemId: () => null,
                widget: () => widget,
                customFields: () => customFields,
                onRestored: () => () => {},
            },
        }).result;
    }

    public updateItem(
        widget: Readonly<Widget>,
        customFields: ReadonlyArray<CustomField>,
        itemId: Uuid,
    ): IPromise<Item> {
        return this.$uibModal.open({
            animation: true,
            backdrop: 'static',
            component: 'itemForm',
            resolve: {
                itemId: () => itemId,
                widget: () => widget,
                customFields: () => customFields,
                onRestored: () => () => {},
            },
        }).result;
    }
}
