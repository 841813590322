import { AnyAction } from 'redux';
import { ORGANIZATION_LIST_DEFAULT_STATE } from 'src/react/organization/constants/OrganizationListDefaultState';
import { OrganizationListState } from 'src/react/organization/state/OrganizationListState';
import { ORGANIZATION_LIST_SEED_ACTIONS } from 'src/react/organization/actions/OrganizationListSeedActions';

export function organizationListSeedReducer(
    state: OrganizationListState,
    action: AnyAction,
): OrganizationListState {
    if (ORGANIZATION_LIST_SEED_ACTIONS.isSuccess(action)) {
        return {
            ...ORGANIZATION_LIST_DEFAULT_STATE,
            organizations: action.data,
        };
    }

    return state;
}
