import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { OrganizationForm } from 'src/types/dto/OrganizationForm';
import { getOrganizationEndpoint } from 'src/react/api/selectors/getEndpoint';
import { IOrganizationEndpoint } from 'src/react/api/endpoints/OrganizationEndpoint';

export function* organizationCreate(
    formData: OrganizationForm,
): SagaIterator {
    const organizationEndpoint: IOrganizationEndpoint = yield select(getOrganizationEndpoint);

    return yield call({
        fn: organizationEndpoint.create,
        context: organizationEndpoint,
    }, formData, {});
}
