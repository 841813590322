import * as React from 'react';
import { bind } from 'lodash-decorators/bind';
import { Uuid } from 'src/types/values/Uuid';
import { Item } from 'src/types/entities/Item';
import { ItemType } from 'src/types/values/ItemType';
import { ItemFormItemModal } from 'src/react/itemForm/components/ItemFormItemModal';
import { ITEM_FORM_RESTORE_ACTIONS } from 'src/react/itemForm/actions/ItemFormRestoreActions';
import { ITEM_FORM_SAVE_RESTORED_ACTIONS } from 'src/react/itemForm/actions/ItemFormSaveRestoredActions';
import { createStructuredSelector } from 'reselect';
import { RootState } from 'src/react/root/state/RootState';
import { ITEM_FORM_LOAD_ITEM_ACTIONS } from 'src/react/itemForm/actions/ItemFormLoadItemActions';
import { connectDecorator } from 'src/decorators/connectDecorator';
import { LoadingWrapper } from 'src/react/common/components/LoadingWrapper';
import { ItemFormValue } from 'src/react/itemForm/types/ItemFormValue';
import { ITEM_FORM_LOAD_ACTIONS } from 'src/react/itemForm/actions/ItemFormLoadActions';
import { getCustomFields } from 'src/react/selectedWidget/selectors/getCustomFields';
import { CustomField } from 'src/types/entities/CustomField';
import { assertTypeIs } from 'src/utils/assertion';
import { PROMISE_LISTENER } from 'src/react/root/constants/promiseListener';
import { BaseError } from 'src/errors/BaseError';
import { getSubmissionError } from 'src/utils/getFormErrors';

type OwnProps = {
    readonly id: Uuid | null;
    readonly onCancel: () => void;
    readonly onSaved: (item: Item) => void;
    readonly onRestored: (item: Item) => void;
};
type StateProps = {
    readonly item: Item | null;
    readonly restoredItem: Item | null;
    readonly loading: boolean;
    readonly itemForm: ItemFormValue | null;
    readonly isSaving: boolean;
    readonly customFields: ReadonlyArray<CustomField>;
};
type DispatchProps = {
    readonly restore: (itemType: ItemType, id: Uuid) => void;
    readonly loadItemData: (id: Uuid | null) => void;
    readonly loadFormData: (item: Item | null) => void;
};
type Props = OwnProps & StateProps & DispatchProps;

type State = {
    readonly editingItem: Item | null;
    readonly isRestoring: boolean;
};

class ItemConnected extends React.Component<Props> {
    public state: State = {
        editingItem: null,
        isRestoring: false,
    };
    private saveRestoredPromise = PROMISE_LISTENER.createAsyncFunction<Uuid, Item>({
        start: ITEM_FORM_SAVE_RESTORED_ACTIONS.REQUEST,
        setPayload: (action, id) => ITEM_FORM_SAVE_RESTORED_ACTIONS.request(id),

        resolve: (action) => ITEM_FORM_SAVE_RESTORED_ACTIONS.isSuccess(action),
        getPayload: (action) => assertTypeIs(action, ITEM_FORM_SAVE_RESTORED_ACTIONS.isSuccess).data,

        reject: (action) => ITEM_FORM_SAVE_RESTORED_ACTIONS.isFailure(action),
        getError: (action) => assertTypeIs(action, ITEM_FORM_SAVE_RESTORED_ACTIONS.isFailure).data,
    });

    public componentDidMount(): void {
        const { id, loadItemData } = this.props;
        loadItemData(id);
    }

    public componentDidUpdate(prevProps: Props): void {
        const { item, restoredItem } = this.props;
        const { editingItem, isRestoring } = this.state;

        if (prevProps.item !== item) {
            this.setState({ editingItem: item });
        }

        if (editingItem !== null && restoredItem !== null
            && restoredItem.id === editingItem.id
            && editingItem.type !== restoredItem.type
            && isRestoring
        ) {
            this.setState({ editingItem: restoredItem });
        }
    }

    public componentWillUnmount(): void {
        this.saveRestoredPromise.unsubscribe();
        this.setState({
            isRestoring: false,
            editingItem: null,
        });
    }

    public render(): JSX.Element {
        const { id } = this.props;
        const { editingItem } = this.state;

        return (
            <div className="item-form-modal">
                <LoadingWrapper loading={id !== null && editingItem === null}>
                    {this.renderForm()}
                </LoadingWrapper>
            </div>
        );
    }

    private renderForm(): JSX.Element | null {
        const { onSaved, onCancel, loadFormData, customFields, itemForm, isSaving } = this.props;
        const { editingItem, isRestoring } = this.state;

        return (
            <ItemFormItemModal item={editingItem}
                               onCancel={onCancel}
                               onSaved={onSaved}
                               restore={this.handleRestore}
                               loadFormData={loadFormData}
                               itemForm={itemForm}
                               isSaving={isSaving}
                               saveRestored={this.handleSaveRestored}
                               isRestoring={isRestoring}
                               customFields={customFields}

            />
        );
    }

    @bind
    private handleRestore(): void {
        const { item, restore } = this.props;

        if (item !== null && confirm('Would you like to restore this item?')) {
            const itemType = item.type !== ItemType.Crawled ? ItemType.Crawled : item.type;
            restore(itemType, item.id);
            this.setState({ isRestoring: true });
        }
    }

    @bind
    private handleSaveRestored(): Promise<void> | null {
        const { restoredItem, onSaved } = this.props;

        return restoredItem !== null
            ? this.saveRestoredPromise.asyncFunction(restoredItem.id)
                .then((item) => onSaved(item))
                .catch((error: BaseError) => { throw getSubmissionError(error); })
            : null;
    }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
    loading: (state) => state.itemForm.loadItem.loading,
    item: (state) => state.itemForm.loadItem.data,
    restoredItem: (state) => state.itemForm.restore.data,
    isSaving: (state) => state.itemForm.save.loading,
    itemForm: (state) => state.itemForm.load.data,
    customFields: getCustomFields,
});

const mapDispatchToProps: DispatchProps = {
    loadItemData: (itemId) => ITEM_FORM_LOAD_ITEM_ACTIONS.request(itemId),
    restore: (itemType, itemId) => ITEM_FORM_RESTORE_ACTIONS.request({ itemType, itemId }),
    loadFormData: (item) => ITEM_FORM_LOAD_ACTIONS.request(item),
};

@connectDecorator<OwnProps, StateProps, DispatchProps>(ItemConnected, mapStateToProps, mapDispatchToProps)
export class ItemFormModal extends React.Component<OwnProps> {}
