import { IComponentOptions, IScope } from 'angular';
import { CrawlerStatusController } from 'src/modules/crawlerStatus/CrawlerStatusController';
import * as CrawlerStatusTemplate from './CrawlerStatusTemplate.html';

export const CRAWLER_STATUS_COMPONENT: IComponentOptions = {
    controller: CrawlerStatusController,
    controllerAs: 'crawlerStatusCtrl',
    template: CrawlerStatusTemplate,
    bindings: { widget: '<', crawlerTask: '<' },
};

export interface CrawlerStatusScope extends IScope {
    crawlerStatusCtrl: CrawlerStatusController;
}
