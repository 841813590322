import './StaticField.scss';
import * as React from 'react';
import { classList } from 'src/utils/classList';

type Props = JSX.IntrinsicElements['div'] & {
    readonly intent?: 'danger' | 'info' | 'success' | 'none';
};

export function StaticField({ intent = 'none', className, ...restProps }: Props): JSX.Element {
    return (
        <div className={classList(className, 'xss-static-field', `xss-static-field--${intent}`)}
             {...restProps}/>
    );
}
