import { ActionAny, createActions } from 'src/utils/createActions';
import { StatisticsPageFilter } from 'src/react/statistics/state/StatisticsPageFilter';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { StatisticsTopSearch } from 'src/types/entities/StatisticsTopSearch';

export type StatisticsCombinedUpdateActions =
    ActionAny<
        StatisticsPageFilter,
        ListWithTotal<StatisticsTopSearch>>;
export const STATISTICS_COMBINED_UPDATE_ACTIONS =
    createActions<
        StatisticsPageFilter,
        ListWithTotal<StatisticsTopSearch>>('STATISTICS_COMBINED_UPDATE');
