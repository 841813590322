import * as React from 'react';
import { BaseFieldProps, Field, WrappedFieldProps } from 'redux-form';
import { Omit } from 'mapped-types-utils';
import { classList } from 'src/utils/classList';
import { fieldMetaClass } from 'src/utils/fieldMetaClass';
import { Selectbox } from 'src/react/common/components/Selectbox';

type SelectboxProps<TOption> = {
    readonly name: string;
    readonly className?: string;
    readonly disabled?: boolean;
    readonly clearable?: boolean;
    readonly isMulti?: boolean;
    readonly placeholder?: React.ReactNode;
    readonly options: ReadonlyArray<TOption>;
    readonly renderOption: (option: TOption) => React.ReactNode;
};

function renderSelectbox<TOption>(props: WrappedFieldProps & SelectboxProps<TOption>): JSX.Element {
    const { input, meta, className, disabled = false, clearable = false, isMulti = false } = props;
    const { renderOption, options, placeholder } = props;

    const onBlur = () => {
        input.onBlur(undefined);
    };

    return (
        <Selectbox value={input.value}
                   options={options}
                   renderOption={renderOption}
                   onChange={input.onChange}
                   onFocus={input.onFocus}
                   onBlur={onBlur}
                   className={classList(className, fieldMetaClass(meta, { disabled, readOnly: false }))}
                   disabled={disabled}
                   clearable={clearable}
                   isMulti={isMulti}
                   placeholder={placeholder}
                   name={input.name}/>
    );
}

type SelectboxFieldProps<TOption>
    = Omit<BaseFieldProps<SelectboxProps<TOption>> & SelectboxProps<TOption>, 'component'>;
export function SelectboxField<TOption>(props: SelectboxFieldProps<TOption>): JSX.Element {
    return <Field format={null} component={renderSelectbox} {...props as any}/>;
}
