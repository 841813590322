import * as React from 'react';
import { Widget } from 'src/types/entities/Widget';
import { Organization } from 'src/types/entities/Organization';
import { StatisticsFilter } from 'src/types/entities/StatisticsFilter';
import { StatisticsPage } from 'src/react/statistics/components/StatisticsPage';
import { SelectedWidget } from 'src/react/selectedWidget/components/SelectedWidget';
import { StatisticsPageWrapper } from 'src/react/statistics/components/StatisticsPageWrapper';

export type StatisticsPageBridgeProps = {
    readonly widget: Widget;
    readonly organization: Organization;
    readonly statisticsFilter: StatisticsFilter | null;
    readonly hasStatisticsData: boolean;
};

export default function (props: StatisticsPageBridgeProps): JSX.Element {
    const {
        widget,
        organization,
        statisticsFilter,
        hasStatisticsData,
    } = props;

    return (
        <SelectedWidget widget={widget}
                        organization={organization}
                        customFields={[]}
                        statisticsFilter={statisticsFilter}>
            <StatisticsPageWrapper>
                <StatisticsPage widget={widget}
                                filter={statisticsFilter}
                                hasSearchesData={hasStatisticsData}
                />
            </StatisticsPageWrapper>
        </SelectedWidget>
    );
}
