import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { Widget } from 'src/types/entities/Widget';
import { StatisticsFilter } from 'src/types/entities/StatisticsFilter';
import { StatisticsPageParams } from 'src/react/statistics/state/StatisticsPageParams';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { createSearchFilter, StatisticsSearchFilter } from 'src/types/dto/StatisticsSearchFilter';
import { getStatisticsUnsuccessfulSearchesEndpoint } from 'src/react/api/selectors/getEndpoint';
import {
    IStatisticsUnsuccessfulSearchesEndpoint
} from 'src/react/api/endpoints/StatisticsUnsuccessfulSearchesEndpoint';

export function* getStatisticsUnsuccessfulSearches(
    filter: StatisticsFilter | null,
    { query, page, sort, dateRange , hasResults }: StatisticsPageParams
): SagaIterator {
    const widget: Widget = yield select(getSelectedWidget);
    const statisticsSearchFilter: StatisticsSearchFilter =
        yield call(createSearchFilter, filter, query, sort, page, dateRange, hasResults);

    const unsuccessfulSearchesEndpoint: IStatisticsUnsuccessfulSearchesEndpoint =
        yield select(getStatisticsUnsuccessfulSearchesEndpoint);

    return yield call({
        fn: unsuccessfulSearchesEndpoint.list,
        context: unsuccessfulSearchesEndpoint,
    }, statisticsSearchFilter, {
        widgetId: widget.id,
    });
}
