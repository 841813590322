import { StatisticsPageState } from 'src/react/statistics/state/StatisticsPageState';

export const STATISTICS_PAGE_DEFAULT_STATE: StatisticsPageState = {
    combinedSearches: {
        loading: false,
        error: null,
        data: null,
    },
    successfulSearches: {
        loading: false,
        error: null,
        data: null,
    },
    unsuccessfulSearches: {
        loading: false,
        error: null,
        data: null,
    },
    lastSearches: {
        loading: false,
        error: null,
        data: null,
    },
};
