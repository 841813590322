import { IPromise, IQService } from 'angular';
import { UserRole } from 'src/types/values/UserRole';
import { Permission } from 'src/types/entities/Permission';
import { Organization } from 'src/types/entities/Organization';
import { Widget } from 'src/types/entities/Widget';
import { IOrganizationService } from 'src/modules/api/OrganizationService';
import { IPermissionService } from 'src/modules/api/PermissionService';
import { IWidgetService } from 'src/modules/api/WidgetService';
import { OrganizationListItem } from 'src/react/organization/state/OrganizationListItem';

organizationListResolver.$inject = [
    '$q',
    'organizationService',
    'permissionService',
    'widgetService',
];
export function organizationListResolver(
    $q: IQService,
    organizationService: IOrganizationService,
    permissionService: IPermissionService,
    widgetService: IWidgetService,
): IPromise<OrganizationListItem[]> {
    function fetchPermissions(
        organization: Organization,
    ): IPromise<Permission[]> {
        if (
            organization.permission.userRole !== UserRole.Admin &&
            organization.permission.userRole !== UserRole.Manager
        ) {
            return $q.resolve([]);
        }

        return permissionService.list(organization, {
            sort: ['createdAt'],
            page: 0,
            pageSize: 100,
        }).then(({ data }) => data);
    }

    function fetchWidgets(
        organization: Organization,
    ): IPromise<Widget[]> {
        return widgetService.list({
            organizationId: organization.id,
            sort: ['createdAt'],
            page: 0,
            pageSize: 100,
            query: '',
            state: [],
        }).then(({ data }) => data);
    }

    function resolveOrganization(
        organization: Organization,
    ): IPromise<OrganizationListItem> {
        return $q.all({
            permissions: fetchPermissions(organization),
            widgets: fetchWidgets(organization),
        }).then(({ permissions, widgets }) => ({
            organization,
            permissions,
            widgets,
        }));
    }

    return organizationService.list({
        query: '',
        sort: ['createdAt'],
        page: 0,
        pageSize: 100,
    }).then(({ data: organizations }) => $q.all(organizations.map(resolveOrganization)));
}
