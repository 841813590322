import { createSelector } from 'reselect';
import { isOneOf } from 'src/utils/isOneOf';
import { getSearchParams } from 'src/react/common/selectors/getSearchParams';
import { getSelectedWidget } from 'src/react/selectedWidget/selectors/getSelectedWidget';
import { DemoPageParams } from 'src/react/demoPage/state/DemoPageParams';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { SearchFuzziness } from 'src/types/values/SearchFuzziness';
import { SearchMatchMode } from 'src/types/values/SearchMatchMode';

export const getDemoPageParams = createSelector([
    getSelectedWidget,
    getSearchParams,
], (widget, searchParams): DemoPageParams => {
    const query = searchParams.get('query');
    const page = searchParams.get('page');
    const fuzzy = searchParams.get('fuzzy');
    const mode = searchParams.get('mode');
    const pinMode = searchParams.get('pinMode');

    return {
        query: query
            ? query
            : '',
        page: page
            ? parsePage(page)
            : 1,
        mode: mode
            ? parseMode(mode)
            : widget.searchParams.searchMatchMode,
        fuzzy: fuzzy
            ? parseFuzzy(fuzzy)
            : (widget.searchParams.enableFuzziness ? 'auto' : 0),
        pinMode: pinMode
            ? parsePinMode(pinMode)
            : false,
    };
});

function parsePage(value: string): UnsignedInteger {
    const asNumber = parseInt(value, 10);
    return isFinite(asNumber) ? Math.max(1, asNumber) : 1;
}

function parsePinMode(value: string): boolean {
    return value === 'true' || value === '1';
}

function parseMode(value: string): SearchMatchMode {
    const validValues = [SearchMatchMode.Prefix, SearchMatchMode.FullText];
    return isOneOf(value, validValues) ? value : SearchMatchMode.FullText;
}

function parseFuzzy(value: string): SearchFuzziness {
    if (value === 'auto') {
        return 'auto';
    }

    const asNumber = parseInt(value, 10);
    return isFinite(asNumber) ? Math.max(0, asNumber) : 'auto';
}
