import './DemoPagePinMode.scss';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { bind } from 'lodash-decorators/bind';
import { memoize } from 'lodash-decorators/memoize';
import { debounce } from 'lodash-decorators/debounce';
import { Item } from 'src/types/entities/Item';
import { Widget } from 'src/types/entities/Widget';
import { DemoFilterParams } from 'src/react/demoPage/state/DemoFilterParams';
import { DemoPageState } from 'src/react/demoPage/state/DemoPageState';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { ErrorMessage } from 'src/react/common/components/ErrorMessage';
import { LoadingWrapper } from 'src/react/common/components/LoadingWrapper';
import { HtmlButton } from 'src/react/common/components/Button';
import { Textbox } from 'src/react/common/components/Textbox';
import { InputStatus } from 'src/react/common/components/InputStatus';
import { Pager } from 'src/react/common/components/Pager';
import { DemoPageNoResults } from 'src/react/demoPage/components/DemoPageNoResults';
import { DemoPageFilterItem } from 'src/react/demoPage/components/DemoPageFilterItem';
import { DemoPagePinnedList } from 'src/react/demoPage/components/DemoPagePinnedList';
import { DEMO_SEARCH_PAGE_SIZE } from 'src/react/demoPage/constants/DemoPageSize';
import { KeyPhrase } from 'src/types/entities/KeyPhrase';

type Props = {
    readonly widget: Widget;
    readonly activePage: UnsignedInteger;
    readonly activeQuery: string;
    readonly isSaving: boolean;
    readonly searchResult: DemoPageState;
    readonly updateResult: (params: DemoFilterParams) => void;
    readonly onEditItem: (item: Item) => void;
    readonly onEditSynonyms: () => void;
    readonly onDeleteKeyPhrase: () => void;
    readonly onPinItem: (item: Item) => void;
    readonly onUnpinItem: (item: Item) => void;
    readonly onChangeSort: (items: ReadonlyArray<Item>) => void;
    readonly onLeavePinMode: (action: 'save' | 'cancel') => void;
    readonly synonyms: JSX.Element | null;

};
type State = DemoFilterParams;

export class DemoPagePinMode extends React.Component<Props, State> {
    public state: State = {
        page: this.props.activePage,
        query: '',
    };

    public render(): React.ReactNode {
        const { query } = this.state;
        const {
            widget,
            activeQuery,
            searchResult,
            isSaving,
            onEditItem,
            onEditSynonyms,
            onUnpinItem,
            onChangeSort,
            synonyms,
        } = this.props;

        const pinnedItems = searchResult.data
            ? searchResult.data.pinnedItems
            : [];

        const pinnedItemsTitle =
            <FormattedMessage id="searchDemo_pinnedItemCount" values={{ count: pinnedItems.length }}/>;

        return (
            <div className="demo-page-pin-mode">
                <div className="demo-page-pin-mode__content">
                    <div className="demo-page-pin-mode__keyphrase">
                        <div className="demo-page-pin-mode__title">
                            <h2 className="demo-page-pin-mode__title-text">
                                {activeQuery}
                            </h2>
                            {synonyms}
                            <div className="demo-page-pin-mode__title-synonyms-manage">
                                <a href="javascript:void(0)" onClick={onEditSynonyms}>
                                    <FormattedMessage id="searchDemo_manageSynonyms"/>
                                </a>
                            </div>
                        </div>
                        <div className="demo-page-pin-mode__cancel">
                            <HtmlButton onClick={this.handleCancelClick}
                                        intent="none"
                                        disabled={isSaving}
                                        block={true}>
                                <FormattedMessage id="searchDemo_leavePinModeAndCancel"/>
                            </HtmlButton>
                        </div>
                        <div className="demo-page-pin-mode__save">
                            {!this.canDelete()
                                ? <HtmlButton onClick={this.handleSaveClick}
                                              intent="secondary"
                                              disabled={isSaving || !this.canSave()}
                                              block={true}>
                                    <FormattedMessage id="searchDemo_leavePinModeAndSave"/>
                                </HtmlButton>
                                : <HtmlButton onClick={this.handleSaveClick}
                                              intent="secondary"
                                              disabled={isSaving}
                                              block={true}>
                                    <FormattedMessage id="searchDemo_leavePinModeAndDelete"/>
                                </HtmlButton>
                            }
                        </div>
                    </div>

                    <LoadingWrapper loading={isSaving}>
                        <div className="demo-page-pin-mode__pinned">
                            <div className="demo-page-pin-mode__pinned-count">
                                <FormattedMessage id="searchDemo_pinnedItems"
                                                  values={{
                                                      count: pinnedItems.length,
                                                      title: <b>{pinnedItemsTitle}</b>
                                                  }}/>
                            </div>
                            <div className="demo-page-pin-mode__pinned-list">
                                <DemoPagePinnedList items={pinnedItems}
                                                    widget={widget}
                                                    onEditItem={onEditItem}
                                                    onUnpinItem={onUnpinItem}
                                                    onChangeSort={onChangeSort}/>
                            </div>
                        </div>
                    </LoadingWrapper>

                    <div className="demo-page-pin-mode__form">
                        <FormattedMessage id="searchDemo_pinModePlaceholder">
                            {(message) => (
                                <InputStatus prefix={<i className="fa fa-search"/>}>
                                    <Textbox type="text"
                                             autoFocus={true}
                                             className="demo-page-pin-mode__query"
                                             placeholder={message.toString()}
                                             value={query}
                                             onChange={this.handleSearchChange}/>
                                </InputStatus>
                            )}
                        </FormattedMessage>
                    </div>

                    <div className="demo-page-pin-mode__result">
                        {this.renderError()}
                        {this.renderResult()}
                    </div>
                </div>

                {this.renderPager()}
            </div>
        );
    }

    private renderError(): JSX.Element | null {
        const { searchResult: { error } } = this.props;
        if (!error) {
            return null;
        }

        return (
            <div className="demo-page-pin-mode__error">
                <ErrorMessage error={error} errorInfo={undefined}/>
            </div>
        );
    }

    private renderResult(): JSX.Element | null {
        const { searchResult: { error, loading } } = this.props;
        if (error) {
            return null;
        }

        return (
            <LoadingWrapper loading={loading}>
                {this.renderFoundItems()}
            </LoadingWrapper>
        );
    }

    private renderFoundItems(): JSX.Element | null {
        const { widget, searchResult: { data } } = this.props;
        if (!data) {
            return null;
        }

        const { query } = this.state;
        const { foundItems: { data: foundItems } } = data;
        if (!foundItems.length) {
            return <DemoPageNoResults query={query} widget={widget}/>;
        }

        return (
            <div className="demo-page-pin-mode__filter-result">
                {foundItems.map(({ item }) => (
                    <div className="demo-page-pin-mode__filter-item" key={item.id}>
                        <DemoPageFilterItem item={item}
                                            widget={widget}
                                            isPinned={this.isItemPinned(item)}
                                            onPinItem={this.getPinItemHandler(item)}
                                            onUnpinItem={this.getUnpinItemHandler(item)}
                                            onEditItem={this.getEditItemHandler(item)}/>
                    </div>
                ))}
            </div>
        );
    }

    private renderPager(): JSX.Element | null {
        const { searchResult: { data } } = this.props;
        if (!data) {
            return null;
        }

        const { page } = this.state;
        const { foundItems: { total } } = data;
        if (total <= DEMO_SEARCH_PAGE_SIZE) {
            return null;
        }

        return (
            <div className="demo-page-search-mode__pager">
                <Pager currentPage={page}
                       pageCount={Math.ceil(total / DEMO_SEARCH_PAGE_SIZE)}
                       maxSize={5}
                       onChangePage={this.handlePageChange}/>
            </div>
        );
    }

    private isItemPinned(item: Item): boolean {
        const { searchResult: { data } } = this.props;
        if (!data) {
            return false;
        }

        return data.pinnedItems.some((other) => other.id === item.id);
    }

    @memoize
    private getEditItemHandler(item: Item): () => void {
        return () => {
            const { onEditItem } = this.props;
            onEditItem(item);
        };
    }

    @memoize
    private getPinItemHandler(item: Item): () => void {
        return () => {
            const { onPinItem } = this.props;
            onPinItem(item);
        };
    }

    @memoize
    private getUnpinItemHandler(item: Item): () => void {
        return () => {
            const { onUnpinItem } = this.props;
            onUnpinItem(item);
        };
    }

    @bind
    private handleSearchChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({ query: event.target.value }, this.updateSearchDebounce);
    }

    @debounce(500)
    private updateSearchDebounce(): void {
        this.setState({ page: 1 }, this.updateResultFromState);
    }

    @bind
    private handlePageChange(page: UnsignedInteger): void {
        this.setState({ page }, this.updateResultFromState);
    }

    @bind
    private updateResultFromState(): void {
        const { query, page } = this.state;
        const { updateResult } = this.props;
        updateResult({ query, page });
    }

    @bind
    private handleSaveClick(): void {
        const { onLeavePinMode, searchResult, onDeleteKeyPhrase } = this.props;
        const pinnedItems = searchResult.data
          ? searchResult.data.pinnedItems
          : [];
        const keyphrase: KeyPhrase | null = searchResult.data
            ? searchResult.data.keyPhrase
            : null;
        const keyPhrasePinnedItems = keyphrase && keyphrase.itemIds
            ? keyphrase.itemIds
            : [];

        if (pinnedItems.length === 0 && keyPhrasePinnedItems.length) {
            onDeleteKeyPhrase();
            return;
        }

        onLeavePinMode('save');
    }

    @bind
    private handleCancelClick(): void {
        const { onLeavePinMode } = this.props;
        onLeavePinMode('cancel');
    }

    private canSave(): boolean {
        const { searchResult: { data } } = this.props;
        return data
            ? data.pinnedItems.length > 0
            : false;
    }

    private canDelete(): boolean {
        const { searchResult: { data } } = this.props;
        return data && data.keyPhrase
            ? data.keyPhrase.itemIds.length > 0 && data.pinnedItems.length === 0
            : false;
    }
}
