import angular from 'angular';

(function () {
    angular.module('ngConfirmClick', []).directive('ngConfirmClick', [
        function () {
            return {
                link(scope, element, attr) {
                    const msg = attr.ngConfirmClick || 'Are you sure?';
                    const clickAction = attr.ngConfirmedClick;
                    element.bind('click', (event) => {
                        if (window.confirm(msg)) {
                            scope.$eval(clickAction);
                        }
                    });
                },
            };
        },
    ]);
}());
