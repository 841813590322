export const DEFAULT_HTML_TEMPLATE_LAYOUT = `
<div class="search-widget__wrapper">
    <input type="text" class="search-widget__input"/>
    <smart-search-widget-suggest class="search-widget__suggest"></smart-search-widget-suggest>
    <smart-search-widget class="search-widget__result"></smart-search-widget>
</div>
`.trim();

export const DEFAULT_HTML_TEMPLATE_RESULT = `
<div class="search-widget__item">
    <div class="search-widget__item-title">
        <a class="search-widget__item-link" href="<%- item.url %>" target="_blank"><%- item.title %></a>
    </div>
    <div class="search-widget__item-description">
        <%= item.description %>
    </div>
</div>
`.trim();

export const DEFAULT_HTML_TEMPLATE_NO_RESULT = `
<div class="search-widget__empty">
    <div class="search-widget__empty-title">Ooops</div>
    <div class="search-widget__empty-text">There are no results for <b>"<%- query %>"</b></div>
</div>
`.trim();

export const DEFAULT_HTML_TEMPLATE_EMPTY = '<% %>';
