import {
    IHttpPromise,
    IHttpResponse,
    IHttpService,
    IPromise,
    IQService,
    IRequestConfig,
    IRequestShortcutConfig,
} from 'angular';
import { bind } from 'lodash-decorators/bind';
import { HttpError } from 'src/errors/HttpError';

export interface IApiService {
    makeGETRequest<TResponse>(
        url: string,
        config?: Readonly<IRequestShortcutConfig>,
    ): IHttpPromise<TResponse>;

    makePOSTRequest<TRequest, TResponse>(
        url: string,
        data: TRequest,
        config?: Readonly<IRequestShortcutConfig>,
    ): IHttpPromise<TResponse>;

    makePUTRequest<TRequest, TResponse>(
        url: string,
        data: TRequest,
        config?: Readonly<IRequestShortcutConfig>,
    ): IHttpPromise<TResponse>;

    makeDELETERequest<TResponse>(
        url: string,
        config?: Readonly<IRequestShortcutConfig>,
    ): IHttpPromise<TResponse>;
}

export class ApiService implements IApiService {
    public static $inject = [
        '$q',
        '$http',
    ];
    public constructor(
        private $q: IQService,
        private $http: IHttpService,
    ) {
    }

    public makeGETRequest<TResponse>(
        url: string,
        config?: Readonly<IRequestShortcutConfig>,
    ): IHttpPromise<TResponse> {
        return this.makeRequest({ ...config, url, method: 'GET' });
    }

    public makePOSTRequest<TRequest, TResponse>(
        url: string,
        data: TRequest,
        config?: Readonly<IRequestShortcutConfig>,
    ): IHttpPromise<TResponse> {
        return this.makeRequest({ ...config, url, data, method: 'POST' });
    }

    public makePUTRequest<TRequest, TResponse>(
        url: string,
        data: TRequest,
        config?: Readonly<IRequestShortcutConfig>,
    ): IHttpPromise<TResponse> {
        return this.makeRequest({ ...config, url, data, method: 'PUT' });
    }

    public makeDELETERequest<TResponse>(
        url: string,
        config?: Readonly<IRequestShortcutConfig>,
    ): IHttpPromise<TResponse> {
        return this.makeRequest({ ...config, url, method: 'DELETE' });
    }

    @bind
    private makeRequest<T>(config: IRequestConfig): IHttpPromise<T> {
        return this.$http<T>(config).catch(this.handleError);
    }

    @bind
    private handleError(response: IHttpResponse<any>): IPromise<never> {
        return this.$q.reject(new HttpError(response));
    }
}
