import * as React from 'react';
import { ListWithTotal } from 'src/types/dto/ListWithTotal';
import { CrawlerLog } from 'src/types/entities/CrawlerLog';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import { Pager } from 'src/react/common/components/Pager';
import { UnsignedInteger } from 'src/types/values/UnsignedInteger';
import { CRAWLER_LOG_PAGE_SIZE } from 'src/react/crawlerLog/constants/CrawlerLogPageSize';
import { bind } from 'lodash-decorators';
import { CrawlerTask } from 'src/types/entities/CrawlerTask';
import { DateFormat } from 'src/react/common/components/DateFormat';
import 'src/react/crawlerLog/components/CrawlerLogPage.scss';
import emptyStateIcon from 'src/images/empty-state-icon__keyphrase.svg';
import { CrawlerStatus } from 'src/types/values/CrawlerStatus';
import { TextSearch } from 'src/react/common/components/TextSearch';

type Props = {
    readonly crawlerLog: ListWithTotal<CrawlerLog> | null;
    readonly crawlerTask: CrawlerTask | null;
    readonly page: number;
    readonly query: string;
    readonly onPageChange: (page: UnsignedInteger) => void;
    readonly onSearchChange: (event: string) => void;
} & InjectedIntlProps;

type State = {
    readonly query: string
};

export class CrawlerLogPage extends React.Component<Props, State> {
    public state: State = {
        query: this.props.query,
    };

    public render(): JSX.Element {
        const { crawlerLog, page, crawlerTask } = this.props;
        const { query } = this.state;
        return (
            <div className="settings-page">
                <div className="top-block">
                    <div className="top-block__left">
                        <h1><FormattedMessage id="crawlerlog_title" /></h1>
                        <span className="top-block__subtitle">
                            <FormattedMessage id={'crawlerlog_subtitle'} />
                        </span>
                    </div>
                    <div className="top-block__right">
                        <FormattedMessage id={'crawlerlog_search_placeholder'}>
                            {(message) => (
                                <TextSearch value={query}
                                            autoFocus={true}
                                            placeholder={message.toString()}
                                            onChange={this.onSearchChange}/>
                            )}
                        </FormattedMessage>
                    </div>
                </div>
                {crawlerTask ?
                    <>
                        <div className="crawler-log__status_block">
                            <div className="crawler-log__status_block--left"
                                 title={this.renderCrawlerStatusInfo(crawlerTask)}>
                                <strong>
                                    {this.renderCrawlerStatus(crawlerTask)}
                                </strong>
                            </div>
                            <div className="crawler-log__status_block--right">
                                {crawlerTask.finishedAt ? <DateFormat date={crawlerTask.finishedAt}/> : ''}
                            </div>
                        </div>
                        {crawlerLog ?
                            <>
                                {this.renderTableData(crawlerLog)}
                                {this.renderPager(crawlerLog, page)}
                            </>
                        : '' }
                    </>
                :  this.renderNoDataMessage() }

            </div>
        );
    }

    private renderTableData(list: ListWithTotal<CrawlerLog>): JSX.Element {
        return (
            <div className="table-wrapper">
                <table className="table">
                    <thead>
                    <tr>
                        <th><FormattedMessage id="crawlerlog_table_url" /></th>
                        <th><FormattedMessage id="crawlerlog_table_type" /></th>
                        <th><FormattedMessage id="crawlerlog_table_time" /></th>
                    </tr>
                    </thead>
                    <tbody>
                    {list.data.map(({ id, url, createdAt, action }) => (
                        <tr key={id}>
                            <td>
                                <a href={url} className="table-link" target="_blank"
                                   rel="nofollow noopener">
                                    {url}
                                </a>
                            </td>
                            <td><FormattedMessage id="crawlerlog_action" values={{ action }} /></td>
                            <td><DateFormat date={createdAt}  /></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    }

    private renderPager(crawlerData: ListWithTotal<CrawlerLog>, page: number): JSX.Element | null {
        const { onPageChange } = this.props;
        const { total } = crawlerData;
        if (!total) {
            return null;
        }

        return (
            <div className="statistics-page__pager">
                <Pager currentPage={page}
                       pageCount={Math.ceil(total / CRAWLER_LOG_PAGE_SIZE)}
                       maxSize={CRAWLER_LOG_PAGE_SIZE}
                       onChangePage={onPageChange} />
            </div>
        );
    }

    @bind
    private onSearchChange (event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            query: event.target.value
        }, () => this.props.onSearchChange(this.state.query));
    }

    private renderCrawlerStatusInfo({
        total,
        processed,
        downloaded,
        queued,
        redirected,
        notfound,
        noindex,
        failed
    }: CrawlerTask): string {
        const { intl } = this.props;
        return intl.formatMessage({ id: 'crawlerlog_status_info' }, {
            total,
            processed,
            downloaded,
            queued,
            redirected,
            notfound,
            noindex,
            failed,
        });
    }

    private renderCrawlerStatus(task: CrawlerTask): JSX.Element {
        const { processed: count, total, status } = task;
        return (
            <>
                <FormattedMessage id="crawlerlog_status" values={{ status, count, total }} />
                {' '}
                {this.getStatusIcon(status)}
            </>
        );
    }

    private getStatusIcon(status: CrawlerStatus): JSX.Element {
        switch (status) {
            case CrawlerStatus.Pending:
            case CrawlerStatus.Running:
                return <i className="si si-Success widget-card__status--in" />;
            case CrawlerStatus.Success:
            case CrawlerStatus.Aborted:
                return <i className="si si-Success widget-card__status--ok" />;
            case CrawlerStatus.Failure:
                return <i className="si si-Fail widget-card__status--fail" />;
        }
    }

    private renderNoDataMessage(): JSX.Element {
        return (
            <div className="table-empty-state">
                <div className="crawler-log__empty-state-image"
                     style={{ backgroundImage: `url(${emptyStateIcon})` }} />
                <p>
                    <FormattedMessage id="crawlerlog_no_data" />
                </p>
            </div>
        );
    }
}
