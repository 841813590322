import { BaseError } from 'src/errors/BaseError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { FieldErrors } from 'src/types/values/FieldError';

export class ValidationError extends RuntimeError {
    public constructor(
        public readonly errors: FieldErrors,
        context?: object,
        previous?: BaseError,
    ) {
        super('Validation failed', context, previous);
    }

    public get name(): string {
        return 'ValidationError';
    }

    public toJSON(): object {
        return {
            ...super.toJSON(),
            errors: this.errors,
        };
    }
}
